import { createAsyncThunk } from "@reduxjs/toolkit";

import { projectService } from "@services";
import { getError } from "@store/getError";

export const getListProject = createAsyncThunk("project/getListProject", async (name: string, thunkAPI) => {
  try {
    const response = await projectService.getList(name);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

// export const getSingleProject: any = async (id: string) => {
//   try {
//     const response = await projectService.getProject(id);
//     return response;
//   } catch (error: any) {
//     return getError(error, tg);
//   }
// };
