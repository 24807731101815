import React, { Dispatch, FC, useState, SetStateAction, useEffect, useMemo } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { styles as btnStyles } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/styles";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import globalState from "@utils/globalState";
import { isTablet } from "@utils/isTablet";

import { EventCommentsTypes } from "./EventComments";

interface IProps {
  isVisible: boolean;
  eventType: EventCommentsTypes;
  editCommentId?: string;
  editCommentText?: string;
  onSave: (comment: string, commentId?: string) => void;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const CommentEditorModal: FC<IProps> = ({ isVisible, editCommentId, editCommentText, onSave, setIsVisible }) => {
  const [comment, setComment] = useState("");
  const inputPlaceholder = useIntl().formatMessage({ id: "comment" });
  const isEditMode = useMemo(() => Boolean(editCommentId), [editCommentId]);
  const isSaveButtonDisabled = useMemo(() => !comment.length || comment === editCommentText, [comment, editCommentText]);
  const isLoading = false;
  const styles = style();

  const handlePressSave = () => {
    onSave(comment, editCommentId);
    setIsVisible(false);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    editCommentText && setComment(editCommentText);
  }, [editCommentText]);

  return (
    <ModalUI isVisible={isVisible} onClose={hideModal}>
      <RenderWithCondition condition={isTablet}>
        <HeaderModal title="comment" isCancel={false} onClose={hideModal} />
      </RenderWithCondition>
      <div style={styles.container}>
        <div>
          <textarea
            style={styles.input}
            placeholder={inputPlaceholder}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            autoFocus={true}
            maxLength={255}
          />
          <button
            disabled={isSaveButtonDisabled}
            style={{
              ...{ marginBottom: 20, flex: 0, backgroundColor: "#297952" },
              ...btnStyles.button,
              ...btnStyles.buttonSave,
              ...(isSaveButtonDisabled && { backgroundColor: "#E3E9E7" }),
            }}
            onClick={handlePressSave}
          >
            <LoaderButtonRenderWithCondition condition={isLoading}>
              <TextFont style={btnStyles.buttonText}>
                <FormattedMessage id={isEditMode ? "save" : "add"} />
              </TextFont>
            </LoaderButtonRenderWithCondition>
          </button>
        </div>
      </div>
    </ModalUI>
  );
};

const style = () => {
  const { colorSchema } = globalState;

  return {
    container: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 17,
      paddingRight: 17,
    },
    input: {
      backgroundColor: colorSchema.white,
      borderRadius: 9,
      padding: 16,
      border: `1px solid ${colorSchema.grey}`,
      fontSize: 18,
      width: "100%",
      minHeight: 150,
      marginTop: 8,
      marginBottom: 8,
      paddingTop: 16,
      paddingLeft: 8,
      paddingRight: 8,
      "-webkit-box-sizing": "border-box",
      "-moz-box-sizing": "border-box",
      "box-sizing": "border-box",
    },
    taskDescription: {
      paddingTop: 16,
    },
  };
};
