import { IGetMeetingSeriesResponse } from "@store/attachedMeetingTasks/api";
import { IMeetingSeries } from "@store/attachedMeetingTasks/slice";

export const sortMeetingsSeries = (meetingsSeries: IGetMeetingSeriesResponse) => {
  const sortedObject: IMeetingSeries[] = [];
  const dates = Object.keys(meetingsSeries);

  dates.sort();

  for (const date of dates) {
    if (meetingsSeries[date].length) {
      sortedObject.push({ day: date, data: meetingsSeries[date] });
    }
  }

  return sortedObject;
};
