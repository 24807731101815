import React, { FC, lazy, Suspense, CSSProperties } from "react";

import { Facebook } from "react-content-loader";

import { userAvatarStyles } from "./styles";

const MyFacebookLoader = () => <Facebook style={userAvatarStyles.userImage} />;

interface IUserAvatar {
  avatarFileId: string | null;
  style?: CSSProperties;
}

const LazyAvatar = lazy(() => import("./LazyAvatar"));

export const UserAvatar: FC<IUserAvatar> = ({ avatarFileId, style }) => (
  <Suspense fallback={<MyFacebookLoader />}>
    <LazyAvatar avatarFileId={avatarFileId} style={style} />
  </Suspense>
);
