import React from "react";

import { TypeKeysPersonalGoal } from "@interfaces/personalGoals.interface";

import { configureIcons } from "./config";

export interface IIcon {
  title: TypeKeysPersonalGoal;
  type: "complete" | "active" | "inactive";
}

export const Icon = ({ title, type }: IIcon) => <div style={{ marginRight: 5 }}>{configureIcons({ title, type })}</div>;
