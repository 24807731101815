import React, { Dispatch, useMemo } from "react";

import dayjs from "dayjs";
import { isObject } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { ITheme } from "@interfaces/index";
import { IStateUser } from "@interfaces/user.interface";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectUserPermissoins } from "@store/user";
import { TextFont, HeaderModal } from "@ui";
import globalState from "@utils/globalState";

import { TypeTab } from "../../types";

import { IData, data } from "./config";

interface IProps {
  onClose: () => void;
  setTab?: Dispatch<TypeTab>;
  user: IStateUser;
  isClose?: boolean;
}

export const UserInformation = ({ onClose, setTab, user, isClose = true }: IProps) => {
  const { isTablet } = useDimension();

  const permissons = useSelector(selectUserPermissoins);

  const renderText = (element: IData) => {
    let text: string | undefined = user[element.value];
    if (element.custom) text = customValue[element.value];

    if (isObject(text)) text = undefined;

    if (element.isLink) {
      return text ? (
        <a href={`mailto:${text}`}>
          <TextFont style={styles.textLink}>{text}</TextFont>
        </a>
      ) : (
        <TextFont style={styles.subText}>не заполнено</TextFont>
      );
    }
    return <TextFont style={styles.subText}>{text ? text : "не заполнено"}</TextFont>;
  };

  const pressSend = () => {
    if (setTab) {
      setTab("bugreport");
    }
  };

  const birthDay = useMemo(() => (user.birthDay ? dayjs(user.birthDay).format("DD MMMM") : undefined), [user.birthDay]);
  const gender = useMemo(() => {
    switch (user.gender) {
      case "MALE":
        return "Мужской";
      case "FEMALE":
        return "Женский";
      default:
        return undefined;
    }
  }, [user.gender]);

  const customValue: Partial<Record<keyof IStateUser, string | undefined>> = useMemo(
    () => ({
      birthDay,
      gender,
      headOfUser: user.headOfUser ? `${user.headOfUser?.firstName} ${user.headOfUser?.lastName}` : undefined,
      department: user.department?.name,
    }),
    [birthDay, gender, user.headOfUser, user.department]
  );

  const styles = style(globalState.colorSchema);

  return (
    <section style={styles.container}>
      <HeaderModal
        title="detail"
        isEdit={false}
        onClose={onClose}
        isClose={isClose}
        style={{ backgroundColor: globalState.colorSchema.disabled }}
      />
      <div style={styles.infoContainer}>
        <div style={styles.infoHead}>
          <RenderWithCondition condition={permissons.VIEW_FULL_PROFILE}>
            <UserAvatar avatarFileId={user.avatarFileId} style={styles.profileImage} />
          </RenderWithCondition>
          <div style={styles.headMain}>
            <TextFont style={styles.textName}>
              {`${user?.firstName ?? ""} ${user?.middleName ? user.middleName : " "} ${user?.lastName ?? ""}`}
            </TextFont>
            <TextFont style={styles.textPhone}>{user?.login}</TextFont>
          </div>
        </div>

        {data.map((element) => (
          <div key={element.title} style={styles.plainContainer}>
            <TextFont style={styles.text}>
              <FormattedMessage id={element.title} />
            </TextFont>
            {renderText(element)}
          </div>
        ))}

        <RenderWithCondition condition={setTab}>
          <button onClick={pressSend} style={styles.buttonSend}>
            <TextFont style={styles.sendText}>
              <FormattedMessage id="Сообщить об ошибке" />
            </TextFont>
          </button>
        </RenderWithCondition>
      </div>
    </section>
  );
};

const style = (Colors: ITheme.IColors) => ({
  container: {
    flex: 1,
  },
  infoContainer: {
    padding: "10px 12px 0 12px",
    paddingTop: 10,
    overflow: "auto",
  },
  infoHead: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  profileImage: {
    width: 95,
    height: 95,
    marginBottom: 10,
    borderRadius: 95,
    border: `3px solid ${Colors.white}`,
  },
  headMain: {
    marginLeft: 15,
    maxWidth: "70%",
  },
  textName: {
    fontWeight: "700",
    fontSize: 20,
    lineHeight: "28px",
  },
  textPhone: {
    fontSize: 16,
    lineHeight: "24px",
  },
  plainContainer: {
    marginBottom: 15,
  },
  text: {
    fontSize: 14,
    lineHeight: "22px",
    color: Colors.grey500,
  },
  textLink: {
    color: Colors.blue,
  },
  subText: {
    fontSize: 16,
    lineHeight: "24px",
  },
  buttonSend: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 56,
    borderRadius: 10,
    backgroundColor: Colors.background.green,
    marginBottom: 20,
  },
  sendText: {
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: "24px",
    color: Colors.white,
  },
});
