import React, { useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { RenderWithCondition } from "@hoc";
import { INote } from "@interfaces/note.interface";
import { deleteNote, updateNote } from "@store/note";
import { TextFont, ArrowSvg, ContentEditable } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDayTime } from "@utils/toFormatTime";

interface IProps extends INote {
  index: number;
  backgroundColor?: string;
}

export const Note = ({ id, content, modifiedDate, createdDate, index, backgroundColor = "#F5F9F8" }: IProps) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(content);

  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleOutsidePress = () => {
    if (title && title !== content) {
      handleEdit();
    }
  };

  const handleDelete = useCallback(() => {
    dispatch(deleteNote(id));
  }, [id]);

  const handleEdit = async () => {
    await dispatch(updateNote({ id, data: { content: title } }));
  };

  const isVisibleModified = useMemo(() => dayjs(modifiedDate).isAfter(createdDate), [modifiedDate, createdDate]);

  return (
    <section style={{ ...styles.container, backgroundColor }}>
      <button style={{ width: "100%" }} onClick={toggleOpen}>
        <div style={styles.header}>
          <TextFont style={styles.title}>{content}</TextFont>
          <div style={styles.rightSide}>
            {isOpen && (
              <button onClick={handleDelete}>
                <SvgDelete height={21} fill="#C7C7C7" />
              </button>
            )}
            <ArrowSvg type={isOpen ? "up" : "down"} fill={"#C7C7C7"} style={styles.arrow} />
          </div>
        </div>
        <div style={styles.dateWrap}>
          <TextFont style={styles.date}>
            <FormattedMessage id="noteCreateDate" />
            {` ${toFormatDayTime(dayjs(createdDate))}`}
          </TextFont>
          <RenderWithCondition condition={isVisibleModified}>
            <TextFont style={{ ...styles.date, ...styles.dateChanged }}>
              <FormattedMessage id="noteModificationDate" />
              {` ${toFormatDayTime(dayjs(modifiedDate))}`}
            </TextFont>
          </RenderWithCondition>
        </div>
      </button>
      {isOpen && <ContentEditable value={title} onChange={setTitle} onBlur={handleOutsidePress} style={styles.input} />}
    </section>
  );
};

const styles = {
  container: {
    width: "100%",
    borderBottom: "1px solid #E3E9E7",
    marginBottom: "8px",
    paddingBottom: "12px",
  },
  active: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 2,
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    width: "70%",
    "text-align": "left",
    textOverflow: "ellipsis",
    "white-space": "pre-line",
    overflow: "hidden",
    height: 24,
  },
  rightSide: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrow: {
    marginLeft: "16px",
  },
  dateWrap: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  date: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: globalState.colorSchema.grey,
  },
  input: {
    color: globalState.colorSchema.text.main,
    fontSize: "18px",
    width: "95%",
    padding: "0 6px",
    outline: "none",
  },
  dateChanged: {
    marginLeft: "24px",
  },
  text: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    marginTop: "14px",
  },
};
