import { createAsyncThunk } from '@reduxjs/toolkit';
import { TCreateExternalUser, meetService } from '@services/meet.service';
import { getError } from '@store/getError';

export const createExternalUser: any = createAsyncThunk(
  'screenDay/createExternalUser',
  async (data: TCreateExternalUser, thunkAPI) => {
    try {
      const response = await meetService.createExternalParticipant(data);

      return response.data;
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getExternalUsers: any = createAsyncThunk(
  'screenDay/getExternalUser',
  async (searchQuery: string, thunkAPI) => {
    try {
      const response = await meetService.getExternalParticipants(searchQuery);

      return response.data;
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);
