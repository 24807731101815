import React, { useEffect, useMemo, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual, xorWith } from "lodash";
import { useIntl } from "react-intl";

import { RenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { Notification } from "@ui/notification/Notification";
import { showToast } from "@utils/toast";

// eslint-disable-next-line import/no-useless-path-segments
import { TimeDay } from "../../../components/screens/day/components/ListTasks/Modal/components/ViewNotSave/components/time/TimeDay";

import { IPlanningWork } from "./types";

import { Calendar, ListTime } from ".";

export const PlanningWork = ({
  isVisible,
  setIsVisible,
  isTime = true,
  startDays = [],
  tempObject,
  onSave,
  selectedWorkDay,
  isMultiple = true,
  startDate,
  keyTitle,
  disable = { button: true, style: true },
  closeAfterPress = true,
  deadline,
  timeEnabled,
  handleData,
  disableAddTimeToggle = false,
}: IPlanningWork) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [days, setDays] = useState<WorkDay[]>(startDays);
  const [localTimeEnabled, setLocalTimeEnabled] = useState(!!timeEnabled);
  const msgCancelExeption = useIntl().formatMessage({ id: "cancelException" });
  const title = useIntl().formatMessage({ id: keyTitle });
  const dayNow = useRef(dayjs());
  const workDay = useMemo(() => days?.filter((i) => i.date === dayNow.current.format("YYYY-MM-DD"))[0], [days]);

  useEffect(() => {
    setIsEdit((Boolean(xorWith(days, startDays, isEqual).length) && (isMultiple || isTime)) || timeEnabled !== localTimeEnabled);
  }, [days, localTimeEnabled]);

  const handlePress = (dateWeek: Dayjs, isDisable: boolean) => {
    if (isDisable && disable?.press) {
      return disable.press();
    }

    const isSelect = !!days.find((i: WorkDay) => i.date === dateWeek.format("YYYY-MM-DD"));

    if (isSelect) {
      if (isMultiple) handleRemoveDay(dateWeek);
    } else {
      if (isMultiple) handleAddDay(dateWeek);
      else handleReplaceDay(dateWeek);
    }
  };

  const handleReplaceDay = (date: Dayjs) => {
    const tempDays = [{ ...days[0], date: date.format("YYYY-MM-DD") }];

    if (isTime) {
      setDays(tempDays);
    } else {
      handleSave(tempDays);
    }
  };

  const handleAddDay = (date: Dayjs) => {
    const index = startDays.findIndex((i) => i.date === date.format("YYYY-MM-DD"));

    if (dayjs(date).isBefore(dayjs(), "date")) {
      showToast("pastDateSelected");
    }

    if (index === -1) {
      setDays([...days, { ...tempObject, date: date.format("YYYY-MM-DD") } as WorkDay]);
    } else {
      setDays([...days, startDays[index]]);
    }
  };

  const handleRemoveDay = (date: Dayjs) => {
    setDays([...days.filter((i: WorkDay) => i.date !== date.format("YYYY-MM-DD"))]);
  };

  const handleSave = (days: WorkDay[]) => {
    handleData?.("timeEnabled", localTimeEnabled);
    onSave(days, localTimeEnabled);
    if (closeAfterPress) {
      setIsVisible(false);
    }
  };

  const handleCancel = () => {
    setDays(startDays ?? []);
    setIsCancel(false);
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <ModalUI isVisible={isVisible} onClose={() => (isEdit ? setIsCancel(true) : handleClose())} scrollEnable={false}>
      <HeaderModal title={title} onClose={handleClose} onSave={() => handleSave(days)} isEdit={isEdit} onCancel={() => setIsCancel(true)} />
      <div style={{ overflow: "auto", height: "95%" }}>
        <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 34, marginBottom: 20 }}>
          <Calendar
            days={days}
            currentDay={days[0]?.date ?? startDate}
            startDay={startDate}
            press={handlePress}
            type={isMultiple ? "multituple" : "default"}
            selectedWorkDay={selectedWorkDay}
            disable={disable}
            deadline={deadline}
          />

          <RenderWithCondition condition={!disableAddTimeToggle}>
            <TimeDay
              workDay={workDay}
              dateNow={dayNow.current}
              workDays={days}
              setWorkDays={setDays}
              timeEnabled={localTimeEnabled}
              setTimeEnabled={setLocalTimeEnabled}
            />
          </RenderWithCondition>

          <RenderWithCondition condition={localTimeEnabled}>
            <ListTime days={days} setDays={setDays} />
          </RenderWithCondition>
        </div>
      </div>
      <RenderWithCondition condition={isCancel}>
        <Notification type="confirm" phraseId={msgCancelExeption} onOk={handleCancel} onCancel={() => setIsCancel(false)} />
      </RenderWithCondition>
    </ModalUI>
  );
};
