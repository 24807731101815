import React, { CSSProperties, HTMLAttributes } from "react";

import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";

import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import globalState from "@utils/globalState";

import { TextFont } from "../TextFont";

interface IHeaderModal extends HTMLAttributes<HTMLDivElement> {
  title: string;
  titleSave?: string;
  isEdit?: boolean;
  isClose?: boolean;
  isSave?: boolean;
  onClose: (...arg: unknown[]) => void;
  onCancel?: (...arg: unknown[]) => void;
  onSave?: (...arg: unknown[]) => void;
  isCancel?: boolean;
  isLoading?: boolean;
  isBorder?: boolean;
  styleTextSave?: CSSProperties;
  header?: JSX.Element | null;
  saveElement?: JSX.Element;
  closeElement?: JSX.Element;
  titleClose?: string;
}

export const HeaderModal = ({
  title,
  isCancel = true,
  isClose = true,
  isEdit = false,
  isSave = true,
  titleSave = "save",
  onClose,
  onSave,
  isLoading = false,
  onCancel,
  isBorder = true,
  styleTextSave,
  header,
  saveElement,
  closeElement,
  titleClose = "close",
  ...props
}: IHeaderModal) => {
  const view = (
    <Modal isBorder={isBorder} style={{ ...props.style, paddingTop: 20 }}>
      {!isEdit || !isCancel ? (
        <RenderWithCondition elseComponent={<div style={styles.leftBlock} />} condition={isClose}>
          {closeElement ?? (
            <button onClick={onClose} style={styles.leftBlock}>
              <TextFont weight="400" style={styles.title}>
                <FormattedMessage id={titleClose} />
              </TextFont>
            </button>
          )}
        </RenderWithCondition>
      ) : (
        <button onClick={onCancel} style={styles.leftBlock}>
          <TextFont weight="400" style={styles.title}>
            <FormattedMessage id="cancel" />
          </TextFont>
        </button>
      )}
      <TextFont weight="700" style={{ ...styles.title, color: globalState.colorSchema.text.main }}>
        <FormattedMessage id={title} />
      </TextFont>

      {isEdit && isSave ? (
        saveElement ?? (
          <div style={styles.rightBlock}>
            <LoaderButtonRenderWithCondition condition={isLoading}>
              <button onClick={onSave}>
                <TextFont style={{ ...styles.title, ...styleTextSave }}>
                  <FormattedMessage id={titleSave} />
                </TextFont>
              </button>
            </LoaderButtonRenderWithCondition>
          </div>
        )
      ) : (
        <div style={styles.rightBlock} />
      )}
    </Modal>
  );

  return header ?? view;
};

const Modal = styled.div<{ isBorder: boolean }>`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  border-bottom-width: ${(props) => (props.isBorder ? "0.5px" : 0)};
  border-bottom-color: rgba(0, 0, 0, 0.3);
  border-bottom-style: solid;
  padding-top: 9px;
  padding-bottom: 9px;
`;

const styles = {
  block: {
    "text-align": "center",
  },
  leftBlock: {
    "text-align": "center",
    justifySelf: "center",
  },
  rightBlock: {
    "text-align": "center",
  },
  title: {
    flex: 1.5,
    "text-align": "center",
    fontSize: "16px",
  },
};
