import { ITask } from "@interfaces/businessGoals.interface";
import { IPersonalTask } from "@interfaces/personalTasks.interface";

import { fieldsToExcludeForPersonalTask } from "./constants";

export const buildPersonalTaskDataBody = (data: ITask): IPersonalTask => {
  const personalTaskData = { ...data } as IPersonalTask;

  for (const field of fieldsToExcludeForPersonalTask) {
    delete personalTaskData[field as keyof IPersonalTask];
  }

  return personalTaskData;
};
