import React from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgCreate } from "@assets/create.svg";
import { selectTheme } from "@store/theme";
import { TextFont } from "@ui/TextFont";
import { myTracker } from "@utils/myTracker";

interface IProps {
  press: () => void;
  titleTextId?: string;
}

export const EmptyBusinessTasks = ({ titleTextId, press }: IProps) => {
  const theme = selectTheme("extra");

  return (
    <aside style={styles.container}>
      <div style={styles.block}>
        <button
          style={styles.button}
          onClick={() => {
            myTracker("CreateTaskOnBusinessGoalClick");
            press();
          }}
        >
          <TextFont style={styles.textNoTask}>
            <FormattedMessage id={titleTextId ? titleTextId : "noTask"} />
          </TextFont>

          <SvgCreate fill={theme.background.green} width={64} height={64} style={styles.iconCreate} />
          <TextFont style={{ ...styles.textAdd, color: theme.text.main }}>
            <FormattedMessage id="add" />
          </TextFont>
        </button>
      </div>
    </aside>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    width: "100%",
    height: "90%",
    alignItems: "center",
    justifyContent: "center",
  },
  block: {
    display: "flex",
    "flex-direction": "column",
    width: "85%",
    height: "60%",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    display: "flex",
    "flex-direction": "column",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  iconCreate: {
    marginTop: "11px",
    marginBottom: "4px",
  },
  textAdd: {
    fontSize: "14px",
  },
  textNoTask: {
    color: "#5F6E67",
    fontSize: "16px",
  },
};
