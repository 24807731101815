import { IMeetParticipants, IRestoredAccessPickerState } from "@interfaces/meet.interface";

export const isArraysOfObjectsDiffer = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]?.id !== arr2[i]?.id) return true;
  }

  return false;
};

export type TStringUnknownTupleArray = [string, unknown[]][];

export function isStringUnknownTupleArray(arg: unknown): arg is TStringUnknownTupleArray {
  if (!Array.isArray(arg)) {
    return false;
  }

  for (const tuple of arg) {
    if (!Array.isArray(tuple) || tuple.length !== 2 || typeof tuple[0] !== "string" || !Array.isArray(tuple[1])) {
      return false;
    }
  }

  return true;
}

export function restoreAccessRightsPickerState(participants: IMeetParticipants[], isEditMode = false): IRestoredAccessPickerState {
  const restoredState: IRestoredAccessPickerState = {
    isCanEditAllChecked: false,
    isCanEditSomeChecked: false,
    isCanInviteAllChecked: !isEditMode,
    isCanInviteSomeChecked: false,
    usersWithEditPermission: [],
    usersWithInvitePermission: [],
  };

  const canEditAndInvite = [];
  const canOnlyInvite = [];
  const noAccess = [];

  for (const user of participants) {
    if (user.status !== "OWNER" && user.permissions?.includes("ADD_PARTICIPANTS") && user.permissions?.includes("EDIT_MEETING")) {
      canEditAndInvite.push(user.user);
    }

    if (user.status !== "OWNER" && user.permissions?.includes("ADD_PARTICIPANTS") && !user.permissions?.includes("EDIT_MEETING")) {
      canOnlyInvite.push(user.user);
    }

    if (user.status !== "OWNER" && !user.permissions?.length) {
      noAccess.push(user.user);
    }
  }

  // State 10-10
  if (canEditAndInvite.length && !canOnlyInvite.length && !noAccess.length) {
    restoredState.isCanEditAllChecked = true;
    restoredState.isCanInviteAllChecked = true;
  }

  // State 01-10
  if (canEditAndInvite.length && canOnlyInvite.length && !noAccess.length) {
    restoredState.isCanEditSomeChecked = true;
    restoredState.isCanInviteAllChecked = true;
    restoredState.usersWithEditPermission = canEditAndInvite;
    restoredState.usersWithInvitePermission = canOnlyInvite;
  }

  // State 01-01
  if (canEditAndInvite.length && canOnlyInvite.length && noAccess.length) {
    restoredState.isCanEditSomeChecked = true;
    restoredState.isCanInviteSomeChecked = true;
    restoredState.usersWithEditPermission = canEditAndInvite;
    restoredState.usersWithInvitePermission = canOnlyInvite;
  }

  // State 01-XX
  if (canEditAndInvite.length && !canOnlyInvite.length && noAccess.length) {
    restoredState.isCanEditSomeChecked = true;
    restoredState.usersWithEditPermission = canEditAndInvite;
  }

  // State XX-01
  if (!canEditAndInvite.length && canOnlyInvite.length && noAccess.length) {
    restoredState.isCanInviteSomeChecked = true;
    restoredState.usersWithInvitePermission = canOnlyInvite;
  }

  // State XX-10
  if (!canEditAndInvite.length && canOnlyInvite.length && !noAccess.length) {
    restoredState.isCanInviteAllChecked = true;
  }

  return restoredState;
}
