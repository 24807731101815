import { WeekDaysShortLabels } from "@constants/calendar";
import { IMeetRepeat, TTypeDayOfWeek, TTypePostpone, TTypeRepeat } from "@interfaces/meet.interface";

export interface CommonFields {
  handleData: (name: keyof IMeetRepeat, value: any) => void;
}

export const arrayPostpone: { title: string; value: TTypePostpone }[] = [
  { value: "WITHOUT_CHANGES", title: "withoutСhanges" },
  { value: "FRIDAY_RESCHEDULING", title: "transferToFriday" },
  { value: "MONDAY_RESCHEDULING", title: "transferToMonday" },
  { value: "DELETION", title: "delete" },
];

export const daysOfWeek: Record<TTypeDayOfWeek, WeekDaysShortLabels> = {
  MONDAY: WeekDaysShortLabels.MONDAY,
  TUESDAY: WeekDaysShortLabels.TUESDAY,
  WEDNESDAY: WeekDaysShortLabels.WEDNESDAY,
  THURSDAY: WeekDaysShortLabels.THURSDAY,
  FRIDAY: WeekDaysShortLabels.FRIDAY,
  SATURDAY: WeekDaysShortLabels.SATURDAY,
  SUNDAY: WeekDaysShortLabels.SUNDAY,
};

export const configPeriod: Record<TTypeRepeat, Record<"every" | "max" | "subject", string | number> | null> = {
  WEEK: {
    every: "Каждую",
    max: 52,
    subject: "неделю",
  },
  MONTH: {
    every: "Каждый",
    max: 12,
    subject: "месяц",
  },
  YEAR: null,
};
