import { CSSProperties } from "react";

import { TTypeParticipants } from "@interfaces/meet.interface";

export interface IAttendanceConfirmationProps {
  meetId: string;
  parentId?: string;
  isSerieOrException?: boolean;
  isSeries: boolean;
  currentUserAttendanceStatus: TTypeParticipants;
  participantStatusComment: string;
  isModal?: boolean;
}

export interface IStatusButtonProps {
  status: TTypeParticipants;
  containerStyles?: CSSProperties;
  onPress: (status: TTypeParticipants) => void;
}

export interface IAttendanceOptionsProps {
  onPress: (status: TTypeParticipants) => void;
}

export enum AttendanceStatuses {
  OWNER = "OWNER",
  ATTENDER = "ATTENDER",
  NON_ATTENDER = "NON_ATTENDER",
  MAYBE_ATTENDER = "MAYBE_ATTENDER",
}
