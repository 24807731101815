import React, { FC, useEffect, useState, lazy, Suspense } from "react";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Back } from "@assets/back.svg";
import { ReactComponent as SvgNotAnswerMeets } from "@assets/three_persons.svg";
import { AsyncStorageKeys } from "@constants/asyncStorageKeys";
import { RenderWithCondition } from "@hoc";
import { SwitchShowPersonalTasks } from "@screens/day/components/header/modal/SwitchShowPersonalTasks";
import { useGetUnansweredCountQuery } from "@services/meetApi";
import { SelectRole } from "@shared";
import { TypeDisabled } from "@shared/calendar";
import { selectCalendar } from "@store/calendar";
import { setStartWeekDay, setViewMode, setIsShowUnansweredMeets, CalendarViewModes } from "@store/calendar/slice";
import { selectCurrentDate } from "@store/screenDay";
import { clearCurrentEvent, setCurrentEventContext } from "@store/screenDay/slice";
import { selectTheme, themeSelector } from "@store/theme";
import { Colors } from "@theme/colors";
import { ModalUI } from "@ui/modal/ModalUI";
import { TextFont } from "@ui/TextFont";

import { styles } from "./styles";
import { WeekMonthSwitcher } from "./WeekMonthSwitcher";

const LazyAppCalendar = lazy(() => import("@shared/calendar/AppCalendar"));

interface IModal {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  press?: (...arg: unknown[]) => void;
  typeDisabled?: TypeDisabled;
  onPressDisabled?: () => void;
  isFullWidth?: boolean;
  isOnlyMonthMode?: boolean;
}

export const Modal: FC<IModal> = ({ isVisible, setIsVisible, press, typeDisabled, onPressDisabled, isFullWidth, isOnlyMonthMode }) => {
  const currentDate = useSelector(selectCurrentDate);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const theme = selectTheme("extra");
  const { extra } = themeSelector();
  const dispatch = useDispatch();

  const { data: unansweredCount } = useGetUnansweredCountQuery();
  const { isShowUnansweredMeets } = useSelector(selectCalendar);

  const getCalendarViewMode = () => {
    const savedMode = localStorage.getItem(AsyncStorageKeys.CalendarViewMode);

    if (savedMode === CalendarViewModes.Week) {
      if (isOnlyMonthMode) {
        dispatch(setViewMode(CalendarViewModes.Month));
      } else {
        dispatch(setViewMode(CalendarViewModes.Week));
      }
    }
  };

  useEffect(() => {
    getCalendarViewMode();
  }, []);

  useEffect(
    () => () => {
      dispatch(setStartWeekDay(dayjs().startOf("week")));
    },
    [],
  );

  return (
    <ModalUI isVisible={isVisible} bg="#fff" onClose={() => setIsVisible(false)} isFullWidth={isFullWidth} scrollEnable={false}>
      <header style={styles.header}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <button
            style={styles.back}
            onClick={() => {
              setIsVisible(false);
              dispatch(setCurrentEventContext(null));
              dispatch(clearCurrentEvent());
            }}
          >
            <Back fill={theme.background.green} />
            <TextFont style={{ ...styles.backTitle, color: theme.text.accent }}>Назад</TextFont>
          </button>

          <RenderWithCondition condition={extra === "ASSISTANT"}>
            <SelectRole type="view" />
          </RenderWithCondition>
        </div>
        <div style={styles.rightButtonContainer}>
          {!isOnlyMonthMode && <WeekMonthSwitcher />}
          <SwitchShowPersonalTasks />
          <button
            onClick={() => dispatch(setIsShowUnansweredMeets(!isShowUnansweredMeets))}
            disabled={!unansweredCount}
            style={{
              ...styles.wrapperNotAnswerMeets,
              backgroundColor: unansweredCount ? Colors.LIGHT.lightRed : Colors.LIGHT.lighGrey,
              cursor: unansweredCount ? "pointer" : "default",
            }}
          >
            <SvgNotAnswerMeets fill={unansweredCount ? Colors.LIGHT.red : Colors.LIGHT.grey500} />
            {!!unansweredCount && (
              <TextFont size={14} color={Colors.LIGHT.red}>
                {unansweredCount}
              </TextFont>
            )}
          </button>
        </div>
      </header>
      <div style={{ height: "92%" }}>
        <Suspense fallback={<></>}>
          <LazyAppCalendar
            setIsVisible={setIsVisible}
            press={press}
            typeDisabled={typeDisabled}
            onPressDisabled={onPressDisabled}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        </Suspense>
      </div>
    </ModalUI>
  );
};
