import dayjs from "dayjs";

import { IEventOfDaySimplified, IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { sortingForMinute } from "@utils/sorting";

interface IReturn {
  forming: boolean;
  inset: boolean;
}

export const calculateConfition = (i: IEventsOfDay, j: IEventsOfDay): IReturn => {
  const diffMinutesStartTime = dayjs(i.startTime).diff(j.startTime, "minutes"); // разница времени начала
  // разница времени начала с временем конца
  const diffMinutesDuration = [dayjs(i.startTime).diff(j.endTime, "minutes"), dayjs(i.endTime).diff(j.startTime, "minutes")];

  const crossing = [dayjs(j.endTime).isAfter(i.startTime), dayjs(i.endTime).isAfter(j.startTime)];

  const isDiffStartTime = diffMinutesStartTime >= -45 && diffMinutesStartTime <= 45;
  const isDiffStartOfEnd =
    (diffMinutesDuration[0] >= 15 || diffMinutesDuration[0] <= -15) &&
    crossing[0] &&
    (diffMinutesDuration[1] >= 15 || diffMinutesDuration[1] <= -15) &&
    crossing[1];

  const isInsetI = dayjs(i.startTime).isAfter(j.startTime, "minutes") && dayjs(i.endTime).isSameOrBefore(j.endTime, "minutes");

  const isInsetJ = dayjs(j.startTime).isAfter(i.startTime, "minutes") && dayjs(j.endTime).isSameOrBefore(i.endTime, "minutes");

  return { forming: isDiffStartTime && isDiffStartOfEnd, inset: isInsetI || isInsetJ };
};

export const filterEvents = (eventsOfDay: IEventOfDaySimplified[], allDayLongEvents?: IEventOfDaySimplified[]) => {
  if (!eventsOfDay) return;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const result: Array<any[] | any> = []; // возращаемый массив
  const includeIds: Array<string> = []; // проходимые раннее элементы

  for (let i = 0; i < eventsOfDay.length; i++) {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    let middleResult: any[] = []; // массив элментов, выстраивающихся паралелльно
    let isMiddle = false; // попадает ли элемент в middleResult
    const isInclude = includeIds.includes(eventsOfDay[i].id);

    if (i === eventsOfDay.length - 1 && !isInclude) {
      result.push(eventsOfDay[i]);
    }

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    eventsOfDay.forEach((j: any, index) => {
      if (index <= i) return;

      const { forming, inset } = calculateConfition(eventsOfDay[i], j);

      if (!includeIds.includes(j.id) && forming) {
        isMiddle = true;
        middleResult.push(j);
        includeIds.push(j.id);
      } else if (inset) {
        j = { ...j, deep: (j.deep ?? 0) + 1 };
      }

      if (index === eventsOfDay.length - 1 && !isInclude) {
        if (isMiddle) {
          middleResult = [{ ...eventsOfDay[i] }, ...middleResult];
          includeIds.push(eventsOfDay[i].id);
        } else {
          result.push({ ...eventsOfDay[i] });
          includeIds.push(eventsOfDay[i].id);
        }
      }
    });

    if (middleResult.length) {
      if (middleResult.length === 1) {
        result.push(middleResult[0]);
      } else {
        const columnsNumberFloor = Math.floor(12 / middleResult.length);
        const columnsNumber = 12 / middleResult.length; // реальноe занятое кол-во столбцов

        result.push(
          middleResult.sort(sortingForMinute).map((i, indexI) => ({
            ...i,
            columnsNumber,
            columns: Array(columnsNumberFloor)
              .fill(1)
              .map((j, indexJ) => indexI * columnsNumberFloor + (indexJ + 1)),
          })),
        );
      }
    }
  }

  if (allDayLongEvents) {
    result.push(allDayLongEvents);
  }

  return result;
};

export const filterWeekEvents = (eventsOfDay: IEventOfDaySimplified[]) => {
  const allDayLongEvents: IEventOfDaySimplified[] = [];
  const filteredData = [];

  for (let i = 0; i < eventsOfDay.length; i++) {
    if (
      dayjs(eventsOfDay[i].startTime).format("HH:mm:ss") === "00:00:00" &&
      dayjs(eventsOfDay[i].endTime).format("HH:mm:ss") === "23:59:59"
    ) {
      allDayLongEvents.push(eventsOfDay[i]);
    } else {
      filteredData.push(eventsOfDay[i]);
    }
  }

  const result = filterEvents(filteredData, allDayLongEvents) ?? [];

  return {
    result,
    allDayLongEvents: allDayLongEvents.length,
  };
};
