import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ACCESS_TOKEN, REFRESH_TOKEN } from "@constants/settings";
import emitter from "@services/emitter";
import { secureStore } from "@utils/secureStore";

import { loginApi, getCodeApi, checkAuth, registerApi } from "./api";

interface IInitalState {
  isLoading: boolean;
  isLoggedIn: boolean;
  codeNumber: null | string;
  phoneNumber: null | string;
  registered: boolean;
  isNoRegistered: boolean;
  errorMsg: null | string;
}

const initialState: IInitalState = {
  isLoading: false,
  isLoggedIn: false,
  codeNumber: null,
  phoneNumber: null,
  registered: false,
  isNoRegistered: false,
  errorMsg: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: () => {
      emitter.emit("logout");
      return initialState;
    },
    logIn: (state: IInitalState) => {
      emitter.emit("login");
      state.isLoggedIn = true;
    },
    clearError: (state: IInitalState) => {
      state.errorMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCodeApi.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getCodeApi.fulfilled, (state, { payload }) => {
        state.phoneNumber = payload.phoneNumber;
        state.registered = payload?.response?.data?.registered ?? true;
        state.isLoading = false;
      })
      .addCase(getCodeApi.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(loginApi.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(loginApi.fulfilled, (state, { payload }) => {
        // сохраняем токен в зашифрованном хранилище
        const accessToken = payload?.response?.data?.access_token;
        const refreshToken = payload?.response?.data?.refresh_token;

        secureStore.saveValue(ACCESS_TOKEN, accessToken);
        secureStore.saveValue(REFRESH_TOKEN, refreshToken);

        state.isLoggedIn = !!state.registered;
        state.isNoRegistered = !state.registered;
        state.isLoading = false;
      })
      .addCase(loginApi.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(checkAuth.fulfilled, (state, { payload }) => {
        state.isLoggedIn = !!payload.accessToken;
        state.isLoading = false;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.errorMsg = action.payload;
      })

      .addCase(registerApi.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(registerApi.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isLoggedIn = true;
      })
      .addCase(registerApi.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export const { logOut, clearError, logIn } = authSlice.actions;
export default authSlice.reducer;
