import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import { ITask } from "@interfaces/businessGoals.interface";
import { TSingleSections } from "@interfaces/businessTask.interface";
import { TaskSubtypes } from "@store/businessTask/constants";
import { toFormatDate } from "@utils/toFormatTime";

import { RootState } from "../store";

import { sortTasksForOneS } from "./utils";

export const selectBusinessTasks = (state: RootState) => state.businessTasks;

export const selectGroupedTasks = createSelector(selectBusinessTasks, (tasks) => {
  const important = tasks.tasks.filter((item: ITask) => item.priority === "IMPORTANT").sort(sortTasksForOneS);
  const urgent = tasks.tasks.filter((item: ITask) => item.priority === "URGENT").sort(sortTasksForOneS);
  const contact = tasks.tasks.filter((item: ITask) => item.priority === "CONTACT").sort(sortTasksForOneS);

  return { urgent, important, contact };
});

export const selectGroupedAllTasks = createSelector(selectBusinessTasks, (tasks) => {
  const important = tasks.allTasks.content.filter((item: ITask) => item.priority === "IMPORTANT");
  const urgent = tasks.allTasks.content.filter((item: ITask) => item.priority === "URGENT");
  const contact = tasks.allTasks.content.filter((item: ITask) => item.priority === "CONTACT");

  return { urgent, important, contact };
});

const selectTasksOfProject = createSelector(selectBusinessTasks, (tasks) => {
  const keyNone = "Без проекта";
  const result: Record<string, ITask[]> = {};

  tasks.allTasks.content.forEach((i) => {
    if (i.project?.name) {
      if (!result[i.project?.name]) {
        result[i.project?.name] = [];
      }
      result[i.project?.name].push(i);
    } else {
      if (!result[keyNone]) {
        result[keyNone] = [];
      }
      result[keyNone].push(i);
    }
  });

  return result;
});

export const selectTightModeTasks = createSelector(selectBusinessTasks, selectGroupedTasks, (tasks, result) => {
  const transferred = tasks.singleSections.transferred.content.slice(0, 2);
  const delegated = tasks.singleSections.delegated.content.slice(0, 2);
  const takenBack = tasks.singleSections.takenBack.content.slice(0, 2);

  if (isEmpty(transferred) && isEmpty(delegated)) {
    return result;
  }

  return { transferred, delegated, takenBack, ...result };
});

export const selectSingleSection = (type: TSingleSections) =>
  createSelector(selectBusinessTasks, (tasks) => {
    const result: Record<string, ITask[]> = {};

    tasks.singleSections[type].content.forEach((i) => {
      const skippedDate = type === "transferred" ? i.skippedDate : toFormatDate(dayjs(i.delegatedFromDateTime ?? i.startDate));

      if (skippedDate) {
        if (!result[skippedDate]) {
          result[skippedDate] = [];
        }
        result[skippedDate].push(i);
      }
    });
    return result;
  });

export const selectAllTasks = createSelector(
  selectBusinessTasks,
  selectGroupedAllTasks,
  selectTasksOfProject,
  (businessTask, priority, project) => {
    const {
      filter: { groupByType },
      allTasks: { content },
    } = businessTask;

    switch (groupByType) {
      case "PROJECT":
        return project;
      case "NONE":
        return content;
      case "PRIORITY":
        return priority;
      default:
        return content;
    }
  }
);

export const selectAllBusinessTasks = createSelector(
  selectBusinessTasks,
  selectGroupedAllTasks,
  selectTasksOfProject,
  (businessTask, priority, project) => {
    const {
      filter: { groupByType },
      allTasks: { content },
    } = businessTask;

    switch (groupByType) {
      case "PROJECT":
        return project;
      case "NONE":
        return content.filter((task) => task.subType === TaskSubtypes.Business);
      case "PRIORITY":
        return priority;
      default:
        return content.filter((task) => task.subType === TaskSubtypes.Business);
    }
  }
);

export const selectBusinessTaskForGoal = (id: string) =>
  createSelector(selectBusinessTasks, (tasks) => tasks.tasks.filter((item) => item.businessGoal?.id === id));
