import React, { CSSProperties, FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import { Props } from "react-modal";

import { RenderWithCondition } from "@components/hoc";

import { TextFont } from "../TextFont";

import { NotificationButton } from "./NotificationButton";
import { NotificationUI } from "./NotificationUI";

interface IProps extends Props {
  phraseId: string;
  subPhraseId?: string;
  phraseOkId?: string;
  phraseCancelId?: string;
  onOk: (() => void) | undefined;
  onCancel: (() => void) | undefined;
  onClose?: () => void;
  styleTextOk?: any;
  styleTextCancel?: any;
  styleContent?: CSSProperties;
}

export const NotificationConfirm: FC<IProps> = ({
  onOk,
  onCancel,
  phraseId,
  subPhraseId,
  phraseOkId = "continue",
  phraseCancelId = "noException",
  styleTextOk = {},
  styleTextCancel = {},
  onClose,
  styleContent = {},
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <NotificationUI isVisible={isVisible} onCancel={onCancel} onClose={onClose} style={styles.modalWrapper} {...props}>
      <section style={{ ...styles.modal, ...styleContent }}>
        <TextFont style={styles.text}>
          <FormattedMessage id={phraseId} />
        </TextFont>
        <RenderWithCondition condition={!!subPhraseId}>
          <TextFont style={{ ...styles.text, paddingTop: 0 }}>
            <FormattedMessage id={subPhraseId} />
          </TextFont>
        </RenderWithCondition>
        <div style={styles.buttons}>
          <div style={styles.buttonCancel}>
            <NotificationButton onHandle={onCancel} setIsVisible={setIsVisible}>
              <TextFont style={{ ...styles.buttonText, ...styleTextCancel }}>
                <FormattedMessage id={phraseCancelId} />
              </TextFont>
            </NotificationButton>
          </div>
          <div style={styles.buttonOK}>
            <NotificationButton onHandle={onOk} setIsVisible={setIsVisible}>
              <TextFont weight="700" style={{ ...styles.buttonText, ...styleTextOk }}>
                <FormattedMessage id={phraseOkId} />
              </TextFont>
            </NotificationButton>
          </div>
        </div>
      </section>
    </NotificationUI>
  );
};

const styles = {
  modalWrapper: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: "14px",
  },
  text: {
    "text-align": "center",
    fontSize: "18px",
    color: "#000000",
    padding: "16px",
    fontWeight: "400",
  },
  buttons: {
    width: "100%",
    display: "flex",
    borderTop: "0.5px solid rgba(60, 60, 67, 0.36)",
  },
  buttonCancel: {
    width: "50%",
    borderRight: "0.5px solid rgba(60, 60, 67, 0.36)",
  },
  buttonOK: {
    width: "50%",
    flexShrink: 0,
    flexGrow: 1,
  },
  buttonText: {
    "text-align": "center",
    color: "#297952",
    fontSize: "18px",
  },
};
