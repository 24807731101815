import React, { FC, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useInfoTask } from "@hooks/useInfoTask";
import { ITask } from "@interfaces/businessGoals.interface";
import { complete, reopen, TaskSubtypes, workDayCancelComplete, workDayComplete, workDayNeedMoreTime } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { IButton, KeysButtons, TaskActions } from "@ui/taskActions";
import { showToast, statusOfDispatchEvent, toFormatDate } from "@utils";

import { Modal } from "../header/modal/Modal";

import { IActiveModal } from "./config";

interface Props {
  item: ITask;
  setActiveModal: (arg: IActiveModal | undefined) => void;
  isVisible: boolean;
  pos: { left: number; top: number };
}

export const ButtonsAnalysis: FC<Props> = ({ item, setActiveModal, isVisible, pos }) => {
  const dispatch = useDispatch<any>();

  const { workDay } = useInfoTask(item);
  const isPersonalTask = item.subType === TaskSubtypes.Personal;
  const isFromOneS = item.isFromOneS ?? false;
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

  const dateNow = useSelector(selectCurrentDate);

  const handleReady = () => {
    dispatch(complete({ id: item.id, isPersonalTask }));
  };

  const handleReturnToWork = () => {
    dispatch(reopen({ id: item.id, isPersonalTask }));
  };

  const handleComment = () => {
    setActiveModal({ visible: "comment", id: item.id });
  };

  const handleDealWith = () => {
    dispatch(workDayComplete({ id: item.id, date: toFormatDate(dateNow), isPersonalTask }));
  };

  const handleCancelDealWith = () => {
    dispatch(workDayCancelComplete({ id: item.id, date: toFormatDate(dateNow), isPersonalTask }));
  };

  const handleNeedMoreTime = async (dateWeek: Dayjs) => {
    const body = { currentDate: toFormatDate(dateNow), additionalDate: toFormatDate(dateWeek) };
    const response = await dispatch(workDayNeedMoreTime({ id: item.id, body, isPersonalTask }));

    if (statusOfDispatchEvent(response) === "fulfilled") {
      setIsVisibleCalendar(false);
    }
  };

  const handleNotEngaged = () => {
    setActiveModal({ visible: "notEngaged", id: item.id });
  };

  const handlersOnPress: Partial<Record<KeysButtons, () => void>> = {
    comment: handleComment,
    dealWith: handleDealWith,
    cancelDealWith: handleCancelDealWith,
    needMoreTime: () => setIsVisibleCalendar(true),
    notEngaged: handleNotEngaged,
    ready: handleReady,
    returnToWork: handleReturnToWork,
  };

  const onPressDisabled = () => {
    showToast(msgParentExtraTime);
  };

  const typeDisabled = useMemo(() => {
    if (dayjs(dateNow).isToday()) {
      return "beforeOrToday";
    }

    if (dayjs(dateNow).isBefore(toFormatDate(dayjs()))) {
      return "before";
    }
  }, [dateNow]);

  const isFutureDay = useMemo(() => dateNow.isAfter(dayjs()), [dateNow]);

  const config = useMemo((): Partial<Record<KeysButtons, IButton>> => {
    let key: KeysButtons = "dealWith";

    if (item.status === "COMPLETED") {
      key = "returnToWork";
    } else if (workDay?.isCompleted) {
      key = "cancelDealWith";
    } else if (item.workDays.length === 1) {
      key = "ready";
    } else {
      key = "dealWith";
    }

    return {
      comment: { onClick: handlersOnPress.comment },
      [key]: { onClick: handlersOnPress[key], disabled: isFutureDay },
      needMoreTime: { onClick: handlersOnPress.needMoreTime },
      notEngaged: { onClick: handlersOnPress.notEngaged },
    };
  }, [isFutureDay, item.status, item.workDays.length, workDay?.isCompleted]);

  const msgParentExtraTime = useIntl().formatMessage({ id: "pastNeedMoreTime" });

  return (
    <>
      <RenderWithCondition condition={isVisible && !isFromOneS}>
        <TaskActions pos={pos} configuration={config} />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleCalendar}>
        <Modal
          isVisible={isVisibleCalendar}
          setIsVisible={setIsVisibleCalendar}
          press={handleNeedMoreTime}
          typeDisabled={typeDisabled}
          onPressDisabled={onPressDisabled}
          isOnlyMonthMode
        />
      </ModalRenderWithCondition>
    </>
  );
};
