import React, { FC, Dispatch, SetStateAction, useMemo, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { IStateUser } from "@interfaces/user.interface";
import { styles as btnStyles } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/styles";
import { IExternalUser, TCreateExternalUser } from "@services/meet.service";
import { createExternalUser, selectExternalUsers } from "@store/externalUsers";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  isVisible: boolean;
  participantEmail: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  closeParticipant: () => void;
  handleAddUser?: (user: IStateUser | IExternalUser, isExtenral?: boolean) => void;
}

export const InviteExternalParticipantBottomSheet: FC<IProps> = ({
  isVisible,
  participantEmail,
  setSearch,
  setIsVisible,
  closeParticipant,
  handleAddUser,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { isLoading } = useSelector(selectExternalUsers);

  const dispatch = useDispatch();

  const isEdit = useMemo(() => firstName.length && lastName.length, [firstName, lastName]);

  const placeholderMeetName = useIntl().formatMessage({ id: "firstName" });
  const placeholderMeetDescription = useIntl().formatMessage({ id: "lastName" });

  const handlePressSave = async () => {
    if (!participantEmail || !firstName || !lastName) return;

    const body: TCreateExternalUser = {
      email: participantEmail,
      firstName,
      lastName,
    };

    const response = await dispatch(createExternalUser(body));

    handleAddUser?.(response.payload, true);
    setSearch && setSearch("");
    setIsVisible(false);
  };

  const styles = style();

  return (
    <ModalUI isVisible={isVisible} backdropOpacity onBackdropPress={() => setIsVisible(false)} onClose={() => setIsVisible(false)}>
      <HeaderModal
        title=" "
        isCancel={false}
        onClose={() => {
          setIsVisible(false);
        }}
      />

      <div style={{ ...styles.container, position: "relative" }}>
        <div style={styles.inputContainer}>
          <input
            style={{ ...styles.input, ...styles.taskName }}
            placeholder={placeholderMeetName}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoFocus={true}
            maxLength={100}
          />
          <input
            style={{ ...styles.input, ...styles.taskDescription }}
            placeholder={placeholderMeetDescription}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            maxLength={100}
          />

          <button
            disabled={!isEdit}
            style={{
              ...btnStyles.button,
              ...btnStyles.buttonSave,
              ...(!isEdit && { backgroundColor: globalState.colorSchema.lighGrey }),
              ...(isEdit && { backgroundColor: globalState.colorSchema.background.green }),
            }}
            onClick={handlePressSave}
          >
            <LoaderButtonRenderWithCondition condition={isLoading}>
              <TextFont style={btnStyles.buttonText}>
                <FormattedMessage id="add" />
              </TextFont>
            </LoaderButtonRenderWithCondition>
          </button>
        </div>
      </div>
    </ModalUI>
  );
};

const style = () => {
  const { colorSchema } = globalState;

  return {
    container: {
      display: "flex",
      "flex-direction": "column",
      justifyContent: "flex-start",
      paddingLeft: 17,
      paddingRight: 17,
    },
    roundContainer: {
      display: "flex",
      top: 10,
      right: 12,
      alignItems: "center",
      justifyContent: "center",
      width: 30,
      height: 30,
      borderRadius: 15,
      backgroundColor: colorSchema.disabled,
      cursor: "pointer",
    },
    inputContainer: {
      display: "flex",
      "flex-direction": "column",
    },
    input: {
      borderRadius: 9,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      paddingBottom: 16,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: colorSchema.grey,
      fontSize: 18,
    },
    taskName: {
      marginTop: 20,
      paddingTop: 16,
    },
    taskDescription: {
      marginTop: 16,
      paddingTop: 16,
    },
  };
};
