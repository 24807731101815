import React, { memo } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import { RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { ITask } from "@interfaces/businessGoals.interface";
import { DelegatedList } from "@shared";
import { takeMe, getGroupedList } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { TextFont } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { toFormatDate } from "@utils";
import globalState from "@utils/globalState";

import { styles } from "./styles";

interface IModalDelegate {
  close: () => void;
  task: ITask;
  isModal?: boolean;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  delegatedToUser?: InterfaceUser.IStateUser | null;
  onTakeMe?: () => void;
  isNeedCloseAfterSave?: boolean;
}

export const ModalDelegate = memo(
  ({ close, task, delegatedToUser, onSave, onTakeMe, isModal = false, isNeedCloseAfterSave = false }: IModalDelegate) => {
    const dispatch = useDispatch();
    const date = useSelector(selectCurrentDate);
    const { isLoading } = userSelector();

    const handleTakeMe = () => {
      const takeMeDispatchWrapper = async () => {
        await dispatch(takeMe(task.id));
        dispatch(getGroupedList({ date: toFormatDate(date), next: 5 }));
      };

      onTakeMe ? onTakeMe() : takeMeDispatchWrapper();
      close && close();
    };

    const renderName = (user: InterfaceUser.IStateUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

    return (
      <ModalUI isVisible={true} onClose={close}>
        <HeaderModal title="delegate" isCancel={false} onClose={close} />

        <RenderWithCondition condition={delegatedToUser && !isLoading}>
          <div style={styles.delegateWrapper}>
            <TextFont type="bold" style={styles.delegateTitle}>
              Делегировано на:
            </TextFont>
            <div style={styles.buttonsContainer}>
              <div style={styles.delegateBlock}>
                <TextFont style={{ fontSize: 14 }}>{renderName(delegatedToUser)}</TextFont>
              </div>
              <button onClick={handleTakeMe} style={{ ...styles.delegateBlock, height: 34, marginLeft: 10 }}>
                <SvgTakeBack width={22} height={22} />
                <TextFont style={{ color: globalState.colorSchema.purple, fontSize: 14, marginLeft: 8 }}>
                  <FormattedMessage id="takeBack" />
                </TextFont>
              </button>
            </div>
          </div>
        </RenderWithCondition>

        <DelegatedList task={task} close={close} onSave={onSave} isModal={isModal} isNeedCloseAfterSave={isNeedCloseAfterSave} />
      </ModalUI>
    );
  }
);
