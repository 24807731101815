import { createSlice } from "@reduxjs/toolkit";

import { IPersonalSettings } from "@interfaces/userSettings.interface";

import { getPersonalSettings, updatePersonalSettings } from "./api";

export interface IUploadedFile {
  id: string;
  name: string;
}

interface IInitialState {
  errorMsg: string | null;
  isLoading: boolean;
  personalSettings: IPersonalSettings | null;
}

const initialState: IInitialState = {
  errorMsg: null,
  isLoading: false,
  personalSettings: null,
};

const userSettingsSlice = createSlice({
  name: "userSettingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPersonalSettings.fulfilled, (state, { payload }) => {
        const data = payload.response.data;

        state.isLoading = false;
        state.personalSettings = data;

        localStorage.setItem("showBusiness", JSON.stringify(data?.showBusinessTasks));
        localStorage.setItem("showPersonal", JSON.stringify(data?.showPersonalTasks));
      })
      .addCase(getPersonalSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })
      .addCase(updatePersonalSettings.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePersonalSettings.fulfilled, (state, { payload }) => {
        const data = payload.response.data;
        state.isLoading = false;
        state.personalSettings = data;
      })
      .addCase(updatePersonalSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export default userSettingsSlice.reducer;
