import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EXTRA_THEME, THEME } from "@constants/settings";
import { TKeysTheme } from "@interfaces/theme.interface";
import { secureStore } from "@utils/secureStore";

interface IState {
  main: TKeysTheme;
  extra: TKeysTheme;
}

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    main: "LIGHT",
    extra: "LIGHT",
  } as IState,
  reducers: {
    changeTheme(state, action: PayloadAction<TKeysTheme>) {
      state.main = action.payload;
      secureStore.saveValue(THEME, action.payload);
    },
    changeExtraTheme(state, action: PayloadAction<TKeysTheme | undefined>) {
      state.extra = action.payload ?? state.main;
      secureStore.saveValue(EXTRA_THEME, action.payload ?? state.main);
    },

    initTheme(state) {
      const [theme, extraTheme] = [localStorage.getItem(THEME), localStorage.getItem(EXTRA_THEME)];

      state.main = (theme ?? "LIGHT") as TKeysTheme;
      state.extra = (extraTheme ?? "LIGHT") as TKeysTheme;
    },
  },
});

export const { changeTheme, changeExtraTheme, initTheme } = themeSlice.actions;
export default themeSlice.reducer;
