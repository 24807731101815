import dayjs from "dayjs";

import { WeekDaysNumbersRU } from "@constants/calendar";
import { TTypeDayOfWeek, TTypeRepeat } from "@interfaces/meet.interface";
import { intl } from "@utils/translate";

import { daysOfWeek } from "./config";

const convertDays = (array: TTypeDayOfWeek[]) => {
  if (!array) return [];
  return [...array].map((i) => daysOfWeek[i]);
};

interface IProps {
  type: TTypeRepeat;
  endTime: string | null;
  days: TTypeDayOfWeek[];
  daysRepeat: string[] | null;
  period: number;
}

export const renderResult = (props: IProps) => {
  switch (props.type) {
    case "WEEK": {
      if (!props.days?.length) return "";
      const repeat = `Повторять ${
        convertDays(props.days).length === 7
          ? "каждый день"
          : `по ${convertDays([...props.days].sort((a, b) => WeekDaysNumbersRU[a] - WeekDaysNumbersRU[b])).join(", ")}`
      } каждую ${props.period ?? 1} неделю`;
      const till = props.endTime ? `\n${intl.getMessage("till")} ${dayjs(props.endTime).format("D MMM YYYY г.")}` : "";
      return `${repeat} ${till}`;
    }
    case "MONTH":
      if (!props.daysRepeat?.length) return "";
      return `Повторять ${[...props.daysRepeat]
        .sort(sortingDays)
        .map((i) => dayjs(i).date())
        .join(", ")} числа каждого ${props.period ?? 1} месяца ${
        props.endTime ? `\nдо ${dayjs(props.endTime).format("DD MMM YYYY г.")}` : ""
      } `;
    case "YEAR":
      if (!props.daysRepeat?.length) return "";
      return `Повторять ${[...props.daysRepeat]
        .sort(sortingDays)
        .map((i) => dayjs(i).format("DD.MM"))
        .join(", ")} каждый год ${props.endTime ? `\nдо ${dayjs(props.endTime).format("DD MMM YYYY г.")}` : ""}`;
    default:
      return "";
  }
};

const sortingDays = (a: string, b: string) => {
  if (dayjs(a) > dayjs(b)) return 1;
  if (dayjs(a) < dayjs(b)) return -1;
  return 0;
};
