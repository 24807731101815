export const setFavicon = () => {
  const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  const hostname = window.location.hostname;

  if (hostname === "adb.dt.test.tap2v.com" || hostname === "localhost") {
    favicon.setAttribute("href", "dt-icon-test.svg");
  } else {
    favicon.setAttribute("href", "dt-icon-prod.svg");
  }
};
