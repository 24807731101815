import React from "react";

import { FormattedMessage } from "react-intl";

import { Notes } from "@shared/notes/notes";
import { TextFont } from "@ui/TextFont";
import globalState from "@utils/globalState";
import { isTablet } from "@utils/isTablet";

export const NotesTablet = () => (
  <section style={styles.container}>
    <div style={styles.header}>
      <TextFont style={{ ...styles.title, margin: "6px 0" }}>
        <FormattedMessage id="notes" />
      </TextFont>
    </div>
    <Notes autoFocus={false} isMounthDate={isTablet} />
  </section>
);

const styles = {
  container: {
    width: "97%",
    height: "96%",
    "overflow-y": "auto",
    backgroundColor: globalState.colorSchema.background.main,
    padding: "12px",
    marginTop: 1,
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
  },
};
