import { IFile } from "@interfaces/files.interfaces";
import { fileService } from "@services/file.service";


export const removeFilesFromServer = (idsToRemove: string[]) => {
  if (!idsToRemove || !idsToRemove.length) return;

  for (const fileId of idsToRemove) {
    fileService.delete(fileId);
  }
};

export const getUniqueMerge = (
  arr1: IFile[],
  arr2: IFile[],
  arr3: IFile[],
  arr4: IFile[]
): IFile[] =>
  [...arr1, ...arr2, ...arr3, ...arr4].reduce((acc: IFile[], current) => {
    const existingItem = acc.find((item) => item.id === current.id);
    !existingItem && acc.push(current);

    return acc;
  }, []);
