import React, { FC, useRef, useState, useMemo } from "react";

import FlatList from "flatlist-react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { IExtendedComment } from "@interfaces/businessGoals.interface";
import { useDeleteMeetingCommentMutation } from "@services/meetApi";
import { deleteTaskComment } from "@store/businessTask/api";
import { userSelector } from "@store/user/selectors";
import { TextFont, ArrowSvg } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import globalState from "@utils/globalState";

import { Comment } from "./Comment";
import { CommentContextMenu } from "./CommentContextMenu";
import { EventCommentsTypes } from "./EventComments";

interface IProps {
  comments: IExtendedComment[];
  eventType: EventCommentsTypes;
  eventId: string;
  isPersonalTask?: boolean;
  handleEdit: (commentId: string, comment: string) => void;
}

export const CommentsList: FC<IProps> = ({ comments, eventType, eventId, isPersonalTask, handleEdit }) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [currentYCoordinate, setCurrentYCoordinate] = useState(0);
  const [isShowAllComments, setIsShowAllComments] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState<string>();
  const [currentCommentText, setCurrentCommentText] = useState<string>();
  const [commentAuthorUserId, setCommentAuthorUserId] = useState<string>();
  const [isConfirmDeletionVisible, setIsConfirmDeletionVisible] = useState(false);
  const { currentUser } = userSelector();
  const dispatch = useDispatch();
  const [deleteMeetingComment] = useDeleteMeetingCommentMutation();

  const sortedComments = useMemo(
    () => [...(comments ?? [])]?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
    [comments],
  );

  const commentsData = useMemo(
    () => (isShowAllComments ? sortedComments : sortedComments.slice(0, 2)),
    [sortedComments, isShowAllComments],
  );

  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const styles = style();

  const onThreeDotsPress = (commentId: string, userId: string, comment: string, top: number) => {
    setCurrentCommentId(commentId);
    setCurrentCommentText(comment);
    setCommentAuthorUserId(userId);
    setCurrentYCoordinate(top);
    setIsContextMenuVisible(true);
  };

  const handleEditWrapper = () => {
    setIsContextMenuVisible(false);
    if (currentUser?.id !== commentAuthorUserId || !currentCommentId) return;

    handleEdit(currentCommentId, currentCommentText ?? "");
  };

  const handleDelete = () => {
    setIsContextMenuVisible(false);
    if (currentUser?.id !== commentAuthorUserId) return;

    setIsConfirmDeletionVisible(true);
  };

  const proceedDeletion = () => {
    setIsConfirmDeletionVisible(false);

    if (eventType === EventCommentsTypes.Task) {
      dispatch(deleteTaskComment({ eventId, commentId: currentCommentId, isPersonalTask }));
    }

    if (eventType === EventCommentsTypes.Meeting) {
      deleteMeetingComment({ eventId, commentId: currentCommentId ?? "" });
    }
  };

  return (
    <div ref={commentsContainerRef}>
      <RenderWithCondition condition={commentsData.length}>
        <FlatList
          list={commentsData}
          renderItem={(item) => (
            <Comment data={item} onThreeDotsPress={onThreeDotsPress} setIsContextMenuVisible={setIsContextMenuVisible} />
          )}
        />
      </RenderWithCondition>
      <RenderWithCondition condition={comments?.length > 2}>
        <div
          onClick={() => {
            setIsShowAllComments(!isShowAllComments);
          }}
          style={styles.toggleCommentListBtn}
        >
          <TextFont style={styles.showMoreText}>
            <FormattedMessage id={isShowAllComments ? "collapse" : "showMore"} />
          </TextFont>
          <ArrowSvg
            style={{ marginLeft: 5 }}
            width={20}
            height={20}
            type={isShowAllComments ? "up" : "down"}
            fill={globalState.colorSchema.grey500}
          />
        </div>
      </RenderWithCondition>

      <RenderWithCondition condition={isContextMenuVisible}>
        <CommentContextMenu
          currentYCoordinate={currentYCoordinate}
          setIsContextMenuVisible={setIsContextMenuVisible}
          handleDelete={handleDelete}
          handleEdit={handleEditWrapper}
        />
      </RenderWithCondition>

      <RenderWithCondition condition={isConfirmDeletionVisible}>
        <NotificationConfirm
          phraseId={"confirmCommentDeletion"}
          onOk={proceedDeletion}
          onCancel={() => setIsConfirmDeletionVisible(false)}
        />
      </RenderWithCondition>
    </div>
  );
};

const style = () => {
  const theme = globalState.colorSchema;

  return {
    container: {
      marginBottom: 10,
      position: "relative",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    dateTime: {
      marginTop: 8,
    },
    contextMenu: {
      width: 200,
      height: 200,
      position: "absolute",
      right: 40,
      paddingVertical: 4,
      borderRadius: 8,
      shadowColor: "rgb(0, 0, 0)",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.09,
      shadowRadius: 6,
      backgroundColor: globalState.colorSchema.white,
    },
    toggleCommentListBtn: {
      display: "flex",
      "flex-direction": "row",
      alignItems: "center",
      cursor: "pointer",
    },
    showMoreText: {
      color: theme.text.grey,
      fontSize: 18,
    },
  };
};
