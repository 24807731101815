import React, { useEffect, useMemo, useRef } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { LoaderRenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { ISession } from "@interfaces/user.interface";
import { selectActiveSessions, selectCurrentSession, getList, removeSession } from "@store/session";
import { selectSession } from "@store/session/selectors";
import { TextFont, HeaderModal } from "@ui";
import globalState from "@utils/globalState";

import { ReactComponent as TabletIcon } from "../../../../../assets/tablet_icon.svg";

import { ReactComponent as SvgAndroid } from "./assets/android.svg";
import { ReactComponent as SvgIOS } from "./assets/ios.svg";
import { ReactComponent as SvgMacOS } from "./assets/macOS.svg";
import { ReactComponent as SvgStopSign } from "./assets/stop-sign.svg";
import { ReactComponent as SvgWindows } from "./assets/windows.svg";

const configureIcons: Record<string, JSX.Element> = {
  iOS: <SvgIOS />,
  MOBILE: <SvgIOS />,
  TABLET: <TabletIcon />,
  Android: <SvgAndroid />,
  Windows: <SvgWindows />,
  "Mac OS": <SvgMacOS />,
  DESKTOP: <SvgMacOS />,
};

interface ISessionComponent {
  onClose: () => void;
}

export const Session = ({ onClose }: ISessionComponent) => {
  const dispatch = useDispatch();
  const { isTablet } = useDimension();

  const { isLoading } = useSelector(selectSession);
  const activeSessions = useSelector(selectActiveSessions);
  const currSession = useSelector(selectCurrentSession);

  const containerRef = useRef<HTMLDivElement>(null);

  const isActiveSessions = useMemo(() => !!activeSessions.length, [activeSessions]);

  useEffect(() => {
    dispatch(getList());
  }, []);

  const handleDeleteSessions = () => {
    dispatch(removeSession(activeSessions.map((i) => i.id)));
  };

  const online = useIntl().formatMessage({ id: "online" });

  const renderBlock = (currSession: ISession) => (
    <div style={styles.block} key={currSession.id}>
      <div style={{ ...styles.active, ...(isActiveSessions && currSession.isCurrent && { paddingBottom: 12 }) }}>
        <div>{currSession.deviceType ? configureIcons[currSession.deviceType] : configureIcons[currSession.operatingSystem]}</div>
        <div style={{ marginLeft: 10 }}>
          <TextFont style={{ ...styles.activeText, fontSize: 16, fontWeight: "700", lineHeight: "24px" }}>
            {currSession.brand ?? ""} {currSession.model ?? ""}
          </TextFont>
          <TextFont style={styles.activeText}>{`DogmaTime ${currSession.appVersion ?? ""} / ${
            currSession.operatingSystem ?? "OS"
          }`}</TextFont>
          {/* <TextFont
              style={[styles.activeText, { color: '#A1B6AD' }]}
            >{`${currSession.location} / ${online}`}</TextFont> */}
        </div>
      </div>

      {isActiveSessions && currSession.isCurrent ? (
        <button onClick={handleDeleteSessions} style={styles.stopSignWrapp}>
          <SvgStopSign />
          <TextFont style={styles.stopSign}>
            <FormattedMessage id="endOtherSessions" />
          </TextFont>
        </button>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <div style={styles.container} ref={containerRef}>
      <HeaderModal
        title="devices"
        isEdit={false}
        onClose={onClose}
        style={{ backgroundColor: globalState.colorSchema.disabled }}
        isClose={!isTablet}
      />
      <LoaderRenderWithCondition condition={isLoading} parent={containerRef.current}>
        <div style={{ marginTop: 20, padding: "0 12px" }}>
          <TextFont style={styles.title}>
            <FormattedMessage id="currentSession" />
          </TextFont>
          {currSession ? renderBlock(currSession) : ""}

          {isActiveSessions ? (
            <>
              <TextFont style={{ color: "#B3BEB8", fontSize: 14, fontWeight: "400", marginTop: 20 }}>
                <FormattedMessage id="endOtherSessionsHelp" />
              </TextFont>
              <TextFont style={{ ...styles.title, marginTop: 20 }}>
                <FormattedMessage id="activeSessions" />
              </TextFont>
              <div style={{ marginTop: 15, height: "55vh", overflow: "auto" }}>{activeSessions.map((session) => renderBlock(session))}</div>
            </>
          ) : (
            ""
          )}
        </div>
      </LoaderRenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    height: "100%",
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
  },
  block: {
    backgroundColor: "#fff",
    borderRadius: 11,
    padding: 12,
    marginTop: 10,
  },
  stopSignWrapp: {
    display: "flex",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "#E3E9E7",
    paddingTop: 12,
  },
  stopSign: {
    color: globalState.colorSchema.red,
    fontSize: 16,
    fontWeight: "400",
    marginLeft: 8,
  },
  active: {
    display: "grid",
    gridTemplateColumns: "0.5fr 13fr",
    gridGap: 5,
  },
  activeText: {
    fontSize: 12,
    fontWeight: "400",
    lineHeight: "20px",
  },
};
