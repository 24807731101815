import { ReactComponent as PriorityB } from "../assets/icon.svg";
import { ReactComponent as PriorityB_done } from "../assets/icon_complete.svg";

import { IIcon } from "./Icon";

export const configureIcons = ({ priority, status }: IIcon) => {
  const object = {
    PRIORITY_A: {
      DONE: <PriorityB_done />,
      NOT_DONE: <PriorityB />,
    },
    PRIORITY_B: {
      DONE: <PriorityB_done />,
      NOT_DONE: <PriorityB />,
    },
    PRIORITY_C: {
      DONE: <PriorityB_done />,
      NOT_DONE: <PriorityB />,
    },
  };

  return object[priority][status];
};
