import { createAsyncThunk } from '@reduxjs/toolkit';
import { fileService } from '@services/file.service';
import { getError } from '@store/getError';

export const getFileInfo = createAsyncThunk('fileAttachments/getFileInfo', async (data: string, thunkAPI) => {
  try {
    const response = await fileService.getFileInfo(data);
    return response.data;
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const uploadFile = createAsyncThunk('fileAttachments/upload', async (data: FormData, thunkAPI) => {
  try {
    return await fileService.upload(data);
  } catch (error: unknown) {
    return getError(error, thunkAPI);
  }
});

export const downloadFile = createAsyncThunk(
  'fileAttachments/download',
  async (data: { id: string; fileName: string }, thunkAPI) => {
    try {
      const { id, fileName } = data;
      return await fileService.download(id, fileName);
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);