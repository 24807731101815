import React, { FC, useEffect } from "react";

import { AttachFileContextMenuButton } from "@shared/fileAttachments";
import globalState from "@utils/globalState";

interface IProps {
  componentMeetingTasks: JSX.Element;
  close: () => void;
  openFilePicker: ({ closeOverModal }: { closeOverModal?: () => void }) => void;
}

export const Menu: FC<IProps> = ({ componentMeetingTasks, close, openFilePicker }) => {
  useEffect(() => {
    document.getElementById("modalMeet").addEventListener("click", (e) => {
      if (!document.getElementById("meetActions1")?.contains(e?.target)) {
        close();
      }
    });
  }, []);

  return (
    <div id="meetActions1" style={{ ...styles.container, position: "absolute" }}>
      {componentMeetingTasks}
      <AttachFileContextMenuButton openFilePicker={() => openFilePicker({ closeOverModal: close })} />
    </div>
  );
};

const styles = {
  container: {
    top: 100,
    right: 12,
    borderRadius: 8,
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.09)",
    backgroundColor: globalState.colorSchema.white,
    zIndex: 999,
  },
};
