import { AnyAction, Dispatch, MiddlewareAPI, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { TSingleSections } from "@interfaces/businessTask.interface";
import { getList } from "@store/businessGoals";
import { get, getFilteredList, getForUpdate, getGroupedList, paggingTransferredList } from "@store/businessTask";
import { filterSingleSectionsByTaskId } from "@store/businessTask/slice";
import { toFormatDate } from "@utils/toFormatTime";

import { actionsBsnGoal, actions, actionsAllTasks, actionsDelegatedTasks, actionsMove, avaliableScreen } from "./constants";

export const updateTasksMiddleWare =
  (store: MiddlewareAPI<Dispatch<AnyAction>, any>) => (next: Dispatch<any>) => async (action: PayloadAction) => {
    const screen = window.location.pathname;
    const isSuccess = action?.meta?.requestStatus === "fulfilled";

    if (avaliableScreen.includes(screen)) {
      const slice = action?.type?.split("/")[0];
      const actionName = action?.type?.split("/")[1];
      const isPersonalTask = action.payload?.isPersonalTask;

      if (slice === "businessTasks" && isSuccess) {
        const payloadId = action.payload?.id;
        const {
          singleSections: { transferred, delegated },
        } = store.getState().businessTasks;

        if (screen === "/month") {
          if (actionsBsnGoal.includes(actionName)) {
            const month = store.getState().date.date.format("YYYY-MM");
            store.dispatch(getList(month));
          }
          if (actions.includes(actionName)) {
            store.dispatch(get({ id: payloadId, isPersonalTask }));
          }
        } else if (screen === "/") {
          if (actionsMove.includes(actionName) && action.payload?.type === "takenBack") {
            handleRemoveTaskFromSingleSection(store, "takenBack", payloadId);
          }

          if (actionsMove.includes(actionName) && transferred.content.findIndex((i) => i.id === payloadId) !== -1) {
            handleRemoveTaskFromSingleSection(store, "transferred", payloadId);
          }
          if (actionsDelegatedTasks.includes(actionName) && delegated.content.findIndex((i) => i.id === payloadId) !== -1) {
            handleRemoveTaskFromSingleSection(store, "delegated", payloadId);
          }
        }

        if (
          (actions.includes(actionName) && (screen === "/" || screen === "/analysis")) ||
          (actionsAllTasks.includes(actionName) && screen === "/tasks")
        ) {
          const response = await store.dispatch(getForUpdate({ id: payloadId, isPersonalTask, actionName }));
          const controlTasks = response?.payload?.response?.data?.controlTasks ?? [];

          if (actionName === "takeMe") {
            for (const item of controlTasks) {
              store.dispatch(getForUpdate({ id: item.id, isPersonalTask: false, actionName }));
            }
          }
        }
      }

      if (actionName === "setOnesTaskResolution" && isSuccess) {
        const extractedId = action?.meta?.arg?.taskId;

        if (extractedId) {
          store.dispatch(get({ id: extractedId, isPersonalTask: false }));
        }

        const date = store.getState().date.date.format("YYYY-MM-DD");

        if (screen === "/") {
          store.dispatch(getGroupedList({ date, next: 5, notLoader: true }));
        } else if (screen === "/tasks") {
          store.dispatch(
            getFilteredList({
              pageable: {
                next: 20,
                current: 0,
                total: 0,
              },
            }),
          );
        }
      }
    }
    next(action);
  };

export const handleRemoveTaskFromSingleSection = async (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  type: TSingleSections,
  payloadId: string,
) => {
  const dateNow = store.getState().screenDay.date ?? dayjs();
  const { singleSections } = store.getState().businessTasks;
  await store.dispatch(filterSingleSectionsByTaskId({ id: payloadId, type }));

  if (singleSections[type].total - singleSections[type].content.length > 0) {
    store.dispatch(
      paggingTransferredList({
        params: { date: toFormatDate(dateNow), next: 1, current: singleSections[type].content.length - 1 },
        type,
      }),
    );
  }
};
