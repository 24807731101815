import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgAttach } from "@assets/attach.svg";
import { TextFont } from "@ui";

interface IProps {
  openFilePicker: () => void;
}

export const AttachFileContextMenuButton: FC<IProps> = ({ openFilePicker }) => (
  <button style={{ padding: 8, display: "flex", alignItems: "center" }} onClick={openFilePicker}>
    <SvgAttach width={19} />
    <TextFont style={{ marginLeft: 8 }} size={16}>
      <FormattedMessage id="attachFile" />
    </TextFont>
  </button>
);
