import { useCallback, useEffect, useRef } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import { saveDate } from "@store/screenDay";
import { updateStartDate } from "@store/screenDay/slice";

export const useAppStateChange = () => {
  const intervalId = useRef<NodeJS.Timer>();
  const dispatch = useDispatch();
  let currentTimeStamp = dayjs();

  const handleDateUpdate = useCallback(() => {
    dispatch(updateStartDate());
    if (dayjs().isAfter(currentTimeStamp, "date")) {
      dispatch(saveDate(dayjs()));

      currentTimeStamp = dayjs();
    }
  }, [currentTimeStamp]);

  useEffect(() => {
    window.removeEventListener("focus", handleDateUpdate);
    window.addEventListener("focus", handleDateUpdate);

    return () => {
      window.removeEventListener("focus", handleDateUpdate);
    };
  }, [handleDateUpdate]);

  useEffect(() => {
    clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      handleDateUpdate();
    }, 60000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [currentTimeStamp]);
};
