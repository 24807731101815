import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgCheckmark } from "@assets/checkmark.svg";
import { ReactComponent as SvgRemove } from "@assets/delete.svg";
import { LoaderRenderWithCondition } from "@hoc";
import { usePagination } from "@hooks/usePagination";
import { IMessage } from "@interfaces/user.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { deleteAllMessages, deleteMessage, getListMessages, pagingMessages, userSelector, viewAllMessages, viewMessage } from "@store/user";
import { Colors } from "@theme/colors";
import { HeaderModal, TaskActions, TextFont } from "@ui";
import globalState from "@utils/globalState";

import { ReactComponent as SvgNotNotifications } from "./assets/not_notifications.svg";
import { ReactComponent as SvgSortingOff } from "./assets/sorting_off.svg";
import { ReactComponent as SvgSortingOn } from "./assets/sorting_on.svg";
import { Message } from "./Message";

interface IProps {
  onClose: () => void;
}

const size = 20;

export const Messages: FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const [isSorted, setIsSorted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    messages: { content: messages, totalPages },
    errorMsg,
    unreadMessages,
    isLoadingMessages,
  } = userSelector();

  const getParameterSort = (value: boolean = isSorted) => (value ? "isViewed,asc&sort=executionTime,desc" : "executionTime,desc");

  const { attrSwipeList, setPage } = usePagination({
    sendPagination: pagingMessages,
    errorMsg,
    argsPagingCallback: { sort: getParameterSort(!isSorted) },
    totalPages,
  });

  useEffect(() => {
    dispatch(getListMessages({ page: 0, size, sort: getParameterSort() }));
  }, []);

  useEffect(() => {
    if (!messages?.length) {
      setIsSorted(false);
    }
    setIsDisabled(!messages?.length);
  }, [messages?.length]);

  const viewAll = () => {
    if (unreadMessages) {
      dispatch(viewAllMessages());
    }
  };

  const pressSorted = () => {
    setIsSorted((props) => !props);
    dispatch(getListMessages({ page: 0, size, sort: getParameterSort(!isSorted) }));
    setPage(0);
  };

  const deleteAll = () => {
    dispatch(deleteAllMessages());
  };

  const resultMessages = useMemo(() => {
    const sort = (a: IMessage, b: IMessage) => {
      if (a.viewed && !b.viewed) return 1;
      if (!a.viewed && b.viewed) return -1;
      return 0;
    };

    if (isSorted) {
      return [...messages].sort(sort);
    }
    return messages;
  }, [isSorted, messages]);

  const sortElement = (
    <button disabled={isDisabled} onClick={pressSorted} style={styles.sorting}>
      {isSorted ? <SvgSortingOn /> : <SvgSortingOff fill={isDisabled ? Colors.LIGHT.grey : Colors.LIGHT.green} />}
    </button>
  );

  const buttons = ({ item, pos, isVisible: isVisibleActions }) =>
    item.status !== "OWNER" &&
    isVisibleActions && (
      <TaskActions
        pos={pos}
        configuration={{
          delete: { onClick: () => dispatch(deleteMessage({ id: item.id, viewed: item.viewed })) },
          readOne: { onClick: () => dispatch(viewMessage(item.id)), disabled: item.viewed },
        }}
      />
    );

  return (
    <section style={styles.container} ref={containerRef}>
      <HeaderModal
        title="notifications"
        onClose={onClose}
        style={{ backgroundColor: globalState.colorSchema.disabled }}
        isSave
        isEdit
        isCancel={false}
        saveElement={sortElement}
      />
      <LoaderRenderWithCondition condition={isLoadingMessages} parent={containerRef.current}>
        <div style={styles.main}>
          {!isEmpty(messages) && (
            <div style={{ height: "100%" }}>
              <div style={{ display: "flex", justifyContent: "center", gap: 10, marginBottom: 12 }}>
                <button style={styles.btn} onClick={viewAll}>
                  <SvgCheckmark />
                  <TextFont size={16}>
                    <FormattedMessage id="readAll" />
                  </TextFont>
                </button>
                <button style={styles.btn} onClick={deleteAll}>
                  <SvgRemove fill={Colors.LIGHT.grey500} />
                  <TextFont size={16}>
                    <FormattedMessage id="deleteAll" />
                  </TextFont>
                </button>
              </div>
              <SwipeList
                data={resultMessages}
                element={(data: { item; index; handleRightClick }) => (
                  <Message handleRightClick={data.handleRightClick} message={data.item} onClose={onClose} />
                )}
                buttons={buttons}
                {...attrSwipeList}
              />
            </div>
          )}
          {isEmpty(messages) && (
            <div style={styles.wrapperEmpty}>
              <SvgNotNotifications />
              <TextFont size={18} style={{ textAlign: "center", marginTop: 10 }} color={globalState.colorSchema.placeholder.main}>
                <FormattedMessage id="notificationNoMore" />
              </TextFont>
            </div>
          )}
        </div>
      </LoaderRenderWithCondition>
    </section>
  );
};

const styles = {
  container: {
    height: "100%",
  },
  main: {
    display: "flex",
    "flex-direction": "column",
    paddingTop: 20,
    gap: 10,
    height: "85%",
  },
  wrapperEmpty: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  sorting: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    width: "100%",
    marginRight: 12,
  },
  btn: {
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "white",
    border: `1px solid ${Colors.LIGHT.background.grey}`,
    padding: "6px 14px",
    gap: 8,
  },
  footer: {
    width: "100%",
    height: 50,
  },
};
