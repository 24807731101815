import React, { FC } from "react";

import { useDispatch, useSelector } from "react-redux";

import { workDayNotComplete } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { isTablet, toFormatDate } from "@utils";

import { ModalClose } from "../../../ListTasks/components/modalClose";
import { reasonsNotEngaged } from "../../config";

interface IProps {
  close: () => void;
  id?: string;
  isPersonalTask: boolean;
}

export const NotEngaged: FC<IProps> = ({ close, id, isPersonalTask }) => {
  const dispatch = useDispatch();

  const dateNow = useSelector(selectCurrentDate);

  const handleSave = (reason: string, comment: string) => {
    dispatch(workDayNotComplete({ id: id, date: toFormatDate(dateNow), body: { comment, reason }, isPersonalTask }));
  };

  return (
    <ModalClose
      isVisible={true}
      setIsVisible={() => close()}
      defaultReason={reasonsNotEngaged.outsideReason}
      onSave={handleSave}
      tabsReason={reasonsNotEngaged}
      isButton={false}
      title="reason"
      bgTab={"white"}
      isTight={!isTablet}
    />
  );
};
