import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { IFilter } from "@interfaces/businessTask.interface";
import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { RootState } from "@store";
import { setGrouping } from "@store/businessTask";
import { selectUserPermissoins } from "@store/user";
import { TextFont, ModalUI } from "@ui";
import globalState from "@utils/globalState";

import { GroupingTabs, TypeGroupTab } from "./assets/constants";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
  applyEvent: (callback?: () => void, proporties?: Partial<IFilter>) => void;
}

export const GroupingModal = ({ isVisible, closeEvent, applyEvent }: IProps) => {
  const dispatch = useDispatch();
  const { groupByType } = useSelector((state: RootState) => state.businessTasks.filter);

  const [localGrouped, setLocalGrouped] = useState(groupByType);

  const permissions = useSelector(selectUserPermissoins);

  const handleSave = () => {
    dispatch(setGrouping(localGrouped));
    applyEvent(closeEvent, { groupByType: localGrouped });
  };

  const isValidGroup = (group: TypeGroupTab) => {
    switch (group) {
      case "PROJECT": {
        return Boolean(permissions.WORK_WITH_SPACE_PROJECTS);
      }
      default: {
        return true;
      }
    }
  };

  return (
    <ModalUI isVisible={isVisible} onClose={closeEvent}>
      <div style={styles.container}>
        <div>
          <div style={styles.groupingHeadContainer}>
            <button onClick={closeEvent} style={{ zIndex: 1 }}>
              <TextFont style={{ ...modalStyles.headerTitle, fontWeight: "400" }}>
                <FormattedMessage id="close" />
              </TextFont>
            </button>
            <button onClick={handleSave} style={{ marginLeft: 12, zIndex: 1 }}>
              <TextFont style={styles.applyText}>
                <FormattedMessage id="apply" />
              </TextFont>
            </button>
            <TextFont style={{ ...styles.title, position: "absolute" }}>
              <FormattedMessage id={"order"} />
            </TextFont>
          </div>
          <div style={styles.mainContainer}>
            <TextFont type="bold" style={{ ...styles.text, marginBottom: 10 }}>
              <FormattedMessage id={"Сгруппировать"} />
            </TextFont>
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              {Object.keys(GroupingTabs).map((element, index) => (
                <RenderWithCondition key={element} condition={isValidGroup(GroupingTabs[element])}>
                  <button
                    key={element + index}
                    onClick={() => setLocalGrouped(GroupingTabs[element])}
                    style={{ ...styles.chooseContainer, borderColor: localGrouped === GroupingTabs[element] ? "#297952" : "#E3E9E7" }}
                  >
                    <TextFont>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </button>
                </RenderWithCondition>
              ))}
            </div>
          </div>
        </div>
        <button onClick={handleSave} style={styles.button}>
          <TextFont style={styles.buttonText}>
            <FormattedMessage id={"apply"} />
          </TextFont>
        </button>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
    justifyContent: "space-between",
  },
  groupingHeadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)",
    padding: 9,
    marginBottom: 40,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    lineHeight: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    "text-align": "center",
  },
  applyText: {
    fontWeight: "700",
    fontSize: 16,
    lineHeight: "24px",
    color: globalState.colorSchema.text.accent,
    zIndex: 1,
  },
  mainContainer: {
    padding: "0 12px",
  },
  text: {
    fontSize: 18,
    lineHeight: "24px",
  },
  chooseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 42,
    width: "48.5%",
    padding: "0 10px",
    marginBottom: 10,
    backgroundColor: "#E3E9E7",
    borderRadius: 5,
    border: "1px solid #E3E9E7",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 0",
    backgroundColor: globalState.colorSchema.background.green,
    borderRadius: 10,
    margin: "10px 12px 20px 12px",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "700",
    "text-align": "center",
  },
};
