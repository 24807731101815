import { ACCESS_TOKEN, DIRECTOR_ID, EXTRA_THEME } from "@constants/settings";

import { secureStore } from "./secureStore";

export const logOutUtil = () => {
  secureStore.deleteValue(ACCESS_TOKEN);
  secureStore.deleteValue(EXTRA_THEME);
  secureStore.deleteValue(DIRECTOR_ID);
};

export const deleteSearchParams = (params: Array<string>) => {
  const url = new URL(window.location.href);

  for (const param of params) {
    if (url.searchParams.has(param)) {
      url.searchParams.delete(param);
    }
  }

  window.history.pushState({}, "", url.toString());
};
