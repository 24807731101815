import { FC, useCallback, useEffect, useState } from "react";
import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { ModalRenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useSubscribe } from "@hooks/useSubscribe";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { ITask } from "@interfaces/personalGoals.interface";
import { IColors } from "@interfaces/theme.interface";
import { Layout } from "@layout";
import { useGetMeetingByIdQuery, useGetMeetingListQuery } from "@services/meetApi";
import { TaskSubtypes, get, selectBusinessTasks } from "@store/businessTask";
import { selectCalendar } from "@store/calendar";
import { selectConfigure } from "@store/configure";
import { selectNotifications, setMeetingId } from "@store/notification";
import { selectCurrentDate, selectScreenDay, updateId } from "@store/screenDay";
import { setActiveTabId } from "@store/screenDay/slice";
import { selectTheme } from "@store/theme";
import { AddCircle, Prompting, TextFont } from "@ui";
import { myTracker } from "@utils/myTracker";
import { toFormatDate } from "@utils/toFormatTime";

import { Notes, tightModeElements } from "../../shared";

import { Header } from "./components/header";
import { Modal as ModalTask } from "./components/ListTasks";
import { ListTasks } from "./components/ListTasks/ListTasks";
import { Tabs } from "./components/tabs";
import { Timetable } from "./components/timetable";
import { Modal } from "./components/timetable/main/modal/Modal";
import { filterEvents } from "./components/timetable/main/util";

const HEADER_HEIGHT = "40px";

export const Day: FC = () => {
  const { activeTabId } = useSelector(selectScreenDay);
  const { isTablet } = useDimension();
  const dispatch = useDispatch();
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isVisibleMeet, setIsVisibleMeet] = useState(false);
  const [isVisibleTask, setIsVisibleTask] = useState(false);
  const [isVisibleModalTask, setIsVisibleModalTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [currentMeet, setCurrentMeet] = useState<TFirstArgPress | undefined>(undefined);
  const [taskEvent, setTaskEvent] = useState<{ id: string; taskType: TaskSubtypes } | null>();

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const notifications = useSelector(selectNotifications);

  const [filteredEventsOfDay, setFilteredEventsOfDay] = useState<(IEventOfDaySimplified | IEventOfDaySimplified[])[]>([]);
  const { eventToReopen, currentId: eventId, joinData, isOpenModalMeet, date } = useSelector(selectScreenDay);
  const selectedDay = useSelector(selectCurrentDate);
  const { currentTask, isOpenModalTask, isPersonalTask } = useSelector(selectBusinessTasks);
  const { isVisibleAppCalendar } = useSelector(selectCalendar);

  const { data: eventsOfDay, isFetching, isLoading, refetch } = useGetMeetingListQuery({ date: toFormatDate(selectedDay) });

  const [meetId, setMeetId] = useState("");
  const [skipFetchMeet, setSkipFetchMeet] = useState(false);

  useSubscribe(
    "updateMeetings",
    () => {
      refetch();
    },
    [selectedDay],
  );

  useEffect(() => {
    setIsFetchingData(true);
  }, [date]);

  useEffect(() => {
    if (!isFetching) {
      setIsFetchingData(false);
    }
  }, [isFetching, isFetchingData]);

  useEffect(() => {
    if (eventId && isOpenModalMeet) {
      setIsVisibleMeet(true);
      handleChangeCurrentMeet("id", eventId);
    }
    if (currentTask && isOpenModalTask && isVisibleAppCalendar) {
      setIsVisibleTask(true);
      setTaskId(currentTask.id);
    }
  }, [eventId, isOpenModalMeet, currentTask, isOpenModalTask]);

  useEffect(() => {
    if (currentTask?.id === taskEvent?.id) return;

    if (currentTask && isOpenModalTask && isVisibleAppCalendar) {
      setIsVisibleTask(true);
      setTaskId(currentTask.id);
      setTaskEvent({ id: currentTask.id, taskType: isPersonalTask ? TaskSubtypes.Personal : TaskSubtypes.Business });
    }
  }, [currentTask, isOpenModalTask, isPersonalTask, taskEvent, isVisibleAppCalendar]);

  useGetMeetingByIdQuery(
    {
      id: meetId,
      repeat: currentMeet?.repeat,
      currentDate: toFormatDate(selectedDay),
    },
    {
      skip: skipFetchMeet,
    },
  );

  useEffect(() => {
    if (notifications.meetingID) {
      handleChangeCurrentMeet("id", notifications.meetingID);
      dispatch(setActiveTabId("timetable"));
      setIsVisibleMeet(true);
      dispatch(setMeetingId(undefined));
    }
  }, [notifications.meetingID]);

  const handlePress = useCallback(
    async (meet: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => {
      if (type === "MEETING") {
        dispatch(updateId({ id: meet.id, modalOpen: true }));
        setIsVisibleMeet(true);
        setCurrentMeet(meet);

        if (taskEvent) {
          setTaskEvent(null);
        }
      }

      if (type === "TASK") {
        setTaskEvent({ taskType: isPersonalTask ? TaskSubtypes.Personal : TaskSubtypes.Business, id: meet.id });
        setIsVisibleTask(true);
      }
    },
    [taskEvent],
  );

  useEffect(() => {
    const url = new URL(location.href);
    if (url.search) {
      const arrQueryParams = Array.from(url.searchParams);

      (async () => {
        for await (const [type, id] of arrQueryParams) {
          if (type === "meeting") {
            setSkipFetchMeet(false);
            setMeetId(id);
            handlePress({ id, repeat: false }, "MEETING");
            break;
          }

          if (type === "task") {
            handlePressTask(id);
            break;
          }
        }
      })();
    }
  }, [location]);

  useEffect(() => {
    /*
      ToDo: продумать вариант обновления экрана встречи без жесткой перезагрузки
      после создания исключения из серии.
    */
    if (eventToReopen?.id) {
      setIsVisibleMeet(true);
      setCurrentMeet({ id: eventToReopen.id, date: eventToReopen.date, repeat: eventToReopen.repeat });
    }
  }, [eventToReopen]);

  useEffect(() => {
    // ToDo: Типизировать данные от селектора и фильтра!!!
    const result = filterEvents(eventsOfDay);

    setFilteredEventsOfDay(result);
  }, [eventsOfDay]);

  useEffect(() => {
    if (joinData) handlePress({ id: "", repeat: Boolean(joinData?.repeat) }, "MEETING");
  }, [joinData]);

  const handleChangeCurrentMeet = (name: keyof TFirstArgPress, val: boolean | string) => {
    setCurrentMeet({
      ...currentMeet,
      [name]: val,
    });
  };

  const handlePressTask = React.useCallback((id: string) => {
    setTaskId(id);
    setIsVisibleModalTask(true);
  }, []);

  const configTabs = {
    notes: <Notes backgroundColor="#fff" />,
    timetable: <Timetable filteredEventsOfDay={filteredEventsOfDay} handlePress={handlePress} isFetching={isLoading} />,
    tasks: <ListTasks isVisible={isVisibleModalTask} id={taskId} setIsVisible={setIsVisibleModalTask} handlePress={handlePressTask} />,
  };

  const mob = () => (
    <section style={{ height: "100%" }}>
      <Tabs />
      <div style={{ margin: activeTabId !== "tasks" ? "12px 12px 0 12px" : "0", height: "90%" }}>{configTabs[activeTabId]}</div>
    </section>
  );

  const styles = makeStyles(selectTheme("extra"));

  const tablet = () =>
    isTightMode ? (
      tightModeElements.day
    ) : (
      <section style={{ ...styles.container }}>
        <div style={{ ...styles.side, ...styles.leftSide, position: "relative" }}>
          <div style={{ ...styles.header, borderTopRightRadius: 20 }}>
            <TextFont type="bold" style={styles.headerText}>
              Задачи
            </TextFont>
          </div>
          <ListTasks isVisible={isVisibleModalTask} id={taskId} setIsVisible={setIsVisibleModalTask} handlePress={handlePressTask} />
          <AddCircle
            press={() => {
              myTracker("CreateTaskOnDayClick");
              handlePressTask("");
            }}
            bottom={"6%"}
            right={0}
          />
        </div>
        <div style={{ ...styles.side, ...styles.centerSide, position: "relative" }}>
          <div style={{ ...styles.header, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
            <TextFont style={styles.headerText}>Расписание</TextFont>
          </div>
          <div style={{ height: `calc(100% - ${HEADER_HEIGHT})` }}>
            <div style={styles.timetable}>
              <Timetable handlePress={handlePress} filteredEventsOfDay={filteredEventsOfDay} isFetching={isLoading || isFetchingData} />
              <AddCircle press={() => handlePress({ id: "", repeat: false }, "MEETING")} bottom={"6%"} right={0} />
            </div>
          </div>
        </div>
        <div style={{ ...styles.side, ...styles.rightSide }}>
          <div style={{ ...styles.header, borderTopLeftRadius: 20 }}>
            <TextFont style={styles.headerText}>Заметки</TextFont>
          </div>
          <div style={styles.notes}>
            <Notes autoFocus={false} />
          </div>
        </div>
      </section>
    );

  return (
    <Layout
      backgroundColor="#fff"
      header={!isTightMode && <Header />}
      footerHeight={isTightMode ? "0px" : undefined}
      headerHeight={isTightMode ? "0px" : undefined}
    >
      {isTablet ? tablet() : mob()}

      <Prompting kind="day" keySetting="isPromptDay" />

      <ModalRenderWithCondition condition={isVisibleMeet}>
        <Modal
          isVisible={isVisibleMeet}
          meetId={currentMeet?.id ?? eventId}
          setIsVisible={setIsVisibleMeet}
          data={joinData ?? {}}
          isRepeating={currentMeet?.repeat}
          meetDate={currentMeet?.date}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleTask}>
        <ModalTask isVisible={isVisibleTask} id={taskEvent?.id ?? ""} setIsVisible={setIsVisibleTask} taskSubType={taskEvent?.taskType} />
      </ModalRenderWithCondition>
    </Layout>
  );
};

const makeStyles = (colors: IColors) => ({
  container: {
    display: "flex",
    height: "100%",
    "flex-direction": "row",
    backgroundColor: "white",
  },
  side: {
    backgroundColor: colors.background.main,
    marginTop: "14px",
    height: "100%",
  },
  leftSide: {
    width: "33%",
    marginRight: "1%",
    borderTopRightRadius: "20px",
  },
  rightSide: {
    width: "33%",
    marginLeft: "1%",
    borderTopLeftRadius: "20px",
  },
  centerSide: {
    width: "33%",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  header: {
    display: "flex",
    backgroundColor: colors.disabled,
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 0",
    height: HEADER_HEIGHT,
  },
  headerText: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    color: colors.text.darkGreen,
  },
  timetable: {
    height: "88%",
    margin: "12px",
  },
  notes: {
    paddingLeft: 12,
    paddingRight: 12,
    height: "87%",
    "overflow-y": "auto",
  },
});
