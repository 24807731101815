import { isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";

import { configNotify } from "./constants";
import { IActionNotify } from "./types";

export const buildConfigNotify = (action: IActionNotify) => {
  let notify = null;

  if (isRejectedWithValue(action)) {
    const message = action?.payload?.errorText || action?.payload;
    if (typeof message === "string") {
      notify = configNotify(message, "error");
    }
  }

  //   if (!action?.payload?.isNotify && !action?.payload?.notify) {
  //     return null;
  //   }

  // если в обьекте ответа возращается конфиг notify, показываем notification с конфигом notify
  // если передали не валидный notify config, отрендерится configNotify.default
  //   if (action?.payload?.notify) {
  //     notify = {
  //       ...configNotify.default,
  //       ...action.payload.notify,
  //     };
  //     return notify;
  //   }

  // если в обьекте ответа возращается ошибка, показываем error notification
  //   if (action?.payload?.success === false) {
  //     notify = configNotify(action?.payload?.errorMassage, 'danger')
  //     return notify;
  //   }

  // если в обьекте ответа возращается success = true, показываем success notification
  if (isFulfilled(action) && action?.payload?.notifyText) {
    notify = configNotify(action?.payload?.notifyText, "success");
    return notify;
  }

  return notify;
};
