import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { RootState } from "@store";

export const selectStoreTag = (state: RootState) => state.tag;

export const tagNameSelector = (id: string) => useSelector((state: RootState) => state.tag?.tags?.filter((i) => i.id === id)[0]?.name);

export const selectSpecialTag = (id: string) => createSelector(selectStoreTag, (tags) => tags?.tags?.filter((i) => i.id === id)[0]);

export const selectSpecialTagByName = (name: string) =>
  createSelector(selectStoreTag, (tags) => tags?.tags?.filter((i) => i.name.toLowerCase() === name.toLowerCase())[0]);
