import { TTypeStatusTask } from "@interfaces/businessGoals.interface";

import { ModalTypeTab, ImportanceTypeTab, ExecutorTypeTab, ISwitches } from "../../types";

export const modalTabs: ModalTypeTab[] = ["group", "tag", "delegatedFrom", "delegatedTo"];
export const importanceTabs: ImportanceTypeTab = { urgent: "URGENT", important: "IMPORTANT", contact: "CONTACT" };
export const executorTabs: ExecutorTypeTab = { onMe: "CURRENT", delegated: "DELEGATED" };
export const statusTabs: Record<string, TTypeStatusTask> = { open: "OPEN", closed: "CLOSED", overdue: "EXPIRED", suspendTask: "PAUSED" };
export const switchesData: ISwitches = {
  isImportance: false,
  isCreateDate: false,
  isDeadlineDay: false,
  isStatus: false,
};
