import { FC, HTMLAttributes, useEffect, useRef } from "react";

import { maxLinesInput } from "@utils/maxLinesInput";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  onChange: (arg: string) => void;
  placeholder?: string;
  maxLength?: number;
}

export const ContentEditable: FC<IProps> = ({ value, onChange, maxLength = 255, placeholder = "", ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.autoFocus) {
      ref.current.focus();
    }
  }, []);

  useEffect(() => {
    if (ref.current.innerText !== value) {
      ref.current.innerText = value;
    }
  });

  const onKeyDown = (event) => {
    if (
      (event.currentTarget.innerText.trim().length >= maxLength || !maxLinesInput(event.currentTarget.innerText)) &&
      event.code !== "Backspace"
    ) {
      event.preventDefault();
    }
  };

  return (
    <div
      data-ph={placeholder}
      onKeyDown={onKeyDown}
      className="textareaElement"
      contentEditable="true"
      ref={ref}
      onInput={(event) => onChange(event.currentTarget.innerText)}
      {...props}
    />
  );
};
