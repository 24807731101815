import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { useGetContainerDimensions } from "@hooks/useGetContainerDimensions";
import { getList, selectBusiness, setCurrentId } from "@store/businessGoals";
import { selectIsModalOpen } from "@store/configure";
import { selectYearMonth } from "@store/date";
import { ItemListContentLoader, TextFont } from "@ui";
import { isTablet } from "@utils";
import globalState from "@utils/globalState";

import { ReactComponent as SvgPlus } from "./assets/plus.svg";
import { List } from "./list/List";
import { Modal } from "./Modal/Modal";

export const TargetBusiness = () => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const { goals, isLoading, currentGoalId } = useSelector(selectBusiness);
  const isModalOpen = useSelector(selectIsModalOpen);
  const yearMonth = useSelector(selectYearMonth);

  useEffect(() => {
    dispatch(getList(yearMonth));
  }, [yearMonth]);

  const setCurrentGoal = useCallback((id: string) => {
    dispatch(setCurrentId(id));
    setIsVisible(true);
  }, []);

  const color = useMemo(
    () => (isTablet && isModalOpen ? globalState.colorSchema.background.grey : globalState.colorSchema.background.darkGreen),
    [isModalOpen],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useGetContainerDimensions(containerRef);

  return (
    <section style={styles.container}>
      <div style={styles.header}>
        <TextFont type="bold" color={color} style={styles.title}>
          <FormattedMessage id="businessGoals" />
        </TextFont>
        <button onClick={() => setCurrentGoal("")} style={{ display: "flex", justifyContent: "flex-end" }}>
          <SvgPlus fill={color} />
        </button>
      </div>
      <div style={{ height: "100%", width: "100%" }} ref={containerRef}>
        <LoaderRenderWithCondition condition={isLoading} loaderComponent={<ItemListContentLoader width={width} height={height} />}>
          <List data={goals} setCurrentGoal={setCurrentGoal} />
        </LoaderRenderWithCondition>
      </div>
      <ModalRenderWithCondition condition={isVisible}>
        <Modal goalId={currentGoalId} isVisible={isVisible} close={() => setIsVisible(false)} />
      </ModalRenderWithCondition>
    </section>
  );
};

const styles = {
  container: {
    paddingTop: "10px",
    backgroundColor: globalState.colorSchema.background.main,
    paddingRight: "13px",
    paddingLeft: "13px",
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    margin: 0,
  },
};
