import React, { FC, useState, useRef, useEffect } from "react";

import { ItemListContentLoader } from "@ui";
import { ItemContentLoader } from "@ui";

export enum LoaderTypes {
  ItemContent = "ItemContent",
  List = "List",
}

interface IProps {
  condition: boolean;
  children: JSX.Element;
  loaderComponent?: JSX.Element;
  loaderType?: LoaderTypes;
  parent?: HTMLDivElement;
  width?: number;
  height?: number;
}

export const LoaderRenderWithCondition: FC<IProps> = ({
  condition,
  children,
  loaderComponent,
  loaderType = LoaderTypes.List,
  parent,
  width = 0,
  height = 0,
}) => {
  const [calculatedWidth, setWidth] = useState(width);
  const [calculatedHeight, setHeight] = useState(height);
  const innerContainerRef = useRef(null);

  const updateDimensions = () => {
    if (parent) {
      const { width, height } = parent.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    } else {
      if (innerContainerRef && innerContainerRef.current) {
        const { width, height } = innerContainerRef.current.getBoundingClientRect();
        setWidth(width);
        setHeight(height);
      }
    }
  };

  useEffect(() => {
    updateDimensions();
  }, [parent, innerContainerRef, condition]);

  const DefaultLoader = () => (
    <>
      {loaderType === LoaderTypes.List ? (
        <ItemListContentLoader width={calculatedWidth} height={calculatedHeight} />
      ) : (
        <ItemContentLoader width={calculatedWidth} height={calculatedHeight} />
      )}
    </>
  );

  if (condition) {
    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }} ref={innerContainerRef}>
        {loaderComponent ? loaderComponent : <DefaultLoader />}
      </div>
    );
  }

  return children;
};
