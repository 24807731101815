import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@store";

export const selectScreenDay = (state: RootState) => state.screenDay;

export const selectGenerateEvents = createSelector(selectScreenDay, (screenDay) => screenDay?.events.map((i) => ({ ...i, deep: 0 })));

export const selectCurrentDate = createSelector(selectScreenDay, (date) => date.date ?? date.startDate);

export const selectSpecifyEvent = (id: string) =>
  createSelector(selectScreenDay, (screenDay) => {
    const event = screenDay.events.filter((item) => item.id === id)[0];
    if (!event && screenDay.eventFromLink?.id === id) {
      return screenDay.eventFromLink;
    }
    return event;
  });
