import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { unionBy } from "lodash";
import { useSelector } from "react-redux";

import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { IMeet } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser, TSubListInfo } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";
import { useGetExternalParticipantsListQuery, useGetParticipationListQuery } from "@services/userApi";
import { getSublistParams } from "@services/utils";
import { selectUserForAssistant } from "@store/user";
import { isEmail } from "@utils/isEmail";

const next = 16;

interface IHookProps {
  meet?: IMeet;
  isGroupAll?: boolean;
  searchQuery?: string;
}

interface IHookReturn {
  listData: (IStateUser | IExternalUser)[];
  selectedUsers: (IStateUser | IExternalUser)[];
  isInviteExternalParticipantBtnVisible: boolean;
  isInviteExternalParticipantModalVisible: boolean;
  isLoading: boolean;
  isFetching: boolean;
  search: string;
  searchedExternalUsersResponse: IExternalUser[];
  initialParticipants: (IStateUser | IExternalUser)[];
  setCurrent: Dispatch<SetStateAction<number>>;
  setSearch: Dispatch<SetStateAction<string>>;
  handleResetSearch: () => void;
  loadMoreData: () => Promise<void>;
  setSearchedExternalUsers: Dispatch<React.SetStateAction<IExternalUser[]>>;
  setGetUsersParams: Dispatch<SetStateAction<IGetUserParams>>;
  setIsInviteExternalParticipantModalVisible: Dispatch<SetStateAction<boolean>>;
  setSelectedUsers: Dispatch<SetStateAction<IStateUser | IExternalUser | (IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem>[]>>;
  setCurrentSublistType: Dispatch<SetStateAction<TSubListInfo>>;
}

export const useHandleParticipantSearch = ({ meet, isGroupAll }: IHookProps): IHookReturn => {
  const [isInviteExternalParticipantModalVisible, setIsInviteExternalParticipantModalVisible] = useState(false);
  const [isInviteExternalParticipantBtnVisible, setIsInviteExternalParticipantBtnVisible] = useState(false);

  const [search, setSearch] = useState("");
  const [userToSearch, setUserToSearch] = useState("");
  const [current, setCurrent] = useState(0);
  const [participantsUsers, setParticipantsUsers] = useState({ content: [] as IStateUser[], total: 0 });
  const [searchedExternalUsers, setSearchedExternalUsers] = useState<IExternalUser[]>([]);
  const [currentSublistType, setCurrentSublistType] = useState<TSubListInfo>("Delegation");
  const currentUser = useSelector(selectUserForAssistant);

  const defaultQueryParams = useMemo(
    () => ({ next, startTime: meet?.startTime ?? null, endTime: meet.endTime ?? null, userId: "" }),
    [meet],
  );

  const defaultGetUserParams = useMemo(
    () => ({
      id: meet?.id ?? "",
      params: {
        startTime: meet?.startTime ?? null,
        endTime: meet?.endTime ?? null,
        current: 0,
        next,
        showFromDelegationChain: true,
        showFromDepartment: false,
        showOthers: false,
        userId: "",
      },
    }),
    [meet],
  );

  const [getUsersParams, setGetUsersParams] = useState<IGetUserParams>(defaultGetUserParams);

  const {
    data: searchedExternalUsersResponse,
    isLoading: isLoadingExternal,
    isFetching: isFetchingExternal,
  } = useGetExternalParticipantsListQuery({
    searchQuery: userToSearch,
  });

  const {
    data: participantsUsersResponse,
    isLoading: isLoadingInner,
    isFetching: isFetchingInner,
  } = useGetParticipationListQuery({
    ...getUsersParams,
    params: { ...getUsersParams.params },
  });

  const isLoading = useMemo(() => isLoadingExternal || isLoadingInner, [isLoadingExternal, isLoadingInner]);
  const isFetching = useMemo(() => isFetchingInner || isFetchingExternal, [isFetchingInner, isFetchingExternal]);

  const initialParticipants: (IStateUser | IExternalUser)[] = useMemo(() => {
    const participants = [];

    if (meet?.participants) {
      for (const user of meet.participants) {
        Object.hasOwn(user, "user") ? participants.push(user.user) : participants.push(user);
      }
    }

    if (meet?.externalUsers?.length) {
      const externalUserWithoutDeleted = meet?.externalUsers.map((ex) => ({ ...ex, isExternalUser: true })).filter((ex) => !ex.deleted);
      participants.push(...externalUserWithoutDeleted);
    }
    return participants;
  }, [meet]);

  const [selectedUsers, setSelectedUsers] = useState<
    (IStateUser | IExternalUser | IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem[]>
  >(initialParticipants);

  const listData = useMemo(() => {
    const selectedUsersIds = selectedUsers.map((item) => item.id);
    const filteredInnerUsers = participantsUsers?.content?.filter((item) => !selectedUsersIds.includes(item.id));
    const filteredExternalUsers = searchedExternalUsers
      ?.filter((item) => !selectedUsersIds.includes(item.id))
      .map((i) => ({ ...i, isExternalUser: true }));
    const filteredSelectedUsers = selectedUsers?.filter((user) => user?.id !== currentUser?.id);

    const parsedSelected = [];

    for (const item of filteredSelectedUsers) {
      if ("user" in item && typeof item?.user === "object") {
        parsedSelected.push({ ...item?.user });
      } else {
        parsedSelected.push(item);
      }
    }

    if (search) {
      return isGroupAll
        ? [...filteredExternalUsers, ...parsedSelected]
        : [...filteredExternalUsers, ...filteredInnerUsers, ...parsedSelected];
    }

    return isGroupAll
      ? [...parsedSelected, ...filteredExternalUsers]
      : [...parsedSelected, ...filteredExternalUsers, ...filteredInnerUsers];
  }, [selectedUsers, searchedExternalUsers, participantsUsers?.content, meet.externalUsers, currentUser]);

  const loadMoreData = useCallback(async () => {
    if (participantsUsers && (currentSublistType === "Others" || search) && current >= participantsUsers.total) return;

    let sublistUpdation = currentSublistType;

    if (current > participantsUsers.total && !search) {
      sublistUpdation = currentSublistType === "Delegation" ? "Department" : "Others";
    }

    const sublistParams = search ? {} : getSublistParams(sublistUpdation);

    const params = {
      id: meet.id,
      params: { query: search, current, ...sublistParams, ...defaultQueryParams },
    };

    setGetUsersParams(params);
    setCurrent(current + next);
    !search && setCurrentSublistType(sublistUpdation);
  }, [participantsUsers, current, search]);

  const handleResetSearch = useCallback(() => {
    setSearch("");
    setSearchedExternalUsers([]);
    setParticipantsUsers({ content: [], total: 0 });
    setGetUsersParams({
      id: meet.id,
      params: { ...defaultQueryParams, showFromDelegationChain: true, current: 0 },
    });
  }, [meet]);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setUserToSearch(search);
      },
      search ? 1000 : 0,
    );

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (!searchedExternalUsers?.length && !participantsUsers?.content?.length && isEmail(search)) {
      setIsInviteExternalParticipantBtnVisible(true);
    } else {
      setIsInviteExternalParticipantBtnVisible(false);
    }
  }, [search, searchedExternalUsers, participantsUsers?.content]);

  useEffect(() => {
    if (!searchedExternalUsers?.length && !participantsUsers?.content?.length && isEmail(search)) {
      setIsInviteExternalParticipantBtnVisible(true);
    } else {
      setIsInviteExternalParticipantBtnVisible(false);
    }
  }, [search, searchedExternalUsers, participantsUsers?.content]);

  useEffect(() => {
    const mergedArrs = unionBy(participantsUsers.content, participantsUsersResponse?.content ?? [], "id");

    const update = {
      content: mergedArrs,
      total: participantsUsersResponse?.total ?? 0,
    };

    setParticipantsUsers(update);
  }, [participantsUsersResponse]);

  useEffect(() => {
    setSearchedExternalUsers(searchedExternalUsersResponse ?? []);
  }, [searchedExternalUsersResponse]);

  useEffect(() => {
    search && setParticipantsUsers({ content: [], total: 0 });
    setGetUsersParams(defaultGetUserParams);
  }, [search]);

  return useMemo(
    () => ({
      search,
      listData,
      selectedUsers,
      isLoading,
      isFetching,
      isInviteExternalParticipantBtnVisible,
      isInviteExternalParticipantModalVisible,
      searchedExternalUsersResponse,
      initialParticipants,
      setSearch,
      setCurrent,
      handleResetSearch,
      loadMoreData,
      setSearchedExternalUsers,
      setGetUsersParams,
      setIsInviteExternalParticipantModalVisible,
      setSelectedUsers,
      setCurrentSublistType,
    }),
    [
      search,
      listData,
      selectedUsers,
      isLoading,
      isFetching,
      isInviteExternalParticipantBtnVisible,
      isInviteExternalParticipantModalVisible,
      searchedExternalUsersResponse,
      initialParticipants,
      setSearch,
      setCurrent,
      handleResetSearch,
      loadMoreData,
      setSearchedExternalUsers,
      setGetUsersParams,
      setIsInviteExternalParticipantModalVisible,
      setSelectedUsers,
      setCurrentSublistType,
    ],
  );
};
