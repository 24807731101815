import React from "react";

import { ReactComponent as SvgRectangle } from "@assets/rectangle.svg";
import { ReactComponent as SvgSuccess } from "@assets/success_task.svg";
import { TTypeIconTask } from "@interfaces/businessGoals.interface";
import { colors } from "@screens/day/components/ListTasks/taskItem/icon/config";

export interface IIcon {
  status: TTypeIconTask;
  isCompleted: boolean;
}

export const Icon = ({ status, isCompleted }: IIcon) => (
  <div style={{ position: "relative" }}>
    <SvgRectangle width={17} height={12} fill={colors(isCompleted)[status].bg} />
    {isCompleted ? <SvgSuccess width={12} height={12} style={{ ...styles.success, position: "absolute" }} /> : ""}
  </div>
);

const styles = {
  success: {
    top: 0,
    left: "20%",
  },
};
