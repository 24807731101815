import { ITheme } from "@interfaces/index";

export const Colors: Record<ITheme.TKeysTheme, ITheme.IColors> = {
  LIGHT: {
    green: "#219653",
    green100: "#EFFAF4",
    greenHover: "#30D158",
    white: "#FFFFFF",
    blue: "#007AFF",
    lightBlue: "#bcd3ff",
    lightRed: "#FFEFEF",
    red: "#FF3B30",
    lightYellow: "#FFF3D4",
    yellow: "#FFB800",
    purple: "#8264FB",
    orange: "#FB9231",
    orange20: "#FB923133",
    grey: "#C7C7C7",
    grey500: "#5F6E67",
    lighGrey: "#E3E9E7",
    black: "#000000",
    darkPurple: "#191945",
    lightPurple: "#F8F9FF",
    disabled: "#ECF0EF",
    lighterYellow: "#FFE9B0",
    softGrey: "#FBFBFB",
    asphalt: "#A1B6AD",

    text: {
      main: "#274335",
      accent: "#297952",
      grey: "#5F6E67",
      darkGreen: "#274335",
    },
    placeholder: {
      main: "#B3BEB8",
      system: "#777777",
    },
    background: {
      main: "#F5F9F8",
      lightGreen: "#72AF5C",
      darkGreen: "#274335",
      green: "#297952",
      grey: "#B3BEB8",
    },
    title: "#274335",
  },
  DARK: {
    green: "#219653",
    green100: "#EFFAF4",
    white: "black",
    greenHover: "#30D158",
    blue: "#007AFF",
    lightBlue: "#bcd3ff",
    lightRed: "#FFEFEF",
    red: "#FF3B30",
    lightYellow: "#FFF3D4",
    yellow: "#FFB800",
    purple: "#8264FB",
    orange: "#FB9231",
    orange20: "#FB923133",
    grey: "#C7C7C7",
    grey500: "#5F6E67",
    lighGrey: "#E3E9E7",
    black: "#000000",
    darkPurple: "#191945",
    lightPurple: "#F8F9FF",
    disabled: "#ECF0EF",
    lighterYellow: "#FFE9B0",
    softGrey: "#FBFBFB",
    asphalt: "#A1B6AD",

    text: {
      main: "#274335",
      accent: "#297952",
      grey: "#5F6E67",
      darkGreen: "#274335",
    },
    placeholder: {
      main: "#B3BEB8",
      system: "#777777",
    },
    background: {
      main: "#F5F9F8",
      lightGreen: "#72AF5C",
      darkGreen: "#274335",
      green: "#297952",
      grey: "#B3BEB8",
    },
    title: "#274335",
  },
  ASSISTANT: {
    green: "#433327",
    green100: "#F0EFEC",
    white: "#FFFFFF",
    blue: "#007AFF",
    lightBlue: "#bcd3ff",
    lightRed: "#FFEFEF",
    red: "#FF3B30",
    lightYellow: "#FFF3D4",
    yellow: "#FFB800",
    purple: "#8264FB",
    orange: "#FB9231",
    orange20: "#FB923133",
    grey: "#C7C7C7",
    grey500: "#5F6E67",
    lighGrey: "#F0EFEC",
    black: "#000000",
    darkPurple: "#191945",
    lightPurple: "#F8F9FF",
    disabled: "#E4E2DB",
    lighterYellow: "#FFE9B0",
    softGrey: "#FBFBFB",
    asphalt: "#A1B6AD",

    text: {
      main: "#433327",
      accent: "#795929",
      grey: "#5F6E67",
      darkGreen: "#433327",
    },
    placeholder: {
      main: "#B3BEB8",
      system: "#777777",
    },
    background: {
      main: "#F9F7F5",
      lightGreen: "#72AF5C",
      darkGreen: "#453831",
      green: "#795929",
      grey: "#B3BEB8",
    },
    title: "#453831",
    greenHover: "#F0EFEC",
  },
};
