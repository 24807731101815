import React, { useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";

import { daysOfWeekDefault } from "@screens/day/components/header/modal/CalendarCustom";
import { stylesForDay } from "@shared/planningWork/utils";
import { selectCurrentDate } from "@store/screenDay";
import { TextFont } from "@ui";
import { toFormatDate } from "@utils/toFormatTime";

type TWeek = Record<string, Dayjs>;

interface IEmptyCalendar {
  type?: "default" | "multituple";
  press: (...arg: any) => void;
  days?: any[];
  currentDay: string;
  startDay: string | null;
  yearOverview?: boolean;
  deadline?: string;
}

export const EmptyCalendar = ({ press, days = [], currentDay, type = "default", startDay, deadline }: IEmptyCalendar) => {
  const dateNow = useSelector(selectCurrentDate);

  const month = useMemo(() => {
    const resultMonth: Array<TWeek> = [];
    let index = 0;
    const arrayDays = [...Array(dateNow.daysInMonth() + 1).keys()].filter((i) => i !== 0);

    for (let i = 0; i < 5; i++) {
      const week: TWeek = {};
      daysOfWeekDefault.forEach((day: string) => {
        if (index < arrayDays.length) {
          week[day] = dateNow.date(arrayDays[index]);
          index += 1;
        }
      });
      resultMonth.push(week);
    }

    return resultMonth;
  }, []);

  return (
    <div style={styles.container}>
      {month.map((week, index) => (
        <div style={styles.bodyRow} key={index}>
          {Object.entries(week).map(([, dateWeek]) => {
            if (!dateWeek) return <div style={styles.bodyCell} />;

            const config = {
              isSelect: {
                multituple: !!days.find((i) => i.date === toFormatDate(dateWeek)),
                default: toFormatDate(dateWeek) === (currentDay ? toFormatDate(dayjs(currentDay)) : toFormatDate(dateNow)),
              },
              isDisabled: {
                multituple: toFormatDate(dateWeek) < toFormatDate(dayjs()),
                default: startDay ? dateWeek < dayjs(startDay) : false,
              },
            };

            const { wrapper, text } = stylesForDay({
              isSelect: config.isSelect[type],
              isToday: toFormatDate(dateNow) === toFormatDate(dateWeek),
              isDeadline: toFormatDate(dayjs(deadline ?? "")) === toFormatDate(dateWeek),
              deadline,
            });

            return (
              <button key={String(dateWeek)} style={{ ...styles.bodyCell, position: "relative" }} onClick={() => press(dateWeek)}>
                <div style={{ ...styles.bodyTextWrap, ...wrapper }}>
                  <TextFont style={{ ...styles.textDay, ...text }}>{`${new Date(String(dateWeek)).getDate()}`}</TextFont>
                </div>
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: "13px",
    padding: "9px 16px",
  },
  bodyRow: {
    display: "flex",
  },
  bodyCell: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "8px",
    width: "14.5%",
  },
  bodyTextWrap: {
    display: "flex",
    "flex-direction": "column",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
    padding: "2px",
  },
  textDay: {
    fontSize: "20px",
    fontWeight: "400",
  },
};
