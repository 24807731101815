import styled from "styled-components";

import globalState from "@utils/globalState";

interface IProps {
  color?: string;
  size?: string;
  vertical?: boolean;
}

export const LineHorizontal = ({ vertical, color, size }: IProps) => (
  <Line vertical={vertical} size={size ?? "1px"} color={color ?? globalState.colorSchema.lighGrey} />
);

const Line = styled.section<IProps>`
  display: flex;
  width: 100%;
  height: ${(props) => props.size};
  background-color: ${(props) => props.color};
`;
