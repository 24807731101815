import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import img from "@assets/notFound.png";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { selectProject } from "@store/project";
import { getListProject } from "@store/project";
import { TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";

import { ProjectGroup } from "./project-group";

const eventHelperUtils = new EventHelperUtils();

interface IProps {
  handleProject?: (name: string, value: any, nameProject?: string) => void;
  style?: any;
  isFilter?: boolean;
}

export const Projects = ({ handleProject = () => "", style = {}, isFilter = false }: IProps) => {
  const dispatch = useDispatch<any>();
  const { projects, isLoading } = useSelector(selectProject);

  const [search, setSearch] = useState<string>("");
  const arrayEqualId = new Set<string>();

  useEffect(() => {
    dispatch(getListProject(""));
  }, []);

  const handleSearch = (val: string) => {
    setSearch(val);
    eventHelperUtils.debounce(() => {
      dispatch(getListProject(val));
    }, 1000);
  };

  return (
    <section style={{ ...styles.container, ...style }}>
      <div style={styles.filterContainer}>
        <div style={styles.searchInput}>
          <SvgSearch />
          <input value={search} onChange={(e) => handleSearch(e.target.value)} style={styles.input} placeholder="Поиск" maxLength={255} />
        </div>
        <RenderWithCondition condition={!!search}>
          <button onClick={() => handleSearch("")}>
            <TextFont style={styles.textDrop}>
              <FormattedMessage id={"drop"} />
            </TextFont>
          </button>
        </RenderWithCondition>
      </div>
      <LoaderRenderWithCondition condition={isLoading}>
        {projects?.length ? (
          <div style={styles.projectsContainer}>
            {projects.map((project, index) => (
              <ProjectGroup
                key={`${project.id}-${index}`}
                arrayEqualId={arrayEqualId}
                handleProject={handleProject}
                isFilter={isFilter}
                search={search}
                {...project}
              />
            ))}
          </div>
        ) : (
          <div style={styles.empty}>
            <img src={img} style={styles.image} />
            <TextFont size={24} type="bold" weight="700" style={{ marginBottom: 20 }}>
              <FormattedMessage id="projectNotFound" />
            </TextFont>
            <TextFont size={16}>
              <FormattedMessage id="projectNotFoundDescription" />
            </TextFont>
          </div>
        )}
      </LoaderRenderWithCondition>
    </section>
  );
};

const styles = {
  container: {
    height: "82%",
    display: "flex",
    "flex-direction": "column",
    backgroundColor: "#F5F9F8",
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 70,
    overflow: "auto",
  },
  filterContainer: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  textDrop: {
    fontSize: 16,
    lineHeight: "24px",
    color: "#297952",
    marginLeft: 15,
  },
  searchInput: {
    flex: 1,
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    height: 38,
    borderRadius: 10,
    backgroundColor: "#ECF0EF",
    padding: "0 8px",
  },
  input: {
    marginLeft: 6,
    width: "100%",
  },
  projectsContainer: {
    display: "flex",
    "flex-direction": "column",
  },
  image: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 30,
  },
  empty: {
    display: "flex",
    "flex-direction": "column",
    marginTop: 70,
    justifyContent: "center",
    alignItems: "center",
  },
};
