const configMounth: Record<string, string> = {
  январь: "Января",
  февраль: "Февраля",
  март: "Марта",
  апрель: "Апреля",
  май: "Мая",
  июнь: "Июня",
  июль: "Июля",
  август: "Августа",
  сентябрь: "Сентября",
  октябрь: "Октября",
  ноябрь: "Ноября",
  декабрь: "Декабря",
};

export function changeFirstAndLastLetter(string: string) {
  return configMounth[string];
}
