import dayjs from "dayjs";

interface ISortingForMinute {
  startTime: string;
  endTime: string;
}

export const sortingForMinute = (a: Partial<ISortingForMinute>, b: Partial<ISortingForMinute>) => {
  if (dayjs(a.startTime).isAfter(b.startTime, "minute")) return 1;
  if (dayjs(a.startTime).isBefore(b.startTime, "minute")) return -1;

  if (dayjs(a.endTime).isBefore(b.endTime, "minutes")) return 1;
  if (dayjs(a.endTime).isAfter(b.endTime, "minutes")) return -1;

  return 0;
};
