import React, { MouseEvent, useMemo } from "react";

import { useSelector } from "react-redux";

import { IGoal } from "@interfaces/businessGoals.interface";
import { selectIsModalOpen } from "@store/configure";
import { TextFont } from "@ui/TextFont";
import globalState from "@utils/globalState";

import { Icon } from "../Icon/Icon";

interface IGoalItem {
  item: IGoal;
  index: number;
  setCurrentGoal: (id: string) => void;
  onRightClick: (arg1: MouseEvent<HTMLButtonElement>, arg2: string) => void;
}

export const GoalItem = ({ item, setCurrentGoal, onRightClick, index, ...props }: IGoalItem) => {
  const { provided, innerRef, snapshot } = props;
  const { colorSchema } = globalState;

  const isModalOpen = useSelector(selectIsModalOpen);

  const isHide = useMemo(() => item.status === "DONE" || isModalOpen, [isModalOpen, item.status]);

  const amount = useMemo(() => {
    if (!item?.businessTasks?.length) {
      return "";
    }

    const allTasks = item.businessTasks.filter((i) => i.status !== "CLOSED").length;
    const completedTasks = item.businessTasks.filter((i) => i.status === "COMPLETED").length;

    if (!allTasks) return "";
    if (!completedTasks) return allTasks;

    return `${completedTasks}/${allTasks}`;
  }, [item.businessTasks]);

  const handlePress = () => {
    setCurrentGoal(item.id);
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  return (
    <section style={{ width: "100%", position: "relative" }}>
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={innerRef}
        style={{ ...styles.container, ...getItemStyle(provided.draggableProps.style) }}
        onClick={handlePress}
        onContextMenu={(e) => onRightClick(e, item.id)}
      >
        <div style={styles.icon}>
          <TextFont style={{ color: isHide ? colorSchema.grey : colorSchema.grey500 }}>{`${index + 1}.`}</TextFont>
          <Icon priority={item.priority} status={item.status} />
        </div>
        <TextFont color={isHide ? colorSchema.grey : colorSchema.text.main} style={{ ...styles.description }}>
          {item.description}
        </TextFont>
        <TextFont color={isHide ? colorSchema.grey : "#787878"} style={styles.tasks}>
          {amount}
        </TextFont>
      </div>
    </section>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "5% 90% 5%",
    alignItems: "center",
    paddingBottom: "10px",
    backgroundColor: "#F5F9F8",
    maxWidth: "100%",
    paddingRight: "12px",
  },
  icon: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
  },
  description: {
    fontSize: "16px",
    fontWeight: "400",
    "text-align": "left",
    textAlignVertical: "center",
    marginLeft: "20px",
    width: "85%",
    overflow: "hidden",
  },
  tasks: {
    alignSelf: "flex-start",
    justifySelf: "flex-end",
    color: "#787878",
    fontSize: "12px",
    fontWeight: "400",
  },
};
