import { BASE_URL, THEME, URL_DEV } from "@constants/settings";
import { ITheme } from "@interfaces";
import { Colors } from "@theme/colors";

import { secureStore } from "./secureStore";

class GlobalState {
  private defaultUrl: string = URL_DEV;
  protected theme: ITheme.TKeysTheme = "LIGHT";
  public colorSchema: ITheme.IColors = Colors.LIGHT;

  public saveTheme(schema: ITheme.TKeysTheme) {
    this.colorSchema = Colors[schema];
    this.theme = schema;
  }

  public async getBaseUrlApi() {
    try {
      const url = await secureStore.getValue(BASE_URL);
      return url;
    } catch (error) {
      return this.defaultUrl;
    }
  }
  public setBaseUrlApi(baseUrl?: string) {
    secureStore.saveValue(BASE_URL, baseUrl ?? this.defaultUrl);
  }
}

export default new GlobalState();
