import React from "react";

import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";

import { TextFont } from "@ui";
import { ModalUI } from "@ui/modal/ModalUI";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
}

export const ModalWarning = ({ isVisible, closeEvent }: IProps) => (
  <ModalUI isVisible={isVisible} onClose={closeEvent}>
    <Container>
      <TextFont style={{ fontSize: "18px", lineHeight: "24px", marginBottom: "20px" }}>
        <FormattedMessage id="authHelp" />
      </TextFont>
      <Button onClick={closeEvent}>
        <TextFont color="#FFFFFF" size={"18px"} weight="700">
          Ок
        </TextFont>
      </Button>
    </Container>
  </ModalUI>
);

const Container = styled.div`
  flex: 1;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
`;

const Button = styled.button`
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #297952;
  border-radius: 10px;
  margin-top: 20px;
`;
