import React, { useMemo, useRef, useState } from "react";

import { isArray, isEqual, isObject, omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useSubscribe } from "@hooks/useSubscribe";
import { TTaskSubType } from "@interfaces/businessTask.interface";
import { Layout } from "@layout";
import { SwipeListSections, Modal, SwipeList } from "@screens/day/components/ListTasks";
import { selectBusinessTasks, dropFilter, getFilteredList, selectAllTasks, paggingFilteredList } from "@store/businessTask";
import { defaultFilter } from "@store/businessTask/constants";
import { selectConfigure } from "@store/configure";
import { selectNotifications, setTaskID } from "@store/notification";
import { AddCircle, EmptyBusinessTasks, ItemListContentLoader, Loader } from "@ui";
import { showToast } from "@utils";
import { myTracker } from "@utils/myTracker";

import { ButtonsAllTasks } from "../day/components/ListTasks/taskItem/buttons";

import { NotFound } from "./components/notFound";
import { Header } from "./header";

export const Task = () => {
  const listInnerRef = useRef();
  const dispatch = useDispatch<any>();
  const { isTablet } = useDimension();

  const {
    filter: { name, groupByType },
    filter,
    isLoadingList,
    isLoadingPaggingAll,
    errorMsg,
    allTasks: { content: listTasks, total },
  } = useSelector(selectBusinessTasks);
  const { isModalOpen } = useSelector(selectConfigure);
  const groupedTasks = useSelector(selectAllTasks);
  const notifications = useSelector(selectNotifications);

  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState("");
  const [taskSubType, setTaskSubType] = useState<TTaskSubType>();

  useSubscribe(
    "updateTasks",
    () => {
      dispatch(
        getFilteredList({
          name,
          groupByType,
          pageable: { next: 20, current: 0 },
          notLoader: true,
        }),
      );
    },
    [name, groupByType],
  );

  React.useEffect(() => {
    if (notifications.task) {
      handlePress(notifications.task.id, notifications.task.type);
      dispatch(setTaskID(undefined));
    }
  }, [notifications.task]);

  const handlePress = (id: string, subType?: TTaskSubType) => {
    setId(id);
    setTaskSubType(subType);
    setIsVisible(true);
  };

  const handleResetFilter = () => {
    dispatch(getFilteredList({ name, groupByType, pageable: { next: 20, current: 0 } }));
    dispatch(dropFilter({ name, groupByType }));
  };

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) - 10 === scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  const loadMoreData = async () => {
    if (!hasMoreItems) return;

    try {
      await dispatch(
        paggingFilteredList({
          ...filter,
          pageable: {
            next: 20,
            current: Math.ceil(listTasks.length / 20) * 20,
          },
        }),
      );
    } catch (error) {
      return showToast("dataDidNotLoaded", "error");
    }
  };

  const hasMoreItems = useMemo(
    () => !(listTasks.length >= total || isLoadingPaggingAll || errorMsg),
    [errorMsg, isLoadingPaggingAll, listTasks.length, total],
  );
  const isDefaultFilter = useMemo(() => isEqual(omit(filter, ["pageable"]), omit(defaultFilter, ["pageable"])), [filter]);

  const renderFooter = (
    <div style={{ ...styles.footer, position: "relative" }}>
      <Loader />
    </div>
  );

  const renderTasks = useMemo(() => {
    const isEmptyArray = !listTasks.length;

    if (isEmptyArray && isDefaultFilter) {
      return <EmptyBusinessTasks press={() => handlePress("")} />;
    } else if (isEmptyArray) {
      return <NotFound handleResetFilter={handleResetFilter} />;
    } else if (isArray(groupedTasks)) {
      return (
        <SwipeList
          onScroll={handleScroll}
          listInnerRef={listInnerRef}
          data={groupedTasks}
          press={handlePress}
          buttons={(data) => <ButtonsAllTasks {...data} />}
          footer={isLoadingPaggingAll && renderFooter}
        />
      );
    } else if (groupedTasks && isObject(groupedTasks)) {
      return (
        <SwipeListSections
          onScroll={handleScroll}
          listInnerRef={listInnerRef}
          data={groupedTasks}
          press={handlePress}
          isAllTasks={true}
          footer={isLoadingPaggingAll && renderFooter}
        />
      );
    }
  }, [groupedTasks, listTasks, isLoadingPaggingAll, isDefaultFilter]);

  return (
    <Layout header={<Header />} backgroundColor="#fff">
      <LoaderRenderWithCondition
        condition={isLoadingList && !isModalOpen}
        loaderComponent={<ItemListContentLoader width={window.innerWidth} height={window.innerHeight} />}
      >
        <TabletContainer>
          <div style={{ width: isVisible && isTablet ? "65%" : "100%", transition: "width 300ms" }}>{renderTasks}</div>
          <ModalRenderWithCondition condition={isVisible}>
            <div style={styles.modalTask}>
              <Modal isModal={!isTablet} isVisible={isVisible} setIsVisible={setIsVisible} id={id} taskSubType={taskSubType} />
            </div>
          </ModalRenderWithCondition>
        </TabletContainer>
      </LoaderRenderWithCondition>
      <RenderWithCondition condition={listTasks.length || !isDefaultFilter}>
        <AddCircle
          press={() => {
            myTracker("CreateTaskOnAllClick");
            handlePress("");
          }}
          bottom={100}
          right={20}
        />
      </RenderWithCondition>
    </Layout>
  );
};

const styles = {
  modalTask: {
    width: "35%",
    backgroundColor: "#F5F9F8",
    zIndex: 999,
  },
  footer: {
    width: "100%",
    height: 50,
  },
};

const TabletContainer = styled.div`
  display: flex;
  height: 95%;
  margin-top: 20px;
`;
