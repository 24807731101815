import React from "react";

import { Projects } from "@shared/projects";
import { ModalUI, HeaderModal } from "@ui";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
}

export const ProjectModal = ({ isVisible, closeEvent }: IProps) => (
  <ModalUI isVisible={isVisible} scrollEnable={false} onClose={closeEvent}>
    <HeaderModal title={"project"} onClose={closeEvent} />
    <Projects style={{ paddingBottom: 20 }} />
  </ModalUI>
);
