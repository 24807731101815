import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AnyAction } from "@reduxjs/toolkit";
import { FlatListProps } from "flatlist-react/lib";
import { useDispatch } from "react-redux";

import { Loader } from "@ui";
import { showToast } from "@utils";
import EventHelperUtils from "@utils/event-helper.utills";

const eventHelperUtils = new EventHelperUtils();

interface IProps {
  sendPagination: (args: Record<string, unknown>) => AnyAction;
  sendList?: (args: Record<string, unknown>) => AnyAction;
  totalPages: number;
  argsPagingCallback?: Record<string, unknown>;
  errorMsg?: unknown;
  size?: number;
  allowSearch?: boolean;
}

interface IReturn {
  attrSwipeList: Partial<FlatListProps<unknown>>;
  setPage: (arg: number) => void;
  search: string;
  setSearch: (arg: string) => void;
}

export const usePagination = ({ size = 20, ...props }: IProps): IReturn => {
  const dispatch = useDispatch();
  const listInnerRef = useRef();

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (props.allowSearch) {
      setPage(0);
      eventHelperUtils.debounce(
        async () => {
          props.sendList && dispatch(props.sendList({ page: 0, size, name: search }));
        },
        search ? 1000 : 0,
      );
    }
  }, [props.allowSearch, search]);

  const loadMoreData = useCallback(async () => {
    if (page + 1 >= props.totalPages || isLoading || props.errorMsg) return;
    setIsLoading(true);

    try {
      await dispatch(props.sendPagination({ page: page + 1, size, ...props.argsPagingCallback }));
      setPage(page + 1);
    } catch (error) {
      return showToast("dataDidNotLoaded", "error");
    }

    setIsLoading(false);
  }, [isLoading, page, props, size]);

  const handleScroll = useCallback(async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) - 10 === scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  }, [loadMoreData]);

  const footer = useMemo(
    () =>
      isLoading && (
        <div style={{ width: "100%", height: 50, position: "relative" }}>
          <Loader />
        </div>
      ),
    [isLoading],
  );

  return useMemo(
    () => ({
      attrSwipeList: {
        onScroll: handleScroll,
        listInnerRef: listInnerRef,
        footer,
      },
      setPage,
      search,
      setSearch,
    }),
    [footer, handleScroll, loadMoreData, search],
  );
};
