/* eslint-disable react-hooks/rules-of-hooks  */

import { useSelector } from "react-redux";

import { Colors } from "@theme/colors";

import { RootState } from "../store";

export const themeSelector = () => useSelector((state: RootState) => state.theme);

export const selectTheme = (type: "extra" | "main" = "main") => useSelector((state: RootState) => Colors[state.theme[type]]);

/* eslint-enable */
