import React, { CSSProperties, FC, useState } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgDelegate } from "@assets/delegate.svg";
import { ModalRenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { InterfaceUser } from "@interfaces/index";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { ModalDelegate } from "../../../components/modalDelegate";

interface IProps {
  task: ITask;
  onSave?: (user: InterfaceUser.IStateUser) => void;
  styleButton?: CSSProperties;
  sizeIcon?: number;
  delegatedToUser?: InterfaceUser.IStateUser | null;
  handleTakeMe?: () => void;
  isNeedCloseAfterSave?: boolean;
}

export const ButtonDelegate: FC<IProps> = ({
  onSave,
  handleTakeMe,
  task,
  styleButton,
  sizeIcon,
  isNeedCloseAfterSave,
  delegatedToUser,
}) => {
  const [isVisibleDelegate, setIsVisibleDelegate] = useState(false);

  return (
    <div>
      <button style={{ ...styles.button, ...styles.buttonDefault, ...styleButton }} onClick={() => setIsVisibleDelegate(true)}>
        <SvgDelegate width={sizeIcon ?? 24} />
        <TextFont color={globalState.colorSchema.yellow} style={styles.text}>
          <FormattedMessage id="delegate" />
        </TextFont>
      </button>

      <ModalRenderWithCondition condition={isVisibleDelegate}>
        <ModalDelegate
          close={() => setIsVisibleDelegate(false)}
          isModal={true}
          onSave={onSave}
          task={task}
          delegatedToUser={delegatedToUser}
          onTakeMe={handleTakeMe}
          isNeedCloseAfterSave={isNeedCloseAfterSave}
        />
      </ModalRenderWithCondition>
    </div>
  );
};

const styles = {
  button: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "8px",
    width: "100%",
  },
  buttonDefault: {
    borderRadius: "11px",
  },
  text: {
    fontSize: "18px",
    fontWeight: "400",
    marginLeft: "8px",
  },
};
