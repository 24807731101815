import React, { FC, useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TTypeOfAction, TTypeParticipants } from "@interfaces/meet.interface";
import { LineHorizontal } from "@shared";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ArrowSvg, ModalUI, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import globalState from "@utils/globalState";

import { AttendanceStatuses } from "../types";

import { getButtonContentByStatus } from "./ButtonContent";
import { ModalComment } from "./ModalComment";

export interface IProps {
  isSerieOrException: boolean;
  currentStatus: TTypeParticipants;
  isStatusSet?: boolean;
  onPress: (status: TTypeParticipants, comment: string) => void;
  setTypeAction: (arg: TTypeOfAction) => void;
  typeAction: TTypeOfAction;
  participantStatusComment: string;
}

export const CurrentAttendanceStatus: FC<IProps> = ({
  currentStatus,
  isStatusSet,
  onPress,
  isSerieOrException,
  setTypeAction,
  typeAction,
  participantStatusComment,
}) => {
  const options = [AttendanceStatuses.ATTENDER, AttendanceStatuses.NON_ATTENDER, AttendanceStatuses.MAYBE_ATTENDER];
  const mapTitles = ["onlyIntoThisMeeting", "intoAllMeetingInSeries"];
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const [currentOption, setCurrentOption] = useState<TTypeParticipants | null>(null);
  const [comment, setComment] = useState("");
  const [isVisibleComment, setisVisibleComment] = useState(false);
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);

  useEffect(() => {
    if (!isSerieOrException) {
      setTypeAction("ALL_SERIES");
    }
  }, [isSerieOrException]);

  const style = styles();

  const onPressHandler = async (arg?: TTypeParticipants) => {
    if (currentOption === null && !arg) return;
    setNotificationVisible(false);
    setIsVisibleOptions(false);

    await onPress(arg ?? currentOption, comment);
    setComment("");
    isSerieOrException && setTypeAction("ALL_SERIES");
  };

  const onPressOption = (option: AttendanceStatuses) => {
    if (option === currentStatus) return;

    setCurrentOption(option);
    if (isSerieOrException) {
      setNotificationVisible(true);
    } else if (option === "ATTENDER") {
      onPressHandler(option);
    } else {
      setisVisibleComment(true);
    }
  };

  const handleNotifyType = (type: TTypeOfAction) => {
    setTypeAction(type);
    setNotificationVisible(false);
    if (currentOption === "ATTENDER") {
      onPressHandler();
    } else {
      setisVisibleComment(true);
    }
  };

  useEffect(() => {
    isStatusSet && setIsVisibleOptions(false);
  }, [isStatusSet]);

  return (
    <div style={style.wrapper}>
      <LineHorizontal />
      <div
        style={{ ...commonStyles.billet, ...{ width: "auto", cursor: "pointer", marginTop: 12 } }}
        onClick={() => setIsVisibleOptions(true)}
      >
        <div style={style.selectedOption}>{getButtonContentByStatus(currentStatus)}</div>
        <div style={{ ...style.selectedOption, ...{ gap: "12px" } }}>
          <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
        </div>
      </div>
      {!!participantStatusComment && (
        <TextFont style={{ marginTop: 12 }} size={14} weight="400" color={Colors.LIGHT.grey500}>
          {participantStatusComment}
        </TextFont>
      )}

      <ModalUI isVisible={isVisibleOptions} className="bottomModal" bg={Colors.LIGHT.white} onClose={() => undefined}>
        <div style={{ ...style.modalInnerContainer, ...{ position: "relative" } }}>
          <div onClick={() => setIsVisibleOptions(false)} style={{ ...style.roundContainer, position: "absolute" }}>
            <SvgClose />
          </div>

          <RenderWithCondition condition={!typeAction}>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <TextFont
                weight="400"
                size={16}
                lineHeight={24}
                style={{ marginTop: 10, marginBottom: 20, alignItems: "center", textAlign: "center", width: "100%" }}
              >
                <FormattedMessage id="wantChangeStatusMeeting" />
              </TextFont>
              <div style={style.line} />
              {mapTitles.map((text, index) => (
                <TabText onClick={() => setTypeAction(index === 0 ? "SINGLE_MEETING" : "ALL_SERIES")}>
                  <TextFont
                    weight="400"
                    size={18}
                    lineHeight={24}
                    color="#297952"
                    style={{ marginTop: 10, marginBottom: 10, textAlign: "center", width: "100%" }}
                  >
                    <FormattedMessage id={text} />
                  </TextFont>

                  <div style={style.line} />
                </TabText>
              ))}
            </div>
          </RenderWithCondition>

          <RenderWithCondition condition={typeAction}>
            <div style={{ marginTop: 30 }}>
              {options.map((option) => (
                <>
                  <div style={style.defaultStatusBtn} onClick={() => onPressOption(option)}>
                    {getButtonContentByStatus(option)}
                  </div>
                  <RenderWithCondition condition={option === AttendanceStatuses.MAYBE_ATTENDER}>
                    <TextFont weight="400" size={14} lineHeight={22} color="#B3BEB8" style={{ marginTop: -10, marginBottom: 10 }}>
                      <FormattedMessage id="setMaybeAttenderStatusMeeting" />
                    </TextFont>
                  </RenderWithCondition>
                  <div style={style.line} />
                </>
              ))}
            </div>
          </RenderWithCondition>
        </div>
        <ModalRenderWithCondition condition={isNotificationVisible}>
          <NotificationConfirm
            phraseId={"wantChangeStatusMeeting"}
            phraseOkId="intoAllMeetingInSeries"
            phraseCancelId="onlyIntoThisMeeting"
            onOk={() => handleNotifyType("ALL_SERIES")}
            onCancel={() => handleNotifyType("SINGLE_MEETING")}
            isOpen={isNotificationVisible}
            onClose={() => setNotificationVisible(false)}
          />
        </ModalRenderWithCondition>

        <ModalComment
          value={comment}
          setValue={setComment}
          onClose={() => setisVisibleComment(false)}
          isVisible={isVisibleComment}
          onSend={onPressHandler}
        />
      </ModalUI>
    </div>
  );
};

const styles = () => {
  const theme = globalState.colorSchema;

  return {
    wrapper: {
      display: "flex",
      "justify-content": "flex-start",
      "flex-direction": "column",
    },
    selectedOption: {
      display: "flex",
      alignItems: "center",
      "justify-content": "flex-start",
    },

    defaultStatusBtn: {
      display: "flex",
      "flex-direction": "row",
      alignItems: "center",
      "justify-content": "flex-start",
      height: 54,
      cursor: "pointer",
    },
    roundContainer: {
      display: "flex",
      cursor: "pointer",
      top: 0,
      right: 12,
      alignItems: "center",
      "justify-content": "center",
      width: 30,
      height: 30,
      "border-radius": 15,
      "background-color": theme.disabled,
    },
    modalInnerContainer: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    line: {
      display: "flex",
      width: "100%",
      height: 1,
      "background-color": "#ddd",
    },
  };
};

const TabText = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background-color: ${globalState.colorSchema.disabled};
  }
`;
