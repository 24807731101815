import React, { FC, useState } from "react";

import dayjs from "dayjs";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@components/hoc";
import { NotificationButton } from "@components/ui/notification/NotificationButton";
import { IEventOfDaySimplified } from "@interfaces/eventsOfDay.interface";
import { TTypeOfAction, TTypeParticipants } from "@interfaces/meet.interface";
import { useSetParticipantStatusMutation } from "@services/meetApi";
import { selectCalendar } from "@store/calendar";
import { selectScreenDay } from "@store/screenDay";
import { filterEventsById, updateMeetAttendanceStatus } from "@store/screenDay/slice";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { ContentEditable, TextFont } from "@ui";
import { toFormatDate } from "@utils/toFormatTime";

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  onSend: () => void;

  statusToSet: TTypeParticipants;
  event: IEventOfDaySimplified;
}

export const ModalMeetStatus: FC<IProps> = ({ isVisible, onClose, onSend, statusToSet, event }) => {
  const [setParticipantStatus] = useSetParticipantStatusMutation();
  const isSeries = !!event.repeat;
  const startInnerModal = !event.currentParticipantStatus ? false : isSeries;

  const [typeAction, setTypeAction] = useState<TTypeOfAction>("ALL_SERIES");
  const [comment, setComment] = useState("");
  const [isOpenInnerModal, setOpenInnerModal] = useState(startInnerModal);

  const { currentEvent: currentMeetEvent } = useSelector(selectScreenDay);
  const { currentEventContext } = useSelector(selectScreenDay);
  const { currentUser, directorId } = userSelector();
  const { periodToLoadCalendar } = useSelector(selectCalendar);

  const dispatch = useDispatch();

  const currentEvent = currentEventContext ? currentEventContext : currentMeetEvent;

  const handleSend = (status: TTypeParticipants, dateSingleOfSeries?: string) => {
    onSend();
    requestStatus(status, comment, dateSingleOfSeries);
    onClose();
  };

  const requestStatus = async (status: TTypeParticipants, text: string, dateSingleOfSeries?: string) => {
    const isSingleEvent = !typeAction && !isSeries;
    const isSerieException = isSeries && typeAction && typeAction === "SINGLE_MEETING";
    const isSerie = typeAction ? typeAction === "ALL_SERIES" && isSeries : isSeries;

    const date = isSingleEvent || !isSerieException || isSerie ? null : toFormatDate(dayjs(event.startTime));

    const response = await setParticipantStatus({
      id: currentEvent?.parentEvent?.id && typeAction === "ALL_SERIES" ? currentEvent?.parentEvent?.id : currentEvent.id,
      status,
      date: dateSingleOfSeries ? dateSingleOfSeries : date,
      comment: text,
      participantId: directorId ? directorId : currentUser?.id ?? "",
      selectedDay: toFormatDate(dayjs(event.startTime)),
      period: periodToLoadCalendar,
    });

    if (Object.prototype.hasOwnProperty.call(response, "data")) {
      setComment("");
      updateLocalEvents(currentEvent?.id, status);
    }
  };

  const updateLocalEvents = (meetId: string, status: TTypeParticipants) => {
    if (status === "NON_ATTENDER") {
      dispatch(filterEventsById(meetId));
    } else {
      dispatch(updateMeetAttendanceStatus({ meetId, statusToSet: status }));
    }
  };

  const handleSelect = (status: TTypeOfAction, date?: string) => {
    setOpenInnerModal(false);
    setTypeAction(status);
    if (statusToSet === "ATTENDER") {
      handleSend(statusToSet, date);
    }
  };
  return createPortal(
    <>
      <Container id="wrapperActions" isVisible={isVisible} onClick={(e) => e.stopPropagation()}>
        <RenderWithCondition condition={isOpenInnerModal}>
          <Content onClick={(e) => e.stopPropagation()}>
            <TextFont style={styles.text}>
              <FormattedMessage id={"wantChangeStatusMeeting"} />
            </TextFont>
            <div style={{ ...styles.buttons, ...{ borderTop: "0px" } }}>
              <div style={styles.buttons}>
                <NotificationButton
                  onHandle={() => {
                    handleSelect("SINGLE_MEETING", toFormatDate(dayjs(event.startTime)));
                  }}
                  setIsVisible={() => undefined}
                >
                  <TextFont weight="700" style={{ ...styles.buttonText }}>
                    <FormattedMessage id={"onlyIntoThisMeeting"} />
                  </TextFont>
                </NotificationButton>
              </div>
              <div style={styles.buttons}>
                <NotificationButton
                  onHandle={() => {
                    handleSelect("ALL_SERIES");
                  }}
                  setIsVisible={() => undefined}
                >
                  <TextFont weight="700" style={{ ...styles.buttonText }}>
                    <FormattedMessage id={"intoAllMeetingInSeries"} />
                  </TextFont>
                </NotificationButton>
              </div>
            </div>
          </Content>
        </RenderWithCondition>

        <RenderWithCondition condition={!isOpenInnerModal}>
          <Content onClick={(e) => e.stopPropagation()}>
            <TextFont size={18} type="bold">
              <FormattedMessage id="comment" />
            </TextFont>
            <ContentEditable style={styles.input} onChange={(e) => setComment(e)} value={comment} autoFocus />

            <ButtonLine>
              <button onClick={onClose} style={{ ...styles.button, backgroundColor: Colors.LIGHT.lighGrey }}>
                <TextFont size={18} type="bold" color={Colors.LIGHT.text.grey}>
                  <FormattedMessage id="cancelClose" />
                </TextFont>
              </button>
              <button onClick={() => handleSend(statusToSet)} style={{ ...styles.button, backgroundColor: Colors.LIGHT.background.green }}>
                <TextFont size={18} type="bold" color={Colors.LIGHT.white}>
                  <FormattedMessage id="send" />
                </TextFont>
              </button>
            </ButtonLine>
          </Content>
        </RenderWithCondition>
      </Container>
    </>,
    document.body,
  );
};

const Container = styled.aside<{ isVisible: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #47434391;
  z-index: 999;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  height: ${(props) => (props.isVisible ? "auto" : 0)};
  transition: all 0.5s ease-in;
`;

const ButtonLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 380px;
  padding: 20px 12px;
  height: auto;
  background-color: white;
  border-radius: 10px;
`;

const styles = {
  input: {
    border: `1px solid ${Colors.LIGHT.background.grey}`,
    borderRadius: 10,
    padding: "16px 12px",
  },
  button: {
    display: "flex",
    borderRadius: 10,
    justifyContent: "center",
    paddingTop: 16,
    paddingBottom: 16,
    width: "45%",
  },
  text: {
    "text-align": "center",
    fontSize: "18px",
    color: "#000000",
    padding: "16px",
    fontWeight: "400",
  },
  buttons: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    borderTop: "0.5px solid rgba(60, 60, 67, 0.36)",
    paddingTop: 6,
  },
  buttonText: {
    "text-align": "center",
    color: "#297952",
    fontSize: "18px",
  },
};
