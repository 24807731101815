import React from "react";

import { ReactComponent as SvgClose } from "@assets/close.svg";
import { ReactComponent as SvgCopy } from "@assets/copy.svg";
import { ReactComponent as SvgDeadline } from "@assets/deadline.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgLocate } from "@assets/locate.svg";
import { ReactComponent as SvgMap } from "@assets/map.svg";
import { ReactComponent as SvgPause } from "@assets/pause.svg";
import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";

export type TKeyButtons = "goal" | "project" | "deadline" | "copy" | "close" | "remove" | "takeBack" | "pause";

interface IValueButtons {
  title: string;
  editTitle?: string;
  allowForComplete?: boolean;
  allowForPause?: boolean;
  icon: JSX.Element;
}

export const config: Record<TKeyButtons, IValueButtons> = {
  goal: {
    title: "goalAdd",
    icon: <SvgLocate />,
    editTitle: "Редактировать цель",
  },
  project: {
    title: "addProject",
    icon: <SvgMap />,
    editTitle: "Редактировать проект",
  },
  deadline: {
    title: "setDeadline",
    icon: <SvgDeadline fill="#C7C7C7" />,
    editTitle: "Редактировать дедлайн",
  },
  copy: {
    title: "copyTask",
    icon: <SvgCopy />,
    allowForComplete: true,
    allowForPause: true,
  },
  close: {
    title: "close",
    icon: <SvgClose fill="#C7C7C7" />,
    allowForPause: true,
  },
  remove: {
    title: "delete",
    icon: <SvgDelete />,
    allowForComplete: true,
    allowForPause: true,
  },
  takeBack: {
    title: "takeBack",
    icon: <SvgTakeBack />,
  },
  pause: {
    title: "suspend",
    icon: <SvgPause />,
  },
};
