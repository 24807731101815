import { createSlice } from "@reduxjs/toolkit";

import { getList, create } from "./api";

interface IInitState {
  tags: { id: string; name: string }[];
  isLoading: boolean;
  errorMsg: null | string;
  tagId: null | string;
  avaliableTags: { id: string; name: string }[];
}

const initialState: IInitState = {
  tags: [],
  avaliableTags: [],
  isLoading: false,
  errorMsg: null,
  tagId: null,
};

const tagSlice = createSlice({
  name: "tagSlice",
  initialState,
  reducers: {
    clearTagId: (state) => {
      state.tagId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getList.fulfilled, (state, { payload }) => {
        if (!payload.search) {
          state.tags = payload?.response?.data;
        }
        state.avaliableTags = payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(getList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(create.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        const result = [...state.tags, payload?.response?.data];

        state.tagId = payload?.response?.data?.id;
        state.tags = result;
        state.avaliableTags = result;
        state.isLoading = false;
      })
      .addCase(create.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export const { clearTagId } = tagSlice.actions;
export default tagSlice.reducer;
