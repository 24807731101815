import React, { useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CalendarGray } from "@assets/calendar-gray.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces/index";
import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { ModalProject } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components/project/modalProject/ModalProject";
import { ModalTag } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components/tag/ModalTag";
import { projectService } from "@services/project.service";
import { setFilter, dropFilter, selectBusinessTasks } from "@store/businessTask";
import { defaultFilter } from "@store/businessTask/constants";
import { selectSpecialTag } from "@store/tag";
import { selectUserPermissoins } from "@store/user";
import { TextFont, ArrowSvg, ModalUI } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDate } from "@utils/toFormatTime";
import { intl } from "@utils/translate";

import { ModalTypeTab, ModalDateTabs, ISwitches } from "../../types";
import { DateTab } from "../dateTab";

import { DelegatedTab } from "./components/DelegatedTab";
import { SwitchBlock } from "./components/SwitchBlock";
import { modalTabs, importanceTabs, executorTabs, statusTabs, switchesData } from "./constants";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
  applyEvent: () => void;
}

export const FilterModal = ({ isVisible, closeEvent, applyEvent }: IProps) => {
  const dispatch = useDispatch<any>();
  const { filter } = useSelector(selectBusinessTasks);
  const {
    createdDateStartRange,
    createdDateEndRange,
    planedEndDateStartRange,
    planedEndDateEndRange,
    executorType,
    priority,
    tagId,
    status,
    name,
    groupByType,
  } = filter;
  const [activeModal, setActiveModal] = useState<ModalTypeTab>("");
  const [activeDateModal, setActiveDateModal] = useState<ModalDateTabs>("");
  const [projectName, setProjectName] = useState("");
  const [switches, setSwitches] = useState(switchesData);

  const permissons = useSelector(selectUserPermissoins);
  const tagStore = useSelector(selectSpecialTag(filter?.tagId ?? ""));

  useEffect(() => {
    const projectId = filter.projectGroupId ?? filter.projectId;

    if (projectId && !projectName) {
      (async () => {
        const response = await projectService.getProject(projectId);
        setProjectName(response.data.name);
      })();
    }

    setSwitches({
      isImportance: Boolean(priority),
      isCreateDate: Boolean(createdDateStartRange || createdDateEndRange),
      isDeadlineDay: Boolean(planedEndDateStartRange || planedEndDateEndRange),
      isStatus: Boolean(status),
    });
  }, []);

  const handleApplyEvent = () => {
    applyEvent();
  };

  const handleSwitch = (key: keyof ISwitches, value: boolean) => {
    const tempFilter = { ...filter };

    switch (key) {
      case "isImportance":
        tempFilter.priority = value ? "URGENT" : null;
        break;
      case "isCreateDate":
        tempFilter.createdDateStartRange = value ? toFormatDate(dayjs()) : null;
        tempFilter.createdDateEndRange = value ? toFormatDate(dayjs()) : null;
        break;
      case "isDeadlineDay":
        tempFilter.planedEndDateStartRange = value ? toFormatDate(dayjs()) : null;
        tempFilter.planedEndDateEndRange = value ? toFormatDate(dayjs()) : null;
        break;
      case "isStatus":
        tempFilter.status = value ? "OPEN" : null;
        break;
      default:
        break;
    }

    setSwitches({
      ...switches,
      [key]: value,
    });

    if (!isEqual(filter, tempFilter)) {
      dispatch(setFilter(tempFilter));
    }
  };

  const handleChange = (key: string, value: boolean | string) => {
    dispatch(setFilter({ [key]: value }));
  };

  const handleDrop = () => {
    dispatch(dropFilter({ name, groupByType }));
    setSwitches(switchesData);
    setProjectName("");
  };

  const pressCalendar = (key: string, dateWeek: Dayjs) => {
    const tempDateWeek: string | null = dateWeek.format("YYYY-MM-DD");
    handleChange(key, tempDateWeek);
    setActiveDateModal("");
  };

  const datePrettier = (value: string) => {
    if (!value) return "";
    return value.split("-").reverse().join(".");
  };

  const dateTabs = () => {
    const closeEvent = () => {
      setActiveDateModal("");
    };

    const tabs: Record<string, JSX.Element> = {
      createdDateStartRange: (
        <DateTab
          dateKey={"createdDateStartRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={createdDateStartRange ?? ""}
        />
      ),
      createdDateEndRange: (
        <DateTab
          dateKey={"createdDateEndRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={createdDateEndRange ?? ""}
        />
      ),
      planedEndDateStartRange: (
        <DateTab
          dateKey={"planedEndDateStartRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={planedEndDateStartRange ?? ""}
        />
      ),
      planedEndDateEndRange: (
        <DateTab
          dateKey={"planedEndDateEndRange"}
          closeEvent={closeEvent}
          handleDate={pressCalendar}
          currentDay={planedEndDateEndRange ?? ""}
        />
      ),
    };

    return tabs[activeDateModal];
  };

  const filteredModalTabs = useMemo(
    () =>
      [...modalTabs].map((tab) => {
        if (tab !== "group" || permissons.WORK_WITH_SPACE_PROJECTS) {
          return tab;
        }
      }),
    [permissons.WORK_WITH_SPACE_PROJECTS, modalTabs],
  );

  const delegatedTitle = (array: InterfaceUser.IStateUser[]) =>
    `${array[0].lastName} ${array[0].firstName[0].toUpperCase()}. ${array.length > 1 ? `+ ${array.length - 1}` : ""}`;

  const renderDisplayData = (tab: ModalTypeTab) => {
    switch (tab) {
      case "group": {
        if (filter.projectId || filter.projectGroupId) return projectName;
        break;
      }
      case "tag": {
        if (filter.tagId && tagStore) return tagStore.name;
        break;
      }
      case "delegatedFrom": {
        if (filter.delegatedFromType)
          return intl.getMessage(filter.delegatedFromType === "DELEGATED_FROM_ME" ? "delegatedMe" : "delegatedAny");
        else if (filter.delegatedFromUsers?.length) return delegatedTitle(filter.delegatedFromUsers);
        return "";
      }
      case "delegatedTo": {
        if (filter.delegatedToType) return intl.getMessage(filter.delegatedToType === "DELEGATED_TO_ME" ? "delegatedMe" : "delegatedAny");
        else if (filter.delegatedToUsers?.length) return delegatedTitle(filter.delegatedToUsers);
        return "";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <ModalUI isVisible={isVisible} onClose={closeEvent}>
      <div style={styles.container}>
        <div style={styles.filterHeadContainer}>
          <button onClick={closeEvent} style={{ zIndex: 1 }}>
            <TextFont style={{ ...modalStyles.headerTitle, fontWeight: "400" }}>
              <FormattedMessage id="close" />
            </TextFont>
          </button>
          <RenderWithCondition condition={!isEqual(filter, defaultFilter)}>
            <button onClick={handleDrop} style={{ marginLeft: 12, zIndex: 1 }}>
              <TextFont style={styles.dropText}>
                <FormattedMessage id="resetAll" />
              </TextFont>
            </button>
          </RenderWithCondition>
          <TextFont style={{ ...styles.title, position: "absolute" }}>
            <FormattedMessage id={"filter"} />
          </TextFont>
        </div>
        <div style={styles.filerMain}>
          <div style={styles.mainHead}>
            {filteredModalTabs.map((modalTab) => (
              <RenderWithCondition key={modalTab} condition={modalTab}>
                <button onClick={() => setActiveModal(modalTab)}>
                  <div style={styles.plainContainer}>
                    <TextFont weight="700" style={styles.text}>
                      <FormattedMessage id={modalTab} />
                    </TextFont>
                    <div style={styles.stateFilter}>
                      <RenderWithCondition condition={renderDisplayData(modalTab)}>
                        <TextFont style={{ width: "70%", color: "#939393", textAlign: "right" }}>{renderDisplayData(modalTab)}</TextFont>
                      </RenderWithCondition>
                      <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
                    </div>
                  </div>
                </button>
              </RenderWithCondition>
            ))}
          </div>
          <div>
            <SwitchBlock title="importance" value={switches.isImportance} onChange={handleSwitch} switchKey="isImportance">
              {Object.keys(importanceTabs).map((element) => (
                <button
                  key={element}
                  onClick={() => handleChange("priority", importanceTabs[element])}
                  style={{
                    ...styles.chooseContainer,
                    borderColor: priority === importanceTabs[element] ? "#297952" : "#E3E9E7",
                    width: "31%",
                    marginRight: 0,
                    minWidth: "auto",
                  }}
                >
                  <TextFont style={styles.text}>
                    <FormattedMessage id={element} />
                  </TextFont>
                </button>
              ))}
            </SwitchBlock>

            <SwitchBlock title="dateOfCreate" value={switches.isCreateDate} onChange={handleSwitch} switchKey="isCreateDate">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <button onClick={() => setActiveDateModal("createdDateStartRange")} style={styles.date}>
                  <TextFont style={styles.text}>{datePrettier(createdDateStartRange ?? "")}</TextFont>
                  <CalendarGray />
                </button>
                <TextFont style={styles.text}>-</TextFont>
                <button onClick={() => setActiveDateModal("createdDateEndRange")} style={styles.date}>
                  <TextFont style={styles.text}>{datePrettier(createdDateEndRange ?? "")}</TextFont>
                  <CalendarGray />
                </button>
              </div>
            </SwitchBlock>

            <SwitchBlock title="deadlineDay" value={switches.isDeadlineDay} onChange={handleSwitch} switchKey="isDeadlineDay">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <button onClick={() => setActiveDateModal("planedEndDateStartRange")} style={styles.date}>
                  <TextFont style={styles.text}>{datePrettier(planedEndDateStartRange ?? "")}</TextFont>
                  <CalendarGray />
                </button>
                <TextFont style={styles.text}>-</TextFont>
                <button onClick={() => setActiveDateModal("planedEndDateEndRange")} style={styles.date}>
                  <TextFont style={styles.text}>{datePrettier(planedEndDateEndRange ?? "")}</TextFont>
                  <CalendarGray />
                </button>
              </div>
            </SwitchBlock>

            <SwitchBlock title="taskStatus" value={switches.isStatus} onChange={handleSwitch} switchKey="isStatus">
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                {Object.keys(statusTabs).map((element) => (
                  <button
                    key={element}
                    onClick={() => handleChange("status", statusTabs[element])}
                    style={{
                      ...styles.chooseContainer,
                      marginBottom: 10,
                      borderColor: status === statusTabs[element] ? "#297952" : "#E3E9E7",
                    }}
                  >
                    <TextFont style={styles.text}>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </button>
                ))}
              </div>
            </SwitchBlock>
          </div>
          <button onClick={handleApplyEvent} style={styles.button}>
            <TextFont style={styles.buttonText}>
              <FormattedMessage id={"apply"} />
            </TextFont>
          </button>
        </div>
      </div>
      <ModalUI isVisible={!!activeDateModal} onClose={() => setActiveDateModal("")}>
        {dateTabs()}
      </ModalUI>
      <ModalTag
        isVisible={activeModal === "tag"}
        setIsVisible={() => setActiveModal("")}
        currentTagId={tagId ?? ""}
        setTag={handleChange}
      />
      <ModalRenderWithCondition condition={activeModal === "group"}>
        <ModalProject
          isVisible={activeModal === "group"}
          close={() => setActiveModal("")}
          handleProject={handleChange}
          isFilter={true}
          title={"filterByGroup"}
          projectName={projectName}
          handleProjectName={setProjectName}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={activeModal === "delegatedFrom"}>
        <DelegatedTab isVisible={true} close={() => setActiveModal("")} initUsers={filter.delegatedFromUsers ?? []} title="delegatedFrom" />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={activeModal === "delegatedTo"}>
        <DelegatedTab isVisible={true} close={() => setActiveModal("")} initUsers={filter.delegatedToUsers ?? []} title="delegatedTo" />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  filterHeadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)",
    padding: 9,
    marginBottom: 40,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    lineHeight: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    "text-align": "center",
  },
  dropText: {
    fontWeight: "700",
    fontSize: 16,
    lineHeight: "24px",
    color: globalState.colorSchema.text.accent,
    zIndex: 1,
  },
  filerMain: {
    padding: "0 12px",
    "overflow-y": "auto",
  },
  mainHead: {
    marginBottom: 20,
    display: "flex",
    "flex-direction": "column",
  },
  plainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #ddd",
  },
  stateFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: "70%",
  },
  text: {
    fontWeight: "400",
    fontSize: 18,
    lineHeight: "24px",
  },
  chooseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 42,
    minWidth: 100,
    width: "auto",
    padding: "0 10px",
    marginRight: 10,
    backgroundColor: "#E3E9E7",
    borderRadius: 5,
    border: "1px solid #E3E9E7",
  },
  date: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 55,
    gap: "5px",
    paddingLeft: 10,
    paddingRight: 20,
    backgroundColor: "#fff",
    border: "1px solid #B3BEB8",
    borderRadius: 10,
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "16px 0px",
    backgroundColor: globalState.colorSchema.background.green,
    borderRadius: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "700",
    "text-align": "center",
  },
};
