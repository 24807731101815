export const userAvatarStyles = {
  userImage: {
    width: 38,
    height: 38,
    borderRadius: 38,
    "object-fit": "cover",
  },
  userBlock: {
    display: "flex",
    "flex-direction": "row",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "8px",
    marginBottom: "10px",
    width: "100%",
  },
  userTitle: {
    fontSize: "16px",
    fontWeight: "700",
    "text-align": "left",
  },
  profileImage: {
    width: 95,
    height: 95,
    "margin-bottom": 10,
    "border-radius": 95,
    "object-fit": "cover",
    border: "3px solid #fff",
  },
};
