import React, { FC, useEffect, useMemo } from "react";

import regexifyString from "regexify-string";

import { IProject } from "@interfaces/projects.interface";
import { TextFont } from "@ui";

import { IPropsProjectGroup, styles } from "./project-group";

interface IProps extends Partial<IPropsProjectGroup> {
  project: IProject;
  index: number;
}

export const ProjectItem: FC<IProps> = ({
  project,
  index,
  counter,
  paddingLeftSub,
  projects,
  name,
  handleProject,
  search,
  childProjectGroups,
  arrayEqualId,
  isFilter,
}) => {
  const isEqualName = useMemo(() => {
    if (search) {
      return new RegExp(search, "gim").test(name || project.shortName);
    }
    return false;
  }, [search, name, project.shortName]);

  useEffect(() => {
    if (isEqualName && typeof project.projectGroupId === "string") {
      arrayEqualId.add(project.projectGroupId);
    }
  }, [isEqualName]);

  const textName = (val: string) => {
    if (!search) return val;

    return regexifyString({
      pattern: new RegExp(search, "gim"),
      decorator: (match, index) => <span style={{ backgroundColor: "#FFE9B0" }}>{match}</span>,
      input: val,
    });
  };

  return (
    <div
      key={`${project.id}-${index}`}
      style={{
        ...styles.projectMain,
        paddingLeft: paddingLeftSub,
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderTopColor: "#E3E9E7",
        borderTopWidth: index === 0 && counter === 1 ? 1 : 0,
        borderBottomColor: "#E3E9E7",
        borderBottomWidth: (projects.length - 1 === index && childProjectGroups.length < 1) || counter > 1 ? 0 : 1,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <TextFont style={{ ...styles.textDot, width: counter === 1 ? 16 : 13 }}>·</TextFont>
        <button
          onClick={() => {
            if (isFilter) handleProject("projectGroupId", "", name);
            handleProject("projectId", project.id, name);
          }}
        >
          <TextFont style={styles.text}>
            {project.shortName && <span style={{ fontWeight: "700" }}>{textName(project.shortName)} </span>}
            {textName(project.name)}
          </TextFont>
        </button>
      </div>
    </div>
  );
};
