import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { IMeet } from "@interfaces/meet.interface";
import { meetService } from "@services";
import { IMeetingTaskAttach } from "@services/meet.service";
import { IAttachEventMeta } from "@services/meet.service";
import { getError } from "@store/getError";

export interface IGetMeetingSeriesResponse {
  [date: string]: IMeet[];
}

export enum AttachEventsEventType {
  MeetingsToTask = "MeetingsToTask",
  TasksToMeeting = "TasksToMeeting",
}

export const attachEvents: any = createAsyncThunk(
  "screenDay/attachEvents",
  async (data: { eventId: string; eventsMeta: IAttachEventMeta[]; eventType: AttachEventsEventType }, thunkAPI) => {
    try {
      const payload: IMeetingTaskAttach[] = [];

      for (const item of data.eventsMeta) {
        payload.push({
          date: dayjs(item.date).format("YYYY-MM-DD"),
          taskId: data.eventType === AttachEventsEventType.MeetingsToTask ? data.eventId : item.id,
          meetingId: data.eventType === AttachEventsEventType.MeetingsToTask ? item.id : data.eventId,
        });
      }

      return await meetService.attachEvents(payload);
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  }
);

export const unattachEvent: any = createAsyncThunk(
  "screenDay/unattachEvent",
  async (data: [{ meetingId: string; taskId: string }], thunkAPI) => {
    try {
      return await meetService.removeTasks(data);
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  }
);

export const getMeetingsSeries: any = createAsyncThunk("screenDay/meetingSeries", async (queryParams: string, thunkAPI) => {
  try {
    const response = await meetService.getSeries(queryParams);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
