import React, { FC } from "react";

import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as SvgAssistant } from "@assets/assistant.svg";
import { IStateUser } from "@interfaces/user.interface";
import { Colors } from "@theme/colors";

import { TextFont } from "./TextFont";

interface IProps {
  assistant: Partial<IStateUser>;
  view?: "svg" | "billet" | "svgInBillet";
}

export const AssistantToast: FC<IProps> = ({ assistant, view = "svg" }) => {
  const toast = (
    <ReactTooltip id="my-tooltip-1" style={styles.toast} place="bottom">
      <TextFont size={14}>{`Ассистент ${assistant.firstName ?? ""} ${assistant.middleName ?? ""} ${assistant.lastName ?? ""}`}</TextFont>
    </ReactTooltip>
  );

  return (
    <div style={{ position: "relative" }}>
      {view === "svg" && (
        <>
          <SvgAssistant data-tooltip-id="my-tooltip-1" />
          {toast}
        </>
      )}

      {view === "svgInBillet" && (
        <div style={{ position: "relative", width: 28 }}>
          <Billet style={{ padding: 5, width: 28 }} data-tooltip-id="my-tooltip-1">
            <SvgAssistant width={17} height={17} fill={"#795929"} />
          </Billet>
          {toast}
        </div>
      )}

      {view === "billet" && (
        <div style={{ position: "relative", maxWidth: 118 }}>
          <Billet>
            <SvgAssistant fill={Colors.ASSISTANT.background.green} />
            <TextFont data-tooltip-id="my-tooltip-1" color={Colors.ASSISTANT.text.accent}>
              Ассистент
            </TextFont>
          </Billet>

          {toast}
        </div>
      )}
    </div>
  );
};

const styles = {
  toast: {
    backgroundColor: "white",
    borderRadius: 6,
    padding: 10,
    boxShadow: " 0px 2px 6px 0px #00000017",
    zIndex: 999,
    width: 232,
  },
};

const Billet = styled.button`
  padding: 2.5px 8px;
  border-radius: 55px;
  background-color: ${Colors.LIGHT.white};
  display: flex;
  align-items: center;
  gap: 2px;
`;
