import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SETTINGS } from "@constants/settings";
import { InterfaceConfigure } from "@interfaces/index";
import { secureStore } from "@utils/secureStore";

import { getInfoVersion, getSettings } from "./api";

const initialState: InterfaceConfigure.IState = {
  isModalOpen: false,
  activeModal: 0,
  pointerEvents: "auto",
  isLoading: false,
  errorMsg: null,
  versionInfo: null,
  settings: {
    isOpenListTasks: false,
    isTightMode: false,
    isPromptDay: false,
    isPromptAnalysis: false,
    isPromptTransfer: false,
  },
};

const configureSlice = createSlice({
  name: "configure",
  initialState,
  reducers: {
    saveModal: (state, action: PayloadAction<boolean>) => {
      let activeModal = 0;

      if (action.payload) {
        activeModal = ++state.activeModal;
      } else if (state.activeModal > 0) {
        activeModal = --state.activeModal;
      } else {
        activeModal = 0;
      }

      state.activeModal = activeModal;
      state.isModalOpen = Boolean(activeModal);
      return state;
    },
    setSetting: (state, action: PayloadAction<{ key: InterfaceConfigure.TypeKeyUserSettings; val: boolean | string }>) => {
      const setting = { ...state.settings, [action.payload.key]: action.payload.val };
      state.settings = setting;
      secureStore.saveValue(SETTINGS, JSON.stringify(setting));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoVersion.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getInfoVersion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.versionInfo = payload.response?.data;
      })
      .addCase(getInfoVersion.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getSettings.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(getSettings.fulfilled, (state, { payload }: PayloadAction<InterfaceConfigure.TypeUserSettings>) => {
        state.settings = payload;
      })
      .addCase(getSettings.rejected, (state, { payload }) => {
        state.errorMsg = payload;
        state.settings = {
          isOpenListTasks: false,
          isTightMode: false,
          isPromptDay: false,
          isPromptAnalysis: false,
          isPromptTransfer: false,
        };
      });
  },
});

export const { saveModal, setSetting } = configureSlice.actions;
export default configureSlice.reducer;
