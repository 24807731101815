import React, { FC, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { ReactComponent as SvgSubTract } from "@assets/subtract.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { ITask } from "@interfaces/businessGoals.interface";
import { IStateUser } from "@interfaces/user.interface";
import { UserInformation } from "@screens/profile/components";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectUserPermissoins } from "@store/user";
import { TextFont, ModalUI, AssistantToast } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDayMonth } from "@utils/toFormatTime";

import { ModalDelegate } from "../../../components/modalDelegate";

interface IProps {
  task: ITask;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  onTakeMe?: () => void;
  disable?: { value: boolean; press: () => void };
}

export const Delegate: FC<IProps> = ({ task, onSave, onTakeMe, disable }) => {
  const [visible, setVisible] = useState<"to" | "from" | undefined>(undefined);
  const [isVisibleDelegate, setIsVisibleDelegate] = useState(false);

  const permissions = useSelector(selectUserPermissoins);

  const handlePressAvatar = (e, type: "from" | "to") => {
    e.stopPropagation();
    setVisible(type);
  };

  return (
    <>
      <RenderWithCondition condition={task.delegatedFromUser}>
        <button onClick={(e) => handlePressAvatar(e, "from")} style={styles.container}>
          <div style={styles.image}>
            <UserAvatar avatarFileId={task.delegatedFromUser?.avatarFileId ?? null} style={{ width: 45, height: 45, borderRadius: 45 }} />
          </div>

          <div style={styles.textBlock}>
            <TextFont style={{ color: globalState.colorSchema.purple, marginBottom: 8 }}>Делегировано от</TextFont>
            <TextFont
              type="bold"
              style={styles.textName}
            >{`${task.delegatedFromUser?.firstName} ${task.delegatedFromUser?.lastName}`}</TextFont>
          </div>

          <div style={styles.sub}>
            {task.delegatedAssistantTask && task.assistant && <AssistantToast assistant={task.assistant as IStateUser} />}
            <RenderWithCondition condition={task.delegatedFromDateTime}>
              <TextFont size={14} color={"#939393"}>
                {toFormatDayMonth(dayjs(task.delegatedFromDateTime ?? ""))}
              </TextFont>
            </RenderWithCondition>
          </div>
        </button>
      </RenderWithCondition>

      <RenderWithCondition condition={permissions.CAN_DELEGATE && task.delegatedToUser}>
        <button
          onClick={() => {
            disable?.value ? disable.press() : setIsVisibleDelegate(true);
          }}
          style={styles.container}
        >
          <button onClick={(e) => handlePressAvatar(e, "to")} style={styles.image}>
            <UserAvatar avatarFileId={task.delegatedToUser?.avatarFileId ?? null} style={{ width: 45, height: 45, borderRadius: 45 }} />
          </button>

          <div style={styles.textBlock}>
            <TextFont style={{ color: globalState.colorSchema.yellow, marginBottom: 8 }}>Делегировано на</TextFont>
            <TextFont
              type="bold"
              style={styles.textName}
            >{`${task.delegatedToUser?.firstName} ${task.delegatedToUser?.lastName}`}</TextFont>
          </div>

          <div style={styles.sub}>
            <SvgSubTract />
            <RenderWithCondition condition={task.delegatedToDateTime}>
              <TextFont size={14} color={"#939393"} style={{ marginTop: 5 }}>
                {toFormatDayMonth(dayjs(task.delegatedToDateTime ?? ""))}
              </TextFont>
            </RenderWithCondition>
          </div>
        </button>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={Boolean(visible)}>
        <ModalUI isVisible={Boolean(visible)} onClose={() => setVisible(undefined)}>
          <UserInformation
            user={visible === "from" ? task.delegatedFromUser : task.delegatedToUser}
            onClose={() => setVisible(undefined)}
          />
        </ModalUI>
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDelegate}>
        <ModalDelegate
          onSave={onSave}
          task={task}
          delegatedToUser={task.delegatedToUser}
          close={() => setIsVisibleDelegate(false)}
          onTakeMe={onTakeMe}
        />
      </ModalRenderWithCondition>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    backgroundColor: globalState.colorSchema.white,
    borderRadius: "10px",
    padding: "8px",
    width: "100%",
  },
  image: {
    width: "50px",
  },
  textBlock: {
    flex: 4,
    marginLeft: "8px",
  },
  textName: {
    fontSize: "16px",
    fontWeight: "700",
  },
  sub: {
    display: "flex",
    "flex-direction": "column",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    gap: 5,
  },
};
