import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgEyeClosed } from "@assets/eye-close.svg";
import { ReactComponent as SvgEye } from "@assets/eye.svg";
import { RenderWithCondition } from "@hoc";
import { setIsHidePersonalTasks } from "@store/calendar/slice";
import { selectTheme } from "@store/theme";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

export const SwitchShowPersonalTasks = () => {
  const dispatch = useDispatch();
  const theme = selectTheme("extra");

  const [displayPersonalEvents, setIsDisplayPersonalEvents] = useState(false);
  const [isToggleVisible, setIsToggleVisible] = useState(true);

  const { directorId } = userSelector();

  const handleSwitchChange = () => {
    setIsDisplayPersonalEvents(!displayPersonalEvents);
    dispatch(setIsHidePersonalTasks(!displayPersonalEvents));
  };

  const getUserDisplayPersonalEventsSetting = async () => {
    const isShowPersonals = localStorage.getItem("showPersonal");
    const isVisible = isShowPersonals ? isShowPersonals === "true" : true;

    setIsToggleVisible(isVisible && !directorId);
  };

  useEffect(() => {
    getUserDisplayPersonalEventsSetting();
  }, []);

  return (
    <RenderWithCondition condition={isToggleVisible}>
      <button onClick={handleSwitchChange} style={styles.container}>
        {displayPersonalEvents ? <SvgEye fill={theme.text.accent} /> : <SvgEyeClosed fill={theme.text.accent} />}
        <TextFont style={{ ...styles.title, color: theme.text.accent }}>
          <FormattedMessage id="personalEvents" defaultMessage="Личные" />
        </TextFont>
      </button>
    </RenderWithCondition>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: Colors.DARK.lighGrey,
    borderRadius: 5,
    paddingLeft: 12,
    height: 22,
  },
  title: {
    marginRight: 10,
    size: 16,
  },
};
