import React, { memo, useEffect, useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgDeadline } from "@assets/deadline.svg";
import { ReactComponent as SvgDone } from "@assets/done_circle.svg";
import { ReactComponent as SvgReturnWork } from "@assets/return_work.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IExtendedComment, ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { IFile } from "@interfaces/files.interfaces";
import { IColors } from "@interfaces/theme.interface";
import { useWorkDaysController } from "@screens/day/components/ListTasks/Modal/components/addWorkDaysReminder";
import { TaskMeetings } from "@screens/day/components/ListTasks/Modal/components/taskMeetings/TaskMeeting";
import { OneSTaskStatusHeader } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/OneSTaskStatusHeader";
import { Resolution1CActions } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/resolutionOneSActionButtons/Resolutions1CActions";
import { PlanningWork, FileAttachments } from "@shared";
import { EventComments } from "@shared";
import { EventCommentsTypes } from "@shared/eventComments/EventComments";
import { removeFilesFromServer } from "@shared/fileAttachments/utils";
import { complete, reopen, selectBusinessTasks, update, takeMe } from "@store/businessTask";
import { selectTheme } from "@store/theme";
import { userSelector } from "@store/user";
import { HeaderModal, TextFont } from "@ui";
import { showToast, toFormatDayMonth } from "@utils";
import globalState from "@utils/globalState";
import { myTracker } from "@utils/myTracker";
import { shouldShowWorkdayReminder } from "@utils/shouldShowWorkdayReminder";
import { intl } from "@utils/translate";

import { Item } from "../../../taskItem/Item";
import { Actions, tempWorkDay } from "../../index";
import { Modal as ModalTask } from "../../Modal";
import { Delegate } from "../delegate/Delegate";

import { Description, HeaderTablet, ViewSaveStatus } from "./components";
import { OneSResolutions } from "./components/resolutionOneSActionButtons/constants";
import { parseOneSAdditionalDescription } from "./utils";

interface IViewSave {
  task: ITask;
  handleClose: () => void;
  setIsSave: (arg: boolean) => void;
  isModal: boolean;
  outModalClose?: () => void;
  isPersonalTask: boolean;
  isFromOneS?: boolean;
  openFilePicker: () => void;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  resetUploadedFiles: () => void;
}

export const ViewSave = memo(
  ({
    task,
    isPersonalTask,
    isFromOneS,
    handleClose,
    setIsSave,
    isModal,
    outModalClose,
    openFilePicker,
    uploadedFiles,
    allFiles,
    deleteFile,
    handleFileDownload,
    resetUploadedFiles,
  }: IViewSave) => {
    const dispatch = useDispatch();

    const [isControlTaskDetailedScreenVisible, setIsControlTaskDetailedScreenVisible] = useState(false);
    const [isVisibleAddDay, setIsVisibleAddDay] = useState(false);
    const [isVisibleActions, setIsVisibleActions] = useState(false);
    const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
    const [isAddWorkDayReminderVisible, setIsWorkDayReminderVisible] = useState(false);

    const linkedTask = useMemo(() => task?.controlledTask || task?.controlTasks?.[0], [task]);
    const isDelegateBtnVisible = useMemo(() => task.delegatedFromUser || (task.delegatedToUser && !task.controlledTaskId), [task]);
    const controlledTask = task?.controlledTask;
    const modalRightBtnTitle = isSaveBtnVisible ? "save" : "change";
    const { currentUser } = userSelector();
    const { isLoadingTask } = useSelector(selectBusinessTasks);
    const theme = selectTheme("extra");

    const canEdit = useMemo(() => task.creator === currentUser?.id || task.delegatedFromUser || task.assistant, [currentUser, task]);

    const taskDescription = useMemo(
      () => (task.isFromOneS ? parseOneSAdditionalDescription(task.oneSTaskAdditionalDescription ?? "") : task.description),
      [task],
    );

    const commentsData = useMemo(() => {
      if (!isPersonalTask) {
        return task?.taskComments ?? [];
      }

      const extendedComments = [] as IExtendedComment[];
      const comments = (task?.comments && task.comments.slice(0)) ?? [];

      const userInfo = {
        id: currentUser?.id ?? "",
        firstName: currentUser?.firstName ?? "",
        lastName: currentUser?.lastName ?? "",
      };

      for (const comment of comments) {
        const extendedComment: IExtendedComment = {
          ...comment,
          user: userInfo,
        };

        extendedComments.push(extendedComment);
      }

      return extendedComments;
    }, [task, currentUser, isPersonalTask]);

    const handleTakeBack = useCallback(async () => {
      await dispatch(takeMe(task.id));

      if (shouldShowWorkdayReminder(task.workDays)) {
        setIsWorkDayReminderVisible(true);
      }
    }, [task]);

    const [WorkDaysModals, WorkDaysComponent] = useWorkDaysController({
      task,
      isPersonalTask,
      isAddWorkDayReminderVisible,
      setIsWorkDayReminderVisible,
      handleClose,
      outModalClose,
      allowWorkdaysAddition: canEdit,
    });

    const handleComplete = () => {
      myTracker("ReadyClickOnTask");
      dispatch(complete({ id: task.id, isPersonalTask }));
    };

    const handleReturnToWork = () => {
      dispatch(reopen({ id: task.id, isPersonalTask }));
      setIsWorkDayReminderVisible(true);
    };

    const handleSaveAddDays = (days: WorkDay[], timeEnabled?: boolean) => {
      dispatch(update({ id: task.id, isPersonalTask, data: { workDays: days, timeEnabled } }));
    };

    const onPressDisable = useCallback(() => {
      showToast("actionUnavailableCompletedTask");
    }, []);

    const isEdit = useMemo(
      () => task.status !== "COMPLETED" && task.status !== "CLOSED" && task.status !== "PAUSED" && canEdit,
      [task.status, canEdit],
    );

    const generateDate = useMemo(() => {
      if (task.isFromOneS) {
        if (task.plannedEndDate) {
          return `${toFormatDayMonth(dayjs(task.plannedEndDate))}`;
        }

        if (task.workDays) {
          const workDay = task.workDays?.length && task.workDays[task.workDays.length - 1].date;
          return `${toFormatDayMonth(dayjs(workDay))}`;
        }
      }

      if (task.plannedEndDate && task.startDate) {
        return `${toFormatDayMonth(dayjs(task.startDate))} - ${toFormatDayMonth(dayjs(task.plannedEndDate))}`;
      } else if (task.startDate) {
        return toFormatDayMonth(dayjs(task.startDate));
      } else if (task.plannedEndDate) {
        const isCurrentYear = dayjs(task.plannedEndDate).isSame(dayjs(), "year");
        return dayjs(task.plannedEndDate).format(isCurrentYear ? "DD.MM" : "DD.MM.YY");
      } else if (task.createdDate) {
        const isCurrentYear = dayjs(task.createdDate).isSame(dayjs(), "year");
        return `${intl.getMessage("created")} ${dayjs(task.createdDate).format(isCurrentYear ? "DD.MM" : "DD.MM.YY")}`;
      }
    }, [task.startDate, task.plannedEndDate, task.createdDate]);

    const onSaveHandler = useCallback(() => {
      (async () => {
        if (!isSaveBtnVisible) {
          setIsSave(false);
          return;
        }

        const updation = { fileIds: [...uploadedFiles.map((file) => file.id), ...(task.files?.map((item) => item.id) ?? [])] };

        dispatch(update({ id: task.id, isPersonalTask, data: updation }));

        resetUploadedFiles();
        setIsSaveBtnVisible(false);
      })();
    }, [uploadedFiles, isSaveBtnVisible]);

    const handleCloseWrapper = useCallback(() => {
      uploadedFiles.length && removeFilesFromServer(uploadedFiles.map((file) => file.id));
      resetUploadedFiles();
      handleClose();
    }, [uploadedFiles]);

    const openFilePickerWrapper = () => {
      setIsVisibleActions(false);
      openFilePicker();
    };

    const stylesWrapperStatus = useMemo(
      () => ({
        ...(((task.status === "CLOSED" && !!task?.closeReason && !!task?.closeComment) ||
          (task.status === "PAUSED" && task.pauseComment)) && {
          borderBottom: "1px solid #A1B6AD",
          marginBottom: 10,
        }),
        display: "flex",
        justifyContent: "space-between",
      }),
      [task?.closeComment, task?.closeReason, task.pauseComment, task.status],
    );

    const isRenderStatus =
      task.status === "CLOSED" || task.status === "COMPLETED" || task.status === "PAUSED" || task?.oneSTaskResolution || task.assistant;

    useEffect(() => {
      uploadedFiles.length ? setIsSaveBtnVisible(true) : setIsSaveBtnVisible(false);
    }, [uploadedFiles]);

    const styles = makeStyles(theme);

    return (
      <LoaderRenderWithCondition condition={isLoadingTask}>
        <>
          <HeaderModal
            title="task"
            onClose={handleCloseWrapper}
            isCancel={false}
            titleSave={modalRightBtnTitle}
            isEdit={!!isEdit}
            onSave={onSaveHandler}
            style={{ backgroundColor: theme.background.main }}
            styleTextSave={{ fontWeight: "700" }}
            header={
              isModal ? null : (
                <HeaderTablet
                  isFromOneS={isFromOneS}
                  isPersonalTask={isPersonalTask}
                  isSaveBtnVisible={isSaveBtnVisible}
                  onSave={onSaveHandler}
                  onClose={handleCloseWrapper}
                  task={task}
                />
              )
            }
          />
          <div style={{ ...styles.container, overflowY: "auto" }}>
            <OneSTaskStatusHeader isFromOnes={isFromOneS} oneSTaskStatus={task.oneSTaskType} />
            <div style={{ ...styles.titleBlock, ...(isPersonalTask ? styles.bgDarkPurple : styles.bgDarkGreen) }}>
              <RenderWithCondition condition={isRenderStatus}>
                <div style={stylesWrapperStatus}>
                  <ViewSaveStatus task={task} />
                  <RenderWithCondition condition={canEdit}>
                    <button onClick={() => setIsVisibleActions(true)}>
                      <SvgDotes fill={"white"} />
                    </button>
                  </RenderWithCondition>
                </div>
              </RenderWithCondition>

              <div style={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
                <TextFont type="bold" style={styles.titleText}>
                  {task.name}
                </TextFont>
                <RenderWithCondition condition={task.status === "OPEN" && !task?.oneSTaskResolution && canEdit && !task.assistant}>
                  <button onClick={() => setIsVisibleActions(true)}>
                    <SvgDotes fill={"white"} />
                  </button>
                </RenderWithCondition>
                <ModalRenderWithCondition condition={isVisibleActions}>
                  <Actions
                    task={task}
                    closeOverModal={handleClose}
                    close={() => setIsVisibleActions(false)}
                    isPersonalTask={isPersonalTask}
                    openFilePicker={openFilePickerWrapper}
                    isFromOneS={task.isFromOneS ?? false}
                    handleTakeBack={handleTakeBack}
                  />
                </ModalRenderWithCondition>
              </div>
              <RenderWithCondition condition={!!taskDescription}>
                <Description text={taskDescription} isPersonalTask={isPersonalTask} />
              </RenderWithCondition>
            </div>
            <div style={styles.main}>
              <div style={styles.tags}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={styles.tag}>
                    <TextFont>
                      <FormattedMessage id={task.priority.toLocaleLowerCase()} />
                    </TextFont>
                  </div>
                  <RenderWithCondition condition={task.tag}>
                    <div style={styles.tag}>
                      <TextFont>{`#${task.tag?.name}`}</TextFont>
                    </div>
                  </RenderWithCondition>

                  <RenderWithCondition condition={task.plannedEndDate || task.startDate || task.createdDate}>
                    <div style={styles.tag}>
                      <TextFont>{generateDate}</TextFont>

                      <RenderWithCondition condition={task.plannedEndDate && !task.startDate}>
                        <SvgDeadline style={{ marginLeft: 5 }} />
                      </RenderWithCondition>
                    </div>
                  </RenderWithCondition>
                </div>
                <RenderWithCondition condition={task.project}>
                  <div style={{ ...styles.tag, marginTop: 10 }}>
                    <TextFont>
                      <RenderWithCondition condition={task.project?.shortName}>
                        <TextFont weight="700" style={{ textAlign: "center" }}>
                          {`${task.project?.shortName} `}
                        </TextFont>
                      </RenderWithCondition>
                      <TextFont style={{ textAlign: "center" }}>{task.project?.name}</TextFont>
                    </TextFont>
                  </div>
                </RenderWithCondition>
              </div>

              <RenderWithCondition condition={isDelegateBtnVisible}>
                <Delegate task={task} disable={{ value: !isEdit, press: onPressDisable }} />
              </RenderWithCondition>

              <RenderWithCondition condition={linkedTask}>
                <div style={{ marginTop: 10 }}>
                  <Item
                    meetingTaskStyles
                    index={0}
                    item={linkedTask}
                    press={() => setIsControlTaskDetailedScreenVisible(true)}
                    onRightClick={() => ""}
                  />
                </div>
              </RenderWithCondition>

              <RenderWithCondition condition={!!task.businessGoal && !controlledTask}>
                <div style={styles.goalBlock}>
                  <TextFont type="bold" style={styles.goalTitle}>
                    Цель задачи
                  </TextFont>
                  <TextFont style={styles.goalDesc}>{task.businessGoal?.description}</TextFont>
                </div>
              </RenderWithCondition>

              {WorkDaysComponent}
              <TaskMeetings
                allowMeetingsAttach={canEdit}
                disable={{ value: !isEdit, press: onPressDisable }}
                taskId={task.id}
                enableAddButton={false}
              />

              <FileAttachments
                disable={{ value: !isEdit, press: onPressDisable }}
                enableAddButton={false}
                openFilePicker={openFilePicker}
                allFiles={allFiles}
                uploadedFiles={uploadedFiles}
                handleFileDownload={handleFileDownload}
                deleteFile={deleteFile}
              />

              <EventComments
                eventType={EventCommentsTypes.Task}
                eventId={task.id}
                comments={commentsData ?? []}
                isPersonalTask={isPersonalTask}
                allowCommentAddition={canEdit}
              />

              <RenderWithCondition condition={!isFromOneS && canEdit}>
                {task.status === "COMPLETED" || task.status === "CLOSED" || task.status === "PAUSED" ? (
                  <button style={{ ...styles.buttonReady, backgroundColor: globalState.colorSchema.lighGrey }} onClick={handleReturnToWork}>
                    <SvgReturnWork fill={globalState.colorSchema.grey500} />
                    <TextFont size={18} style={{ marginLeft: 10 }} color={globalState.colorSchema.text.grey}>
                      <FormattedMessage id="returnToWork" />
                    </TextFont>
                  </button>
                ) : (
                  <button
                    style={{ ...styles.buttonReady, ...(isPersonalTask ? styles.bgDarkPurple : styles.bgGreen) }}
                    onClick={handleComplete}
                  >
                    <SvgDone fill="white" />
                    <TextFont size={18} style={{ marginLeft: 10 }} color={"white"}>
                      <FormattedMessage id="readyTask" />
                    </TextFont>
                  </button>
                )}
              </RenderWithCondition>

              <RenderWithCondition condition={isFromOneS}>
                <Resolution1CActions
                  oneSTaskResolution={task.oneSTaskResolution}
                  availableResulutions={task.oneSTaskAvailableResolutions ?? []}
                  taskId={task.id}
                  oneSTaskStatus={task?.oneSTaskStatus ?? OneSResolutions.AGREED_WITH_REMARKS}
                />
              </RenderWithCondition>
            </div>
          </div>
          <RenderWithCondition condition={isVisibleAddDay}>
            <PlanningWork
              isVisible={isVisibleAddDay}
              setIsVisible={setIsVisibleAddDay}
              isTime={Boolean(task.timeEnabled)}
              startDate={task.startDate}
              startDays={task.workDays}
              tempObject={tempWorkDay}
              onSave={handleSaveAddDays}
              keyTitle="workDay"
              deadline={task.plannedEndDate}
              timeEnabled={task.timeEnabled}
            />
          </RenderWithCondition>
          <ModalRenderWithCondition condition={isControlTaskDetailedScreenVisible}>
            <ModalTask
              isVisible={isControlTaskDetailedScreenVisible}
              setIsVisible={setIsControlTaskDetailedScreenVisible}
              id={linkedTask?.id ?? ""}
            />
          </ModalRenderWithCondition>
          {WorkDaysModals}
        </>
      </LoaderRenderWithCondition>
    );
  },
);

const makeStyles = (colors: IColors) => ({
  container: {
    height: "90%",
  },
  titleBlock: {
    display: "flex",
    "flex-direction": "column",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 17,
    backgroundColor: globalState.colorSchema.background.darkGreen,
    paddingBottom: 30,
  },
  titleText: {
    color: globalState.colorSchema.white,
    fontSize: 24,
    fontWeight: "700",
  },
  main: {
    display: "flex",
    "flex-direction": "column",
    backgroundColor: colors.background.main,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: -15,
    gap: 15,
  },
  tags: {
    width: "100%",
  },
  tag: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "7.5px 0 7.5px 7.5px",
    borderRadius: 7,
    backgroundColor: globalState.colorSchema.white,
    marginRight: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  goalBlock: {
    flex: 1,
    borderBottomColor: "#E3E9E7",
    borderBottomWidth: 1,
  },
  goalTitle: {
    fontSize: 14,
    fontWeight: "700",
  },
  buttonReady: {
    display: "flex",
    borderRadius: 10,
    marginTop: 20,
    padding: "16px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  goalDesc: {
    fontSize: 16,
    fontWeight: "400",
  },
  bgDarkPurple: {
    backgroundColor: colors.darkPurple,
  },
  bgGreen: {
    backgroundColor: colors.background.green,
  },
  bgDarkGreen: {
    backgroundColor: colors.background.darkGreen,
  },
});
