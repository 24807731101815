import React, { useState } from "react";

import { Dayjs } from "dayjs";

import { ReactComponent as Back } from "@assets/back.svg";
import { MonthsOfYear } from "@screens/mounth/components/header/YearMonthsOverview";
import { TextFont, ModalUI } from "@ui";
import { NotificationClear } from "@ui/notification/NotificationClear";

import { styles } from "./styles";

interface IModal {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  pressMonth: (date: Dayjs, isDisabled: boolean) => void;
  startDate?: string;
  selectedMonths?: string[];
  isActiveDisabled?: boolean;
  displayNoteSomeMonth?: boolean;
}

export const Modal = ({
  isVisible,
  setIsVisible,
  pressMonth,
  isActiveDisabled = true,
  selectedMonths,
  startDate,
  displayNoteSomeMonth,
}: IModal) => {
  const [isVisibleNote, setIsVisibleNote] = useState(displayNoteSomeMonth);
  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose} style={{ paddingTop: "20px" }}>
      <section style={styles.header}>
        <button style={styles.back} onClick={handleClose}>
          <Back />
          <TextFont style={styles.backTitle}>Назад</TextFont>
        </button>
        <TextFont type="bold" style={styles.headerTitle}>
          Месяц
        </TextFont>
      </section>
      <MonthsOfYear
        containerStyles={styles.main}
        selectedMonths={selectedMonths}
        onMonthPress={pressMonth}
        startDate={startDate}
        isActiveDisabled={isActiveDisabled}
      />

      {isVisibleNote && <NotificationClear phraseId={"goalTransferWarning"} onOk={() => setIsVisibleNote(false)} />}
    </ModalUI>
  );
};
