import { AnyAction, Dispatch, MiddlewareAPI, PayloadAction } from "@reduxjs/toolkit";

import { StoreTagTypes, api } from "@services/api";

import { actionsToResetCalendar, actionsToResetExternalUsers, actionsToHardResetAllCache } from "./constants";

export const resetRTKQCacheMiddleWare =
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  (store: MiddlewareAPI<Dispatch<AnyAction>, any>) => (next: Dispatch<any>) => async (action: PayloadAction) => {
    const actionName = action.type.split("/")[1];
    const isSuccess = action?.meta?.requestStatus === "fulfilled";

    if (isSuccess && actionsToResetCalendar.includes(actionName)) {
      store.dispatch(api.util.invalidateTags([StoreTagTypes.Calendar]));
    }

    if (isSuccess && actionsToResetExternalUsers.includes(actionName)) {
      store.dispatch(api.util.invalidateTags([StoreTagTypes.ExternalUser]));
    }

    if (actionsToHardResetAllCache.includes(actionName)) {
      store.dispatch(api.util.invalidateTags(Object.values(StoreTagTypes)));
    }

    next(action);
  };
