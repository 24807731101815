import React, { CSSProperties, FC, useEffect, useState } from "react";

import ContentLoader, { IContentLoaderProps } from "react-content-loader";

import defaultAvatar from "@assets/avatar_temp.png";
import { RenderWithCondition } from "@hoc";
import { fileService } from "@services/file.service";

import { userAvatarStyles } from "./styles";

interface ILazyAvatar {
  avatarFileId: string | null;
  style: CSSProperties;
}

export const AvatarPreloader: FC<IContentLoaderProps> = ({ ...props }) => (
  <ContentLoader width={160} height={95} viewBox="0 0 200 200" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <circle cx="100" cy="100" r="100" />
  </ContentLoader>
);

const LazyAvatar: FC<ILazyAvatar> = ({ avatarFileId, style }) => {
  const [avatar, setAvatar] = useState<string>();

  const getAvatar = async () => {
    if (!avatarFileId) return;

    const avatarURI = await fileService.getAvatar(avatarFileId);

    setAvatar(avatarURI);
  };

  useEffect(() => {
    getAvatar();
  }, [avatarFileId]);

  return (
    <>
      <RenderWithCondition condition={!avatarFileId}>
        <img src={defaultAvatar} style={{ ...userAvatarStyles.userImage, ...style }} alt="user avatar" />
      </RenderWithCondition>
      <RenderWithCondition condition={avatarFileId}>
        {avatar ? (
          <img src={avatar} style={{ ...userAvatarStyles.userImage, ...style }} alt="user avatar" />
        ) : (
          <AvatarPreloader style={{ ...userAvatarStyles.userImage, ...style }} />
        )}
      </RenderWithCondition>
    </>
  );
};
export default LazyAvatar;
