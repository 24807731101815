import { useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";

import { useTime } from "@hooks/useTime";
import { IMeet } from "@interfaces/meet.interface";
import { selectCurrentDate } from "@store/screenDay";
import { selectUserForAssistant } from "@store/user";
import { toFormatDate, toFormatISO } from "@utils";

import { TStringUnknownTupleArray, isStringUnknownTupleArray } from "../utils";

import { tempMeet } from "./constants";
import { TUseData } from "./types";

export const useData: TUseData = ({ meet, joinData, setIsEdit }) => {
  const [isVisibleStart, setIsVisibleStart] = useState(false);
  const [isVisibleEnd, setIsVisibleEnd] = useState(false);
  const [isVisibleRepeat, setIsVisibleRepeat] = useState(false);

  const dateNow = useSelector(selectCurrentDate);
  const currentUser = useSelector(selectUserForAssistant);

  const { startTime, endTime, handleTime } = useTime({
    startValue: meet ? meet.startTime : joinData?.startTime,
    endValue: meet ? meet.endTime : joinData?.endTime,
  });

  const state =
    meet ??
    ({
      ...tempMeet,
      participants: [{ ...currentUser, userId: currentUser?.id, status: "OWNER" }],
      date: dateNow.format("YYYY-MM-DD"),
      startTime,
      endTime,
      ...(joinData ?? {}),
    } as IMeet);
  const [data, setData] = useState<IMeet>(state);

  const formatData = useMemo(() => ({ ...data, startTime, endTime }), [startTime, endTime, data]);

  const isEditData = useMemo(() => {
    if (meet) {
      return !isEqual(
        { ...formatData, participants: formatData.participants?.map((i) => i.userId).sort() },
        { ...meet, participants: meet.participants?.map((i) => i.userId).sort() },
      );
    }
    return !isEqual(formatData, tempMeet);
  }, [formatData, meet]);

  useEffect(() => {
    setIsEdit(!!data?.name?.trim() && isEditData);
  }, [data.name, isEditData]);

  const pressCalendar = (dateWeek: Dayjs) => {
    const day = dateWeek.date();
    const month = dateWeek.month();
    const year = dateWeek.year();

    handleTime("startTime", toFormatISO(dayjs(startTime).date(day).month(month).year(year)));
    handleTime("endTime", toFormatISO(dayjs(endTime).date(day).month(month).year(year)));
    handleData("date", toFormatDate(dayjs(startTime).date(day).month(month).year(year)));
  };

  const handleData = (name: string | TStringUnknownTupleArray, value: unknown) => {
    if (typeof name === "string") {
      setData({
        ...data,
        [name]: value,
      });

      return;
    }

    if (isStringUnknownTupleArray(name)) {
      const updation = { ...data };

      for (const item of name) {
        updation[item[0]] = item[1];
      }

      setData(updation);
    }
  };

  const formatDataSave = useMemo(
    () => ({ ...formatData, userIds: data.participants?.map((i) => i.userId) }),
    [formatData, data.participants],
  );

  const formatDataEdit = useMemo(() => ({ ...formatData, participants: data.participants ?? null }), [data.participants, formatData]);

  return useMemo(
    () => ({
      isVisibleStart,
      setIsVisibleStart,
      isVisibleEnd,
      setIsVisibleEnd,
      isVisibleRepeat,
      setIsVisibleRepeat,
      pressCalendar,
      formatDataSave,
      formatDataEdit,
      formatData,
      data,
      setData,
      state,
      startTime,
      endTime,
      handleData,
      handleTime,
      dateNow,
    }),
    [isVisibleStart, isVisibleEnd, isVisibleRepeat, formatData, formatDataEdit, formatDataSave, data, dateNow],
  );
};
