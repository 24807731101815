import { createSlice } from "@reduxjs/toolkit";

import { IProjectMain } from "@interfaces/projects.interface";

import { getListProject } from "./api";

interface IProjectsSlice {
  projects: IProjectMain[];
  isLoading: boolean;
  errorMsg: null | string;
}

const initialState: IProjectsSlice = {
  projects: [],
  isLoading: false,
  errorMsg: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListProject.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getListProject.fulfilled, (state, { payload }) => {
        const for1C = payload?.response?.data[0]?.childProjectGroups;

        state.projects = for1C.length ? for1C : payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(getListProject.rejected, (state, action: any) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export default projectSlice.reducer;
