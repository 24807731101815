import React, { FC, memo, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate } from "@store/screenDay";
import { TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import globalState from "@utils/globalState";
import { toFormatDate, toFormatISO } from "@utils/toFormatTime";

import { Modal } from "../modal/Modal";

interface IRow {
  hour: number;
  width: number;
  currentTime?: Dayjs;
  isHide?: boolean;
}

export const Row: FC<IRow> = memo(function Row({ hour, width, currentTime, isHide }) {
  const currentMinute = currentTime?.minute();

  const [isVisible, setIsVisible] = useState(false);
  const [joinTime, setJoinTime] = useState({});

  const dateNow = useSelector(selectCurrentDate);

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const handlePress = () => {
    if (hour === 24) return;

    const startTime = dateNow.hour(hour).minute(0).second(0);
    const calculatedEndTime =
      startTime.format("HH") === "23" ? toFormatISO(startTime.endOf("hour")) : toFormatISO(startTime.add(1, "hour"));

    setJoinTime({ startTime: toFormatISO(startTime), endTime: calculatedEndTime });
    setIsVisible(true);
  };

  const formatCurrentMinute = (currentMinute ?? 0) < 10 ? `0${currentMinute}` : currentMinute;
  const formatHour = useMemo(() => {
    let tempHour = hour;

    if (tempHour === 24) tempHour = 0;

    return tempHour < 10 ? `0${tempHour}` : tempHour;
  }, [hour]);
  const isCurrentHour = hour === currentTime?.hour() && toFormatDate(dateNow) === toFormatDate(dayjs());

  return (
    <div style={{ display: "flex", height: 38, position: "relative", ...(isHide && { opacity: 0.3 }) }}>
      <TextFont style={styles.hour}>{`${formatHour}:00`}</TextFont>
      <button style={{ ...styles.container, position: "relative" }} disabled={isHide} onClick={handlePress}>
        <div style={{ ...styles.line, ...styles.lineDefault }} />

        <RenderWithCondition condition={isTightMode}>
          <div style={{ ...styles.halfHourLine, position: "absolute" }} />
        </RenderWithCondition>
      </button>

      <RenderWithCondition condition={isCurrentHour && currentTime}>
        <div style={{ ...styles.currentTime, position: "absolute", top: Math.ceil(currentMinute / 2.5) }}>
          <TextFont style={styles.textCurrentTime}>{`${formatHour}:${formatCurrentMinute}`}</TextFont>
          <div style={{ ...styles.line, ...styles.lineCurrentTime, width }} />
        </div>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} meetId={""} data={joinTime} setIsVisible={setIsVisible} />
      </ModalRenderWithCondition>
    </div>
  );
}, arePropsEqual);

const styles = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
  },
  hour: {
    fontWeight: "400",
    fontSize: 12,
    lineHeight: "20px",
    textaAlign: "center",
    color: "#939393",
    width: 50,
    marginTop: -8,
    height: "100%",
  },
  line: {
    height: 0.9,
    width: "100%",
  },
  lineDefault: {
    backgroundColor: "#CFCFCF",
    zIndex: 2,
  },
  halfHourLine: {
    height: 0.5,
    zIndex: 10,
    width: "100%",
    backgroundColor: "#E3E9E7",
    left: 50,
    top: 0,
  },
  currentTime: {
    display: "flex",
    zIndex: 2,
    alignItems: "center",
    width: "100%",
    left: 17,
  },

  textCurrentTime: {
    color: globalState.colorSchema.red,
    marginRight: 8,
    fontSize: 10,
  },
  lineCurrentTime: {
    backgroundColor: globalState.colorSchema.red,
  },
};
