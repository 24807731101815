import React, { FC, ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";

import { RenderWithCondition } from "@hoc";
import { TextFont } from "@ui";

import { ISwitches } from "../../../types";

interface IProps {
  children: ReactNode;
  value: boolean;
  onChange: (name: keyof ISwitches, val: boolean) => void;
  switchKey: keyof ISwitches;
  title: string;
}

export const SwitchBlock: FC<IProps> = ({ children, value, onChange, switchKey, title }) => (
  <aside style={{ marginBottom: 30 }}>
    <div style={styles.container}>
      <TextFont size={18} weight="700">
        <FormattedMessage id={title} />
      </TextFont>
      <Toggle checked={value} onChange={(e) => onChange(switchKey, e.target.checked)} icons={false} />
    </div>
    <RenderWithCondition condition={Boolean(value)}>
      <div style={{ ...styles.main, opacity: value ? 1 : 0 }}>{children}</div>
    </RenderWithCondition>
  </aside>
);

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "opacity 300ms",
  },
};
