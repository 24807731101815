import { AxiosResponse } from "axios";

import { http } from "@api/httpClient";
import { ICreateGoalDTO, IGoal, TypeStatusGoal } from "@interfaces/businessGoals.interface";

class BusinessGoalsService {
  getList = async (yearMonth: string): Promise<any> => await http.get(`/api/api-gateway/v1/business-goals?yearMonth=${yearMonth}`);

  updateList = async (data: IGoal[]): Promise<any> => await http.post("/api/crud/v1/business-goals/list", data);

  create = async (data: ICreateGoalDTO): Promise<any> => await http.post("/api/crud/v1/business-goals", data);

  update = async (id: string, data: Record<string, string>): Promise<any> =>
    await http.patch(`/api/api-gateway/v1/business-goals/${id}`, data);

  delete = async (id: string): Promise<any> => await http.delete(`/api/crud/v1/business-goals/${id}`);

  continue = async (id: string, yearMonth: string): Promise<any> => await http.post(`/api/crud/v1/business-goals/${id}/date/${yearMonth}`);

  moveDate = async (id: string, yearMonth: string): Promise<any> =>
    await http.post(`/api/crud/v1/business-goals/${id}/move-date/${yearMonth}`);

  moveMonth = async (id: string, fromYearMonth: string, toYearMonth: string): Promise<AxiosResponse<unknown, unknown>> =>
    await http.post(`/api/crud/v1/business-goals/${id}/move-month?fromYearMonth=${fromYearMonth}&toYearMonth=${toYearMonth}`);

  updateStatus = async (id: string, status: TypeStatusGoal): Promise<any> =>
    await http.post(`/api/crud/v1/business-goals/${id}/update-status/${status}`);
}

export const businessGoalsService = new BusinessGoalsService();
