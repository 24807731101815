import React, { FC } from "react";

import globalState from "@utils/globalState";

import { IAttendanceOptionsProps, AttendanceStatuses, IStatusButtonProps } from "../types";

import { getButtonContentByStatus } from "./ButtonContent";

const StatusButton: FC<IStatusButtonProps> = ({ status, containerStyles, onPress }) => {
  const style = styles();

  return (
    <div onClick={() => onPress(status)} style={{ ...style.statusButton, ...containerStyles, width: "100%" }}>
      {getButtonContentByStatus(status)}
    </div>
  );
};

export const AttendanceOptions: FC<IAttendanceOptionsProps> = ({ onPress }) => {
  const style = styles();

  return (
    <>
      <div style={style.attendanceOptionRow}>
        <StatusButton
          status={AttendanceStatuses.ATTENDER}
          onPress={() => {
            onPress(AttendanceStatuses.ATTENDER);
          }}
          // containerStyles={style.greenBorder}
        />
        <StatusButton
          status={AttendanceStatuses.NON_ATTENDER}
          onPress={() => {
            onPress(AttendanceStatuses.NON_ATTENDER);
          }}
          containerStyles={{ marginLeft: 10 }}
        />
      </div>
      <div style={style.attendanceOptionRow}>
        <StatusButton
          status={AttendanceStatuses.MAYBE_ATTENDER}
          onPress={() => {
            onPress(AttendanceStatuses.MAYBE_ATTENDER);
          }}
        />
      </div>
    </>
  );
};

const styles = () => {
  const theme = globalState.colorSchema;

  return {
    statusButton: {
      display: "flex",
      "background-color": theme.lighGrey,
      height: 44,
      "padding-left": 16,
      "padding-right": 16,
      "border-radius": 5,
      "flex-direction": "row",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      cursor: "pointer",
    },
    attendanceOptionRow: {
      display: "flex",
      "flex-direction": "row",
      justifyContent: "space-between",
      width: "100%",
    },
    greenBorder: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.background.green,
    },
    modal: {
      display: "flex",
      backgroundColor: theme.white,
      borderRadius: 10,
      justifyContent: "flex-start",
      paddingVertical: 20,
      position: "absolute",
      width: "100%",
      bottom: 0,
      minHeight: 200,
    },
  };
};
