import { createAsyncThunk } from "@reduxjs/toolkit";

import { monthGoalService } from "@services";
import { getError } from "@store/getError";

interface IDataGoal {
  id: string;
  description: string;
}

export const getMonthGoal: any = createAsyncThunk("monthGoal/get", async (yearMonth: string, thunkAPI) => {
  try {
    const response = await monthGoalService.getMonthGoal(yearMonth);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const saveMonthGoal: any = createAsyncThunk("monthGoal/save", async ({ id, description }: IDataGoal, thunkAPI) => {
  try {
    const response = await monthGoalService.saveMonthGoal(id, description);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
