export const tempTask = {
  name: "",
  description: "",
  status: "OPEN",
  priority: "IMPORTANT",
  plannedEndDate: "",
  phoneNumber: null,
  delegatedToUser: "",
  businessGoalId: "",
  projectId: "",
  timeEnabled: false,
} as any;

export const tempWorkDay = {
  isCompleted: false,
  startTime: null,
  endTime: null,
  notCompletedComment: null,
  notCompletedReason: null,
  status: null,
  comment: null,
};
