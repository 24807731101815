import React, { useMemo, MouseEvent } from "react";

import { useSelector } from "react-redux";

import { ReactComponent as SvgDelegate } from "@assets/delegate.svg";
import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import { RenderWithCondition } from "@hoc";
import { useInfoTask } from "@hooks/useInfoTask";
import { ITask } from "@interfaces/businessGoals.interface";
import { IStateUser } from "@interfaces/user.interface";
import { selectUserPermissoins } from "@store/user";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { Icon } from "./Icon";

interface IProps {
  task: ITask;
  press: (id: string) => void;
  onRightClick: (arg1: MouseEvent<HTMLButtonElement>, arg2: string) => void;
}

export const RenderItem = ({ task, press, onRightClick }: IProps) => {
  const { colorSchema } = globalState;

  const { status, isCompleted, handleCheckBox } = useInfoTask(task);

  const permissions = useSelector(selectUserPermissoins);

  const textDelegate = (delegated: IStateUser | null) =>
    `${delegated?.lastName} ${delegated?.firstName ? `${delegated?.firstName.slice(0, 1)}.` : ""}`;

  const colorTitle = useMemo(
    () => (isCompleted || status === "dealWith" ? colorSchema.grey : colorSchema.text.main),
    [isCompleted, status]
  );

  return (
    <button onClick={() => press(task.id)} onContextMenu={(e) => onRightClick(e, task.id)} style={styles.block}>
      <button onClick={handleCheckBox} style={{ display: "flex", alignItems: "center" }}>
        <Icon status={status} isCompleted={isCompleted} />
      </button>
      <div style={styles.wrapperText}>
        <TextFont color={colorTitle} style={styles.blockText}>
          <RenderWithCondition condition={task.tag?.name}>
            <TextFont type="bold" style={{ color: colorSchema.text.main, fontWeight: "700" }}>{`${task.tag?.name} `}</TextFont>
          </RenderWithCondition>
          {task.name}
        </TextFont>

        <div>
          <RenderWithCondition condition={permissions.CAN_DELEGATE && task.delegatedToUser && !task.isFromOneS}>
            <div style={styles.blockDelegate}>
              <div style={styles.blockDelegateIcon}>
                <SvgDelegate width={10} height={10} />
              </div>
              <RenderWithCondition condition={task.delegatedToUser?.lastName}>
                <TextFont
                  color={task.status === "COMPLETED" ? "#FFE296" : colorSchema.yellow}
                  style={styles.delegateText}
                >{`на ${textDelegate(task.delegatedToUser)}`}</TextFont>
              </RenderWithCondition>
            </div>
          </RenderWithCondition>

          <RenderWithCondition condition={task.delegatedFromUser}>
            <div style={styles.blockDelegate}>
              <div style={styles.blockDelegateIcon}>
                <SvgTakeBack width={8} height={8} />
              </div>
              <RenderWithCondition condition={task.delegatedFromUser?.lastName}>
                <TextFont
                  color={task.status === "COMPLETED" ? "#DFD8FF" : colorSchema.purple}
                  style={styles.delegateText}
                >{`от ${textDelegate(task.delegatedFromUser)}`}</TextFont>
              </RenderWithCondition>
            </div>
          </RenderWithCondition>
        </div>
      </div>
    </button>
  );
};

const styles = {
  block: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 8,
    paddingTop: 2,
    backgroundColor: globalState.colorSchema.background.main,
    borderBottom: "1px solid #E3E9E7",
    height: "2.5vh",
    width: "100%",
  },
  blockText: {
    display: "flex",
    gap: "5px",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: "20px",
  },
  wrapperText: {
    width: "92%",
    display: "flex",
    alignItems: "center",
  },
  blockDelegate: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 10,
    // flex: 0.5
  },
  blockDelegateIcon: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
  },
  delegateText: {
    "text-align": "right",
    fontSize: 12,
    fontWeight: "400",
    marginLeft: 3,
  },
};
