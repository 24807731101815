import axios from "axios";

import { http } from "@api/httpClient";
import { REFRESH_TOKEN } from "@constants/settings";
import { logOutUtil } from "@utils/appUtils";
import globalState from "@utils/globalState";
import { secureStore } from "@utils/secureStore";

export type DataGetAuthToken = {
  phone_number: string;
  sms_code: string;
  grant_type: "sms_code";
  device: {
    device_id: string;
    brand: string;
    model: string;
    app_version: string;
    operating_system: string;
  };
};

export type ResponseGetAuthToken = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
};

export type ResponseGetAuthSmsCode = {
  secondsToRetry: number;
  registered: boolean;
};

export interface DataRegister {
  firstName: string;
  lastName: string;
  middleName: string;
}

class AuthService {
  getAuthSmsCode = async (phoneNumber: string): Promise<ResponseGetAuthSmsCode> => {
    const http = axios.create({
      baseURL: `https://${window.location.host}`,
      withCredentials: true,
    });
    return await http.post(`/mobile/auth/sms/${phoneNumber}`);
  };

  register = async (data: DataRegister): Promise<any> => await http.patch("/api/uaa/v1/users/me", data);

  getAuthToken = async (data: DataGetAuthToken): Promise<ResponseGetAuthToken> => await http.post("/mobile/auth/token", data);

  updateTokensApi = async () => {
    try {
      const refresh_token = await secureStore.getValue(REFRESH_TOKEN);

      if (refresh_token != null) {
        const body = { refresh_token, grant_type: REFRESH_TOKEN };
        const response = await axios.post(`https://${window.location.host}/web/auth/token`, body);

        return response;
      }
    } catch (error: any) {
      if (error?.request?.status === 401) {
        logOutUtil();
        window.location.pathname = "/auth";
      }
    }
  };
}

export const authService = new AuthService();
