import React, { HTMLAttributes, useEffect, useState } from "react";

import classNames from "classnames";
import Modal, { Props } from "react-modal";
import "./modalUI.css";
import "./animated.css";
import { useDispatch } from "react-redux";

import { useDimension } from "@hooks/useDimension";
import { saveModal } from "@store/configure";

interface IProps extends HTMLAttributes<Props> {
  isVisible: boolean;
  children: React.ReactNode;
  style?: any;
  className?: string;
  bg?: string;
  isFullWidth?: boolean;
  onClose: () => void;
  onShow?: () => void;
  scrollEnable?: boolean;
  id?: string;
}

export const ModalUI = ({
  isVisible = false,
  bg = "#F5F9F8",
  children,
  style,
  className,
  scrollEnable = true,
  isFullWidth,
  onClose,
  onShow,
  id,
  ...props
}: IProps) => {
  const { isTablet, windowDimensions } = useDimension();

  const dispatch = useDispatch();

  const toggleOpen = (flag: boolean) => {
    if (flag && onShow) {
      onShow();
    }
    dispatch(saveModal(flag));
  };

  return (
    <Modal
      id={id}
      isOpen={isVisible}
      onAfterOpen={() => toggleOpen(true)}
      onAfterClose={() => toggleOpen(false)}
      ariaHideApp={false}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      shouldCloseOnEsc
      className={classNames(
        "modal",
        {
          modalTablet: isFullWidth,
        },
        className,
      )}
      style={{
        content: {
          backgroundColor: bg,
          ...(!isTablet && { width: windowDimensions.width - 25, height: windowDimensions.height - 100 }),
          zIndex: 999,
          ...(scrollEnable && { overflowY: "auto" }),
          ...style,
        },
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};
