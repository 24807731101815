import React, { CSSProperties, FC, ReactNode } from "react";

import { styled } from "styled-components";

import { NavBar } from "./components/navBar";

interface IProps {
  header?: JSX.Element;
  headerHeight?: string;
  footerHeight?: string;
  children: ReactNode;
  backgroundColor?: string;
  mainStyle?: CSSProperties;
}

export const Layout: FC<IProps> = ({ header, children, backgroundColor, mainStyle, headerHeight, footerHeight }) => (
  <Container isHeader={Boolean(header)} bg={backgroundColor}>
    <Header headerHeight={headerHeight}>{header ?? null}</Header>
    <Main headerHeight={headerHeight} footerHeight={footerHeight} style={mainStyle}>
      {children}
    </Main>
    <Footer>
      <NavBar />
    </Footer>
  </Container>
);

const HEADER_HEIGHT = "50px";
const FOOTER_HEIGHT = "70px";

const Container = styled.div<{ bg?: string; isHeader: boolean }>`
  height: ${() => `${window.innerHeight}px`};
  background-color: ${(props) => props.bg ?? "white"};
`;

const Footer = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
  height: ${FOOTER_HEIGHT};
`;

const Main = styled.main<{ headerHeight?: string; footerHeight?: string }>`
  height: ${(props) => `calc(100% - ${props.headerHeight ?? HEADER_HEIGHT} - ${props.footerHeight ?? FOOTER_HEIGHT})`};
  overflow: hidden;
`;

const Header = styled.header<{ headerHeight?: string }>`
  height: ${(props) => props.headerHeight ?? HEADER_HEIGHT};
`;
