import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { RootState } from "@store";

export const selectProject = (state: RootState) => state.project;

export const selectSpecialProject = (id: string) =>
  createSelector(selectProject, (project) => project.projects.filter((i) => i.id === id)[0]);

export const tagNameSelector = (id: string) => useSelector((state: RootState) => state.project.projects.filter((i) => i.id === id)[0].name);
