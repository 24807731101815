import React, { FC, useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";

import { ModalRenderWithCondition } from "@components/hoc";
import { Modal } from "@components/screens/day/components/timetable/main";
import { filterEvents } from "@components/screens/day/components/timetable/main/util";
import { HeaderModal, ModalUI, TextFont } from "@components/ui";
import { useDimension } from "@hooks/useDimension";
import { IUnansweredMeetList, TTypeParticipants } from "@interfaces/meet.interface";
import { eventsService } from "@services/events.service";
import { Colors } from "@theme/colors";
import { toFormatDate } from "@utils";

import TimetableViewMeet from "./TimetableViewMeet";

interface IProps {
  onClose: () => void;
  selectMeet: IUnansweredMeetList;
  onPressStatus: (arg: IUnansweredMeetList, type: TTypeParticipants) => void;
}

export const ModalTimetable: FC<IProps> = ({ onClose, selectMeet, onPressStatus }) => {
  const { isTablet } = useDimension();

  const [isVisible, setIsVisible] = useState(false);
  const [filteredEventsOfDay, setFilteredEventsOfDay] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await eventsService.getEventsOfDay(toFormatDate(dayjs(selectMeet.startTime)));
      const result = filterEvents(response.data.map((i) => ({ ...i, deep: 0 })));

      // TODO: Типизировать данные от селектора и фильтра!!!
      setFilteredEventsOfDay(result);
    })();
  }, [selectMeet.startTime, selectMeet.meetingStatus]);

  const handlePress = useCallback(() => {
    setIsVisible(true);
  }, []);

  const checkStatus = (status: TTypeParticipants) => {
    const isSelectStatus = status === selectMeet.meetingStatus;

    return {
      text: isSelectStatus ? "white" : Colors.LIGHT.text.main,
      style: {
        backgroundColor: isSelectStatus ? Colors.LIGHT.background.green : "white",
        border: `${isSelectStatus ? 0 : 1}px solid ${Colors.LIGHT.text.main}`,
      },
    };
  };

  const handlePressStatus = (arg: IUnansweredMeetList, type: TTypeParticipants) => {
    onPressStatus(arg, type);
    onClose();
  };

  const styles = style(isTablet);

  const tablet = (
    <section style={styles.containerTablet}>
      <div>
        <TextFont size={18} weight="700">
          {dayjs(selectMeet.startTime).format("DD MMM YYYY")}
        </TextFont>
        <TextFont size={10.5} weight="400">
          {dayjs(selectMeet.startTime).format("dddd")}
        </TextFont>
      </div>
      <div id="timetableUnanswered" style={styles.container}>
        <TimetableViewMeet eventsOfDay={filteredEventsOfDay} handlePress={handlePress} lightId={selectMeet.id} />
      </div>
    </section>
  );

  const mob = (
    <ModalUI onClose={onClose} isVisible style={styles.bgWhite}>
      <HeaderModal title={dayjs(selectMeet.startTime).format("DD MMM")} onClose={onClose} />

      <div id="timetableUnanswered" style={styles.container}>
        <TimetableViewMeet eventsOfDay={filteredEventsOfDay} handlePress={handlePress} lightId={selectMeet.id} />
      </div>

      <div style={styles.wrapperButton}>
        <button
          onClick={() => handlePressStatus(selectMeet, "MAYBE_ATTENDER")}
          style={{ ...styles.button, ...checkStatus("MAYBE_ATTENDER").style, width: "100%" }}
        >
          <TextFont color={checkStatus("MAYBE_ATTENDER").text} size={18} type="bold">
            Может быть пойду
          </TextFont>
        </button>
        <div style={{ display: "flex", gap: 12, marginBottom: 20 }}>
          <button
            onClick={() => handlePressStatus(selectMeet, "NON_ATTENDER")}
            style={{
              ...styles.button,

              flex: 1,

              ...checkStatus("NON_ATTENDER").style,
            }}
          >
            <TextFont color={checkStatus("NON_ATTENDER").text} size={18} type="bold">
              Нет
            </TextFont>
          </button>
          <button
            onClick={() => handlePressStatus(selectMeet, "ATTENDER")}
            style={{ ...styles.button, flex: 1, ...checkStatus("ATTENDER").style }}
          >
            <TextFont color={checkStatus("ATTENDER").text} size={18} type="bold">
              Да
            </TextFont>
          </button>
        </div>
      </div>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} meetId={selectMeet.id} setIsVisible={setIsVisible} onAfterClose={onClose} isRenderDotes={false} />
      </ModalRenderWithCondition>
    </ModalUI>
  );

  return isTablet ? tablet : mob;
};

const style = (isTablet: boolean) => ({
  container: {
    height: isTablet ? "100%" : "88%",
  },
  wrapperButton: {
    display: "flex",
    "flex-direction": "column",
    position: "absolute",
    gap: 12,
    bottom: 0,
    right: 20,
    left: 20,
  },
  button: {
    display: "flex",
    borderRadius: 10,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  bgWhite: {
    backgroundColor: Colors.LIGHT.white,
  },
  containerTablet: {
    paddingLeft: 15,
    paddingTop: 10,
    backgroundColor: Colors.LIGHT.white,
    height: "99%",
  },
});
