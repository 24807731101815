import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as RemoveGreen } from "@assets/remove-green.svg";
import { ReactComponent as SearchGreen } from "@assets/search-green.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IFilter } from "@interfaces/businessTask.interface";
import { SelectRole } from "@shared";
import { RootState } from "@store";
import { getFilteredList, setFilter } from "@store/businessTask";
import { selectTheme, themeSelector } from "@store/theme";
import { selectUserPermissoins } from "@store/user";
import { TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import globalState from "@utils/globalState";
import { myTracker } from "@utils/myTracker";

import { ReactComponent as Filter } from "./assets/filter.svg";
import { ReactComponent as Grouping } from "./assets/grouping.svg";
import { ReactComponent as Project } from "./assets/project.svg";
import { FilterModal } from "./components/filterModal";
import { GroupingModal } from "./components/groupingModal";
import { ProjectModal } from "./components/projectModal";

const eventHelperUtils = new EventHelperUtils();

export const Header = () => {
  const dispatch = useDispatch<any>();
  const { filter } = useSelector((state: RootState) => state.businessTasks);
  const { name } = filter;
  const permissons = useSelector(selectUserPermissoins);
  const theme = selectTheme("extra");
  const { extra } = themeSelector();

  const [isSearchPressed, setIsSearchPressed] = useState<boolean>(false);

  const [isShownProject, setIsShownProject] = useState<boolean>(false);
  const [isShownGrouping, setIsShownGrouping] = useState<boolean>(false);
  const [isShownFilter, setIsShownFilter] = useState<boolean>(false);
  const [search, setSearch] = useState(name);

  useEffect(() => {
    if (search) {
      setIsSearchPressed(true);
    }
  }, []);

  useEffect(() => {
    applyEvent();
  }, []);

  const applyEvent = async (callback?: () => void, proporties?: Partial<IFilter>) => {
    dispatch(
      getFilteredList({
        ...filter,
        pageable: {
          next: 20,
          current: 0,
        },
        ...proporties,
      }),
    );
    if (callback) callback();
  };

  const closeGroupModal = () => {
    setIsShownGrouping(false);
  };

  const closeFilterModal = () => {
    setIsShownFilter(false);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    dispatch(setFilter({ name: value }));

    eventHelperUtils.debounce(() => {
      dispatch(
        getFilteredList({
          ...filter,
          name: value,
          pageable: {
            next: 20,
            current: 0,
          },
        }),
      );
    }, 1000);
  };

  const handleToggleSearch = (arg: boolean) => {
    if (!arg && search) {
      handleSearch("");
    }
    setIsSearchPressed(arg);
  };

  const colorSvg = extra === "ASSISTANT" ? "#453831" : "#297952";

  return (
    <section style={{ ...styles.container, position: "relative" }}>
      <div style={{ flex: 2, zIndex: 1, alignItems: "center", gap: 20, display: "flex" }}>
        <RenderWithCondition condition={permissons.WORK_WITH_SPACE_PROJECTS}>
          <button
            disabled={extra === "ASSISTANT"}
            onClick={() => setIsShownProject(true)}
            style={{ display: "flex", justifySelf: "flex-start" }}
          >
            <Project fill={extra === "ASSISTANT" ? "#EEEDE9" : "#297952"} />
          </button>
        </RenderWithCondition>
        <RenderWithCondition condition={extra === "ASSISTANT"}>
          <SelectRole type="view" />
        </RenderWithCondition>
      </div>
      <TextFont type="bold" style={{ ...styles.title, color: theme.text.main }}>
        <FormattedMessage id={"availableTasks"} />
      </TextFont>
      <div style={styles.rightContainer}>
        <div style={styles.filterContainer}>
          {isSearchPressed && (
            <div style={styles.searchInput}>
              <SvgSearch />
              <input
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                style={styles.input}
                placeholder="Поиск"
                maxLength={255}
              />
            </div>
          )}
          <button onClick={() => handleToggleSearch(!isSearchPressed)} style={{ marginLeft: 20 }}>
            {isSearchPressed ? <RemoveGreen fill={colorSvg} /> : <SearchGreen fill={colorSvg} />}
          </button>
        </div>

        <button
          onClick={() => {
            myTracker("GroupingClick");
            setIsShownGrouping(true);
          }}
          style={{ marginLeft: 20, width: 50, height: 25, display: "flex", justifyContent: "flex-end", alignItems: "center" }}
        >
          <Grouping fill={colorSvg} />
        </button>
        <button
          onClick={() => {
            myTracker("FilterClick");
            setIsShownFilter(true);
          }}
          style={{ marginLeft: 0, width: 42, display: "flex", justifyContent: "flex-end" }}
        >
          <Filter fill={colorSvg} />
        </button>
      </div>
      <ModalRenderWithCondition condition={isShownProject}>
        <ProjectModal isVisible={isShownProject} closeEvent={() => setIsShownProject(false)} />
      </ModalRenderWithCondition>
      <FilterModal applyEvent={() => applyEvent(closeFilterModal)} isVisible={isShownFilter} closeEvent={closeFilterModal} />
      <ModalRenderWithCondition condition={isShownGrouping}>
        <GroupingModal applyEvent={applyEvent} isVisible={isShownGrouping} closeEvent={closeGroupModal} />
      </ModalRenderWithCondition>
    </section>
  );
};

const styles = {
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 13px",
    height: "100%",
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    lineHeight: "24px",
    flex: 1,
    "text-align": "center",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    flex: 2,
    justifyContent: "flex-end",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    flex: 1.5,
    justifyContent: "flex-end",
  },
  textDrop: {
    fontSize: 16,
    lineHeight: "24px",
    color: globalState.colorSchema.text.accent,
    marginLeft: 15,
  },
  searchInput: {
    width: "90%",
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderRadius: 10,
    backgroundColor: "#ECF0EF",
    padding: "9px 8px",
  },
  input: {
    marginLeft: 6,
    width: "100%",
  },
};
