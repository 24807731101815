import React from "react";

import { ReactComponent as SvgDoubleCopy } from "@assets/copy_double.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { ReactComponent as SvgMove } from "@assets/move.svg";
import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { ReactComponent as SvgOwner } from "@assets/owner_grey.svg";
import { ReactComponent as SvgPeople } from "@assets/people.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ReactComponent as SvgToManage } from "@assets/to_manage.svg";

export type TKeyButtons =
  | "edit"
  | "transfer"
  | "remove"
  | "did_not_take"
  | "will_not_take"
  | "cancel"
  | "newMeetThisParticipants"
  | "toManage"
  | "toBeOrganizer"
  | "copyParticipantsFIO";

interface IValueButtons {
  title: string;
  icon: JSX.Element;
  colorRed?: boolean;
  requiresAuthorRights?: boolean;
}

export const config: Record<TKeyButtons, IValueButtons> = {
  edit: {
    title: "change",
    icon: <SvgEdit width={24} height={24} fill={"#C7C7C7"} />,
    requiresAuthorRights: true,
  },
  transfer: {
    title: "meetingTransfer",
    icon: <SvgMove width={24} height={24} fill={"#C7C7C7"} />,
    requiresAuthorRights: true,
  },
  did_not_take: {
    title: "tookPlaceNo",
    icon: <SvgNotTake width={24} height={24} />,
  },
  will_not_take: {
    title: "takePlaceNo",
    icon: <SvgNotTake width={24} height={24} />,
  },
  cancel: {
    title: "cancel",
    icon: <SvgRemove width={24} height={12} fill={"red"} />,
    requiresAuthorRights: true,
    colorRed: true,
  },
  remove: {
    title: "delete",
    icon: <SvgDelete />,
    requiresAuthorRights: true,
    colorRed: true,
  },
  newMeetThisParticipants: {
    title: "meetSameParticipant",
    icon: <SvgPeople />,
  },
  toBeOrganizer: {
    title: "toBeOrganizer",
    icon: <SvgOwner />,
  },
  toManage: {
    title: "transferControlMeeting",
    icon: <SvgToManage />,
    requiresAuthorRights: true,
  },
  copyParticipantsFIO: {
    title: "copyParticipantsNames",
    icon: <SvgDoubleCopy />,
  },
};
