import React, { useCallback, useState, useMemo, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { styled } from "styled-components";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TypeKeysPersonalGoal } from "@interfaces/personalGoals.interface";
import { selectIsModalOpen } from "@store/configure";
import { selectYearMonth } from "@store/date";
import { getList } from "@store/personalGoals";
import { personalEmptyGoalsSelector, personalFillGoalsSelector, selectPersonalGoals } from "@store/personalGoals/selectors";
import { ArrowSvg, TextFont } from "@ui";
import { isTablet } from "@utils";
import globalState from "@utils/globalState";

import { GoalItem } from "./Goaltem";
import { Icon } from "./Icon/Icon";
import { Modal } from "./Modal/Modal";

import "./animated.css";

export const TargetSelf = () => {
  const dispatch = useDispatch();

  const [currentGoal, setCurrentGoal] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);
  const [isDropDown, setIsDropDown] = useState(true);
  const [currentCategory, setCurrentCategory] = useState<TypeKeysPersonalGoal | undefined>(undefined);

  const goals = useSelector(selectPersonalGoals).goals;
  const fillTasks = useSelector(personalFillGoalsSelector);
  const emptyTasks = useSelector(personalEmptyGoalsSelector);
  const isModalOpen = useSelector(selectIsModalOpen);
  const yearMonth = useSelector(selectYearMonth);

  useEffect(() => {
    dispatch(getList(yearMonth));
  }, [yearMonth]);

  const setModalOpen = (isOpen: boolean) => {
    setIsVisible(isOpen);
  };

  const handlePressIcon = useCallback((goalId: string) => {
    setCurrentGoal(goalId);
    setModalOpen(true);
  }, []);

  const handlePressEmptyGoal = (category: TypeKeysPersonalGoal) => {
    const fillGoal = goals.filter((i) => i.category === category)[0];
    setCurrentGoal(fillGoal ? fillGoal.id : "");
    setCurrentCategory(category);
    setModalOpen(true);
  };

  const ratioTasks = useMemo(() => {
    if (!fillTasks.length) {
      return "";
    }

    let all = 0;
    let completed = 0;

    fillTasks.map((i) => {
      completed += i.personalTasks.filter((task) => task.completed).length;
      all += i.personalTasks.length;
    });

    return `${completed}/${all}`;
  }, [fillTasks]);

  return (
    <Container>
      <button style={styles.header} onClick={() => setIsDropDown(!isDropDown)}>
        <TextFont
          type="bold"
          color={isModalOpen ? globalState.colorSchema.placeholder.main : globalState.colorSchema.text.main}
          style={{ ...styles.title, margin: "6px 0" }}
        >
          <FormattedMessage id="personalGoals" />
        </TextFont>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TextFont>{ratioTasks}</TextFont>
          <CSSTransition key={"arrow"} in={isDropDown} className="arrow" timeout={300}>
            <button onClick={() => setIsDropDown(!isDropDown)}>
              <ArrowSvg type={"up"} fill={globalState.colorSchema.background.darkGreen} style={{ marginLeft: 8 }} width={26} height={26} />
            </button>
          </CSSTransition>
        </div>
      </button>
      <RenderWithCondition condition={isDropDown && fillTasks.length}>
        <aside style={styles.list}>
          {fillTasks.map((item) => (
            <GoalItem item={item} handlePressIcon={handlePressIcon} key={item.id} />
          ))}
        </aside>
      </RenderWithCondition>

      <RenderWithCondition condition={isDropDown}>
        <aside style={styles.listIcon}>
          {emptyTasks.map((item, index) => (
            <button onClick={() => handlePressEmptyGoal(item)} key={index}>
              <Icon type={isTablet && isModalOpen ? "inactive" : "active"} title={item} />
            </button>
          ))}
        </aside>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} goalId={currentGoal} setIsVisible={setModalOpen} category={currentCategory} />
      </ModalRenderWithCondition>
    </Container>
  );
};

const Container = styled.section`
  background-color: ${() => globalState.colorSchema.background.main};
  border-radius: 0 20px 0 0;
  padding-left: 12px;
  padding-right: 12px;
`;

const styles = {
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  list: {
    marginTop: "12px",
    borderBottom: "1px solid #E3E9E7",
  },
  listIcon: {
    display: "flex",
    marginTop: "14px",
  },
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
  },
};
