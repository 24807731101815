import React, { FC, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";

import { RenderWithCondition } from "@hoc";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";
import { isTablet } from "@utils/index";

import { HEIGHT_BLOCK, WIDTH_BLOCK } from "../constants";

interface IRow {
  hour: number;
  date: Dayjs;
  rows: number;
  isLightColumnNumber: number;
}

export const Row: FC<IRow> = ({ hour, date, rows, isLightColumnNumber }) => {
  const currentMinute = dayjs().minute();

  const isCurrentHour = useMemo(() => hour === dayjs().hour() && date.isSame(dayjs(), "date"), [date, hour]);

  const dynamicPadding = useMemo(() => {
    if (currentMinute >= 0 && currentMinute <= 15) {
      return 0;
    }

    if (currentMinute > 15 && currentMinute <= 30) {
      return 7;
    }

    if (currentMinute > 30 && currentMinute <= 60) {
      return 15;
    }
  }, [currentMinute]);

  const formatHour = useMemo(() => (hour < 10 ? `0${hour}` : hour), [hour]);

  return (
    <div style={{ position: "relative", width: WIDTH_BLOCK }}>
      <TextFont style={styles.hour}>{formatHour}</TextFont>

      <div>
        {[...Array(rows).keys()].map((i) => (
          <div key={i} style={{ ...styles.block, ...(isLightColumnNumber === i && { backgroundColor: "#f5f6f6", opacity: 0.5 }) }} />
        ))}
      </div>

      <RenderWithCondition condition={isCurrentHour}>
        <div style={{ ...styles.currentTime, paddingLeft: dynamicPadding, position: "absolute" }}>
          <div style={styles.lineCircle} />
          <div style={styles.line} />
        </div>
      </RenderWithCondition>
    </div>
  );
};

const styles = {
  hour: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    fontSize: 14,
    "text-align": "center",
    color: "#939393",
    padding: "6px 0",
    width: WIDTH_BLOCK,
    height: 56,
  },
  block: {
    height: HEIGHT_BLOCK,
    width: WIDTH_BLOCK,
    borderRight: `1px solid ${globalState.colorSchema.lighGrey}`,
    borderTop: isTablet ? `1px solid ${globalState.colorSchema.lighGrey}` : "0",
  },
  line: {
    width: 1,
    height: "100%",
    backgroundColor: globalState.colorSchema.red,
  },
  currentTime: {
    zIndex: 3,
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    top: 0,
    height: "300%",
  },
  lineCircle: {
    width: 5,
    height: 5,
    borderRadius: 5,
    backgroundColor: globalState.colorSchema.red,
  },
};
