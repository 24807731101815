import { Ref, useEffect, useMemo, useRef } from "react";

import Autolinker from "autolinker";

interface IProps {
  text: string;
  className?: string;
}

type TUseAutoLink = ({ text, className }: IProps) => { ref: Ref<HTMLSpanElement> };

export const useAutoLink: TUseAutoLink = ({ text, className = "autoLink" }) => {
  const curentRef = useRef(null);

  useEffect(() => {
    if (curentRef.current) {
      curentRef.current.innerHTML = Autolinker.link(text, {
        className,
      });
    }
  }, [curentRef, text]);

  return useMemo(() => ({ ref: curentRef }), [curentRef]);
};
