import React from 'react';
import { useLottie } from 'lottie-react';
import lottieEmailSent from './lottieEmailSent.json';

export const SuccessfulSendAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieEmailSent,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const { View } = useLottie(defaultOptions)

  return (
    <div style={{ height: 80, width: 80 }}>
      {View}
    </div>
  )
}
