export const fieldsToExcludeForPersonalTask = [
  'delegatedTo',
  'delegatedFrom',
  'delegatedFromUser',
  'delegatedFromDateTime',
  'delegatedToUser',
  'delegatedToDateTime',
  'attachedMeetings',
  'controlledTaskId',
  'controlledTask',
  'controlTasks',
  'projectId',
  'project',
];
