import { IStateUser } from "@interfaces/user.interface";

export interface IData {
  title: string;
  value: keyof IStateUser;
  custom?: boolean;
  isLink?: boolean;
}
export const data: IData[] = [
  {
    title: "subdivision",
    value: "department",
    custom: true,
  },
  {
    title: "jobTitle",
    value: "workPosition",
  },
  {
    title: "birthday",
    value: "birthDay",
    custom: true,
  },
  {
    title: "sex",
    value: "gender",
    custom: true,
  },
  {
    title: "workPhone",
    value: "workPhone",
  },
  {
    title: "interofficeTelephone",
    value: "workPhoneInner",
  },
  {
    title: "contactEmail",
    value: "email",
    isLink: true,
  },
  //   {
  //     title: 'supervisor',
  //     value: 'headOfUser',
  //     custom: true,
  //   },
];
