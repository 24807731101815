import React, { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgDone } from "@assets/done_thin.svg";
import { getList, selectBusinessGoals } from "@store/businessGoals";
import { selectYearMonth } from "@store/date";
import { TextFont, ModalUI, HeaderModal } from "@ui";
import globalState from "@utils/globalState";

interface IModalGoal {
  isVisible: boolean;
  close: () => void;
  goalId: string;
  setGoal: (name: string, value: any) => void;
}

export const ModalGoal = ({ isVisible, close, goalId, setGoal }: IModalGoal) => {
  const dispatch = useDispatch();

  const goals = useSelector(selectBusinessGoals);
  const yearMonth = useSelector(selectYearMonth);

  useEffect(() => {
    dispatch(getList(yearMonth));
  }, []);

  const handlePress = (value: string | null = null) => {
    setGoal("businessGoalId", value);
    close();
  };

  return (
    <ModalUI isVisible={isVisible} onClose={close}>
      <HeaderModal title="goal" onClose={close} />
      <div style={styles.main}>
        {goals.length ? (
          <div style={styles.mainBlock}>
            {goals.map((goal) => (
              <button key={goal.id} style={styles.goal} onClick={() => handlePress(goal.id)}>
                <TextFont
                  color={goalId === goal.id ? globalState.colorSchema.text.accent : globalState.colorSchema.text.main}
                  style={styles.text}
                >
                  {goal.description}
                </TextFont>
                {goalId === goal.id ? <SvgDone /> : ""}
              </button>
            ))}
            <button style={{ paddingTop: 14 }} onClick={() => handlePress()}>
              <TextFont style={styles.text}>
                <FormattedMessage id="emptyField" />
              </TextFont>
            </button>
          </div>
        ) : (
          <TextFont style={{ textAlign: "center", fontSize: 18, fontWeight: "400" }}>
            <FormattedMessage id="businessGoalAdd" />
          </TextFont>
        )}
      </div>
    </ModalUI>
  );
};

const styles = {
  header: {
    display: "flex",
    "flex-direction": "row",
    borderBottom: "1px solid #000000",
    padding: "9px",
  },
  title: {
    display: "flex",
    width: "33%",
    "flex-direction": "row",
    justifyContent: "center",
  },
  headerTitle: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#297952",
  },
  main: {
    paddingTop: "20px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  mainBlock: {
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "20px",
    paddingTop: "6px",
    backgroundColor: "#FFFFFF",
    borderRadius: "13px",
  },
  goal: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    paddingBottom: "11px",
    paddingTop: "14px",
    borderBottom: "0.5px solid #E3E9E7",
    width: "100%",
  },
  text: {
    fontSize: "18px",
    fontWeight: "400",
  },
};
