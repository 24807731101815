import React, { useMemo, useEffect, useRef, useState } from "react";

interface IUseGetContainerDimensionsReturn {
  width: number;
  height: number;
}

export const useGetContainerDimensions = (
  containerRef: React.MutableRefObject<HTMLDivElement>,
  pingMeasurement = false,
): IUseGetContainerDimensionsReturn => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateDimensions = () => {
    if (containerRef && containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    }
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (!pingMeasurement) return;

    const intervalId = setInterval(updateDimensions, 200);

    const timeoutId = setTimeout(() => clearInterval(intervalId), 1000);

    return () => {
      clearTimeout(intervalId);
      clearTimeout(timeoutId);
    };
  }, [pingMeasurement]);

  return useMemo(
    () => ({
      width,
      height,
    }),
    [width, height],
  );
};
