import React, { FC } from "react";

import { TTypeDayOfWeek } from "@interfaces/meet.interface";
import { TextFont } from "@ui";

import { CommonFields, daysOfWeek } from "../config";

interface IProps extends CommonFields {
  days: TTypeDayOfWeek[];
}

export const DayOfWeek: FC<IProps> = ({ handleData, days }) => {
  const handleCheckBox = (key: keyof typeof daysOfWeek, value: boolean) => {
    const tempDays = value ? [...days, key] : days.filter((i) => i !== key);
    handleData("dayOfWeeks", tempDays.length ? tempDays : []);
  };

  return (
    <section>
      <TextFont style={{ fontSize: 18, marginBottom: 6 }}>Выберите день недели</TextFont>
      <div style={styles.wrapper}>
        {Object.keys(daysOfWeek).map((i: any) => (
          <div key={i} style={styles.block}>
            <input
              type="checkbox"
              onChange={(e) => handleCheckBox(i, e.target.checked)}
              checked={days.includes(i)}
              style={styles.checkbox}
              className="input"
              color="#219653"
            />

            <TextFont style={{ fontSize: 16, marginTop: 4, textAlign: "center" }}>{daysOfWeek[i]}</TextFont>
          </div>
        ))}
      </div>
    </section>
  );
};

const styles = {
  checkbox: {
    marginRight: 10,
    width: "auto",
    height: "auto",
  },
  wrapper: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: "10px 5px 10px 16px",
    borderRadius: 9,
  },
  block: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
  },
};
