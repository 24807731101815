import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { LIMIT_LETTERS_TAG } from "@constants/logic";
import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { create, getList, selectSpecialTag, selectStoreTag } from "@store/tag";
import { clearTagId } from "@store/tag/slice";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import globalState from "@utils/globalState";

const eventHelperUtils = new EventHelperUtils();

interface IModalTag {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  currentTagId: string;
  setTag: (name: string, value: any, text?: string) => void;
}

export const ModalTag = ({ isVisible, setIsVisible, setTag, currentTagId }: IModalTag) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const { avaliableTags, tagId, isLoading } = useSelector(selectStoreTag);
  const currentTag = useSelector(selectSpecialTag(currentTagId));

  useEffect(() => {
    dispatch(getList(""));
  }, []);

  useEffect(() => {
    if (tagId) {
      handlePress(tagId);
      dispatch(clearTagId());
    }
  }, [tagId]);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleInput = (text: string) => {
    const value = text.replace(/\s/g, "");
    setSearch(value);

    eventHelperUtils.debounce(() => {
      dispatch(getList(value));
    }, 1000);
  };

  const handleDrop = () => {
    setSearch("");
    dispatch(getList(""));
  };

  const handlePress = (id: string, name?: string) => {
    setTag("tagId", id, name);
    setIsVisible(false);
  };

  const addTag = (name: string) => {
    dispatch(create(name));
    setSearch("");
  };

  const phSearch = useIntl().formatMessage({ id: "findAndAdd" });

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose} scrollEnable={false}>
      <HeaderModal title="tags" onClose={handleClose} />
      <div style={styles.main}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={styles.searchInput}>
            <SvgSearch />
            <input
              value={search}
              onChange={(e) => handleInput(e.target.value)}
              style={styles.input}
              placeholder={phSearch}
              maxLength={LIMIT_LETTERS_TAG}
            />
          </div>
          <RenderWithCondition condition={Boolean(search)}>
            <button style={styles.searchButton} onClick={handleDrop}>
              <TextFont style={styles.searchButtonText}>
                <FormattedMessage id="drop" />
              </TextFont>
            </button>
          </RenderWithCondition>
        </div>
        <TextFont style={{ color: "#B3BEB8", marginTop: 10 }}>
          <FormattedMessage id="tagDescription" />
        </TextFont>
        <RenderWithCondition condition={Boolean(currentTag?.id)}>
          <div style={{ paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: "#E3E9E7" }}>
            <button style={styles.currentTag} onClick={() => setTag("tagId", null)}>
              <TextFont style={{ ...styles.tagText, marginRight: 7 }}>{currentTag?.name}</TextFont>
              <SvgRemove fill="#B3BEB8" width={9} height={9} />
            </button>
          </div>
        </RenderWithCondition>
        <LoaderRenderWithCondition condition={isLoading}>
          <div style={{ display: "flex", flexDirection: "column", marginTop: 10, maxHeight: "80%", overflowY: "auto" }}>
            {avaliableTags.length
              ? avaliableTags.map(
                  (tag) =>
                    tag.id !== currentTag?.id && (
                      <button key={tag.id} style={{ marginBottom: 17 }} onClick={() => handlePress(tag.id, tag.name)}>
                        <TextFont style={styles.tagText}>{tag?.name}</TextFont>
                      </button>
                    )
                )
              : search && (
                  <button style={{ display: "flex", flexDirection: "row" }} onClick={() => addTag(search)}>
                    <TextFont style={{ marginRight: 3 }}>{`${search} - `}</TextFont>
                    <TextFont>
                      <FormattedMessage id="tagCreate" />
                    </TextFont>
                  </button>
                )}
          </div>
        </LoaderRenderWithCondition>
      </div>
    </ModalUI>
  );
};

const styles = {
  header: {
    display: "flex",
    "flex-direction": "row",
    borderBottom: "1px solid black",
    padding: "9px",
  },
  title: {
    width: "33%",
    display: "flex",
    "flex-direction": "row",
    justifyContent: "center",
  },
  headerTitle: {
    fontWeight: "700",
    fontSize: "16px",
    color: globalState.colorSchema.text.accent,
  },
  main: {
    paddingTop: "20px",
    paddingRight: "15px",
    paddingLeft: "9px",
    height: "100%",
  },
  searchInput: {
    flex: 3,
    display: "flex",
    "flex-direction": "row",
    borderRadius: "10px",
    backgroundColor: "#ECF0EF",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "11px",
    paddingBottom: "11px",
  },
  input: {
    marginLeft: "6px",
    width: "100%",
  },
  searchButton: {
    flex: 1,
    marginLeft: "14px",
  },
  searchButtonText: {
    color: globalState.colorSchema.text.accent,
    fontSize: "16px",
    fontWeight: "400",
  },
  tagText: {
    fontSize: "16px",
    fontWeight: "400",
  },
  currentTag: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "45px",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "3px",
    paddingBottom: "3px",
    marginTop: "10px",
    alignSelf: "flex-start",
  },
};
