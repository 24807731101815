export type TFilter = "all" | "attender" | "non_attender" | "maybe_attender" | "non_answer" | "with_comment";

export const tempFilters: Record<TFilter, number> = {
  all: 0,
  attender: 0,
  non_attender: 0,
  maybe_attender: 0,
  with_comment: 0,
  non_answer: 0,
};

export const titleFilter: Record<TFilter, string> = {
  all: "Все",
  attender: "Пойду",
  non_attender: "Не пойду",
  maybe_attender: "Может быть пойду",
  non_answer: "Без ответа",
  with_comment: "С комментарием",
};

export enum ChangeEvents {
  Add = "Add",
  Remove = "Remove",
}

export const next = 10;
export const firstNext = 5;
