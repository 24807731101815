import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { RootState } from "@store";
import { toFormatDate } from "@utils/toFormatTime";

export const selectNotes = (state: RootState) => state.note;

export const selectNotesCurrentDay = (day: string) =>
  createSelector(selectNotes, (note) => note.notes.filter((i) => toFormatDate(dayjs(i.createdDate)) === day));

export const selectNotesCurrentMonth = (month: string) =>
  createSelector(selectNotes, (note) => note.notes.filter((i) => dayjs(i.createdDate).format("YYYY-MM") === month));

export const notesSelector = () => useSelector((state: RootState) => state.note?.notes);
