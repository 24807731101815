import { http } from "@api/httpClient";

class ProjectService {
  getList = async (name: string): Promise<any> =>
    await http.get("/api/crud/v1/project-groups", {
      params: {
        name,
      },
    });

  getProject = async (id: string): Promise<any> => await http.get(`/api/crud/v1/projects/${id}`);
}

export const projectService = new ProjectService();
