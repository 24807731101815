import globalState from "@utils/globalState";

export const styles = {
  main: {
    width: "97%",
    paddingTop: "40px",
    paddingLeft: "3%",
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: "700",
    color: globalState.colorSchema.text.main,
    marginBottom: "12px",
  },
  mainMonths: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "center",
    "flex-wrap": "wrap",
  },
  mainMonthsBlock: {
    display: "flex",
    minWidth: "30.5%",
    height: "46px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    marginBottom: "10px",
    marginRight: "10px",
    paddingVertical: "11px",
  },
  mainMonthsBlockActive: {
    border: `1px solid ${globalState.colorSchema.text.accent}`,
  },
  mainMonthsBlockDisabled: {
    backgroundColor: "#ECF0EF",
  },
  mainMonthsText: {
    fontSize: "14px",
    fontWeight: "400",
    color: globalState.colorSchema.text.main,
  },
  mainMonthsTextDisabled: {
    color: globalState.colorSchema.grey,
  },
  back: {
    flex: 1.5,
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
  },
  backTitle: {
    fontSize: "16px",
    color: "#297952",
    marginLeft: "5px",
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    borderBottomWidth: "0.5px",
    borderBottomColor: "rgba(0, 0, 0, 0.3)",
    padding: "9px",
    paddingBottom: "12px",
    marginTop: 20,
  },
  headerTitle: {
    flex: 2,
    fontWeight: "700",
    fontSize: "16px",
    color: globalState.colorSchema.text.main,
  },
  year: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    paddingRight: "15px",
    marginBottom: "10px",
  },
};
