import React, { FC, useEffect, useMemo, useRef } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SvgCalendar } from "@assets/calendar_red.svg";
import { RenderWithCondition } from "@hoc";
import { useBoolean } from "@hooks/useBoolean";
import { IMeetRepeat } from "@interfaces/meet.interface";
import { Calendar } from "@shared/planningWork";
import { RootState } from "@store/store";
import { selectTheme } from "@store/theme";
import { commonStyles } from "@styles/styles";
import { TextFont } from "@ui";
import { showToast, toFormatDate } from "@utils";
import globalState from "@utils/globalState";

interface IProps {
  startTime: string;
  isVisibleStart: boolean;
  pressCalendar: (arg: Dayjs) => void;
  disabled: boolean;
  repeat: IMeetRepeat;
  startDays: any[];
}

export const Date: FC<IProps> = ({ startTime, isVisibleStart, pressCalendar, disabled, repeat, startDays }) => {
  const blockRef = useRef(null);
  const theme = selectTheme("extra");

  const [value, { toggle }] = useBoolean();

  useEffect(() => {
    if (value) {
      blockRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [value, blockRef]);

  const onPress = () => {
    if (disabled) return showToast("dateSelectionUnavaliable");
    return toggle();
  };

  const handlePressCalendar = (arg: any) => {
    pressCalendar(arg);
    toggle();
  };

  const { startDate } = useSelector((state: RootState) => state.screenDay);

  const currentDay = useMemo(
    () => (dayjs(startTime).isBefore(dayjs()) ? dayjs().format("YYYY-MM-DD") : dayjs(startTime).format("YYYY-MM-DD")),
    [startTime],
  );

  return (
    <div ref={blockRef} style={{ ...styles.container, ...(disabled && { backgroundColor: globalState.colorSchema.disabled }) }}>
      <button
        style={{ ...commonStyles.billet, background: "none", ...(isVisibleStart && { borderBottom: "1px solid #E3E9E7" }) }}
        onClick={onPress}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <SvgCalendar style={{ marginRight: 8 }} />
          <TextFont style={styles.textBold}>
            <FormattedMessage id="date" />
          </TextFont>
        </div>
        <div style={commonStyles.wrapperDate}>
          <div style={{ ...commonStyles.blockDate, marginRight: 11, backgroundColor: theme.background.main }}>
            <TextFont>{dayjs(startTime).format("DD MMM YYYYг.")}</TextFont>
          </div>
        </div>
      </button>

      <RenderWithCondition condition={value}>
        <Calendar
          currentDay={currentDay}
          startDay={toFormatDate(startDate)}
          press={handlePressCalendar}
          deadline={toFormatDate(dayjs(repeat?.endTime ?? ""))}
          days={startDays}
        />
      </RenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    marginTop: 8,
    borderRadius: 11,
  },
  textBold: {
    fontSize: 16,
    fontWeight: "700",
  },
};
