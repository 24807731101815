import { createAsyncThunk } from "@reduxjs/toolkit";

import { dateService } from "@services";
import { getError } from "@store/getError";

export const getFirstMonth: any = createAsyncThunk("dateSlice/getFirstMonth", async (a, thunkAPI) => {
  try {
    const response = await dateService.getFirstDate();
    return { response };
  } catch (error: any) {
    getError(error, thunkAPI);
  }
});
