import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { TTypeMeetingStatus } from "@interfaces/meet.interface";
import { useRemoveMeetingStatusMutation } from "@services/meetApi";
import { selectCurrentDate } from "@store/screenDay";
import { TextFont } from "@ui";
import { toFormatDate } from "@utils";

interface IProps {
  id: string;
  status: TTypeMeetingStatus;
}

export const Actions: FC<IProps> = ({ status, id }) => {
  const [removeMeetingStatus] = useRemoveMeetingStatusMutation();

  const date = useSelector(selectCurrentDate);

  const buttonsMeetingStatus: Record<TTypeMeetingStatus, string> = {
    DID_NOT_TAKE_PLACE: "cancelMeetBack",
    WILL_NOT_TAKE_PLACE: "cancelFutureMeetBack",
  };

  const handleMeetingStatus = () => {
    removeMeetingStatus({ id, date: toFormatDate(date) });
  };

  return (
    <div>
      <TextFont type="bold" weight="700" size={18} style={{ marginBottom: 8 }}>
        <FormattedMessage id="availableActions" />
      </TextFont>

      <div>
        <button style={styles.button} onClick={handleMeetingStatus}>
          <SvgNotTake />
          <TextFont size={18} style={{ marginLeft: 8 }}>
            <FormattedMessage id={buttonsMeetingStatus[status]} />
          </TextFont>
        </button>
      </div>
    </div>
  );
};

const styles = {
  button: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: 11,
    padding: "12px 40px 12px 8px",
    width: "100%",
  },
};
