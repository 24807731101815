import React, { Dispatch, FC, SetStateAction } from "react";

import { FormattedMessage } from "react-intl";
import { Tooltip } from "react-tooltip";

import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { ReactComponent as SvgQuestion } from "../../../../assets/question.svg";
import { modalStyles } from "../../../../styles";

interface IPersonalTaskCheckBox {
  onValueChange: Dispatch<SetStateAction<boolean>>;
  isChecked: boolean;
}

export const PersonalTaskCheckbox: FC<IPersonalTaskCheckBox> = ({ isChecked = false, onValueChange }) => (
  <div style={{ ...commonStyles.billet, ...styles.container }}>
    <div style={styles.innerContainer}>
      <input
        type="checkbox"
        onChange={() => onValueChange(!isChecked)}
        checked={isChecked}
        style={{ ...styles.checkbox, ...{ border: "1px solid red" } }}
        className="input purpleCheckbox"
      />
      <TextFont type="regular" style={modalStyles.blockTurnText}>
        <FormattedMessage id={"personalTask"} />
      </TextFont>

      <SvgQuestion data-tooltip-id="tooltip-personal-task" style={{ marginLeft: 9, marginTop: 3 }} />
    </div>

    <Tooltip id="tooltip-personal-task" style={styles.tooltip} place="top-end">
      <TextFont weight="500" style={{ fontSize: 12 }}>
        <FormattedMessage id="personalTaskDescription" />
      </TextFont>
    </Tooltip>
  </div>
);

const styles = {
  container: {
    paddingVertical: 8,
    width: "auto",
  },
  innerContainer: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    padding: "10px",
    backgroundColor: Colors.LIGHT.white,
    zIndex: 999,
    width: "300px",
    borderRadius: "10px",
    shadowColor: "rgba(0, 0, 0)",
    shadowRadius: 6,
    shadowOpacity: 0.09,
    shadowOffset: { width: 0, height: 2 },
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.09)",
  },
  checkbox: {
    marginRight: "10px",
    width: "18px",
    height: "18px",
  },
};
