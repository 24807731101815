export interface IReason {
  notRelevant: "NOT_RELEVANT";
  duplicate: "DUPLICATE";
  other: "OTHER";
  [propName: string]: any;
}

export type TypeReason = "NOT_RELEVANT" | "DUPLICATE" | "OTHER";

export const reasonTabs: IReason = { notRelevant: "NOT_RELEVANT", duplicate: "DUPLICATE", other: "OTHER" };

export const titleReason: Record<TypeReason, string> = {
  DUPLICATE: "duplicate",
  NOT_RELEVANT: "notRelevant",
  OTHER: "closedTask",
};
