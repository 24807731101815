import { http } from "@api/httpClient";

class MonthGoalService {
  getMonthGoal = async (yearMonth: string): Promise<any> => await http.get(`/api/crud/v1/month-goals/actual/${yearMonth}`);

  saveMonthGoal = async (id: string, description: string): Promise<any> =>
    await http.patch(`/api/crud/v1/month-goals/${id}`, { description });
}

export const monthGoalService = new MonthGoalService();
