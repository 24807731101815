export const generateWorkDays = (days: { date: string }[]) => {
  const result = [];

  for (const item of days) {
    const workDay = {
      isCompleted: false,
      startTime: null,
      endTime: null,
      notCompletedComment: null,
      notCompletedReason: null,
      status: null,
      comment: null,
      date: item.date,
    };

    result.push(workDay);
  }

  return result;
};
