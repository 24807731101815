import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { TSingleSections } from "@interfaces/businessTask.interface";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  show?: () => void;
  styleWrapper?: any;
  styleTextShow?: any;
  type: TSingleSections;
}

const title: Record<TSingleSections, string> = {
  transferred: "taskFromPreviousDays",
  delegated: "delegatedTasks",
  takenBack: 'takenBackDelegatedTasks',
};

export const SingleSectionHeader: FC<IProps> = ({ show, styleWrapper, styleTextShow, type }) => (
  <button onClick={show} style={{ ...styles.container, ...styleWrapper }}>
    <TextFont size={16} style={{ color: globalState.colorSchema.red }}>
      <FormattedMessage id={title[type]} />
    </TextFont>
    <TextFont size={14} style={styleTextShow}>
      <FormattedMessage id="showAll" />
    </TextFont>
  </button>
);

const styles = {
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "5px",
    alignItems: "center",
  },
};
