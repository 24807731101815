import React, { FC, memo, useState } from "react";

import { FormattedMessage } from "react-intl";

import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";
import { SelectedUsersList } from "@shared/selectedUserList/SelectedUsersList";
import { TextFont, Checkbox } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  meet: IMeet;
  titleTextId: string;
  isAllParticipantsEditable: boolean;
  isSomeParticipantsEditable: boolean;
  isAllParticipantsChecked: boolean;
  isSomeParticipantsChecked: boolean;
  selectedUsers: IStateUser[];
  unavailableUsersIds?: string[];
  handleAddUser: (users: (IStateUser | IExternalUser)[]) => void;
  handleRemoveUser: (id: string, isExternalUser: boolean) => void;
  handleAllParticipantsCheckbox: () => void;
  handleSomeParticipantsCheckbox: () => void;
}

export const OptionsSection: FC<IProps> = memo(function OptionsSection({
  meet,
  titleTextId,
  isAllParticipantsEditable,
  isSomeParticipantsEditable,
  isAllParticipantsChecked,
  isSomeParticipantsChecked,
  selectedUsers = [],
  unavailableUsersIds = [],
  handleAddUser,
  handleRemoveUser,
  handleAllParticipantsCheckbox,
  handleSomeParticipantsCheckbox,
}: IProps) {
  const [isUserPickerVisible, setIsUserPickerVisible] = useState(false);

  return (
    <div style={styles.container}>
      <TextFont size={16} weight="700" type="bold">
        <FormattedMessage id={titleTextId} />
      </TextFont>
      <div style={styles.divider} />
      <div style={styles.option}>
        <TextFont size={16} weight="700" type="bold">
          <FormattedMessage id="allParticipants" defaultMessage={"Все участники"} />
        </TextFont>
        <Checkbox
          onChange={() => handleAllParticipantsCheckbox()}
          isChecked={isAllParticipantsChecked}
          style={{ marginRight: 8, marginLeft: 5 }}
          disabled={!isAllParticipantsEditable}
        />
      </div>
      <div style={styles.divider} />
      <div style={styles.optionContainer}>
        <div style={{ ...styles.option, ...{ marginBottom: 12 } }}>
          <TextFont size={16} weight="700" type="bold">
            <FormattedMessage id="someParticipants" defaultMessage={"Только некоторые"} />
          </TextFont>
          <Checkbox
            onChange={() => handleSomeParticipantsCheckbox()}
            isChecked={isSomeParticipantsChecked}
            style={{ marginRight: 8, marginLeft: 5 }}
            disabled={!isSomeParticipantsEditable}
          />
        </div>

        <RenderWithCondition condition={isSomeParticipantsEditable && isSomeParticipantsChecked}>
          <>
            <SelectedUsersList
              meet={meet}
              isSave={false}
              handleDeleteItem={handleRemoveUser}
              handleAddUser={handleAddUser}
              isVisibleUserPicker={isUserPickerVisible}
              onCloseUserList={() => setIsUserPickerVisible(false)}
              disableStandardAddButtons
              hideHeader
              innerUsers={selectedUsers}
              unavailableUsersIds={unavailableUsersIds}
            />

            <button style={styles.modifyListBtn} onClick={() => setIsUserPickerVisible(true)}>
              <TextFont size={16} color={globalState.colorSchema.text.accent}>
                <FormattedMessage id={selectedUsers.length ? "changeList" : "addParticipants"} />
              </TextFont>
            </button>
          </>
        </RenderWithCondition>
      </div>
    </div>
  );
});

const styles = {
  container: {
    backgroundColor: globalState.colorSchema.white,
    padding: 12,
    borderRadius: 10,
  },
  divider: {
    height: 1,
    flex: 0.8,
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: globalState.colorSchema.lighGrey,
  },
  optionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  option: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
  },
  modifyListBtn: {
    alignSelf: "center",
    paddingTop: 13,
    paddingBottom: 13,
  },
};
