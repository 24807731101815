import React, { useEffect, useState } from "react";

import "./errorNetwork.css";
import { TextFont } from "../TextFont";

export const ErrorNetwork = () => {
  const [online, setOnline] = useState(navigator.onLine);

  const handleOnline = (event: Event) => {
    setOnline(event.type === "online");
  };

  useEffect(() => {
    window.addEventListener("offline", handleOnline);
    window.addEventListener("online", handleOnline);

    return () => {
      removeEventListener("offline", handleOnline);
      removeEventListener("online", handleOnline);
    };
  }, []);

  if (online) return null;
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ ...styles.container, position: "absolute" }}>
        <TextFont className="loading" color={"white"}>
          Отсутствует соединение с интернетом ...
        </TextFont>
      </div>
    </div>
  );
};

const styles = {
  container: {
    zIndex: 999,
    top: 45,
    display: "flex",
    padding: "2px 10px",
    justifyContent: "center",
    alignItems: "center",
    height: 28,
    backgroundColor: "#ff0000ec",
    borderRadius: 10,
  },
};
