import React, { FC, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgWrapperPeople } from "@assets/people_wrapper.svg";
import { LoaderRenderWithCondition } from "@components/hoc";
import { Modal } from "@components/screens/day/components/timetable/main";
import { renderResult } from "@components/screens/day/components/timetable/main/modal/components/viewNotSave/components/repeat/utils";
import { HeaderModal, ModalUI, SearchInput, TextFont } from "@components/ui";
import { useDimension } from "@hooks/useDimension";
import { IUnansweredMeetList, TTypeParticipants } from "@interfaces/meet.interface";
import { useSetParticipantStatusMutation } from "@services/meetApi";
import { useGetUnansweredListQuery } from "@services/meetApi";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { showToast, toFormatDate } from "@utils";

import { ModalTimetable } from "./ModalTimetable";

interface IProps {
  onClose: () => void;
}

export const UnansweredMeets: FC<IProps> = ({ onClose }) => {
  const [setParticipantStatus] = useSetParticipantStatusMutation();
  const { isTablet } = useDimension();

  const [search, setSearch] = useState("");
  const [isVisibleTimetable, setIsVisibleTimetable] = useState(false);
  const [selectMeet, setSelectMeet] = useState<IUnansweredMeetList | null>(null);

  const { currentUser } = userSelector();

  const { data: unansweredMeets, isFetching, refetch } = useGetUnansweredListQuery({ name: "" });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (unansweredMeets?.length) {
      setSelectMeet(unansweredMeets[0]);
    }
  }, [unansweredMeets]);

  const handlePress = (arg: IUnansweredMeetList) => {
    setSelectMeet(arg);
    setIsVisibleTimetable(true);
  };

  const handleSendStatus = async (arg: IUnansweredMeetList, type: TTypeParticipants) => {
    if (type === arg.meetingStatus) return;

    const res = await setParticipantStatus({
      id: arg.id,
      status: type,
      participantId: currentUser?.id ?? "",
      comment: "",
      selectedDay: toFormatDate(dayjs(arg.startTime)),
    });

    if (Object.prototype.hasOwnProperty.call(res, "data")) {
      showToast("Изменения сохранены");
    }

    selectNext(arg);
  };

  const selectNext = (currentMeet: IUnansweredMeetList) => {
    const index = filteredMeets.findIndex((m) => m.id === currentMeet.id);
    if (filteredMeets.length === 1 || index === filteredMeets.length - 1) return;

    setSelectMeet(filteredMeets[index + 1]);
  };

  const filteredMeets = useMemo(
    () => [...(unansweredMeets ?? [])].filter((i) => new RegExp(search, "ig").test(i.name)),
    [unansweredMeets, search],
  );

  const styles = style(isTablet);

  const list = (
    <section style={styles.container}>
      <div style={styles.searchWrapper}>
        <SearchInput value={search} setValue={setSearch} isDrop autoRenderDrop />
      </div>

      <div style={styles.list}>
        {filteredMeets.map((item, index) => (
          <button
            key={item.id}
            onClick={() => handlePress(item)}
            style={{ ...styles.item, ...(selectMeet?.id === item.id && isTablet && styles.itemSelected) }}
          >
            <SvgWrapperPeople />
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <TextFont
                weight="500"
                size={16}
                type="bold"
                color={isTablet && selectMeet?.id === item.id ? "white" : Colors.LIGHT.text.main}
              >
                {item.name}
              </TextFont>
              <TextFont size={12} style={styles.text} color={isTablet && selectMeet?.id === item.id ? "white" : Colors.LIGHT.text.grey}>
                {item.repeat
                  ? renderResult({
                      type: item.repeat?.type,
                      endTime: item.repeat?.endTime,
                      days: item.repeat?.dayOfWeeks,
                      daysRepeat: item.repeat?.days,
                      period: item.repeat?.repetitionPeriod,
                    })
                  : dayjs(item.startTime).format("dddd, DD MMM • HH:mm–") + dayjs(item.endTime).format("HH:mm")}
              </TextFont>

              <div style={styles.wrapperButtons}>
                <button
                  style={{
                    ...styles.button,
                    ...styles.buttonLeft,
                    ...(item.meetingStatus === "ATTENDER" && { backgroundColor: "#DAF1E5" }),
                  }}
                  onClick={() => handleSendStatus(item, "ATTENDER")}
                >
                  <TextFont size={14} lineHeight={22} color={"#121212E5"}>
                    Да
                  </TextFont>
                </button>
                <button
                  style={{
                    ...styles.button,
                    ...styles.buttonCenter,
                    ...(item.meetingStatus === "NON_ATTENDER" && { backgroundColor: "#DAF1E5" }),
                  }}
                  onClick={() => handleSendStatus(item, "NON_ATTENDER")}
                >
                  <TextFont size={14} lineHeight={22} color={"#121212E5"}>
                    Нет
                  </TextFont>
                </button>
                <button
                  style={{
                    ...styles.button,
                    ...styles.buttonRight,
                    ...(item.meetingStatus === "MAYBE_ATTENDER" && { backgroundColor: "#DAF1E5" }),
                  }}
                  onClick={() => handleSendStatus(item, "MAYBE_ATTENDER")}
                >
                  <TextFont size={14} lineHeight={22} color={"#121212E5"}>
                    Может быть
                  </TextFont>
                </button>
              </div>
            </div>
          </button>
        ))}
      </div>
    </section>
  );

  const closeElement = (
    <div style={{ flex: 1 }}>
      <button style={styles.styleCloseBtn} onClick={onClose}>
        <TextFont size={16} weight="700" color={Colors.LIGHT.text.grey}>
          <FormattedMessage id="close" />
        </TextFont>
      </button>
    </div>
  );

  return (
    <ModalUI
      isVisible
      onClose={onClose}
      style={isTablet && { width: "95%", bottom: 23, left: 50, top: 23 }}
      backdropOpacity
      scrollEnable={false}
    >
      <HeaderModal
        title="listOfMeetings"
        onClose={onClose}
        isSave={false}
        isBorder={!isTablet}
        closeElement={isTablet ? closeElement : undefined}
      />

      <LoaderRenderWithCondition condition={isFetching}>
        <>
          {isTablet && (
            <div style={{ display: "flex", paddingTop: 10, height: "95%" }}>
              <aside style={styles.leftSide}>{list}</aside>
              <aside style={styles.centerSide}>
                {selectMeet && (
                  <ModalTimetable onPressStatus={handleSendStatus} onClose={() => setIsVisibleTimetable(false)} selectMeet={selectMeet} />
                )}
              </aside>
              <aside style={styles.rightSide}>
                {selectMeet && (
                  <Modal
                    isVisible={Boolean(selectMeet)}
                    meetId={selectMeet.id}
                    setIsVisible={() => ""}
                    isModal={false}
                    isRenderDotes={false}
                  />
                )}
              </aside>
            </div>
          )}

          {!isTablet && (
            <>
              {list}

              {isVisibleTimetable && selectMeet && (
                <ModalTimetable onPressStatus={handleSendStatus} onClose={() => setIsVisibleTimetable(false)} selectMeet={selectMeet} />
              )}
            </>
          )}
        </>
      </LoaderRenderWithCondition>
    </ModalUI>
  );
};

const style = (isTablet: boolean) => ({
  container: {
    paddingTop: isTablet ? 0 : 20,
    height: "97%",
  },
  list: {
    height: isTablet ? "93%" : "80%",
    paddingRight: isTablet ? 10 : 0,
    paddingLeft: isTablet ? 10 : 0,
    "overflow-y": "auto",
  },
  styleCloseBtn: {
    display: "flex",
    width: 120,
    padding: "10px 22px",
    borderRadius: 10,
    backgroundColor: Colors.LIGHT.lighGrey,
    justifyContent: "center",
    marginLeft: 15,
  },
  text: {
    width: "95%",
  },
  leftSide: {
    width: "30%",
    paddingLeft: 10,
  },
  centerSide: {
    width: "36%",
    borderLeft: `0.75px solid ${Colors.LIGHT.lighGrey}`,
  },
  rightSide: {
    width: "35%",
    borderLeft: `0.75px solid ${Colors.LIGHT.lighGrey}`,
  },
  item: {
    display: "flex",
    gap: 14,
    padding: "8px 10px",
    backgroundColor: isTablet ? Colors.LIGHT.background.main : "white",
    borderBottom: `1px solid ${Colors.LIGHT.lighGrey}`,
    marginBottom: isTablet ? 6 : 0,
    width: "100%",
  },
  itemSelected: {
    backgroundColor: "#274335B2",
    borderRadius: 6,
  },
  searchWrapper: {
    paddingRight: 12,
    paddingLeft: isTablet ? 0 : 12,
    marginBottom: 20,
  },
  wrapperButtons: {
    display: "flex",
  },
  button: {
    padding: "5px 13px",
    borderBottom: "0.5px solid #12121229",
    borderTop: "0.5px solid #12121229",
    backgroundColor: "white",
  },
  buttonCenter: {
    borderLeft: "0.5px solid #12121229",
    borderRight: "0.5px solid #12121229",
  },
  buttonLeft: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderLeft: "0.5px solid #12121229",
  },
  buttonRight: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderRight: "0.5px solid #12121229",
  },
});
