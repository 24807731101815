import React, { useEffect, useMemo } from "react";

import dayjs from "dayjs";

import { WorkDay } from "@interfaces/businessGoals.interface";
import { toFormatStartTime } from "@utils";

import { Time } from "./Time";

interface IListTime {
  days: any[];
  setDays: (arg: WorkDay[]) => void;
}

export const ListTime = ({ days, setDays }: IListTime) => {
  const isValidTime = useMemo(() => days.every((i) => i.startTime && i.endTime), [days]);

  useEffect(() => {
    const tempDays = [...days].map((i) => ({
      ...i,
      startTime: i.startTime ?? toFormatStartTime(dayjs(i.date)).startTime,
      endTime: i.endTime ?? toFormatStartTime(dayjs(i.date)).endTime,
    }));
    setDays(tempDays);
  }, [days.length]);

  const onChange = (idx: number) => (name: string, value: any, id: string) => {
    setDays((days) => [
      ...days.slice(0, idx),
      {
        ...days[idx],
        [name]: value,
      },
      ...days.slice(idx + 1),
    ]);
  };

  if (!isValidTime) return null;

  return (
    <>
      {days.map((i, idx) => (
        <Time key={i.id} day={i} onChange={onChange(idx)} />
      ))}
    </>
  );
};
