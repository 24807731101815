import { FC, useMemo } from "react";

import { useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { TGradientColor, useAnimatedText } from "@hooks/useAnimatedText";
import { useAutoLink } from "@hooks/useAutoLink";
import { selectIsModeAssistant } from "@store/user";
import { ArrowSvg } from "@ui";
import globalState from "@utils/globalState";

interface ITaskDescription {
  text: string;
  isPersonalTask?: boolean;
}

export const Description: FC<ITaskDescription> = ({ text, isPersonalTask }) => {
  const { ref: refDesc } = useAutoLink({ text });
  const isModeAssistant = useSelector(selectIsModeAssistant);

  const gradientColor = useMemo((): TGradientColor => {
    if (isPersonalTask) return "VIOLET";
    if (isModeAssistant) return "BROWN";
    return "GREEN";
  }, [isModeAssistant, isPersonalTask]);

  const { isDropdown, isOpen, setIsOpen, LinearGradientComponent, stylesTransition } = useAnimatedText({
    ref: refDesc,
    gradientColor,
  });

  return (
    <>
      <RenderWithCondition condition={text}>
        <div style={{ ...styles.wrapperDesc, position: "relative" }}>
          <span
            ref={refDesc}
            style={{
              ...styles.titleDesc,
              width: isDropdown ? "90%" : "100%",
              ...(isDropdown && { height: isOpen ? "auto" : 72, ...stylesTransition }),
            }}
          >
            {text}
          </span>
          <RenderWithCondition condition={isDropdown && !isOpen}>{LinearGradientComponent}</RenderWithCondition>
          <RenderWithCondition condition={isDropdown}>
            <button onClick={() => setIsOpen(!isOpen)} style={{ alignSelf: "flex-end", marginLeft: 8 }}>
              <ArrowSvg type={isOpen ? "up" : "down"} fill={globalState.colorSchema.background.grey} width={26} height={26} />
            </button>
          </RenderWithCondition>
        </div>
      </RenderWithCondition>
    </>
  );
};

const styles = {
  wrapperDesc: {
    display: "flex",
  },
  titleDesc: {
    color: globalState.colorSchema.white,
    fontSize: 18,
    fontWeight: "400",
    marginTop: 10,
    fontFamily: "Roboto-Regular, sans-serif",
    "word-wrap": "break-word",
    "white-space": "pre-line",
  },
};
