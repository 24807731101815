import React, { useCallback, useMemo, useRef } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { RenderWithCondition } from "@hoc";
import { ITask, TypeKeysPersonalGoal } from "@interfaces/personalGoals.interface";
import { getGoal, updateListTasks } from "@store/personalGoals";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { Notification } from "@ui/notification/Notification";
import { TextFont } from "@ui/TextFont";
import { formattedArrayTask } from "@utils";
import globalState from "@utils/globalState";

import { Icon } from "../Icon/Icon";

import { translateCategory } from "./constants";
import { TaskItem } from "./TaskItem";
import { useStartData } from "./useStartData";

interface IModal {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  goalId: string;
  category: TypeKeysPersonalGoal;
}

export const Modal = ({ isVisible, setIsVisible, goalId, category }: IModal) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { isEdit, isCancel, setIsCancel, tasks, setTasks, isAddTask, setIsAddTask, isCanAddTask, goal, yearMonth } = useStartData({
    goalId,
  });

  const handleAddTask = () => {
    if (!isCanAddTask) return;

    setIsAddTask(true);
    setTasks([...tasks, { name: "", completed: false, position: 0, id: uuidv4() }]);
  };

  const unCorrectTasks = useMemo(() => {
    if (tasks.length) {
      return !tasks.every((task) => task.name.trim().length >= 1);
    }
    return false;
  }, [tasks]);

  const handleSave = () => {
    const tasksBody = formattedArrayTask(goal?.personalTasks ?? [], tasks);
    if (goalId) {
      dispatch(updateListTasks({ goalId, tasks: tasksBody }));
    } else {
      dispatch(getGoal({ category, yearMonth, tasks: tasksBody }));
    }
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
    if (goal) {
      setTasks(goal.personalTasks);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setIsAddTask(false);
  };

  const handleCancelNotifications = useCallback(() => {
    setIsCancel(false);
  }, [setIsCancel]);

  const msgCancelExeption = useIntl().formatMessage({ id: "cancelException" });

  return (
    <ModalUI isVisible={isVisible} onClose={isEdit ? () => setIsCancel(true) : handleClose}>
      <HeaderModal
        title="personalGoals"
        onClose={handleClose}
        isEdit={isEdit && !unCorrectTasks}
        onCancel={() => setIsCancel(true)}
        onSave={handleSave}
        isBorder
      />
      <div style={styles.container}>
        <div style={styles.mainTitle}>
          <Icon type="active" title={goal ? goal.category : category} />
          <TextFont type="bold" style={styles.mainTitleText}>
            <FormattedMessage id={translateCategory[goal ? goal.category : category]} />
          </TextFont>
        </div>
        <main ref={ref}>
          <RenderWithCondition condition={tasks.length}>
            <div>
              {tasks.map((task: ITask) => (
                <TaskItem
                  key={task.id}
                  task={task}
                  tasks={tasks}
                  setTasks={setTasks}
                  isAddTask={isAddTask}
                  setIsAddTask={setIsAddTask}
                  addTask={handleAddTask}
                  ref={ref}
                />
              ))}
            </div>
          </RenderWithCondition>
          {isCanAddTask ? (
            <button
              style={{ ...styles.button, backgroundColor: unCorrectTasks ? "#ECF0EF" : globalState.colorSchema.white }}
              onClick={handleAddTask}
              disabled={unCorrectTasks}
            >
              <TextFont color={unCorrectTasks ? globalState.colorSchema.grey : globalState.colorSchema.text.accent} size={"16px"}>
                + Добавить цель
              </TextFont>
            </button>
          ) : (
            <div style={{ borderTopWidth: "1px", borderTopColor: globalState.colorSchema.lighGrey, paddingTop: "10px" }}>
              <TextFont color={globalState.colorSchema.placeholder.main}>
                <FormattedMessage id="goalAddException" />
              </TextFont>
            </div>
          )}
        </main>
      </div>

      <RenderWithCondition condition={isCancel}>
        <Notification phraseId={msgCancelExeption} onCancel={handleCancelNotifications} onOk={handleCancel} type="confirm" />
      </RenderWithCondition>
    </ModalUI>
  );
};

export const styles = {
  container: {
    padding: "12px",
  },
  mainTitle: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    width: "80%",
    marginBottom: "30px",
  },
  mainTitleText: {
    marginLeft: "10px",
    fontSize: "18px",
    fontWeight: "700",
    paddingRight: "10px",
  },
  button: {
    backgroundColor: "#FFFFFF",
    padding: "10px",
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: "11px",
    width: "100%",
    "text-align": "left",
  },
};
