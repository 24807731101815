import { useCallback, useMemo, useState } from "react";

type Value = boolean;
type UseBoolean = (initialState?: Value) => [Value, { on: () => void; off: () => void; toggle: () => void }];

export const useBoolean: UseBoolean = (initialState = false) => {
  const [value, setValue] = useState<Value>(initialState);

  const on = useCallback(() => {
    setValue(true);
  }, []);

  const off = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  return useMemo(() => [value, { on, off, toggle }], [off, on, toggle, value]);
};
