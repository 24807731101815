import React, { useState, useMemo } from "react";

import { useDispatch } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { IDataUserMessage } from "@interfaces/user.interface";
import { SendFeedbackNotification } from "@screens/profile/components/sender/sendFeedback/sendFeedbackNotification";
import { useFileAttachments } from "@shared/fileAttachments";
import { sendUserMessage } from "@store/user/api";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import globalState from "@utils/globalState";

import { Sender } from ".";

interface IProps {
  onClose: () => void;
}

export const ErrorSender = ({ onClose }: IProps) => {
  const dispatch = useDispatch();
  const { isTablet } = useDimension();

  const [message, setMessage] = useState<string>("");
  const [isSendFeedbackVisible, setIsSendFeedbackVisible] = useState(false);
  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState(false);
  const freezedInitialIds = useMemo(() => [], []);

  const { allFiles, uploadedFiles, deleteFile, handleFileDownload, openFilePicker, FileInputField } = useFileAttachments({
    initialFiles: freezedInitialIds,
  });

  const sendEvent = async () => {
    setIsLoadingSendMessage(true);
    const data: IDataUserMessage = {
      fileIds: allFiles.map((file) => file.id),
      message,
      messageType: "BUG_REPORT",
    };

    const response = await dispatch(sendUserMessage(data));

    if (response.payload.response.status === 204) {
      setIsSendFeedbackVisible(true);
    }
    setIsLoadingSendMessage(false);
  };
  return (
    <div style={{ pointerEvents: isLoadingSendMessage ? "none" : "auto" }}>
      <HeaderModal
        title="bugreport"
        isEdit={false}
        onClose={onClose}
        style={{ backgroundColor: globalState.colorSchema.disabled }}
        isClose={!isTablet}
      />
      <Sender
        message={message}
        setMessage={setMessage}
        sendEvent={sendEvent}
        isLoading={isLoadingSendMessage}
        placeholderId="descriptionOfProblem"
        allowFileAttach
        allFiles={allFiles}
        uploadedFiles={uploadedFiles}
        deleteFile={deleteFile}
        handleFileDownload={handleFileDownload}
        openFilePicker={openFilePicker}
      />
      <RenderWithCondition condition={isSendFeedbackVisible}>
        <SendFeedbackNotification closeParent={onClose} setIsSendFeedbackVisible={setIsSendFeedbackVisible} />
      </RenderWithCondition>
      <FileInputField />
    </div>
  );
};
