import { http } from "@api/httpClient";
import { IFile } from "@interfaces/files.interfaces";
import { showToast } from "@utils/toast";

export interface DataUpdateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
}

export interface IParamsDelegationList {
  query: string;
  total?: number;
  current?: number;
  next?: number;
}

const avatarTempStorage = new Map();

class FileService {
  constructor() {
    // do nothing
  }

  baseURL = "/api/s3-storage/v1/file";

  getFileInfo = async (id: string): Promise<IFile> => await http.get(`${this.baseURL}/${id}`);

  upload = async (data: FormData): Promise<any> => {
    try {
      return await http.post(`${this.baseURL}/upload?bucket=DEFAULT`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (e) {
      if (e.response.status !== 415) {
        showToast("fileUploadFailure", "error");
      }
    }
  };

  getAvatar = async (id: string): Promise<string> => {
    try {
      if (avatarTempStorage.has(id)) {
        return avatarTempStorage.get(id);
      }

      const response = await http.get(`api/s3-storage/v1/file/download/${id}`, {
        headers: {
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const avatarURL = URL.createObjectURL(blob);

      avatarTempStorage.set(id, avatarURL);

      return avatarURL;
    } catch (error: unknown) {
      console.error("Failed to fetch an avatar");
    }
  };

  download = async (id: string, fileName: string): Promise<any> => {
    try {
      const response = await http.get(`${this.baseURL}/download/${id}?directDownload=false`, {
        headers: {
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });

      const blob = await new Blob([response.data]);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (e) {
      showToast("fileDownloadFailure", "error");
    }
  };

  delete = async (id: string): Promise<any> => {
    try {
      return await http.delete(`${this.baseURL}/${id}`);
    } catch (e) {
      showToast("fileDeletionFailure", "error");
    }
  };
}

export const fileService = new FileService();
