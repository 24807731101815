import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch, MiddlewareAPI } from "redux";

import { showToast } from "@utils";

import { buildConfigNotify } from "./config";
import { notUsedStatus } from "./constants";
import { IActionNotify } from "./types";

export const NotifyMiddleware =
  ({ dispatch, getState }: MiddlewareAPI<IActionNotify>) =>
  (next: Dispatch<AnyAction>) =>
  (action: IActionNotify) => {
    const actionName = action.type.split("/")[1];

    const configNotify = buildConfigNotify(action);
    const status = action?.payload?.response?.status ?? 200;

    if (status && !notUsedStatus.includes(status) && configNotify) {
      showToast(configNotify.message, configNotify.type);
    }

    if (navigator.onLine || actionName === "checkAuth") return next(action);
  };
