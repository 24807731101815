class AppSecureStore {
  saveValue(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getValue(key: string): any {
    const result = localStorage.getItem(key);
    if (result && result !== "undefined") {
      return result;
    }

    throw new Error("fsfsdf");
  }

  deleteValue(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export const secureStore = new AppSecureStore();
