import globalState from "@utils/globalState";

export const styles = {
  main: {
    width: "100%",
    paddingTop: "30px",
    paddingLeft: "12px",
    marginRight: "35px",
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: "700",
    color: globalState.colorSchema.text.main,
    marginBottom: "12px",
  },
  mainMonths: {
    display: "flex",
    "flex-direction": "row",
    flexWrap: "wrap",
  },
  mainMonthsBlock: {
    display: "flex",
    minWidth: "30%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E3E9E7",
    borderRadius: "5px",
    marginBottom: "10px",
    marginRight: "10px",
    paddingVertical: "11px",
  },
  mainMonthsBlockActive: {
    border: `1px solid ${globalState.colorSchema.text.accent}`,
  },
  mainMonthsBlockDisabled: {
    backgroundColor: "#ECF0EF",
  },
  mainMonthsText: {
    fontSize: "18px",
    fontWeight: "400",
    color: globalState.colorSchema.text.main,
  },
  mainMonthsTextDisabled: {
    color: globalState.colorSchema.grey,
  },
  back: {
    flex: 1.5,
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
  },
  backTitle: {
    fontSize: "16px",
    color: globalState.colorSchema.text.accent,
    marginLeft: 5,
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    borderBottomWidth: "0.5px",
    borderBottomColor: "#000000",
    padding: "9px",
    paddingBottom: "12px",
  },
  headerTitle: {
    flex: 2,
    fontWeight: "700",
    fontSize: "16px",
    color: globalState.colorSchema.text.main,
    textTransform: "capitalize",
  },
  year: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    paddingRight: "12px",
    marginBottom: "12px",
  },
  rightButtonContainer: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",
    gap: 8,
  },
  wrapperNotAnswerMeets: {
    display: "flex",
    height: 30,
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 51,
    gap: 4,
    alignItems: "center",
  },
};
