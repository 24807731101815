import React, { useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { TextFont, Billet } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import globalState from "@utils/globalState";

import { reasonTabs } from "../../Modal/components/viewSave/assets/constants";

interface IProps {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  onSave?: (reason: string, comment: string) => void;
  tabsReason?: Record<string, any>;
  defaultReason?: string;
  isButton?: boolean;
  title?: string;
  bgTab?: string;
  isTight?: boolean;
}

export const ModalClose = ({
  isVisible,
  setIsVisible,
  isButton = true,
  onSave = () => "",
  defaultReason = reasonTabs.notRelevant,
  tabsReason = reasonTabs,
  title = "Закрыть задачу",
  bgTab = globalState.colorSchema.white,
  isTight = false,
}: IProps) => {
  const [isShown, setIsShown] = useState(false);

  const [reason, setReason] = useState<any>(defaultReason);
  const [comment, setComment] = useState("");

  const handleSelect = (value: any) => {
    setReason(value);
    setIsShown(false);
  };

  const handleSave = () => {
    if (onSave) {
      onSave(reason, comment);
    }
    setIsVisible(false);
  };

  const validReason = useMemo(() => {
    for (const value in tabsReason) {
      if (tabsReason[value] === reason) return value;
    }
  }, [reason, tabsReason]);

  return (
    <ModalUI isVisible={isVisible} onClose={() => setIsVisible(false)}>
      <div style={{ flex: 1 }} onClick={(e) => e.stopPropagation()}>
        <HeaderModal
          title={title}
          onClose={() => setIsVisible(false)}
          isEdit={true}
          isCancel={false}
          onSave={handleSave}
          style={!isTight && { marginBottom: 20 }}
          isBorder={false}
        />
        <div style={{ padding: 12, display: "flex", flexDirection: "column" }}>
          <RenderWithCondition condition={!isTight}>
            <TextFont type="bold" style={styles.title}>
              <FormattedMessage id={"reason"} />
            </TextFont>
          </RenderWithCondition>
          <Billet
            textId={validReason}
            onPress={() => setIsShown(true)}
            styleText={styles.textButton}
            styleButton={{
              ...styles.selectButton,
              backgroundColor: bgTab,
              marginBottom: isTight ? "9px" : "20px",
              minHeight: isTight && "48px",
            }}
          />
          <textarea
            style={{ ...styles.input, backgroundColor: bgTab, marginBottom: isTight && "9px", minHeight: isTight && "48px" }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={"Комментарий"}
            autoFocus={isTight}
            maxLength={100}
          />
          <RenderWithCondition condition={isButton}>
            <button onClick={handleSave} style={styles.button}>
              <TextFont style={styles.buttonText}>
                <FormattedMessage id={"save"} />
              </TextFont>
            </button>
          </RenderWithCondition>
        </div>
      </div>
      {isShown ? (
        <ModalUI isVisible={isShown} onClose={() => setIsShown(false)} className="bottomModal">
          <div onClick={(e) => e.stopPropagation()}>
            <button onClick={() => setIsShown(false)} style={{ ...styles.roundContainer, position: "absolute" }}>
              <SvgClose />
            </button>
            <div style={{ marginTop: 50 }}>
              {Object.keys(tabsReason).map((element) => (
                <div key={element} style={{ backgroundColor: reason === tabsReason[element] ? "#ECF0EF" : "none" }}>
                  <button onClick={() => handleSelect(tabsReason[element])} style={styles.optionButton}>
                    <TextFont>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </button>
                  <div style={styles.line} />
                </div>
              ))}
            </div>
          </div>
        </ModalUI>
      ) : (
        ""
      )}
    </ModalUI>
  );
};

const styles = {
  title: {
    fontWeight: "700",
    fontSize: 18,
    "text-transform": "capitalize",
    marginBottom: 0,
  },
  input: {
    fontSize: 18,
    borderRadius: 10,
    color: globalState.colorSchema.text.main,
    border: "1px solid #fff",
    backgroundColor: "#ECF0EF",
    marginBottom: 20,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    width: "90%",
    minHeight: 130,
  },
  button: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#297952",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "700",
    "text-align": "center",
  },
  selectButton: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 11,
  },
  textButton: {
    fontSize: 16,
    fontWeight: "400",
  },
  modal: {
    minHeight: "auto",
    margin: 0,
    marginTop: "40%",
    backgroundColor: "#fff",
    borderRadius: 10,
    justifyContent: "flex-start",
    paddingTop: 20,
    paddingBottom: 20,
  },
  roundContainer: {
    display: "flex",
    "flex-direction": "column",
    top: 15,
    right: 12,
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ECF0EF",
  },
  optionButton: {
    display: "flex",
    "flex-direction": "column",
    height: 50,
    justifyContent: "center",
    paddingLeft: 12,
    paddingRight: 12,
    width: "100%",
  },
  line: {
    height: 1,
    backgroundColor: "#ddd",
    marginHorizontal: 12,
  },
};
