import { IMeet, IMeetRepeat } from "@interfaces/meet.interface";

export const tempMeetRepeat: Partial<IMeetRepeat> = {
  dayOfWeeks: [],
  endTime: null,
  days: null,
  postpone: "WITHOUT_CHANGES",
  amount: 1,
  repetitionPeriod: 1,
};
export const tempMeet: Partial<IMeet> = {
  name: "",
  description: "",
  startTime: null,
  endTime: null,
  repeat: null,
  participants: null,
  createdByCurrentUser: true,
  places: [],
};

export type TypeRepeat = "WEEK" | "MONTH" | "YEAR" | "NOT_REPEAT";

export const titleType: Record<TypeRepeat, string> = {
  WEEK: "meetingRepeatWeek",
  MONTH: "meetingRepeatMonth",
  YEAR: "meetingRepeatYear",
  NOT_REPEAT: "doNotRepeat",
};
