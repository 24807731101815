import { AxiosResponse } from "axios";

import { http } from "@api/httpClient";
import { IAddWorkDay, IClose, ICommentWorkDay, IMoveDate, INeedMoreTime, IUpdatePosition } from "@services/businessTasks.service";

export class PersonalTasksService {
  constructor() {
    // do nothing
  }

  get = async (id: string): Promise<any> => await http.get(`/api/api-gateway/v1/personal-tasks/${id}`);

  create = async (data: Record<string, string>): Promise<any> => await http.post("/api/api-gateway/v1/personal-tasks", data);

  complete = async (id: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/complete`);

  reopen = async (id: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/reopen`);

  clone = async (id: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/clone`);

  update = async (id: string, data: Record<string, string>): Promise<any> =>
    await http.patch(`/api/api-gateway/v1/personal-tasks/${id}`, data);

  close = async ({ id, comment, reason }: IClose): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/close`, {
      comment,
      reason,
    });

  pause = async (id: string, comment: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/pause`, { comment });

  delete = async (id: string): Promise<any> => await http.delete(`/api/crud/v1/personal-tasks/${id}`);

  deleteWorkDay = async (id: string, date: string): Promise<any> => await http.delete(`/api/crud/v1/personal-tasks/${id}/work-day/${date}`);

  takeToWork = async (id: string, date: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/add/${date}`);

  addWorkDay = async (id: string, body: IAddWorkDay): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/add`, body);

  workDayComplete = async (id: string, date: string): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/complete/${date}`);

  workDayCancelComplete = async (id: string, date: string): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/not-complete/${date}`);

  workDayNotComplete = async (id: string, date: string, body?: any): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/not-complete`, { ...body, date });

  workDayNeedMoreTime = async (id: string, body?: INeedMoreTime): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/need-more-time`, body);

  commentWorkDay = async (id: string, body: ICommentWorkDay): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/comment`, body);

  moveDate = async ({ id, date, newDate }: IMoveDate): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/${date}/move-date/${newDate}`);

  markWorkDay = async (id: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/mark`);

  forgetWorkDay = async (id: string): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/work-day/forget`);

  updatePosition = async ({ data, date }: IUpdatePosition): Promise<any> =>
    await http.post(`/api/crud/v1/personal-tasks/list/${date}`, data);

  addComment = async (taskId: string, comment: string): Promise<AxiosResponse<{ comment: string }, unknown>> =>
    await http.post(`/api/crud/v1/personal-tasks/${taskId}/comments`, { comment });

  editComment = async (taskId: string, commentId: string, comment: string): Promise<AxiosResponse<{ comment: string }, unknown>> =>
    await http.patch(`/api/crud/v1/personal-tasks/${taskId}/comments/${commentId}`, { comment });

  deleteComment = async (taskId: string, commentId: string): Promise<AxiosResponse<unknown, unknown>> =>
    await http.delete(`/api/crud/v1/personal-tasks/${taskId}/comments/${commentId}`);
}

export const personalTasks = new PersonalTasksService();
