import React, { FC, useEffect, useMemo, useRef } from "react";

import { Dayjs } from "dayjs";

import { IMeetParticipants } from "@interfaces/meet.interface";
import { useGetMeetingAttendanceQuery } from "@services/meetApi";
import { ArrowSvg, TextFont } from "@ui";
import { calendar, toFormatISO } from "@utils";
import globalState from "@utils/globalState";

import { HOURTOSCROLL, arrHours } from "../../../../../constants";

import { BlockTime } from "./components/BlockTime";
import { Row } from "./components/Row";
import { RowParticipant } from "./components/RowParticipant";
import { WIDTH_BLOCK } from "./constants";

interface IProps {
  time: { startTime: string; endTime: string };
  currentDate: Dayjs;
  setCurrentDate: (arg: Dayjs) => void;
  participants: IMeetParticipants[];
  participantLight: string;
  handleHourSlotPress: (hour: number) => void;
  meetId?: string;
}

export const Activity: FC<IProps> = ({
  currentDate,
  setCurrentDate,
  participants,
  participantLight,
  time,
  handleHourSlotPress,
  meetId,
}) => {
  const refView = useRef(null);

  const { data: attendance } = useGetMeetingAttendanceQuery({
    startTime: toFormatISO(currentDate.hour(0)),
    endTime: toFormatISO(currentDate.hour(23)),
    userIds: participants?.map((i) => i.userId),
    excludedMeetingId: meetId ?? "",
  });

  useEffect(() => {
    refView?.current?.scrollTo(HOURTOSCROLL * WIDTH_BLOCK, 0);
  }, [refView]);

  const isLightColumnNumber = useMemo(
    () => participants.findIndex((i) => i.userId === participantLight),
    [participantLight, participants.length],
  );

  return (
    <section style={{ marginTop: 20 }}>
      <div style={{ display: "flex" }}>
        <div style={styles.calendar}>
          <div style={styles.header}>
            <button onClick={() => setCurrentDate(currentDate.subtract(1, "day"))}>
              <ArrowSvg fill="#274335" type="left" />
            </button>
            <TextFont type="bold" size={16} weight="700">{`${calendar.getWeekDayFull(currentDate.day())}, ${currentDate.format(
              "DD MMMM",
            )}`}</TextFont>
            <button onClick={() => setCurrentDate(currentDate.add(1, "day"))}>
              <ArrowSvg fill="#274335" type="right" />
            </button>
          </div>

          <div style={{ overflowX: "auto", overflowY: "hidden" }} ref={refView}>
            <div style={{ position: "relative", width: "100%" }}>
              <div style={{ position: "absolute", top: 67, width: "100%", height: "100%", zIndex: 0 }}>
                {attendance?.map((i) => (
                  <RowParticipant key={i.userId} events={i.meetings} />
                ))}
              </div>
              <div style={{ display: "flex", zIndex: 100 }}>
                {arrHours.map((hour, index) => (
                  <div style={{ cursor: hour === 24 ? "" : "pointer" }} onClick={() => handleHourSlotPress(hour)}>
                    <Row rows={participants.length} isLightColumnNumber={isLightColumnNumber} key={index} hour={hour} date={currentDate} />
                  </div>
                ))}
              </div>
              <BlockTime time={time} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  calendar: {
    width: "95%",
    backgroundColor: globalState.colorSchema.white,
    marginTop: 10,
    marginLeft: 5,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 0",
    borderBottom: `1px solid ${globalState.colorSchema.lighGrey}`,
  },
};
