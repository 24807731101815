import React, { FC, memo, useMemo } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgCancel } from "@assets/cancel.svg";
import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  meet: IMeet;
  statusParticipant?: IMeetParticipants;
  pressDotes: () => void;
  isRenderDotes: boolean;
}

export const ViewSaveHeader: FC<IProps> = memo(function ViewSaveHeader({ meet, statusParticipant, pressDotes, isRenderDotes }) {
  const meetingStatus = useMemo(() => {
    if (!meet.active) return "canceled";
    if (statusParticipant?.meetingStatus === "DID_NOT_TAKE_PLACE") return "didNotTake";
    if (statusParticipant?.meetingStatus === "WILL_NOT_TAKE_PLACE") return "willNotTake";

    return null;
  }, [meet.active, statusParticipant?.meetingStatus]);

  const config: Record<"canceled" | "didNotTake" | "willNotTake", { title: string; icon: JSX.Element; colorText: string }> = {
    canceled: { title: "statusCancelForAll", icon: <SvgCancel width={17} height={17} style={styles.icon} />, colorText: "#FF3B30" },
    didNotTake: {
      title: "statusTookPlaceNo",
      icon: <SvgNotTake width={17} height={17} style={styles.icon} fill={"#FFB800"} />,
      colorText: "#FFB800",
    },
    willNotTake: {
      title: "statusTakePlaceNo",
      icon: <SvgNotTake width={17} height={17} style={styles.icon} fill={"#FFB800"} />,
      colorText: "#FFB800",
    },
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <RenderWithCondition condition={meetingStatus}>
        <div style={styles.iconWrapper}>
          {meetingStatus && config[meetingStatus].icon}
          <TextFont style={{ ...styles.iconText, color: config[meetingStatus]?.colorText }}>
            <FormattedMessage id={config[meetingStatus]?.title} />
          </TextFont>
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={!meetingStatus && isRenderDotes}>
        <button style={{ alignSelf: "flex-end" }} onClick={pressDotes}>
          <SvgDotes fill={"white"} />
        </button>
      </RenderWithCondition>
    </div>
  );
});

const styles = {
  iconWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 9px",
    borderRadius: 55,
    alignItems: "center",
    alignSelf: "flex-start",
    backgroundColor: globalState.colorSchema.white,
    marginBottom: 6,
    marginRight: 10,
  },
  icon: {
    marginRight: 5,
  },
  iconText: {
    fontSize: 14,
    color: globalState.colorSchema.green,
  },
};
