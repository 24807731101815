import React, { useMemo } from "react";

import dayjs from "dayjs";

import { WIDTH_BLOCK } from "../constants";

export const BlockTime = ({ time }: { time: { startTime: string; endTime: string } }) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width = widthMinute * dayjs(time.endTime).diff(time.startTime, "minutes");
    const left = dayjs(time.startTime).hour() * WIDTH_BLOCK + widthMinute * dayjs(time.startTime).minute();
    const color = "#C7C7C742";

    return { width, left, color };
  }, [time.endTime, time.startTime]);

  return (
    <div
      style={{
        ...styles.event,
        position: "absolute",
        width: params.width,
        left: params.left,
        backgroundColor: params.color,
      }}
    />
  );
};

const styles = {
  event: {
    display: "flex",
    height: "100%",
    top: 0,
  },
};
