import { FC, useMemo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/close.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { selectTheme } from "@store/theme";
import { userSelector } from "@store/user";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  isPersonalTask: boolean;
  isSaveBtnVisible: boolean;
  isFromOneS: boolean;
  onClose: (...arg: unknown[]) => void;
  onSave?: (...arg: unknown[]) => void;
  task: ITask;
}

export const HeaderTablet: FC<IProps> = ({ isFromOneS, isPersonalTask, isSaveBtnVisible, onClose, onSave, task }) => {
  const theme = selectTheme("extra");
  const { directorId } = userSelector();

  const isEditAllowed = useMemo(() => {
    if (directorId) {
      return task.createdByAssistant;
    }

    return task.status === "OPEN" && !isFromOneS;
  }, [directorId, task, isFromOneS]);

  return (
    <div style={{ backgroundColor: isPersonalTask ? globalState.colorSchema.darkPurple : theme.background.darkGreen }}>
      <Container>
        <RenderWithCondition condition={isSaveBtnVisible}>
          <button onClick={onSave}>
            <TextFont style={{ color: "white", marginRight: 20, marginTop: -5, fontSize: 18, fontWeight: "400" }}>
              <FormattedMessage id="save" />
            </TextFont>
          </button>
        </RenderWithCondition>
        <RenderWithCondition condition={isEditAllowed}>
          <button onClick={onSave}>
            <SvgEdit fill={"#fff"} style={{ marginRight: 20 }} />
          </button>
        </RenderWithCondition>
        <button onClick={onClose}>
          <SvgClose fill={"#fff"} />
        </button>
      </Container>
    </div>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  padding-bottom: 5px;
  border-top-left-radius: 20px;
`;
