import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";

import { ModalUI, HeaderModal, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  close: () => void;
  onSave: (comment: string) => void;
  title?: string;
}

export const ModalComment: FC<IProps> = ({ close, onSave, title = "" }) => {
  const [comment, setComment] = useState("");

  return (
    <ModalUI isVisible={true} onClose={close}>
      <div style={{ flex: 1 }}>
        <HeaderModal title={title} onClose={close} isEdit={true} isCancel={false} onSave={() => onSave(comment)} />
        <div style={{ padding: "20px 12px 0px 12px" }}>
          <textarea
            style={styles.input}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={"Комментарий"}
            maxLength={100}
          />
          <button onClick={() => onSave(comment)} style={styles.button}>
            <TextFont style={styles.buttonText}>
              <FormattedMessage id={"save"} />
            </TextFont>
          </button>
        </div>
      </div>
    </ModalUI>
  );
};

const styles = {
  input: {
    fontSize: 18,
    borderRadius: 10,
    color: globalState.colorSchema.text.main,
    border: "1px solid #fff",
    backgroundColor: "white",
    marginBottom: 20,
    padding: "16px 20px",
    width: "90%",
    minHeight: 130,
  },
  button: {
    width: "100%",
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#297952",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "700",
    "text-align": "center",
  },
};
