import point3 from "./assets/mobile/point3.png";
import point4 from "./assets/mobile/point4.png";
import point5 from "./assets/mobile/point5.png";
import point6 from "./assets/mobile/point6.png";
import point6_android from "./assets/mobile/point6_android.png";
import point4_pc from "./assets/pc/point4.png";
import point5_pc from "./assets/pc/point5.png";

interface ITab {
  id: "pc" | "mobile";
  title: string;
}

interface IInstructionPoint {
  title: string;
  img?: string;
  imgAndroid?: string;
}

export const tabs: Array<ITab> = [
  { id: "pc", title: "forPc" },
  { id: "mobile", title: "forMobile" },
];

export const instruction: Record<"pc" | "mobile", IInstructionPoint[]> = {
  pc: [
    { title: "Установите Zoom на компьютер или телефон;" },
    {
      title: "Зарегистрируйтесь в Zoom, используя свой e-mail. Если Вы уже зарегистрированы в Zoom, войдите в свою учетную запись;",
    },
    { title: "В Главном окне нажмите на иконку выпадающего списка возле кнопки «Новая конференция»;" },
    {
      title: "Включите галочку напротив пункта «Использовать мой идентификатор персональной конференции (РМ)»;",
      img: point4_pc,
    },
    {
      title:
        "Далее нажмите стрелку вправо в строке с вашим личным идентификатором для вызова дополнительного меню, и выберите пункт «Копировать приглашение»",
      img: point5_pc,
    },
    {
      title: "Вставьте скопированную ссылку в поле «Ссылка на Zoom для проведения встреч» в вашем профиле в приложении DogmaTime.",
    },
    {
      title: "Готово! Теперь указанная ссылка будет использоваться для всех встреч, назначенных вами в приложении DogmaTime.",
    },
  ],
  mobile: [
    { title: "Установите Zoom на компьютер или телефон;" },
    {
      title: "Зарегистрируйтесь в Zoom, используя свой e-mail. Если Вы уже зарегистрированы в Zoom, войдите в свою учетную запись;      ",
    },
    { title: "В Главном окне нажмите на иконку «Новая конференция»;", img: point3 },
    {
      title: "Включите галочку напротив пункта «Использовать мой идентификатор персональной конференции (РМ)»;",
      img: point4,
    },
    { title: "Далее нажмите на иконку, и выберите пункт «Отправить приглашение»", img: point5 },
    { title: "Далее выберите пункт «Копировать в буфер обмен»", img: point6, imgAndroid: point6_android },
    {
      title: "Вставьте скопированную ссылку в поле «Ссылка на Zoom для проведения встреч » в вашем профиле в приложении DogmaTime.",
    },
    {
      title: "Готово! Теперь указанная ссылка будет использоваться для всех встреч, назначенных вами в приложении DogmaTime.",
    },
  ],
};
