import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { BusinessTasksService, businessTasks } from "@services/businessTasks.service";
import { PersonalTasksService, personalTasks } from "@services/personalTasks.service";

interface IChangeTask {
  id: string;
  task?: Partial<ITask>;
  workDay?: {
    date: string;
    obj: Partial<WorkDay>;
  };
  tasks: ITask[];
}

export const changeTask = ({ task, workDay, id, tasks }: IChangeTask) => {
  const tempTasks = [...tasks];
  const taskIndex = tempTasks.findIndex((item) => item.id === id);
  let tempWorkDays, workDayIndex;

  if (taskIndex === -1) {
    return tasks;
  }

  if (workDay) {
    tempWorkDays = [...(tempTasks[taskIndex].workDays ?? [])];
    workDayIndex = tempWorkDays.findIndex((item) => item.date === workDay?.date);
    tempWorkDays.splice(workDayIndex, 1, { ...tempWorkDays[workDayIndex], ...workDay.obj });
  }

  const data = {
    ...tempTasks[taskIndex],
    ...(task ?? {}),
    workDays: tempWorkDays ?? task?.workDays ?? tempTasks[taskIndex].workDays,
  };
  tempTasks.splice(taskIndex, 1, data);

  return tempTasks;
};

export const getServiceTasks = (isPersonal: boolean): PersonalTasksService | BusinessTasksService =>
  isPersonal ? personalTasks : businessTasks;

export const sortTasksForOneS = (a: ITask, b: ITask) => {
  if (a.isFromOneS && !b.isFromOneS) return 1;
  if (!a.isFromOneS && b.isFromOneS) return -1;
  return 0;
};