import React, { FC, useState, useMemo, MouseEvent } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { RenderWithCondition } from "@hoc";
import { IExtendedComment } from "@interfaces/businessGoals.interface";
import { userSelector } from "@store/user/selectors";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  data: IExtendedComment;
  onThreeDotsPress: (commentId: string, userId: string, comment: string, top: number) => void;
  setIsContextMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Comment: FC<IProps> = ({ data, onThreeDotsPress }) => {
  const isModified = useMemo(() => data.createdDate !== data.modifiedDate, [data]);
  const createdDate = dayjs(data.createdDate).format("DD MMM YY");
  const createdTime = dayjs(data.createdDate).format("HH:mm");
  const modifiedDate = dayjs(data.modifiedDate).format("DD MMM YY");
  const modifiedTime = dayjs(data.modifiedDate).format("HH:mm");
  const [isShowFullCommentText, setIsShowFullCommentText] = useState(false);
  const { currentUser } = userSelector();

  const onThreeDotsPressWrapper = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLButtonElement;
    const { top } = target.getBoundingClientRect();

    onThreeDotsPress(data.id, data.user?.id, data.comment, top);
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <TextFont size={16} style={{ fontWeight: "bold" }}>
            {data.user?.firstName} {data.user?.lastName}
          </TextFont>
          <RenderWithCondition condition={currentUser?.id === data?.user?.id}>
            <button onClick={onThreeDotsPressWrapper}>
              <SvgDotes />
            </button>
          </RenderWithCondition>
        </div>
        <div onClick={() => setIsShowFullCommentText(!isShowFullCommentText)} style={{ ...styles.commentContainer }}>
          <TextFont size={16} style={{ ...styles.commentText, ...(!isShowFullCommentText && { WebkitLineClamp: "2" }) }}>
            {data.comment}
          </TextFont>
        </div>

        <div style={styles.dateTimeContainer}>
          <TextFont size={12} style={styles.dateTime}>
            <RenderWithCondition condition={isModified}>
              <>
                <FormattedMessage id="commentCreated" defaultMessage="Создан" />{" "}
              </>
            </RenderWithCondition>
            {createdDate}, {createdTime}
          </TextFont>
          <RenderWithCondition condition={isModified}>
            <TextFont size={12} style={styles.dateTime}>
              <FormattedMessage id="commentModified" defaultMessage="Изменён" /> {modifiedDate}, {modifiedTime}
            </TextFont>
          </RenderWithCondition>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    marginBottom: 15,
  },
  header: {
    height: 25,
    display: "flex",
    alignItems: "center",
    "flex-direction": "row",
    justifyContent: "space-between",
  },
  dateTimeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dateTime: {
    marginTop: 8,
  },
  contextMenu: {
    width: 200,
    height: 200,
    position: "absolute",
    right: 0,
    top: 20,
    paddingVertical: 4,
    borderRadius: 8,
    shadowColor: "rgb(0, 0, 0)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.09,
    shadowRadius: 6,
    backgroundColor: globalState.colorSchema.white,
  },
  commentText: {
    "line-height": 24,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    webkitLineClamp: "2",
    LineClamp: "2",
    "-line-clamp": "2",
    WebkitBoxOrient: "vertical",
    "-webkit-box-orient": "vertical",
  },
  commentContainer: {
    overflow: "hidden",
    cursor: "pointer",
  },
};
