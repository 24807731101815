import React, { FC, useCallback } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { TextFont, ModalUI } from "@ui";

import { Tab, tabs } from "./constants";

interface IProps {
  close: () => void;
  deleteSerie: () => void;
  deleteFromSerie: () => void;
  isEdit?: boolean;
  data: string[];
  classNameButton?: Record<string, string>;
  textTitle?: string;
  colorTextButton?: string;
}

export const ModalDelete: FC<IProps> = ({
  close,
  deleteFromSerie,
  deleteSerie,
  isEdit,
  data,
  classNameButton,
  textTitle,
  colorTextButton,
}) => {
  const handlePress = useCallback((key: number) => {
    if (key === 0) {
      deleteFromSerie();
    } else {
      deleteSerie();
    }
    close();
  }, []);

  return (
    <ModalUI isVisible={true} onClose={close}>
      <div style={{ ...styles.container, position: "absolute" }}>
        <button onClick={close} style={{ ...styles.roundContainer, position: "absolute" }}>
          <SvgClose />
        </button>
        <RenderWithCondition condition={textTitle}>
          <>
            <div style={{ ...styles.textUp }}>
              <TextFont style={{ fontSize: 18, alignItems: "center", textAlign: "center", maxWidth: 300 }}>
                <FormattedMessage id={textTitle} />
              </TextFont>
            </div>
            <div style={styles.line} />
          </>
        </RenderWithCondition>
        <div>
          {data.map((element, index) => (
            <div key={element}>
              <button onClick={() => handlePress(index)} style={{ ...styles.optionButton, ...classNameButton }}>
                <TextFont style={{ fontSize: 18 }} color={colorTextButton ?? ""}>
                  <FormattedMessage id={element} />
                </TextFont>
              </button>
              <div style={styles.line} />
            </div>
          ))}
        </div>
        <RenderWithCondition condition={isEdit}>
          <TextFont size={18} color={"#B3BEB8"} style={{ marginTop: 15, paddingLeft: 8, textAlign: "center" }}>
            <FormattedMessage id="allSeriesWarning" />
          </TextFont>
        </RenderWithCondition>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    paddingTop: 50,
    width: "100%",
  },
  textUp: {
    display: "flex",
    "flex-direction": "column",
    marginBottom: 30,
    alignItems: "center",
  },
  roundContainer: {
    display: "flex",
    "flex-direction": "column",
    top: 20,
    right: 12,
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ECF0EF",
  },
  line: {
    height: 1,
    backgroundColor: "#ddd",
    margin: "0 12px",
  },
  optionButton: {
    display: "flex",
    "flex-direction": "column",
    height: 50,
    justifyContent: "center",
    padding: "0 12px",
    width: "100%",
  },
};
