import { createAsyncThunk } from "@reduxjs/toolkit";

import { noteService } from "@services";
import { getError } from "@store/getError";

interface IGetList {
  yearMonth: string;
  userId: string;
}

interface ICreate {
  yearMonth: string;
  content: string;
}

interface IUpdate {
  id: string;
  data: Record<string, string>;
}

export const getList: any = createAsyncThunk("note/getList", async (data: IGetList, thunkAPI) => {
  try {
    const response = await noteService.getList(data.yearMonth, data.userId);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const createNote: any = createAsyncThunk("note/createNote", async (data: ICreate, thunkAPI) => {
  try {
    const response = await noteService.create(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateNote: any = createAsyncThunk("note/updateNote", async (data: IUpdate, thunkAPI) => {
  try {
    const response = await noteService.update(data.id, data.data);
    return { response, id: data.id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteNote: any = createAsyncThunk("note/deleteNote", async (id: string, thunkAPI) => {
  try {
    await noteService.delete(id);
    return { id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
