import { AxiosResponse } from "axios";

import { http } from "@api/httpClient";
import { IMeet, TTypeMeetingStatus, TTypeParticipants } from "@interfaces/meet.interface";
import { TPeriodToLoadCalendar } from "@store/calendar/slice";

export interface IMoveDateDTO {
  newDate: string;
  startTime: string;
  endTime: string;
}

export interface IMeetingTaskAttach {
  meetingId: string;
  taskId: string;
  date: string;
}

export interface IAttachEventMeta {
  date: string;
  id: string;
}

export interface IAttendanceDTO {
  startTime: string;
  endTime: string;
  userIds: string[];
  excludedMeetingId?: string;
}

export interface ICommonSlotDTO extends IAttendanceDTO {
  duration: number;
}

export interface IExternalUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
  isExternalUser?: boolean;
  deleted: boolean;
}

export interface IGetMettingsPeriod {
  startDate: string;
  endDate: string;
  name: string;
}

export type TCreateExternalUser = Omit<IExternalUser, "id" | "userId">;

export interface ISetCommentParams {
  id: string;
  status: TTypeParticipants;
  comment: string;
  date?: string | null;
  selectedDay: string;
  participantId: string;
  period?: TPeriodToLoadCalendar;
}

class MeetService {
  getMeeting = async (id: string): Promise<IMeet> => await http.get(`/api/api-gateway/v1/meetings/${id}`);

  getMeetingFromSerie = async (id: string, date: string): Promise<IMeet> =>
    await http.get(`/api/api-gateway/v1/meetings/v1/meetings/${id}/date/${date}`);

  getSeries = async (params: string): Promise<any> => await http.get(`/api/calendar/v1/meetings/series?${params}`);

  create = async (data: IMeet): Promise<IMeet> => await http.post("/api/api-gateway/v1/meetings", data);

  updateCalendarEventFromSerie = async (id: string, date: string, data: Record<string, unknown>): Promise<IMeet> =>
    await http.patch(`/api/calendar/v1/meetings/${id}/date/${date}`, data);

  updateFromSerie = async (id: string, date: string, data: Record<string, any>): Promise<any> =>
    await http.patch(`/api/api-gateway/v1/meetings/${id}/date/${date}`, data);

  update = async (id: string, data: Record<string, any>): Promise<any> =>
    await http.patch(`/api/api-gateway/v1/meetings/series/${id}`, data);

  deleteFromSerie = async (id: string, date: string): Promise<any> => await http.delete(`/api/calendar/v1/meetings/${id}/date/${date}`);

  deleteSerie = async (id: string): Promise<any> => await http.delete(`/api/calendar/v1/meetings/series/${id}`);

  delete = async (id: string): Promise<any> => await http.delete(`/api/calendar/v1/meetings/${id}`);

  moveDate = async (id: string, data: IMoveDateDTO): Promise<any> => await http.post(`/api/calendar/v1/meetings/${id}/move-date`, data);

  attachEvents = async (data: IMeetingTaskAttach[]): Promise<any> => await http.post("/api/calendar/v1/meetings/attach-tasks", data);

  addParticipantsToMeetingSerie = async (id: string, data: Record<string, unknown>, userId?: string): Promise<any> => {
    const directorIdParam = userId ? `?userId=${userId}` : "";

    return await http.patch(`/api/calendar/v1/meetings/series/${id}/participants${directorIdParam}`, data);
  };

  addParticipantsToSingleMeetOrSerieException = async (
    id: string,
    date: string,
    data: Record<string, unknown>,
    userId?: string,
  ): Promise<any> => {
    const directorIdParam = userId ? `?userId=${userId}` : "";

    return await http.patch(`/api/calendar/v1/meetings/${id}/date/${date}/participants${directorIdParam}`, data);
  };

  updateParticipantsPermissions = async (id: string, data: Record<string, unknown>, userId?: string): Promise<any> => {
    const directorIdParam = userId ? `?userId=${userId}` : "";

    return await http.put(`/api/calendar/v1/meetings/${id}/participants/permissions${directorIdParam}`, data);
  };

  removeTasks = async (data: any): Promise<any> => await http.post("/api/calendar/v1/meetings/remove-tasks", data);

  attendance = async (data: IAttendanceDTO): Promise<any> =>
    await http.get(
      `/api/calendar/v1/meetings/attendance?${Object.entries(data)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );

  commonSlot = async (data: ICommonSlotDTO): Promise<any> =>
    await http.get(
      `/api/calendar/v1/meetings/common-slot?${Object.entries(data)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );

  setParticipantsStatus = async (id: string, status: TTypeParticipants, comment: string, date?: string): Promise<any> => {
    const dateParam = date ? `/date/${date}` : "";
    await http.post(`/api/calendar/v1/meetings/${id}${dateParam}/participants/status/${status}`, { comment });
  };

  viewParticipantStatusComment = async (id: string, date: string, userId: string): Promise<any> =>
    await http.post(`/api/calendar/v1/meetings/${id}/date/${date}/participants/${userId}/status/comments/view`);

  setDisableNotifications = async (id: string, data: any): Promise<any> => {
    await http.put(`/api/calendar/v1/meetings/${id}/notification/disable-settings`, data);
  };

  getExternalParticipants = async (searchQuery: string): Promise<any> =>
    await http.get(`/api/calendar/v1/external-users?query=${searchQuery}`);

  createExternalParticipant = async (data: TCreateExternalUser): Promise<any> => await http.post("/api/calendar/v1/external-users", data);

  updateMeetingStatus = async (id: string, date: string, type: TTypeMeetingStatus) =>
    await http.patch(`/api/calendar/v1/meetings/${id}/date/${date}/participants/meeting-status/${type}`);

  removeMeetingStatus = async (id: string, date: string) =>
    await http.delete(`/api/calendar/v1/meetings/${id}/date/${date}/participants/meeting-status`);

  addComment = async (eventId: string, comment: string): Promise<AxiosResponse<{ comment: string }, unknown>> =>
    await http.post(`/api/calendar/v1/meetings/${eventId}/comments`, { comment });

  editComment = async (eventId: string, commentId: string, comment: string): Promise<AxiosResponse<{ comment: string }, unknown>> =>
    await http.patch(`/api/calendar/v1/meetings/${eventId}/comments/${commentId}`, { comment });

  deleteComment = async (eventId: string, commentId: string): Promise<AxiosResponse<unknown, unknown>> =>
    await http.delete(`/api/calendar/v1/meetings/${eventId}/comments/${commentId}`);

  getAvailablePlaces = async () => await http.get("/api/calendar/v1/meetings/places/available");

  removeFromShedule = async (id: string) => await http.patch(`/api/calendar/v1/meetings/${id}/canceled/remove-from-schedule`);

  getMettingsPeriod = async (params: IGetMettingsPeriod) =>
    await http.get(
      `/api/calendar/v1/meetings/period?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );
  getParticipantsAll = async (id: string, params: { startTime: string; endTime: string }) =>
    await http.get(
      `/api/api-gateway/v1/meetings/${id}/participants/all?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );

  getUnansweredCount = async () => await http.get("/api/calendar/v1/meetings/unanswered-count");

  getUnansweredMeetList = async (name: string) => await http.get(`/api/calendar/v1/meetings/unanswered?name=${name}`);
}

export const meetService = new MeetService();
