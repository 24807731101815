import React, { Dispatch, FC, SetStateAction, useState, useMemo } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { styles as btnStyles } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/styles";
import { setOnesTaskResolution } from "@store/businessTask/api";
import { ModalUI, HeaderModal, TextFont } from "@ui";
import { isTablet } from "@utils";
import globalState from "@utils/globalState";

import { IResoutionButton, OneSResolutions } from "./constants";

interface IProps {
  isVisible: boolean;
  buttonMeta: IResoutionButton | null;
  taskId: string;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const AddResolutionCommentModal: FC<IProps> = ({ taskId, isVisible, buttonMeta, setIsVisible }) => {
  const [comment, setComment] = useState("");
  const isEdit = useMemo(
    () =>
      comment.length ||
      buttonMeta?.type === OneSResolutions.AGREED ||
      buttonMeta?.type === OneSResolutions.COMPLETE ||
      buttonMeta?.type === OneSResolutions.EXECUTED ||
      buttonMeta?.type === OneSResolutions.FAMILIARIZED,
    [comment],
  );
  const placeholder = useIntl().formatMessage({ id: "yourRemarks" });
  const isLoading = false;
  const styles = style();
  const dispatch = useDispatch();

  const handlePressSave = () => {
    if (buttonMeta?.type) {
      dispatch(setOnesTaskResolution({ taskId, resolution: buttonMeta.type, comment }));
    }
  };

  return (
    <ModalUI isVisible={isVisible && Boolean(buttonMeta)} backdropOpacity onBack={() => setIsVisible(false)}>
      <RenderWithCondition condition={isTablet}>
        <HeaderModal
          title="comment"
          isCancel={false}
          onClose={() => {
            setIsVisible(false);
          }}
        />
      </RenderWithCondition>
      <div style={styles.container}>
        <textarea
          style={styles.input}
          placeholder={placeholder}
          value={comment}
          autoFocus={true}
          maxLength={255}
          onChange={(e) => setComment(e.target.value)}
        />
        <button
          disabled={!isEdit}
          style={{
            ...{ marginBottom: 20, flex: 0, backgroundColor: "#297952" },
            ...btnStyles.button,
            ...btnStyles.buttonSave,
            ...(!isEdit && { backgroundColor: "#E3E9E7" }),
          }}
          onClick={handlePressSave}
        >
          <LoaderButtonRenderWithCondition condition={isLoading}>
            <TextFont style={btnStyles.buttonText}>
              <FormattedMessage id={buttonMeta?.textId} defaultMessage={buttonMeta?.defaultMessage} />
            </TextFont>
          </LoaderButtonRenderWithCondition>
        </button>
      </div>
    </ModalUI>
  );
};

const style = () => {
  const { colorSchema } = globalState;

  return {
    container: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    },
    input: {
      backgroundColor: colorSchema.white,
      borderRadius: 9,
      fontSize: 18,
      minHeight: 150,
      borderWidth: 1,
      borderColor: colorSchema.grey,
      width: "100%",
      marginTop: 8,
      marginBotton: 8,
      paddingTop: 16,
      paddingLeft: 8,
      paddingRight: 8,
      "-webkit-box-sizing": "border-box",
      "-moz-box-sizing": "border-box",
      "box-sizing": "border-box",
    },
  };
};
