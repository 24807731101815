import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI } from "@ui";

interface IActionsSheetModeToDate {
  onClose: () => void;
  goToSpecificDay: () => void;
  openAddDays: () => void;
  containerStyle?: React.CSSProperties;
}

const ActionsSheetModeToDate: FC<IActionsSheetModeToDate> = (props) => (
  <ModalUI isVisible={true} className="bottomModal" onClose={props.onClose}>
    <div>
      <button onClick={props.onClose} style={{ ...styles.roundContainer, position: "absolute" }}>
        <SvgClose />
      </button>
      <div style={styles.containerActionSheetButtons}>
        <TextFont style={styles.textNavigationAddDay} onClick={props.goToSpecificDay}>
          <FormattedMessage id={"goToDay"} defaultMessage={"Перейти в день"} />
        </TextFont>
        <div style={styles.divider} />
        <TextFont style={styles.textNavigationAddDay} onClick={props.openAddDays}>
          <FormattedMessage id={"setWorkTime"} defaultMessage={"Установить время работы"} />
        </TextFont>
      </div>
    </div>
  </ModalUI>
);

export default ActionsSheetModeToDate;

const styles = {
  roundContainer: {
    display: "flex",
    "flex-direction": "column",
    top: 20,
    right: 12,
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: Colors.LIGHT.disabled,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.DARK.lighGrey,
    width: "100%",
  },
  containerActionSheetButtons: {
    marginTop: 65,
    marginBottom: 50,
  },
  textNavigationAddDay: {
    fontSize: 18,
    color: Colors.LIGHT.text.darkGreen,
    padding: 12,
    cursor: "pointer",
  },
};
