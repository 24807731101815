import React from "react";

import { TypePriorityGoal, TypeStatusGoal } from "@interfaces/businessGoals.interface";

import { configureIcons } from "./config";

export interface IIcon {
  priority: TypePriorityGoal;
  status: TypeStatusGoal;
}

export const Icon = ({ priority, status }: IIcon) => <div style={styles.itemIcon}>{configureIcons({ priority, status })}</div>;

const styles = {
  itemIcon: {
    marginRight: 5,
  },
};
