import React, { CSSProperties, FC } from "react";

import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  userName: string;
  lastName?: string;
  translucent?: boolean;
  style?: CSSProperties;
  textStyle?: CSSProperties;
}

export const LetterAvatar: FC<IProps> = ({ userName, translucent, lastName, style, textStyle }) => {
  const avatarLetters = `${userName?.[0]?.toUpperCase() || ""}${lastName?.[0]?.toUpperCase() || ""}`;
  return (
    <div style={{ ...styles.letterAvatar, ...(translucent && { opacity: 0.5 }), ...style }}>
      <TextFont style={{ ...styles.avatarLetter, ...textStyle }}>{avatarLetters}</TextFont>
    </div>
  );
};

const styles = {
  letterAvatar: {
    width: 45,
    height: 45,
    borderRadius: 45,
    marginRight: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: Colors.LIGHT.lighGrey,
    backgroundColor: Colors.LIGHT.background.main,
  },
  avatarLetter: {
    size: 16,
    color: Colors.LIGHT.text.grey,
    fontWeight: "700",
  },
};
