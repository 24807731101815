import { http } from "@api/httpClient";

class SessionService {
  getList = async (): Promise<any> => await http.get("/api/uaa/v1/sessions/active");

  remove = async (ids: string[]): Promise<any> => await http.delete(`/api/uaa/v1/sessions/logout?ids=${ids.join(",")}`);

  removeCurrent = async (): Promise<any> => await http.delete("/api/uaa/v1/sessions/current/logout");
}

export const sessionService = new SessionService();
