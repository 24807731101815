import React, { CSSProperties, FC, HTMLAttributes } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { Colors } from "@theme/colors";

import { TextFont } from "./TextFont";

interface IProps extends HTMLAttributes<HTMLInputElement> {
  value: string;
  setValue: (arg: string) => void;
  placeholder?: string;
  isDrop?: boolean;
  autoRenderDrop?: boolean;
  styleWrapper?: CSSProperties;
  styleInput?: CSSProperties;
  onDrop?: () => void;
}

export const SearchInput: FC<IProps> = ({ value, setValue, placeholder = "search", isDrop, autoRenderDrop, onDrop, ...props }) => {
  const phSearch = useIntl().formatMessage({ id: placeholder });

  const renderIsDrop = autoRenderDrop ? !!value : true;

  const handleDrop = () => {
    setValue("");
    onDrop?.();
  };

  const input = (
    <div style={{ ...styles.searchInput, ...props.styleInput }}>
      <SvgSearch />
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={styles.input}
        placeholder={phSearch}
        maxLength={255}
        autoFocus
        {...props}
      />
    </div>
  );

  if (isDrop) {
    return (
      <div style={{ ...styles.wrapper, ...props.styleWrapper }}>
        {input}
        {renderIsDrop && (
          <button onClick={handleDrop}>
            <TextFont size={16} color={Colors.LIGHT.text.accent}>
              <FormattedMessage id="drop" />
            </TextFont>
          </button>
        )}
      </div>
    );
  }

  return input;
};

const styles = {
  wrapper: {
    display: "flex",
    gap: 14,
    alignItems: "center",
  },
  searchInput: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    height: 38,
    borderRadius: 10,
    backgroundColor: "#ECF0EF",
    padding: "1px 8px",
  },
  input: {
    marginLeft: 6,
    width: "90%",
  },
};
