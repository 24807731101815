import React, { FC, useEffect, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgSettings } from "@assets/settings.svg";
import { regExpOnlyUrl, regExpUrl } from "@constants/regExp";
import { DIRECTOR_LINK } from "@constants/settings";
import { RenderWithCondition } from "@hoc";
import { LinkToZoom } from "@screens/profile/components/Settings/linkToZoom/LinkToZoom";
import { useGetAvailablePlacesQuery } from "@services/meetApi";
import { selectIsDogmaUser, userSelector } from "@store/user";
import { selectUserPersonalSettings } from "@store/userSettings";
import { updatePersonalSettings } from "@store/userSettings/api";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  onClose: () => void;
  onSave: (arg: string, index: number) => void;
  currentPlace: { title: string; index: number };
  places: string[];
}

export const ModalPlacement: FC<IProps> = ({ onClose, onSave, currentPlace, places }) => {
  const dispatch = useDispatch();
  const { directorId } = userSelector();

  const [text, setText] = useState("");
  const [permanentLink, setPermanentLink] = useState("");
  const [directorPermanentLink, setDirectorPermanentLink] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [skipGetPlaces, setSkipGetPlaces] = useState(true);

  const settings = useSelector(selectUserPersonalSettings);
  const isDogmaUser = useSelector(selectIsDogmaUser);

  const { data: availablePlaces } = useGetAvailablePlacesQuery(undefined, {
    skip: skipGetPlaces,
  });

  useEffect(() => {
    if (
      availablePlaces &&
      currentPlace &&
      currentPlace.title !== settings?.videoConferencePermanentUrl &&
      !availablePlaces?.includes(currentPlace.title)
    ) {
      setText(currentPlace.title);
    }
  }, [availablePlaces, currentPlace, settings?.videoConferencePermanentUrl]);

  useEffect(() => {
    if (!availablePlaces && isDogmaUser) {
      setSkipGetPlaces(false);
    }
  }, [availablePlaces, isDogmaUser]);

  useEffect(() => {
    if (!directorId) return;

    const directorLink = localStorage.getItem(DIRECTOR_LINK);
    setDirectorPermanentLink(directorLink ?? "");
  }, [directorId]);

  const handleSave = (val: string) => {
    onSave(val, currentPlace.index);
    onClose();
  };

  const handleUserLink = async () => {
    if (directorPermanentLink) {
      handleSave(directorPermanentLink);
    } else if (settings?.videoConferencePermanentUrl) {
      handleSave(settings.videoConferencePermanentUrl);
    } else {
      setIsVisible(true);
    }
  };

  const handleSavePermanentLink = () => {
    dispatch(updatePersonalSettings({ id: directorId, body: { videoConferencePermanentUrl: permanentLink } }));
    handleSave(permanentLink);
  };

  const isEdit = !!permanentLink.match(regExpOnlyUrl) && !places.includes(permanentLink);
  const isPermanentLink = useMemo(() => {
    if (directorPermanentLink) return places.includes(directorPermanentLink);
    return places.includes(settings?.videoConferencePermanentUrl ?? "");
  }, [directorPermanentLink, places, settings?.videoConferencePermanentUrl]);

  const textPermanentLinkBtn = useMemo(() => {
    if (directorId) {
      if (directorPermanentLink) return "Использовать постоянную ссылку руководителя";
      return "Использовать постоянную ссылку ассистента";
    }
    return "Использовать постоянную ссылку";
  }, [directorId, directorPermanentLink]);

  return (
    <ModalUI onClose={onClose} isVisible>
      <HeaderModal
        title="Выбрать место"
        isCancel={false}
        isEdit={!!text && text !== currentPlace.title}
        onSave={() => handleSave(text.match(regExpUrl) ? text.match(regExpUrl)[0] : text)}
        onClose={onClose}
        styleTextSave={{ fontWeight: "700" }}
      />
      <section style={styles.container}>
        <div>
          <TextFont type="bold" style={styles.title}>
            <FormattedMessage id="meetPlaceIn" />
          </TextFont>

          <div>
            <input
              value={text}
              style={styles.input}
              onChange={(e) => setText(e.target.value)}
              placeholder="Место встречи"
              maxLength={255}
            />
            <TextFont size={12} style={{ marginLeft: 16 }} color={globalState.colorSchema.grey500}>
              Напишите название места или вставьте ссылку
            </TextFont>
          </div>
        </div>
        <div>
          <TextFont type="bold" style={styles.title}>
            <FormattedMessage id="meetPlaceOnline" />
          </TextFont>
          <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
            <button
              onClick={handleUserLink}
              disabled={isPermanentLink}
              style={{
                ...styles.btn,

                backgroundColor: isPermanentLink ? globalState.colorSchema.grey : globalState.colorSchema.background.green,
              }}
            >
              <TextFont color={"white"} size={16} weight="700">
                {textPermanentLinkBtn}
              </TextFont>
            </button>
            <button onClick={() => setIsVisible(true)} style={styles.settings}>
              <SvgSettings />
            </button>
          </div>
        </div>

        <RenderWithCondition condition={availablePlaces}>
          <div>
            <TextFont type="bold" style={styles.title}>
              Переговорная
            </TextFont>
            <div style={{ paddingLeft: -12, paddingRight: -12 }}>
              {availablePlaces?.map(
                (i) =>
                  !places.includes(i) && (
                    <button onClick={() => handleSave(i)} style={styles.place} key={i}>
                      <TextFont size={18}>{i}</TextFont>
                    </button>
                  ),
              )}
            </div>
          </div>
        </RenderWithCondition>
      </section>

      <ModalUI onClose={() => setIsVisible(false)} isVisible={isVisible}>
        <HeaderModal
          title="Постоянная ссылка"
          isEdit={isEdit}
          isCancel={false}
          onSave={handleSavePermanentLink}
          onClose={() => setIsVisible(false)}
        />
        <div style={{ padding: "20px 12px" }}>
          <LinkToZoom link={permanentLink} setLink={setPermanentLink} />
        </div>
      </ModalUI>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    marginTop: 15,
    paddingLeft: 12,
    paddingRight: 12,
    gap: 30,
    "overflow-y": "auto",
  },
  title: {
    fontWeight: "700",
    fontSize: 18,
    marginBottom: 4,
  },
  input: {
    padding: 16,
    backgroundColor: "white",
    borderRadius: 10,
    width: "90%",
  },
  place: {
    borderBottom: "1px solid #E3E9E7",
    padding: 12,
  },
  btn: {
    padding: "12px 16px",
    borderRadius: 10,
  },
  settings: {
    padding: 10,
    backgroundColor: globalState.colorSchema.lighGrey,
    borderRadius: 10,
  },
};
