import { sortBy } from "lodash";

import { TOneSTaskResolutions } from "@interfaces/businessGoals.interface";

import { IResoutionButton, resolutionActions } from "./constants";

export const generateButtonDescriptionsAsset = (availableResolutions: TOneSTaskResolutions[]): IResoutionButton[] => {
  const result: IResoutionButton[] = [];

  for (const key of availableResolutions) {
    if (key in resolutionActions) {
      result.push(resolutionActions[key]);
    }
  }

  return result;
};
