import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  setIsContextMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentYCoordinate: number;
  handleDelete: () => void;
  handleEdit: () => void;
}

export const CommentContextMenu: FC<IProps> = ({ currentYCoordinate, setIsContextMenuVisible, handleDelete, handleEdit }) => {
  const styles = style();

  return (
    <div style={styles.outsidePressWrapper} onClick={() => setIsContextMenuVisible(false)}>
      <div style={{ ...styles.contextMenu, ...{ top: currentYCoordinate } }}>
        <button style={styles.contextMenuButton} onClick={handleEdit}>
          <div style={styles.iconContainer}>
            <SvgEdit fill={globalState.colorSchema.grey} />
          </div>
          <TextFont style={styles.contextMenuBtnText}>
            <FormattedMessage id={"edit"} />
          </TextFont>
        </button>

        <button style={styles.contextMenuButton} onClick={handleDelete}>
          <div style={styles.iconContainer}>
            <SvgDelete />
          </div>
          <TextFont style={styles.contextMenuBtnText}>
            <FormattedMessage id={"delete"} />
          </TextFont>
        </button>
      </div>
    </div>
  );
};

const style = () => {
  const theme = globalState.colorSchema;

  return {
    outsidePressWrapper: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      position: "absolute",
      borderRadius: 33,
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
    contextMenu: {
      width: 200,
      height: "auto",
      position: "absolute",
      right: 50,
      borderRadius: 8,
      backgroundColor: theme.white,
      paddingTop: 10,
    },
    contextMenuButton: {
      display: "flex",
      marginBottom: 10,
      alignItems: "center",
      "flex-direction": "row",
    },
    contextMenuBtnText: {
      fontSize: 18,
    },
    iconContainer: {
      width: 30,
      height: 25,
      alignItems: "center",
      justifyContent: "center",
    },
  };
};
