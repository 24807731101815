import React, { FC, Dispatch, useEffect, useState, useRef, useCallback } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { RemindMeetings } from "@components/screens/profile/components/Settings/components/RemindMeetings";
import { LoaderRenderWithCondition } from "@hoc";
import { IStateUser } from "@interfaces/user.interface";
import { api, StoreTagTypes } from "@services/api";
import { setIsHidePersonalTasks } from "@store/calendar/slice";
import { userSelector } from "@store/user";
import { selectIsLoadingUserSettings, selectShowBusiness, selectShowPersonal, selectUserPersonalSettings } from "@store/userSettings";
import { getPersonalSettings, updatePersonalSettings } from "@store/userSettings/api";
import { HeaderModal, TextFont } from "@ui";
import globalState from "@utils/globalState";
import { isTablet } from "@utils/isTablet";

import { TypeTab } from "../../types";

import { SettingsOptionItem } from "./components/SettingsOptionItem";
import { LinkToZoom } from "./linkToZoom/LinkToZoom";

interface ISettings {
  onClose: () => void;
  setTab?: Dispatch<TypeTab>;
  user: IStateUser;
}

export const Settings: FC<ISettings> = ({ onClose, user }) => {
  const personalSettings = useSelector(selectUserPersonalSettings);

  const [isToggle1Enabled, setIsToggle1Enabled] = useState(true);
  const [isToggle2Enabled, setIsToggle2Enabled] = useState(true);
  const [isCancelNotificationsPastMeetings, setCancelNotificationsPastMeetings] = useState<boolean>(
    personalSettings?.deleteNotificationsReminderMeetingAfterCompleted,
  );
  const [isSyncOneSToggleEnabled, setIsSyncOneSToggleEnabled] = useState(false);
  const [shouldUpdatePreferences, setShouldUpdatePreferences] = useState(false);
  const [link, setLink] = useState("");

  const fetchedShowBusiness = useSelector(selectShowBusiness);
  const fetchedShowPersonal = useSelector(selectShowPersonal);
  const isLoadingUserSettings = useSelector(selectIsLoadingUserSettings);
  const settings = useSelector(selectUserPersonalSettings);
  const { directorId } = userSelector();
  const fetchedShowOneS = settings?.oneSTasksSync ?? false;

  const storedShowBusiness = useRef<string | null>(null);
  const storedShowPersonal = useRef<string | null>(null);

  const dispatch = useDispatch();
  const styles = style();

  const handleToggle1Change = useCallback(() => {
    if (isToggle2Enabled) {
      setIsToggle1Enabled(!isToggle1Enabled);
      setShouldUpdatePreferences(true);
    }
    dispatch(api.util.invalidateTags([StoreTagTypes.Meet, StoreTagTypes.Calendar]));
  }, [isToggle1Enabled, isToggle2Enabled]);

  const handleToggle2Change = useCallback(() => {
    if (isToggle1Enabled) {
      dispatch(setIsHidePersonalTasks(isToggle2Enabled));
      setIsToggle2Enabled(!isToggle2Enabled);
      setShouldUpdatePreferences(true);
    }
    dispatch(api.util.invalidateTags([StoreTagTypes.Meet, StoreTagTypes.Calendar]));
  }, [isToggle1Enabled, isToggle2Enabled]);

  const handeleOneSToggle = () => {
    setIsSyncOneSToggleEnabled(!isSyncOneSToggleEnabled);
    setShouldUpdatePreferences(true);
  };

  const handleCancelNotifications = () => {
    setCancelNotificationsPastMeetings(!isCancelNotificationsPastMeetings);
    setShouldUpdatePreferences(true);
  };

  const getStoredUserPreferences = () => {
    storedShowBusiness.current = localStorage.getItem("showBusiness");
    storedShowPersonal.current = localStorage.getItem("showPersonal");

    if (storedShowBusiness.current !== null && storedShowPersonal.current !== null) {
      setIsToggle1Enabled(!storedShowBusiness.current);
      setIsToggle2Enabled(!storedShowPersonal.current);
    }
  };

  const updateDisplayPreferences = async () => {
    localStorage.setItem("showBusiness", JSON.stringify(isToggle1Enabled));
    localStorage.setItem("showPersonal", JSON.stringify(isToggle2Enabled));

    dispatch(
      updatePersonalSettings({
        id: directorId,
        body: {
          showBusinessTasks: isToggle1Enabled,
          showBusinessEvents: isToggle1Enabled,
          showPersonalTasks: isToggle2Enabled,
          showPersonalEvents: isToggle2Enabled,
          oneSTasksSync: isSyncOneSToggleEnabled,
          deleteNotificationsReminderMeetingAfterCompleted: isCancelNotificationsPastMeetings,
        },
      }),
    );

    setShouldUpdatePreferences(false);
  };

  useEffect(() => {
    getStoredUserPreferences();
    dispatch(getPersonalSettings({ id: directorId }));
  }, []);

  useEffect(() => {
    shouldUpdatePreferences && updateDisplayPreferences();
  }, [shouldUpdatePreferences]);

  useEffect(() => {
    setIsToggle1Enabled(Boolean(fetchedShowBusiness));
    localStorage.setItem("showBusiness", JSON.stringify(fetchedShowBusiness));
  }, [fetchedShowBusiness, storedShowBusiness.current]);

  useEffect(() => {
    setIsToggle2Enabled(Boolean(fetchedShowPersonal));
    localStorage.setItem("showPersonal", JSON.stringify(fetchedShowPersonal));
  }, [fetchedShowPersonal, storedShowPersonal.current]);

  useEffect(() => {
    setIsSyncOneSToggleEnabled(fetchedShowOneS);
  }, [fetchedShowOneS]);

  return (
    <div style={{ height: "100%" }}>
      <HeaderModal title="settings" isCancel={false} onClose={onClose} style={isTablet && { backgroundColor: "#ECF0EF", paddingTop: 40 }} />
      <LoaderRenderWithCondition condition={isLoadingUserSettings}>
        <div style={styles.container}>
          <LinkToZoom setLink={setLink} link={link} />

          <div>
            <TextFont size={18} style={styles.settingsText}>
              <FormattedMessage id="settingsTaskMeetTitle" defaultMessage="Отображение встреч и задач" />
            </TextFont>
            <SettingsOptionItem
              textId={"settingsBusinessMeetingsOption"}
              defaultText="Отображать бизнес встречи и задачи"
              isToggleEnabled={isToggle1Enabled}
              onToggleChange={handleToggle1Change}
              disableToggle={!isToggle2Enabled}
            />
            <SettingsOptionItem
              textId={"settingsPersonalMeetingsOption"}
              defaultText="Отображать личные встречи и задачи"
              isToggleEnabled={isToggle2Enabled}
              onToggleChange={handleToggle2Change}
              disableToggle={!isToggle1Enabled}
            />
            <TextFont size={12} style={styles.thinText}>
              <FormattedMessage
                id="settingsTaskMeetDescription"
                defaultMessage="При выключенном параметре соответствующие встречи и задачи не отображаются в списке задач и календарях"
              />
            </TextFont>

            <SettingsOptionItem
              textId={"deleteNotificationsPastMeetings"}
              isToggleEnabled={personalSettings?.deleteNotificationsReminderMeetingAfterCompleted}
              onToggleChange={handleCancelNotifications}
              style={{ height: 56 }}
              disableToggle={false}
            />
            <RemindMeetings
              durations={personalSettings?.notificationDurations}
              types={personalSettings?.notificationTypes}
              currentId={user?.id}
            />

            <SettingsOptionItem
              textId={"settingsSyncWithOneS"}
              defaultText="Синхронизация с 1С"
              isToggleEnabled={isSyncOneSToggleEnabled}
              onToggleChange={handeleOneSToggle}
              disableToggle={false}
            />
          </div>
        </div>
      </LoaderRenderWithCondition>
    </div>
  );
};

const style = () => {
  const { colorSchema } = globalState;

  return {
    container: {
      display: "flex",
      "flex-direction": "column",
      height: "85%",
      "overflow-y": "auto",
      marginTop: 20,
      paddingLeft: 12,
      paddingRight: 12,
      gap: 30,
    },
    settingsText: {
      color: colorSchema.text.darkGreen,
      fontWeight: "700",
    },
    thinText: {
      fontWeight: "400",
      paddingTop: 10,
      color: colorSchema.text.darkGreen,
    },
  };
};
