import React, { useCallback }  from "react";

import { useDispatch, useSelector } from "react-redux";

import { selectIsModalOpen } from "@store/configure";
import { selectScreenDay } from "@store/screenDay/selectors";
import { setActiveTabId } from "@store/screenDay/slice";

import { Tab } from "./Tab";
import { ITab, TabIds } from "./types";

const configTabs: Array<Omit<ITab, "onPress">> = [
  { id: "tasks", title: "Задачи" },
  { id: "timetable", title: "Расписание" },
  { id: "notes", title: "Заметки" },
];

const HeaderDecorator = () => (
  <div style={styles.headerDecoratorContainer}>
    <div style={styles.headerDecorator} />
  </div>
);

export const Tabs = () => {
  const isModalOpen = useSelector(selectIsModalOpen);
  const { activeTabId } = useSelector(selectScreenDay);
  const dispatch = useDispatch();

  const onPress = useCallback((tabId: TabIds) => {
    dispatch(setActiveTabId(tabId));
  }, []);

  return (
    <>
      {isModalOpen ? (
        <HeaderDecorator />
      ) : (
        <div style={styles.container}>
          <div style={styles.tabs}>
            {configTabs.map((tab) => (
              <Tab key={tab.id} {...tab} isActive={activeTabId === tab.id} onPress={() => onPress(tab.id)} width="33%" />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    height: 50,
    backgroundColor: "#F5F9F8",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 12,
  },
  tabs: {
    width: "100%",
    height: 32,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  headerDecoratorContainer: {
    backgroundColor: "#000",
    width: "100%",
    height: 100,
  },
  headerDecorator: {
    backgroundColor: "#A6A6A6",
    height: 130,
    width: "90%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: "12.5%",
    alignSelf: "center",
    justifySelf: "center",
  },
};
