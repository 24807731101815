import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { AsyncStorageKeys } from "@constants/asyncStorageKeys";
import { IColors } from "@interfaces/theme.interface";
import { selectCalendar } from "@store/calendar";
import { setViewMode } from "@store/calendar/slice";
import { CalendarViewModes } from "@store/calendar/slice";
import { selectTheme } from "@store/theme/selectors";
import { TextFont } from "@ui";

export const WeekMonthSwitcher: FC = () => {
  const { viewMode } = useSelector(selectCalendar);
  const dispatch = useDispatch();
  const theme = selectTheme("extra");
  const isMonthSelected = viewMode === CalendarViewModes.Month;
  const isWeekSelected = viewMode === CalendarViewModes.Week;
  const styles = makeStyles(theme);

  const setViewModeWrapper = (selectedMode: CalendarViewModes) => {
    dispatch(setViewMode(selectedMode));
    localStorage.setItem(AsyncStorageKeys.CalendarViewMode, selectedMode);
  };

  return (
    <div style={styles.container}>
      <button
        onClick={() => setViewModeWrapper(CalendarViewModes.Month)}
        style={{ ...styles.button, ...styles.leftButton, ...{ backgroundColor: isMonthSelected ? theme.background.green : theme.white } }}
      >
        <TextFont size={10} color={isMonthSelected ? theme.white : theme.background.green} style={{ textAlign: "center" }}>
          <FormattedMessage id={"month"} defaultMessage={"Месяц"} />
        </TextFont>
      </button>

      <button
        onClick={() => setViewModeWrapper(CalendarViewModes.Week)}
        style={{ ...styles.button, ...styles.rightButton, ...{ backgroundColor: isWeekSelected ? theme.background.green : theme.white } }}
      >
        <TextFont size={10} color={isWeekSelected ? theme.white : theme.background.green} style={{ textAlign: "center" }}>
          <FormattedMessage id={"week"} defaultMessage={"Неделя"} />
        </TextFont>
      </button>
    </div>
  );
};

const makeStyles = (colors: IColors) => ({
  container: {
    display: "flex",
    "flex-direction": "row",
    height: 22,
  },
  button: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.background.green,
    paddingTop: 3,
    width: 55,
  },
  leftButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
