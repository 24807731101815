import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

import { StoreTagTypes, api } from "@services/api";
import { getGroupedList } from "@store/businessTask";

import { toFormatDate } from "./toFormatTime";

export const updateScreens = (store: ToolkitStore, type: "personal" | "task" | "meeting") => {
  const screen = window.location.pathname;
  const date = toFormatDate(store.getState()?.screenDay?.date);

  switch (screen) {
    case "/":
      if (type === "personal" || type === "task") {
        return store.dispatch(getGroupedList({ date, next: 5, notLoader: true }));
      }

      if (type === "meeting") {
        return store.dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
      }

      break;
    default:
      break;
  }
};
