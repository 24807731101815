export const notifyParticipantsTextIdOptions = {
  titleTextId: "youHaveChangedParticipantsListNotification",
  okTextId: "notifyAllParticipants",
  cancelTextId: "notifyOnlyNewAndDeletedParticipants",
};

export const confirmNotifyParticpantsTextIdOptions = {
  titleTextId: "youHaveChangedMeetingNotifyParticipants",
  okTextId: "yes",
  cancelTextId: "noException",
};
