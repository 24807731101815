import businessGoalsReducer, { setCurrentId } from "@store/businessGoals/slice";

export {
  selectBusinessGoals,
  selectCurrentGoalId,
  selectSpecifyGoal,
  selectBusiness,
  selectBusinessTaskInGoal,
} from "@store/businessGoals/selectors";

export { getList, create, update, deleteGoal, moveDate, updateStatus, continueGoal } from "./api";

export { setCurrentId };

export default businessGoalsReducer;
