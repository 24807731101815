import { TNotificationDurations, TNotificationName, TNotificationTypes } from "./meet.interface";

export enum NotificationDurations {
  FIFTEEN_MINUTES = "FIFTEEN_MINUTES",
  ONE_HOUR = "ONE_HOUR",
  ONE_DAY = "ONE_DAY",
}

export enum NotificationTypes {
  EMAIL = "EMAIL",
  PUSH = "PUSH",
}
export enum NotificationName {
  CONFIRM_MEETING_PARTICIPANT_STATUS = "CONFIRM_MEETING_PARTICIPANT_STATUS",
  MEETING_STATUS_COMMENT = "MEETING_STATUS_COMMENT",
}

export interface IEventsDisplaySettings {
  isShowBusiness: boolean;
  isShowPersonal: boolean;
}
export interface IDisableNotifications {
  notificationName: TNotificationName;
  notificationTypes: TNotificationTypes[];
}

export interface IPersonalSettings {
  showBusinessTasks: true;
  deleteNotificationsReminderMeetingAfterCompleted: boolean;
  showPersonalTasks: true;
  oneSTasksSync: true;
  showOneSTasks: true;
  showBusinessEvents: true;
  showPersonalEvents: true;
  notificationDurations: TNotificationDurations[];
  notificationTypes: TNotificationTypes[];
  videoConferencePermanentUrl: string;
}
