import { useMemo } from "react";

import { useSelector } from "react-redux";

import { tempTask, tempWorkDay } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/constants";
import { selectCurrentDate } from "@store/screenDay";
import { toFormatDate } from "@utils/toFormatTime";

import { TypeKey } from "./constants";

export const useStartData = (priority: TypeKey) => {
  const currentDate = useSelector(selectCurrentDate);

  const data = useMemo(
    () => ({
      ...tempTask,
      priority: priority.toUpperCase(),
      startDate: toFormatDate(currentDate),
      workDays: [{ date: toFormatDate(currentDate), ...tempWorkDay }],
    }),
    [priority, currentDate]
  );

  return useMemo(
    () => ({
      data,
    }),
    [data]
  );
};
