import React, { Dispatch, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";

import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY } from "@constants/calendar";
import { RootState } from "@store";
import { saveDate } from "@store/screenDay";
import { TextFont, ArrowSvg } from "@ui";

export const daysOfWeekDefault = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export const configWeek = {
  MONDAY: "пн",
  TUESDAY: "вт",
  WEDNESDAY: "ср",
  THURSDAY: "чт",
  FRIDAY: "пт",
  SATURDAY: "сб",
  SUNDAY: "вс",
};

type TWeek = Record<string, Dayjs>;

interface ICalendarCustom {
  setIsVisible: Dispatch<boolean>;
  press: (...arg: any) => void;
}

export const CalendarCustom = ({ setIsVisible, press }: ICalendarCustom) => {
  const dispatch = useDispatch();
  const { date, startDate } = useSelector((state: RootState) => state.screenDay);

  const selectedDate = date ? date : startDate;
  const titleSelectedMonth = dayjs.months()[selectedDate.month()];

  const month: Array<TWeek> = useMemo(() => {
    const selectedYear = dayjs(selectedDate).year();
    const selectedMonth = dayjs(selectedDate).month();
    const firstDayCurrentMonth = dayjs().year(selectedYear).month(selectedMonth).date(1);
    let isEndMonth = false;
    const resultMonth: Array<TWeek> = [];
    let tempWeek = 0;
    while (isEndMonth === false) {
      const week: TWeek = {};

      daysOfWeekDefault.forEach((day: string, index) => {
        const firstDayIndexWeek = new Date(String(firstDayCurrentMonth)).getDay();
        const temp = index - firstDayIndexWeek + 1;
        week[day] = dayjs(firstDayCurrentMonth).add(temp + tempWeek, "day");
        if (
          dayjs(firstDayCurrentMonth)
            .add(temp + tempWeek - 1, "day")
            .get("month") !==
            dayjs(firstDayCurrentMonth)
              .add(temp + tempWeek, "day")
              .get("month") &&
          tempWeek > 20
        ) {
          isEndMonth = true;
        }
      });
      tempWeek += 7;
      resultMonth.push(week);
    }

    return resultMonth;
  }, [selectedDate]);

  return (
    <aside>
      <div style={styles.headerArrow}>
        <button
          onClick={() => {
            dispatch(saveDate(selectedDate.subtract(1, "month")));
          }}
        >
          <ArrowSvg type="left" fill="#5F6E67" width={26} height={26} />
        </button>

        <TextFont style={styles.headerTitle}>{titleSelectedMonth}</TextFont>

        <button
          onClick={() => {
            dispatch(saveDate(selectedDate.add(1, "month")));
          }}
        >
          <ArrowSvg type="right" fill="#5F6E67" width={26} height={26} />
        </button>
      </div>
      <div style={styles.header}>
        {Object.entries(configWeek).map(([key, value]) => (
          <div style={styles.cell} key={key}>
            <TextFont style={styles.text}>{value}</TextFont>
          </div>
        ))}
      </div>

      <div style={styles.table}>
        {month.map((week, index) => (
          <div style={styles.bodyRow} key={index}>
            {Object.entries(week).map(([dayWeek, dateWeek]) => {
              const numberDay = dateWeek.date();
              const numberMonth = dateWeek.month();
              const numberYear = dateWeek.year();
              const isToday =
                numberDay === dayjs(startDate).date() && numberMonth === dayjs(startDate).month() && numberYear === dayjs(startDate).year();

              return (
                <button
                  key={String(dateWeek)}
                  style={styles.bodyCell}
                  onClick={() => {
                    press(dateWeek);
                    setIsVisible(false);
                  }}
                >
                  <div style={{ ...styles.bodyTextWrap, backgroundColor: isToday && "#297952" }}>
                    <TextFont style={isToday && styles.textToday}>{`${new Date(String(dateWeek)).getDate()}`}</TextFont>
                  </div>
                </button>
              );
            })}
          </div>
        ))}
      </div>
    </aside>
  );
};

const styles = {
  headerArrow: {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
  },
  headerTitle: {
    fontWeight: "700",
    fontSize: "16px",
    "text-transform": "capitalize",
  },
  header: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
    "flex-direction": "row",
    marginTop: "4px",
    marginBottom: "4px",
  },
  cell: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  text: {
    color: "#B3BEB8",
  },

  table: {
    width: "100%",
    height: "88%",
  },

  bodyRow: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    flex: 1,
  },
  bodyCell: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    borderTop: "1px solid #B3BEB8",
    borderLeft: "1px solid #B3BEB8",
    paddingTop: "8px",
  },
  bodyTextWrap: {
    display: "flex",
    "flex-direction": "column",
    width: "24px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    padding: "4px",
  },
  cellToday: {
    backgroundColor: "#297952",
  },
  textToday: {
    color: "#fff",
  },
  cellSelected: {
    backgroundColor: "#5F6E67",
  },
  textSelected: {
    color: "#fff",
  },
};
