import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@store";

export const selectBusiness = (state: RootState) => state.businessGoals;

export const selectBusinessGoals = (state: RootState) => state.businessGoals.goals;
export const selectCurrentGoalId = (state: RootState) => state.businessGoals.currentGoalId;

export const selectSpecifyGoal = (id: string) => createSelector(selectBusinessGoals, (goals) => goals.filter((item) => item.id === id)[0]);

export const selectBusinessTaskInGoal = (goalId: string, taskId: string) =>
  createSelector(selectSpecifyGoal(goalId), (goal) => goal.businessTasks.filter((i) => i.id === taskId)[0]);
