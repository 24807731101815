import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { useTime } from "@hooks/useTime";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { commonStyles } from "@styles/styles";
import { TextFont, TimePicker } from "@ui";
import { EditTimerOptions } from "@ui/TimePicker";
import { toFormatDate, toFormatHHmm } from "@utils";

interface ITime {
  day: WorkDay;
  onChange: (name: EditTimerOptions, value: any, id: string) => void;
}

export const Time = ({ day, onChange }: ITime) => {
  const { startTime, endTime, handleTime } = useTime({ startValue: day?.startTime, endValue: day?.endTime });

  const [isVisibleStart, setIsVisibleStart] = useState(false);
  const [isVisibleEnd, setIsVisibleEnd] = useState(false);

  useEffect(() => {
    onChange(EditTimerOptions.StartTime, startTime, day.id);
  }, [startTime]);

  useEffect(() => {
    onChange(EditTimerOptions.EndTime, endTime, day.id);
  }, [endTime]);

  const onChangeStart = (selectedDate: string) => handleTime("startTime", dayjs(`${toFormatDate(dayjs(day.date))} ${selectedDate}`));

  const onChangeEnd = (selectedDate: string) => handleTime("endTime", dayjs(`${toFormatDate(dayjs(day.date))} ${selectedDate}`));

  const handleVisibleStart = () => {
    setIsVisibleStart(!isVisibleStart);
    setIsVisibleEnd(false);
  };

  const handleVisibleEnd = () => {
    setIsVisibleEnd(!isVisibleEnd);
    setIsVisibleStart(false);
  };

  if (!day.startTime && !day.endTime) return null;

  return (
    <section style={styles.container}>
      <TextFont style={styles.title}>{dayjs(day.date).format("D MMMM")}</TextFont>
      <>
        <button
          style={{
            ...commonStyles.billet,
            ...styles.timeButton,
          }}
          onClick={handleVisibleStart}
        >
          <TextFont style={styles.textBold}>
            <FormattedMessage id="beginDay" />
          </TextFont>
          <TimePicker onChange={(value) => onChangeStart(value)} value={toFormatHHmm(dayjs(startTime ?? ""))} />
        </button>
      </>
      <>
        <button style={{ ...commonStyles.billet, ...styles.timeButton }} onClick={handleVisibleEnd}>
          <TextFont style={styles.textBold}>
            <FormattedMessage id="endingDay" />
          </TextFont>
          <TimePicker onChange={(value) => onChangeEnd(value)} value={toFormatHHmm(dayjs(endTime ?? ""))} />
        </button>
      </>
    </section>
  );
};

const styles = {
  container: {
    marginTop: "40px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "400",
  },
  textBold: {
    fontSize: "16px",
    fontWeight: "700",
  },
  timeButton: {
    display: "grid",
    gridTemplateColumns: "80% 20%",
    paddingTop: 0,
    paddingBottom: 0,
  },
};
