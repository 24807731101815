import { ReactComponent as Icon_career_complete } from "../assets/complete/career.svg";
import { ReactComponent as Icon_education_complete } from "../assets/complete/education.svg";
import { ReactComponent as Icon_ethics_complete } from "../assets/complete/ethics.svg";
import { ReactComponent as Icon_family_complete } from "../assets/complete/family.svg";
import { ReactComponent as Icon_health_complete } from "../assets/complete/health.svg";
import { ReactComponent as Icon_social_complete } from "../assets/complete/social.svg";
import { ReactComponent as Icon_career_active } from "../assets/default/career.svg";
import { ReactComponent as Icon_education_active } from "../assets/default/education.svg";
import { ReactComponent as Icon_ethics_active } from "../assets/default/ethics.svg";
import { ReactComponent as Icon_family_active } from "../assets/default/family.svg";
import { ReactComponent as Icon_health_active } from "../assets/default/health.svg";
import { ReactComponent as Icon_social_active } from "../assets/default/social.svg";

import { IIcon } from "./Icon";

export const configureIcons = ({ title, type }: IIcon) => {
  const color = type === "active" ? "#297952" : "#B3BEB8";

  const object = {
    FAMILY_AND_HOME: {
      default: <Icon_family_active fill={color} />,
      complete: <Icon_family_complete />,
    },
    FINANCE_AND_CAREER: {
      default: <Icon_career_active fill={color} />,
      complete: <Icon_career_complete />,
    },
    PHYSICAL_CONDITION_AND_HEALTH: {
      default: <Icon_health_active fill={color} />,
      complete: <Icon_health_complete />,
    },
    DEVELOPMENT_AND_SELF_EDUCATION: {
      default: <Icon_education_active fill={color} />,
      complete: <Icon_education_complete />,
    },
    SOCIAL_AND_CULTURAL: {
      default: <Icon_social_active fill={color} />,
      complete: <Icon_social_complete />,
    },
    SPIRITUAL_AND_ETHICAL: {
      default: <Icon_ethics_active fill={color} />,
      complete: <Icon_ethics_complete />,
    },
  };

  return object[title][type === "complete" ? "complete" : "default"];
};
