export interface IResoutionButton {
  type: OneSResolutions;
  textId: string;
  defaultMessage: string;
  isAccent: boolean;
  commentRequired: boolean;
  resolution: OneSResolutions;
}

export enum OneSResolutions {
  EXECUTED = "EXECUTED",
  AGREED = "AGREED",
  AGREED_WITH_REMARKS = "AGREED_WITH_REMARKS",
  NOT_AGREED = "NOT_AGREED",
  FAMILIARIZED = "FAMILIARIZED",
  REPEAT_AGREEMENT = "REPEAT_AGREEMENT",
  COMPLETE_AGREEMENT = "COMPLETE_AGREEMENT",
  REGISTER = "REGISTER",
  DO_NOT_REGISTER = "DO_NOT_REGISTER",
  REPEAT_REGISTRATION = "REPEAT_REGISTRATION",
  COMPLETE_REGISTRATION = "COMPLETE_REGISTRATION",
  CONFIRMED = "CONFIRMED",
  NOT_CONFIRMED = "NOT_CONFIRMED",
  SIGN = "SIGN",
  REJECT = "REJECT",
  REPEAT_CONFIRMATION = "REPEAT_CONFIRMATION",
  COMPLETE_CONFIRMATION = "COMPLETE_CONFIRMATION",
  REPEAT_SIGNATURE = "REPEAT_SIGNATURE",
  COMPLETE_SIGNATURE = "COMPLETE_SIGNATURE",
  RETURN = "RETURN",
  COMPLETE = "COMPLETE",
}

export const resolutionActions: Record<OneSResolutions, IResoutionButton> = {
  EXECUTED: {
    type: OneSResolutions.EXECUTED,
    resolution: OneSResolutions.EXECUTED,
    textId: "oneSExecute",
    defaultMessage: "Исполнено",
    commentRequired: true,
    isAccent: true,
  },
  AGREED: {
    type: OneSResolutions.AGREED,
    resolution: OneSResolutions.AGREED,
    textId: "oneSApprove",
    defaultMessage: "Согласовано",
    commentRequired: true,
    isAccent: true,
  },
  AGREED_WITH_REMARKS: {
    type: OneSResolutions.AGREED_WITH_REMARKS,
    resolution: OneSResolutions.AGREED_WITH_REMARKS,
    textId: "oneSApproveWithComments",
    defaultMessage: "Согласовано с замечаниями",
    commentRequired: true,
    isAccent: false,
  },
  NOT_AGREED: {
    type: OneSResolutions.NOT_AGREED,
    resolution: OneSResolutions.NOT_AGREED,
    textId: "oneSNotApprove",
    defaultMessage: "Не согласовано",
    commentRequired: true,
    isAccent: false,
  },
  FAMILIARIZED: {
    type: OneSResolutions.FAMILIARIZED,
    resolution: OneSResolutions.FAMILIARIZED,
    textId: "oneSFamiliarize",
    defaultMessage: "Ознакомился",
    commentRequired: true,
    isAccent: true,
  },
  REPEAT_AGREEMENT: {
    type: OneSResolutions.REPEAT_AGREEMENT,
    resolution: OneSResolutions.REPEAT_AGREEMENT,
    textId: "oneSRepeatAgreement",
    defaultMessage: "Повторить согласование",
    commentRequired: false,
    isAccent: false,
  },
  COMPLETE_AGREEMENT: {
    type: OneSResolutions.COMPLETE_AGREEMENT,
    resolution: OneSResolutions.COMPLETE_AGREEMENT,
    textId: "oneSCompleteAgreement",
    defaultMessage: "Завершить согласование",
    commentRequired: false,
    isAccent: false,
  },
  REGISTER: {
    type: OneSResolutions.REGISTER,
    resolution: OneSResolutions.REGISTER,
    textId: "oneSRegistrate",
    defaultMessage: "Зарегистрировать",
    commentRequired: false,
    isAccent: false,
  },
  DO_NOT_REGISTER: {
    type: OneSResolutions.DO_NOT_REGISTER,
    resolution: OneSResolutions.DO_NOT_REGISTER,
    textId: "oneSDoNotRegistrate",
    defaultMessage: "Не регистрировать",
    commentRequired: true,
    isAccent: false,
  },
  REPEAT_REGISTRATION: {
    type: OneSResolutions.REPEAT_REGISTRATION,
    resolution: OneSResolutions.REPEAT_REGISTRATION,
    textId: "oneSRepeatRegistration",
    defaultMessage: "Повторить регистрацию",
    commentRequired: true,
    isAccent: false,
  },
  COMPLETE_REGISTRATION: {
    type: OneSResolutions.COMPLETE_REGISTRATION,
    resolution: OneSResolutions.COMPLETE_REGISTRATION,
    textId: "oneSCompleteRegistration",
    defaultMessage: "Завершить регистрацию",
    commentRequired: false,
    isAccent: false,
  },
  CONFIRMED: {
    type: OneSResolutions.CONFIRMED,
    resolution: OneSResolutions.CONFIRMED,
    textId: "oneSConfirmed",
    defaultMessage: "Утверждено",
    commentRequired: false,
    isAccent: false,
  },
  NOT_CONFIRMED: {
    type: OneSResolutions.NOT_CONFIRMED,
    resolution: OneSResolutions.NOT_CONFIRMED,
    textId: "oneSNotConfirmed",
    defaultMessage: "Не утверждено",
    commentRequired: true,
    isAccent: false,
  },
  COMPLETE_CONFIRMATION: {
    type: OneSResolutions.COMPLETE_CONFIRMATION,
    resolution: OneSResolutions.COMPLETE_CONFIRMATION,
    textId: "oneSCompleteConfirmation",
    defaultMessage: "Завершить утверждение",
    commentRequired: false,
    isAccent: false,
  },
  SIGN: {
    type: OneSResolutions.SIGN,
    resolution: OneSResolutions.SIGN,
    textId: "oneSSign",
    defaultMessage: "Подписать",
    commentRequired: false,
    isAccent: false,
  },
  REJECT: {
    type: OneSResolutions.REJECT,
    resolution: OneSResolutions.REJECT,
    textId: "oneSReject",
    defaultMessage: "Отклонить",
    commentRequired: true,
    isAccent: false,
  },
  REPEAT_CONFIRMATION: {
    type: OneSResolutions.REPEAT_CONFIRMATION,
    resolution: OneSResolutions.REPEAT_CONFIRMATION,
    textId: "oneSRepeatConfirmation",
    defaultMessage: "Повторить утверждение",
    commentRequired: false,
    isAccent: false,
  },
  REPEAT_SIGNATURE: {
    type: OneSResolutions.REPEAT_SIGNATURE,
    resolution: OneSResolutions.REPEAT_SIGNATURE,
    textId: "oneSRepeatSignature",
    defaultMessage: "Повторить подписание",
    commentRequired: true,
    isAccent: false,
  },
  COMPLETE_SIGNATURE: {
    type: OneSResolutions.COMPLETE_SIGNATURE,
    resolution: OneSResolutions.COMPLETE_SIGNATURE,
    textId: "oneSCompleteSignature",
    defaultMessage: "Завершить подписание",
    commentRequired: false,
    isAccent: false,
  },
  RETURN: {
    type: OneSResolutions.RETURN,
    resolution: OneSResolutions.RETURN,
    textId: "returnToWorks",
    defaultMessage: "Вернуть",
    commentRequired: true,
    isAccent: false,
  },
  COMPLETE: {
    type: OneSResolutions.COMPLETE,
    resolution: OneSResolutions.COMPLETE,
    textId: "returnToWorks",
    defaultMessage: "Завершить",
    commentRequired: true,
    isAccent: false,
  },
};
