import React, { useEffect, useMemo, useState } from "react";

import regexifyString from "regexify-string";

import { ReactComponent as Folder } from "@assets/folder.svg";
import { ReactComponent as SmallFolder } from "@assets/small-folder.svg";
import { IProjectMain } from "@interfaces/projects.interface";
import { TextFont, ArrowSvg } from "@ui";

import { ProjectItem } from "./project-item";

export interface IPropsProjectGroup extends IProjectMain {
  counter?: number;
  paddingLeftMain?: number;
  paddingLeftSub?: number;
  search: string;
  handleProject: (name: string, value: any, nameProject?: string) => void;
  isFilter?: boolean;
  arrayEqualId: Set<string>;
}

export const ProjectGroup = ({
  id,
  name,
  shortName,
  search,
  projects,
  childProjectGroups,
  parentProjectGroupId,
  counter = 1,
  paddingLeftMain = 15,
  paddingLeftSub = 25,
  handleProject = () => "",
  isFilter = true,
  arrayEqualId,
}: IPropsProjectGroup) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const notEmpty = projects.length > 0 || childProjectGroups.length > 0;

  const isEqualName = useMemo(() => {
    if (search) {
      return new RegExp(search, "gim").test(name || shortName);
    }
    return false;
  }, [search, name, shortName]);

  useEffect(() => {
    if (isEqualName) {
      arrayEqualId.add(parentProjectGroupId ?? id);
    }
  }, [isEqualName]);

  useEffect(() => {
    if (arrayEqualId.has(id)) {
      setIsOpen(true);
      arrayEqualId.add(parentProjectGroupId ?? id);
    }
  }, [arrayEqualId]);

  const textName = (val: string) => {
    if (!search) return val;

    return regexifyString({
      pattern: new RegExp(search, "gim"),
      decorator: (match, index) => <span style={{ backgroundColor: "#FFE9B0" }}>{match}</span>,
      input: val,
    });
  };

  return (
    <div
      style={{
        ...styles.projectContainer,
        marginBottom: counter === 1 ? 10 : 0,
        borderBottomLeftRadius: isOpen ? 0 : 10,
        borderBottomRightRadius: isOpen ? 0 : 10,
      }}
    >
      <div
        style={{
          ...styles.projectMain,
          paddingLeft: paddingLeftMain,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", width: counter === 1 ? 20 : 16, justifyContent: "center" }}>{counter <= 2 ? <Folder /> : <SmallFolder />}</div>
          <button
          // onClick={() => {
          //   if (isFilter) {
          //     if (notEmpty) {
          //       handleProject("projectId", "", name);
          //       handleProject("projectGroupId", id, name);
          //       return;
          //     }
          //     handleProject("projectId", id, name);
          //     handleProject("projectGroupId", "", name);
          //   }
          //   if (notEmpty) return;
          //   handleProject("projectId", id, name);
          // }}
          >
            <TextFont style={{ ...styles.text, fontWeight: counter === 1 ? "bold" : "400" }}>{textName(name)}</TextFont>
          </button>
        </div>
        {notEmpty ? (
          <button onClick={() => setIsOpen(!isOpen)} style={{ alignItems: "flex-end", width: 41 }}>
            <div style={{ width: 26, justifyContent: "center" }}>
              <ArrowSvg
                type={isOpen ? "up" : "down"}
                fill={counter <= 2 ? "#B3BEB8" : "#DAF1E5"}
                height={counter <= 2 ? 26 : 20}
                width={counter <= 2 ? 26 : 20}
              />
            </div>
          </button>
        ) : null}
      </div>
      <div style={{ display: isOpen ? "block" : "none" }}>
        {projects.map((project, index) => (
          <ProjectItem
            project={project}
            childProjectGroups={childProjectGroups}
            arrayEqualId={arrayEqualId}
            projects={projects}
            search={search}
            index={index}
            paddingLeftSub={paddingLeftSub + 10}
            isFilter={isFilter}
            handleProject={handleProject}
            {...project}
          />
        ))}
        {childProjectGroups.map((childProjectGroup, index) => (
          <ProjectGroup
            key={`${childProjectGroup.id}-${index}`}
            counter={counter + 1}
            search={search}
            paddingLeftMain={paddingLeftMain + 10}
            paddingLeftSub={paddingLeftSub + 10}
            handleProject={handleProject}
            arrayEqualId={arrayEqualId}
            {...childProjectGroup}
          />
        ))}
      </div>
    </div>
  );
};

export const styles = {
  projectContainer: {
    backgroundColor: "white",
    borderRadius: "10px",
  },
  projectMain: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "50px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: "7px",
    paddingRight: "10px",
  },
};
