import React from "react";

import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgReturnToWork } from "@assets/return_work.svg";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

export const HeaderAnalysis = () => {
  const navigate = useNavigate();

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", alignItems: "center", padding: "0 10px" }}>
      <button onClick={() => navigate(-1)} style={{ justifySelf: "flex-start" }}>
        <SvgReturnToWork fill={globalState.colorSchema.background.green} />
      </button>
      <TextFont style={{ fontWeight: "700", textAlign: "center" }}>
        <FormattedMessage id="dayAnalysis" />
      </TextFont>
      <div></div>
    </div>
  );
};
