import React, { ReactNode, useEffect, useState } from "react";

import styled from "styled-components";

import globalState from "@utils/globalState";

import { TextFont } from "./TextFont";

interface ITabButton {
  tabText?: string;
  colorType?: "primary_black" | "secondary_white" | "primary_white" | "secondary_black";
  buttonSize?: "large" | "normal" | "medium" | "small";
  className?: string;
  clickEvent: () => void;
  type?: "submit" | "reset" | "button";
  svgIconLeft?: ReactNode;
  svgIconRight?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSquare?: boolean;
  active?: boolean;
  isRadioTab?: boolean;
}

export default function TabButton({ tabText, clickEvent, isDisabled, svgIconLeft, active, isRadioTab }: ITabButton) {
  const [isActiveInner, setIsActiveInner] = useState(false); // ToDo: no need anymore?

  useEffect(() => {
    setIsActiveInner(active);
  }, [active]);

  function onHandle() {
    if (!isDisabled) {
      clickEvent();
      setIsActiveInner(!isActiveInner);
    }
  }

  return (
    <TabWrap onClick={active && isRadioTab ? undefined : onHandle} active={active} disabled={isDisabled}>
      {svgIconLeft && <IconLeft>{svgIconLeft}</IconLeft>}
      <TextFont className="justify-center" size="18px" color={globalState.colorSchema.text.main}>
        {tabText}
      </TextFont>
    </TabWrap>
  );
}

const TabWrap = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  width: 100%;
  gap: 9px;
  height: 46px;
  padding: 11px 24px;
  border-radius: 5px;
  border: 1px solid ${globalState.colorSchema.lighGrey};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: ${globalState.colorSchema.lighGrey};
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${globalState.colorSchema.grey};
  }
  &:active {
    background-color: ${globalState.colorSchema.grey};
  }
  ${({ active, disabled }) =>
    active &&
    !disabled &&
    `
    border: 1px solid ${globalState.colorSchema.green};
    transition: all 0.5s linease-in-outear;
   }
  `}
  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${globalState.colorSchema.grey500};
    cursor: not-allowed;
    transition: all 0.5s linear;
    &:active {
    background-color: ${globalState.colorSchema.grey500};
    }
    &:hover {
    background-color: ${globalState.colorSchema.grey500};
   }
  `}
`;

const IconLeft = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
