import React, { useMemo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TTypePriorityTask } from "@interfaces/businessGoals.interface";
import { TextFont } from "@ui/TextFont";

const priorities = [
  { priority: "URGENT", title: "urgent" },
  { priority: "IMPORTANT", title: "important" },
  { priority: "CONTACT", title: "contact" },
] as { priority: TTypePriorityTask; title: string }[];

interface IPriority {
  priority: TTypePriorityTask;
  setPriority: (name: string, value: TTypePriorityTask) => void;
}

export const Priority = ({ priority, setPriority }: IPriority) => (
  <section style={styles.container}>
    <TextFont type="bold" style={styles.mainTitle}>
      <FormattedMessage id="taskPriority" />
    </TextFont>
    <div style={styles.main}>
      {priorities.map((item) => (
        <Button
          key={item.priority}
          isBorder={item.priority === priority}
          onClick={() => item.priority !== priority && setPriority("priority", item.priority)}
        >
          <TextFont style={styles.title}>
            <FormattedMessage id={item.title} />
          </TextFont>
        </Button>
      ))}
    </div>
  </section>
);

const styles = {
  container: {
    flex: 1,
  },
  main: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  mainTitle: {
    fontSize: 18,
    fontWeight: "700",
  },
  title: {
    fontSize: 18,
    fontWeight: "400",
    "text-align": "center",
    margin: 6,
  },
};

const Button = styled.button<{ isBorder: boolean }>`
  width: 32%;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.extra.lighGrey};
  ${(props) =>
    props.isBorder &&
    `   
        backgroundColor: ${props.theme.extra.lighGrey};
        border: 1px solid ${props.theme.extra.background.green};
       `}
`;
