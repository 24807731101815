import React, { useRef } from "react";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { useTask } from "@hooks/useTask";
import { create as createTask } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { Notification } from "@ui/notification/Notification";
import { toFormatDate } from "@utils/toFormatTime";

import { TypeKey } from "./constants";
import { useStartData } from "./useStartData";

interface ICreateTask {
  priority: TypeKey;
  setIsCreating: (arg: TypeKey | undefined) => void;
}

export const CreateTask = ({ priority, setIsCreating }: ICreateTask) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { data } = useStartData(priority);
  const { name, setText, text, onChangeTitle, setIsErrorTitle, isErrorTitle, handleSaveTitle } = useTask({});

  const dateNow = useSelector(selectCurrentDate);

  const handleSave = async () => {
    if (!name) return setIsCreating(undefined);

    const tagId = await handleSaveTitle();

    if (tagId === null) return;

    await dispatch(createTask({ body: { ...data, name, tagId }, isAdd: toFormatDate(dateNow) === data.startDate, isPersonalTask: false }));
    setText("");
    setIsErrorTitle(false);
    inputRef.current.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key === "Enter" && handleSave();
  };

  const placeholder = useIntl().formatMessage({ id: "tagtask" });

  return (
    <>
      <input
        value={text}
        onChange={(e) => onChangeTitle(e.target.value)}
        placeholder={placeholder}
        autoFocus
        onBlur={() => handleSave()}
        onKeyDown={handleKeyDown}
        style={styles.container}
        ref={inputRef}
      />

      {isErrorTitle ? <Notification type="clear" phraseId="tagDescription" phraseOkId="clear" onOk={() => setIsCreating(undefined)} /> : ""}
    </>
  );
};

const styles = {
  container: {
    height: "100%",
    marginLeft: "7px",
  },
};
