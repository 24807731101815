import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDimension } from "@hooks/useDimension";
import { Layout } from "@layout";
import { selectIsModeAssistant } from "@store/user";

import { Header, NotesTablet, TargetBusiness, TargetMounth, TargetSelf } from "./components";

export const Month = () => {
  const { isTablet } = useDimension();
  const navigate = useNavigate();

  const isModeAssistant = useSelector(selectIsModeAssistant);

  useEffect(() => {
    if (isModeAssistant) navigate("/");
  }, [isModeAssistant]);

  const mob = () => (
    <section style={{ height: "100%" }}>
      <TargetMounth />
      <div style={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100%", overflowY: "auto", marginTop: -15 }}>
        <TargetSelf />
        <TargetBusiness />
      </div>
    </section>
  );

  const tablet = () => (
    <div style={styles.containerTablet}>
      <div style={styles.leftSide}>
        <TargetMounth />
        <div style={{ height: "78%" }}>
          <TargetSelf />
          <TargetBusiness />
        </div>
      </div>
      <div style={styles.rightSide}>
        <NotesTablet />
      </div>
    </div>
  );

  return <Layout header={<Header />}>{isTablet ? tablet() : mob()}</Layout>;
};

const styles = {
  containerTablet: {
    height: "100%",
    display: "flex",
    "flex-direction": "row",
  },
  leftSide: {
    width: "50%",
    marginRight: "1%",
    backgroundColor: "#F5F9F8",
    "overflow-y": "auto",
  },
  rightSide: {
    width: "50%",
    marginLeft: "1%",
    backgroundColor: "#F5F9F8",
    "overscroll-y": "auto",
    height: "100%",
  },
};
