import ReactLoading from "react-loading";
import styled from "styled-components";

import globalState from "@utils/globalState";

interface IProps {
  withoutBlur?: boolean | undefined;
  posY?: string;
  height?: number;
  width?: number;
}

export const LoaderButton = ({ posY, withoutBlur, height = 15, width = 15 }: IProps) => (
  <LoaderContainer posY={posY ?? "0px"} withoutBlur={withoutBlur}>
    <ReactLoading type="spokes" color={globalState.colorSchema.green} height={height} width={width} />
  </LoaderContainer>
);

const LoaderContainer = styled.section<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: ${(props) => props.posY};
  bottom: 0px;
  background-color: "transparent";
`;
