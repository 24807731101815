import { useMemo, useState, useCallback } from "react";

import { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { IGoal, TypeStatusGoal } from "@interfaces/businessGoals.interface";
import { continueGoal, moveDate, updateStatus } from "@store/businessGoals";
import { moveMonth } from "@store/businessGoals/api";
import { selectYearMonth } from "@store/date";
import { showToast } from "@utils/toast";

export const useStartAction = (goal: IGoal) => {
  const dispatch = useDispatch();

  const [isVisibleMove, setIsVisibleMove] = useState(false);
  const [isVisibleContinue, setIsVisibleContinue] = useState(false);

  const currentDate = useSelector(selectYearMonth);

  const generateToastDisable = useCallback(
    (yearMonth: Dayjs, beforeMsg: string, afterMsg: string) => {
      if (yearMonth.isBefore(currentDate, "month")) {
        return showToast(beforeMsg);
      }

      if (yearMonth.isAfter(currentDate, "month")) {
        return showToast(afterMsg);
      }
    },
    [currentDate]
  );

  const handleStatus = useCallback(
    (status: TypeStatusGoal) => {
      dispatch(updateStatus({ id: goal.id, status }));
    },
    [dispatch, goal.id]
  );

  const handleMove = useCallback(
    (yearMonth: Dayjs, isDisabled: boolean) => {
      if (isDisabled) {
        return generateToastDisable(yearMonth, "transferAlert", "transferAlertBusy");
      }

      const formattedYearMonth = yearMonth.format("YYYY-MM");

      if (goal.yearMonths.includes(formattedYearMonth)) return;

      if (goal.yearMonths.length > 1) {
        dispatch(moveMonth({ id: goal.id, fromYearMonth: currentDate, toYearMonth: formattedYearMonth }));
      } else {
        dispatch(moveDate({ id: goal.id, yearMonth: formattedYearMonth }));
      }

      setIsVisibleMove(false);
      return true;
    },
    [currentDate, dispatch, generateToastDisable, goal.id, goal.yearMonths]
  );

  const handleContinue = useCallback(
    (yearMonth: Dayjs, isDisabled: boolean) => {
      if (isDisabled) {
        return generateToastDisable(yearMonth, "continueAlert", "transferAlertBusy");
      }

      dispatch(continueGoal({ id: goal.id, yearMonth: yearMonth.format("YYYY-MM") }));
      setIsVisibleContinue(false);
      return true;
    },
    [dispatch, generateToastDisable, goal.id]
  );

  return useMemo(
    () => ({
      isVisibleMove,
      setIsVisibleMove,
      isVisibleContinue,
      setIsVisibleContinue,
      handleContinue,
      currentDate,
      handleStatus,
      handleMove,
    }),
    [isVisibleContinue, isVisibleMove, currentDate, handleStatus, handleMove]
  );
};
