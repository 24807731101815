import React, { Dispatch, FC, SetStateAction } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgCheckList } from "@assets/checklist.svg";
import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { ModalUI, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IAddWorkDaysReminder {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setIsVisibleAddDay: Dispatch<SetStateAction<boolean>>;
}

export const AddWorkDaysReminder: FC<IAddWorkDaysReminder> = ({ isVisible, setIsVisible, setIsVisibleAddDay }) => {
  const style = styles();

  const handleAddDays = () => {
    setIsVisible(false);
    setIsVisibleAddDay(true);
  };

  return (
    <ModalUI isVisible={isVisible} onClose={() => setIsVisible(false)}>
      <div style={style.modalInnerContainer}>
        <button onClick={() => setIsVisible(false)} style={style.roundContainer}>
          <SvgClose />
        </button>
        <div style={style.container}>
          <SvgCheckList />
          <TextFont style={style.descriptionText}>
            <FormattedMessage id="taskWorkDaysAddDescription" defaultMessage={"Не забудьте распланировать работу над задачей "} />
          </TextFont>

          <button style={style.buttonAddWorkDays} onClick={handleAddDays}>
            <TextFont size={18} type="bold" weight="700" color={"white"}>
              <FormattedMessage id="taskWorkDaysAdd" defaultMessage={"Добавить дни работы"} />
            </TextFont>
          </button>

          <button style={style.buttonPlanLater} onClick={() => setIsVisible(false)}>
            <TextFont size={18} type="regular" weight="400" color={globalState.colorSchema.green}>
              <FormattedMessage id="planLater" defaultMessage={"Планировать позже"} />
            </TextFont>
          </button>
        </div>
      </div>
    </ModalUI>
  );
};

const styles = () => {
  const theme = globalState.colorSchema;

  return {
    roundContainer: {
      display: "flex",
      position: "absolute",
      top: 10,
      right: 12,
      alignItems: "center",
      justifyContent: "center",
      width: 30,
      height: 30,
      borderRadius: 15,
      backgroundColor: theme.disabled,
    },
    modal: {
      backgroundColor: theme.white,
      borderRadius: 10,
      justifyContent: "flex-start",
      paddingVertical: 20,
      position: "absolute",
      width: "100%",
      bottom: 0,
    },
    modalInnerContainer: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      paddingLeft: 16,
      paddingRight: 16,
      marginBottom: 20,
    },
    container: {
      display: "flex",
      "flex-direction": "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 70,
    },
    descriptionText: {
      marginTop: 20,
      fontSize: 18,
      fontWeight: "400",
      textAlign: "center",
    },
    buttonAddWorkDays: {
      display: "flex",
      borderRadius: 10,
      paddingVertical: 16,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: 46,
      height: 56,
      backgroundColor: theme.background.green,
    },
    buttonPlanLater: {
      marginTop: 30,
    },
    planLaterBtnText: {
      color: theme.background.green,
    },
  };
};
