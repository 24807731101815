import { createSelector } from "@reduxjs/toolkit";

import { TypeKeysPersonalGoal } from "@interfaces/personalGoals.interface";
/* eslint-disable function-paren-newline */

import { RootState } from "@store";

const categories: TypeKeysPersonalGoal[] = [
  "FAMILY_AND_HOME",
  "FINANCE_AND_CAREER",
  "PHYSICAL_CONDITION_AND_HEALTH",
  "DEVELOPMENT_AND_SELF_EDUCATION",
  "SOCIAL_AND_CULTURAL",
  "SPIRITUAL_AND_ETHICAL",
];

export const selectPersonalGoals = (state: RootState) => state.personalGoals;

export const personalFillGoalsSelector = createSelector(selectPersonalGoals, (goals) =>
  goals.goals.filter((item) => item.personalTasks?.length)
);

export const personalEmptyGoalsSelector = createSelector(selectPersonalGoals, (goals) => {
  if (!goals.goals.length) return categories;

  const tempCategories = [...categories];

  goals.goals.map((item) => {
    const index = tempCategories.findIndex((i) => i === item.category);

    if (index !== -1 && item.personalTasks?.length) {
      tempCategories.splice(index, 1);
    }
  });

  return tempCategories;
});

export const selectCurrentGoal = (id: string) =>
  createSelector(selectPersonalGoals, (goals) => goals.goals.filter((item) => item.id === id)[0]);
/* eslint-enable */
