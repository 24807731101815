import { createAsyncThunk } from "@reduxjs/toolkit";

import { sessionService } from "@services";
import { api } from "@services/api";
import { getError } from "@store/getError";

export const getList: any = createAsyncThunk("session/getList", async (thunkAPI) => {
  try {
    const response = await sessionService.getList();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const removeSession: any = createAsyncThunk("session/removeSession", async (ids: string[], thunkAPI) => {
  try {
    await sessionService.remove(ids);
    return { ids };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const removeCurrentSession: any = createAsyncThunk("session/removeCurrentSession", async (_, thunkAPI) => {
  try {
    await sessionService.removeCurrent();

    thunkAPI.dispatch(api.util.invalidateTags([]));
  } catch (error: unknown) {
    return getError(error, thunkAPI);
  }
});
