export const actionsToResetCalendar = [
  "removeMeetingStatus",
  "updateMeetingStatus",
  "deleteMeet",
  "deleteMeetSerie",
  "deleteMeetFromSerie",
  "setParticipantStatus",
  "update",
  "create",
  "deleteTask",
  "complete",
  "close",
  "pause",
  "clone",
  "moveDate",
  "workDayCancelComplete",
  "addWorkDay",
  "forgetAllTasks",
  "takeToWork",
  "delegate",
  "takeMe",
  "reopen",
];

export const actionsToResetExternalUsers = ["createExternalUser"];

export const actionsToHardResetAllCache = ["changeExtraTheme"];
