interface IConfigButtons {
  id: number;
  label: "Месяц" | "День" | "Все задачи" | "Еще";
  path: any;
  isDisabledAssistant?: boolean;
}

export const configButtons: IConfigButtons[] = [
  {
    id: 0,
    label: "Месяц",
    path: "month",
    isDisabledAssistant: true,
  },
  {
    id: 1,
    label: "День",
    path: "",
  },
  {
    id: 2,
    label: "Все задачи",
    path: "tasks",
  },
  {
    id: 3,
    label: "Еще",
    path: "profile",
  },
];
