import { memo, FC, useRef, useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgMeet } from "@assets/meet.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { Modal } from "@screens/day/components/timetable/main/modal/Modal";
import { IAttachEventMeta } from "@services/meet.service";
import { AttachEventsEventType, unattachEvent } from "@store/attachedMeetingTasks/api";
import { attachEvents } from "@store/attachedMeetingTasks/api";
import { getSelectedMeetings, selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import {
  removeSelectedItemId,
  addSelectedItemId,
  addSelectedMeetings,
  removeSelectedMeetingById,
  addEventsMeta,
  removeItemFromEventsMeta,
} from "@store/attachedMeetingTasks/slice";
import { selectTheme } from "@store/theme";
import { commonStyles } from "@styles/styles";
import { TextFont } from "@ui";
import { ArrowSvg } from "@ui";
import globalState from "@utils/globalState";

import { EventItem } from "./EventItem";
import { ModalMeetingAttach } from "./ModalMeetingsList";

interface ITaskMeetings {
  allowMeetingsAttach?: boolean;
  taskId: string;
  initialTaskIds?: string[];
  onlyButton?: boolean;
  closeOverModal?: () => void;
  enableAddButton?: boolean;
  disable?: { value: boolean; press: () => void };
}

export const TaskMeetings: FC<ITaskMeetings> = memo(
  ({ allowMeetingsAttach: allowTaskAddition, taskId, closeOverModal, onlyButton = false, enableAddButton = true, disable }) => {
    const dispatch = useDispatch();
    const { errorMsg } = useSelector(selectAttachedEvents);
    const attachedMeetings = useSelector(getSelectedMeetings);
    const [isDetailedMeetingViewModalVisible, setIsDetailedMeetingViewModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const currenDetailedMeetingId = useRef("");
    const uniqueIds = new Set<string>();
    const theme = selectTheme("extra");

    const openMeetingDetailedPage = (meetingId: string) => {
      currenDetailedMeetingId.current = meetingId;
      setIsDetailedMeetingViewModalVisible(true);
    };

    const handleAttachItems = (itemsEntities: IMeet[], eventsMeta: IAttachEventMeta[]) => {
      dispatch(addSelectedMeetings(itemsEntities));

      for (const item of eventsMeta) {
        uniqueIds.add(item.id);
      }

      for (const id of Array.from(uniqueIds)) {
        dispatch(addSelectedItemId(id));
      }

      if (taskId && eventsMeta.length) {
        dispatch(attachEvents({ eventId: taskId, eventsMeta, eventType: AttachEventsEventType.MeetingsToTask }));
        return;
      }

      dispatch(addEventsMeta(eventsMeta));
      if (closeOverModal) closeOverModal();
    };

    const onEventDelete = (eventId: string) => {
      if (taskId) {
        dispatch(unattachEvent([{ meetingId: eventId, taskId }]));
      }

      dispatch(removeSelectedItemId(eventId));
      dispatch(removeSelectedMeetingById(eventId));
      dispatch(removeItemFromEventsMeta(eventId));
    };

    useEffect(() => {
      if (errorMsg) {
        for (const id of Array.from(uniqueIds)) {
          onEventDelete(id);
        }
        uniqueIds.clear();
      }
    }, [errorMsg]);

    const onPress = () => {
      if (disable?.value) {
        return disable.press();
      }
      return setIsModalVisible(true);
    };

    const renderList = attachedMeetings.map((item, index) => {
      const renderItem = (item: IMeet) => (
        <EventItem
          key={item.id}
          item={item}
          allowDeletion={allowTaskAddition}
          onDelete={() => onEventDelete(item.id)}
          date={""}
          onPress={openMeetingDetailedPage}
        />
      );
      return renderItem(item);
    });

    return (
      <>
        {attachedMeetings.length && !onlyButton ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextFont style={modalStyles.mainH1}>
              <FormattedMessage id="taskAppointments" />
            </TextFont>
            <RenderWithCondition condition={attachedMeetings.length}>
              <div style={styles.list}>{renderList}</div>
            </RenderWithCondition>
            <RenderWithCondition condition={allowTaskAddition}>
              <button style={commonStyles.billet} onClick={onPress}>
                <TextFont style={{ ...modalStyles.blockTurnText, color: theme.text.accent }}>
                  {"+ "}
                  <FormattedMessage id="addMeeting" defaultMessage={"Добавить встречу"} />
                </TextFont>
                <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
              </button>
            </RenderWithCondition>
          </div>
        ) : (
          enableAddButton && (
            <button style={{ padding: 8, display: "flex", alignItems: "center" }} onClick={() => setIsModalVisible(true)}>
              <SvgMeet width={17} height={15} fill={globalState.colorSchema.placeholder.main} />
              <TextFont
                color={onlyButton ? globalState.colorSchema.text.main : globalState.colorSchema.placeholder.main}
                style={{ marginLeft: 8 }}
                size={onlyButton ? 16 : 18}
              >
                <FormattedMessage id="meetingAdd" />
              </TextFont>
            </button>
          )
        )}
        <RenderWithCondition condition={isModalVisible}>
          <ModalMeetingAttach
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
            handleAttachItems={handleAttachItems}
            onItemDelete={onEventDelete}
          />
        </RenderWithCondition>

        {/* Open meeting detailed page */}
        <RenderWithCondition condition={isDetailedMeetingViewModalVisible}>
          <Modal
            isVisible={isDetailedMeetingViewModalVisible}
            meetId={currenDetailedMeetingId.current}
            setIsVisible={setIsDetailedMeetingViewModalVisible}
          />
        </RenderWithCondition>
      </>
    );
  },
);

const styles = {
  list: {
    display: "flex",
    "flex-direction": "column",
    gap: 8,
    marginTop: 8,
  },
};
