import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";

import { RenderWithCondition } from "@hoc";
import { TextFont, Billet } from "@ui";

import { modalStyles } from "../../../../styles";
import { CommonFields } from "../types";

import { ModalProject } from "./modalProject/ModalProject";

interface IProps extends CommonFields {
  name: string;
  bg?: string;
  styleText?: any;
  styleButton?: any;
  memoizeProjectName?: React.Dispatch<React.SetStateAction<string | null>>;
}

export const Project: FC<IProps> = ({ name, memoizeProjectName, handleData, bg, styleButton, styleText }) => {
  const [isVisibleProject, setIsVisibleProject] = useState(false);
  const [projectName, setProjectName] = useState<string | null>(name);

  useEffect(() => {
    if (!projectName || !memoizeProjectName) return;

    memoizeProjectName(projectName);
  }, [projectName]);

  return (
    <section style={{ display: "flex", flexDirection: "column" }}>
      <TextFont type="bold" style={{ ...modalStyles.mainH1, ...styleText }}>
        <FormattedMessage id="project" />
      </TextFont>
      <Billet
        textId={projectName ? projectName : "nothingSelected"}
        styleButton={{ backgroundColor: bg, ...styleButton }}
        styleText={{ fontWeight: "400" }}
        onPress={() => setIsVisibleProject(true)}
      />

      <RenderWithCondition condition={isVisibleProject}>
        <ModalProject
          isVisible={isVisibleProject}
          close={() => setIsVisibleProject(false)}
          projectName={projectName ?? ""}
          handleProject={handleData}
          handleProjectName={setProjectName}
        />
      </RenderWithCondition>
    </section>
  );
};
