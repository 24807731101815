import { ReactComponent as SvgPromtAnalysis } from "./assets/promptAnalysis.svg";
import { ReactComponent as SvgPromtDay } from "./assets/promptDay.svg";
import { ReactComponent as SvgPromtTransfer } from "./assets/promptTransfer.svg";

export type Kind = "day" | "transfer" | "analysis";

export const img: Record<Kind, JSX.Element> = {
  day: <SvgPromtDay />,
  transfer: <SvgPromtTransfer />,
  analysis: <SvgPromtAnalysis />,
};

export const title: Record<Kind, string> = {
  day: "Действия с задачей",
  transfer: "Действия с задачей предыдущих дней",
  analysis: "Действия с задачей в анализе дня",
};
