import React, { FC } from "react";

import { LoaderButton } from "@ui/LoaderButton";

interface IProps {
  condition: boolean;
  children: JSX.Element;
  loaderComponent?: JSX.Element;
}

export const LoaderButtonRenderWithCondition: FC<IProps> = ({ condition, children, loaderComponent = <LoaderButton /> }) => {
  if (condition) {
    return <div style={{ position: "relative", width: "100%" }}>{loaderComponent}</div>;
  }

  return children;
};
