import React from "react";

import { ReactComponent as SvgCreate } from "@assets/create.svg";
import { selectTheme } from "@store/theme";

interface IProps {
  press: () => void;
  bottom: number | string;
  right: number | string;
}

export const AddCircle = ({ press, bottom, right }: IProps) => {
  const theme = selectTheme("extra");
  return (
    <button style={{ bottom, right, position: "absolute", zIndex: 990 }} onClick={press}>
      <SvgCreate fill={theme.background.green} width={55} height={55} />
    </button>
  );
};
