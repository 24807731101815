import React, { FC, useState } from "react";

import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { ModalRenderWithCondition } from "@hoc";
import { workDayComment } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { toFormatDate } from "@utils";

interface Props {
  close: () => void;
  id?: string;
  isPersonalTask: boolean;
}

export const Comment: FC<Props> = ({ close, id, isPersonalTask = false }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [isCancel, setIsCancel] = useState(false);

  const date = toFormatDate(useSelector(selectCurrentDate));

  const handleSave = () => {
    dispatch(workDayComment({ id, body: { date, comment }, isPersonalTask }));
    close();
  };

  const phComment = useIntl().formatMessage({ id: "comment" });

  return (
    <ModalUI isVisible={true} onClose={comment ? () => setIsCancel(true) : () => close()}>
      <HeaderModal
        title="comment"
        isEdit={!!comment}
        onSave={handleSave}
        onCancel={() => setIsCancel(true)}
        onClose={close}
        isBorder={false}
      />

      <div style={styles.container}>
        <textarea
          style={styles.input}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={phComment}
          maxLength={255}
        />
      </div>

      <ModalRenderWithCondition condition={isCancel}>
        <NotificationConfirm phraseId="cancelException" onOk={close} onCancel={() => setIsCancel(false)} />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const styles = {
  container: {
    padding: "30px 20px",
  },
  input: {
    borderRadius: 9,
    backgroundColor: "white",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    fontSize: 18,
    minHeight: 105,
    width: "93%",
  },
};
