import React, { FC, useEffect } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { TTypePriorityTask } from "@interfaces/businessGoals.interface";
import { TextFont } from "@ui";
import { maxLinesInput } from "@utils";

import { CommonFields } from "../types";
import { Colors } from "@theme/colors";

interface IProps extends CommonFields {
  name: string;
  description: string;
  priority: TTypePriorityTask;
  autoFocusName: boolean;
  text: string;
  outsidePress: () => void;
  onChangeTitle: (arg: string) => void;
}

export const Inputs: FC<IProps> = ({ name, description, handleData, priority, autoFocusName, text, outsidePress, onChangeTitle }) => {
  useEffect(() => {
    handleData("name", name);
  }, [name]);

  const changeDesc = (val: string) => {
    handleData("description", val);
  };

  const changeTitle = (val: string) => {
    if (maxLinesInput(val)) {
      onChangeTitle(val);
    }
  };

  const textAreaAdjust = (element) => {
    element.target.style.height = "0.1px";
    element.target.style.height = `${element.target.scrollHeight}px`;
  };

  const placeholderTaskName = `${useIntl().formatMessage({ id: "tagtask" })}`;
  const placeholderTaskDescription = useIntl().formatMessage({
    id: priority === "CONTACT" ? "phoneNumber" : "taskDescroption",
  });

  return (
    <Container>
      <SubInput>
        <RenderWithCondition condition={!text}>
          <LocalPlaceHolder>
            <TextFont color={Colors.LIGHT.placeholder.system} type="bold" size={18}>
              <FormattedMessage id="nameTask" defaultMessage={"Наименование задачи"} />
            </TextFont>
            <TextFont color={Colors.LIGHT.placeholder.system} type="bold" size={12}>
              {placeholderTaskName}
            </TextFont>
          </LocalPlaceHolder>
        </RenderWithCondition>
        <textarea
          style={{ ...styles.input, ...styles.taskName, paddingTop: 12 }}
          value={text}
          onChange={(e) => changeTitle(e.target.value)}
          maxLength={100}
          onBlur={() => outsidePress()}
          autoFocus={autoFocusName}
          onKeyUp={textAreaAdjust}
        />
      </SubInput>

      <SubInput>
        <textarea
          style={{ ...styles.input, ...styles.taskDescription }}
          placeholder={placeholderTaskDescription}
          value={description}
          onChange={(e) => changeDesc(e.target.value)}
          onKeyUp={textAreaAdjust}
        />
      </SubInput>
    </Container>
  );
};

const styles = {
  input: {
    backgroundColor: "transparent",
    borderRadius: "9px",
    paddingLeft: "16px",
    fontSize: "16px",
    width: "94%",
    paddingRight: "8px",
    paddingBottom: "8px",
    "z-index": "5",
  },

  taskName: {
    minHeight: "72px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  taskDescription: {
    minHeight: "56px",
    paddingTop: "16px",
  },
};

const SubInput = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #ffff;
  border-radius: 9px;
  z-index: 1;
`;
const LocalPlaceHolder = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 5px;
  top: 19px;
  left: 18px;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  margin: 8px 0px 8px 0px;
  gap: 8px;
  flex-direction: column;
  position: relative;
`;
