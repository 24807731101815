import React, { FC } from "react";

import { isFulfilled } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Routes, useLocation, useNavigate } from "react-router-dom";

import emitter from "@services/emitter";
import { checkAuth } from "@store/auth";

interface IProps {
  children: React.ReactNode;
  publicPaths?: string[];
  authPaths?: string[];
}

const ExtendedRoutes: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const isPublicLocation = (props.publicPaths ?? []).includes(location.pathname);
    const isAuthLocation = (props.authPaths ?? []).includes(location.pathname);

    (async () => {
      if (isAuthLocation) {
        const isAuth = isFulfilled(await dispatch(checkAuth()));
        if (isAuth) {
          emitter.emit("login");

          navigate("/");
        }
      } else if (!isPublicLocation) {
        const isAuth = isFulfilled(await dispatch(checkAuth()));
        if (!isAuth) {
          navigate("/auth");
        }
      }
    })();
  }, [location]);

  return <Routes>{props.children}</Routes>;
};

export default ExtendedRoutes;
