import { useState, useEffect, useMemo } from "react";

const START_WIDTH_TABLET = 768;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useDimension = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isTablet = useMemo(() => windowDimensions.width >= START_WIDTH_TABLET, [windowDimensions.width]);

  return { windowDimensions, isTablet };
};
