import React, { FC, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { Deadline } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components";
import { Calendar } from "@shared/planningWork";
import { Billet, TextFont, ModalUI, HeaderModal } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import globalState from "@utils/globalState";
import { toFormatDate, toFormatISO } from "@utils/toFormatTime";

import { titleType } from "../../constants";

import { DayOfWeek, EmptyCalendar, RepeatInfo, RepeatType } from "./components";
import { arrayPostpone, configPeriod } from "./config";
import { useData } from "./useData";
import { renderResult } from "./utils";

interface IProps {
  close: () => void;
  meet: IMeet;
  changeMeet: (name: string, value: any) => void;
}

export const Repeat: FC<IProps> = ({ close, meet, changeMeet }) => {
  const {
    data,
    handleData,
    isCancel,
    setIsCancel,
    isNotValidPeriod,
    isEdit,
    isVisibleType,
    setIsVisibleType,
    isRenderResult,
    dateNow,
    setData,
  } = useData({
    meet,
  });

  const handleSave = () => {
    changeMeet("repeat", {
      ...data,
      postpone: data.type === "WEEK" ? null : data.postpone,
      endTime: data.endTime ? toFormatISO(dayjs(`${data.endTime}23:59`)) : null,
    });
    close();
  };

  const onPressDay = (dateWeek: Dayjs) => {
    let tempDays = [...(data.days ?? [])];

    if (data.days?.includes(toFormatDate(dateWeek))) {
      tempDays = tempDays.filter((i) => i !== toFormatDate(dateWeek));
    } else {
      tempDays = [...tempDays, toFormatDate(dateWeek)];
    }
    handleData("days", tempDays);
  };

  const reset = () => {
    changeMeet("repeat", null);
    close();
  };

  const handleInputPeriod = (value: string, max: number) => {
    if (Number(value) > max) {
      handleData("repetitionPeriod", max);
    } else {
      handleData("repetitionPeriod", value);
    }
  };

  const propsForCalendar = {
    type: "multituple",
    days: data.days ? data.days.map((i) => ({ date: i })) : [],
    press: onPressDay,
    currentDay: toFormatDate(dateNow),
    startDay: toFormatDate(dateNow),
    deadline: toFormatDate(dayjs(data.endTime ?? "")),
  };

  return (
    <ModalUI isVisible={true} onClose={isEdit ? () => setIsCancel(true) : () => close()}>
      <HeaderModal title="repeat" onClose={close} isEdit={isEdit} onCancel={() => setIsCancel(true)} onSave={handleSave} />

      <div style={styles.container}>
        <Billet textId={titleType[data?.type ?? "WEEK"]} onPress={() => setIsVisibleType(true)} />

        <RenderWithCondition condition={data.type !== "YEAR"}>
          <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
            <TextFont size={18}>{configPeriod[data.type]?.every}</TextFont>
            <input
              value={String(data.repetitionPeriod)}
              maxLength={2}
              max={configPeriod[data.type]?.max}
              min={1}
              type="number"
              style={{
                ...styles.period,
                border: `1px solid ${isNotValidPeriod ? "red" : globalState.colorSchema.background.grey}`,
              }}
              onChange={(e) => handleInputPeriod(e.target.value, configPeriod[data.type]?.max as number)}
            />
            <TextFont size={18}>{configPeriod[data.type]?.subject}</TextFont>
          </div>
        </RenderWithCondition>

        <RenderWithCondition condition={data?.type === "WEEK"}>
          <DayOfWeek handleData={handleData} days={data?.dayOfWeeks ?? []} />
        </RenderWithCondition>

        <RenderWithCondition condition={data?.type !== "WEEK"}>
          <div>
            <TextFont size={18} type="bold" style={{ marginBottom: 12 }}>
              Выберите даты повтора
            </TextFont>
            {data.type === "MONTH" ? <EmptyCalendar {...propsForCalendar} /> : <Calendar {...propsForCalendar} />}
          </div>
        </RenderWithCondition>

        <Deadline
          handleData={handleData}
          date={data.endTime ?? ""}
          startDate={toFormatDate(dateNow)}
          isVisibleTooltip={false}
          field="endTime"
          titleId="repeatAbout"
        />
        <RenderWithCondition condition={data?.type !== "WEEK"}>
          <RepeatInfo textInfo="infoForRepeatMeetings" />
        </RenderWithCondition>

        <RenderWithCondition condition={isRenderResult}>
          <div>
            <TextFont type="bold" style={{ fontSize: 18, marginBottom: 6 }}>
              <FormattedMessage id="whatResult" />:
            </TextFont>
            <TextFont style={{ fontSize: 18, lineHeight: "24px" }}>
              {renderResult({
                type: data.type,
                endTime: data.endTime,
                days: data.dayOfWeeks,
                daysRepeat: data?.days ?? [],
                period: data.repetitionPeriod,
              })}
            </TextFont>
          </div>
        </RenderWithCondition>

        <RenderWithCondition condition={data?.days?.length && data.type !== "WEEK"}>
          <div>
            <TextFont size={18} type="bold">
              <FormattedMessage id="weekendMeetingDescription" />
            </TextFont>

            <div style={{ marginTop: 12 }}>
              {arrayPostpone.map((postpone) => (
                <button key={postpone.title} style={styles.radioButton} onClick={() => handleData("postpone", postpone.value)}>
                  <TextFont size={18}>
                    <FormattedMessage id={postpone.title} />
                  </TextFont>
                  <input
                    type="radio"
                    style={{ accentColor: "#219653" }}
                    onClick={() => handleData("postpone", postpone.value)}
                    value={postpone.value}
                    checked={postpone.value === data.postpone}
                  />
                </button>
              ))}
            </div>
          </div>
        </RenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleType}>
          <RepeatType changeMeet={setData} close={() => setIsVisibleType(false)} type={data.type} reset={reset} />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isCancel}>
          <NotificationConfirm phraseId="cancelException" onOk={close} onCancel={() => setIsCancel(false)} />
        </ModalRenderWithCondition>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    gap: 25,
    padding: "10px 12px 0 12px",
    minHeight: "95%",
    overflow: "auto",
  },
  radioButton: {
    padding: 10,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  period: {
    width: 79,
    height: 48,
    backgroundColor: "white",
    borderRadius: 10,
    "text-align": "center",
    paddingLeft: 12,
    fontSize: 18,
  },
};
