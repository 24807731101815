import React, { FC } from "react";

import { RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces/index";
import { IStateUser } from "@interfaces/user.interface";
import { userAvatarStyles } from "@shared/userAvatar";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { TextFont } from "@ui";

interface IProps {
  item: IStateUser;
  setSelectUser: (arg: IStateUser | undefined) => void;
  close: () => void;
  taskId?: string;
  isModal: boolean;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  isNeedCloseAfterSave: boolean;
  proceedDelegation: (uid: string) => void;
}

export const Item: FC<IProps> = ({ item, setSelectUser, close, taskId, onSave, isNeedCloseAfterSave = true, proceedDelegation }) => {
  const pressUser = (user: InterfaceUser.IStateUser) => {
    if (isNeedCloseAfterSave) {
      close();
    }
    if (onSave) {
      onSave(user);
    } else if (taskId) {
      proceedDelegation(user.id);
    }
  };

  const avatarPressHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (setSelectUser) {
      setSelectUser(item);
    }
  };

  const renderName = (user: IStateUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

  return (
    <button style={userAvatarStyles.userBlock} onClick={() => pressUser(item)}>
      <button onClick={avatarPressHandler}>
        <UserAvatar avatarFileId={item.avatarFileId} />
      </button>
      <div style={{ width: "80%" }}>
        <TextFont style={userAvatarStyles.userTitle}>{renderName(item)}</TextFont>

        <RenderWithCondition condition={item.workPosition}>
          <TextFont style={{ fontSize: 14, color: "#B3BEB8", textAlign: "left" }}>{item.workPosition}</TextFont>
        </RenderWithCondition>
      </div>
    </button>
  );
};
