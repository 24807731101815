import { createAsyncThunk } from "@reduxjs/toolkit";

import { SETTINGS } from "@constants/settings";
import { configureService } from "@services";
import { getError } from "@store/getError";
import { secureStore } from "@utils/secureStore";

import version from "../../appVersion.json";

export const getInfoVersion: any = createAsyncThunk("configure/getInfoVersion", async (thunkAPI) => {
  try {
    const response = await configureService.getInfoVersion(version.versionName);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getSettings: any = createAsyncThunk("configure/getSettings", async (thunkAPI) => {
  try {
    const settings = await secureStore.getValue(SETTINGS);
    return JSON.parse(settings);
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
