import React, { CSSProperties, FC, memo, useEffect, useState } from 'react';
import defaultAvatar from '@assets/avatar_temp.png';
import { fileService } from '@services/file.service';
import { RenderWithCondition } from '@hoc';
import {userAvatarStyles} from "@shared/userAvatar";
import {AvatarPreloader} from "@shared/userAvatar/LazyAvatar";

interface IUserAvatar {
  size?: number;
  avatarFileId?: string;
  containerStyles?: CSSProperties;
}

export const ProfileUserAvatar: FC<IUserAvatar> = memo(function UserAvatar({
 size = 95,
 avatarFileId,
 containerStyles,
}: IUserAvatar) {
  const [avatar, setAvatar] = useState<string>();
  const handleUserProfileAvatar = async () => {
    if (!avatarFileId) return;

    const avatarURI = await fileService.getAvatar(avatarFileId);

    setAvatar(avatarURI);
  };

  useEffect(() => {
    handleUserProfileAvatar();
  }, [avatarFileId]);

  return (
    <div style={containerStyles}>
      <RenderWithCondition condition={!avatarFileId}>
        <img src={defaultAvatar} alt='user avatar' style={{...userAvatarStyles.profileImage, ...{ width: size, height: size }}} />
      </RenderWithCondition>

      <RenderWithCondition condition={avatarFileId}>
        {avatar ? (
          <img src={avatar} style={{...userAvatarStyles.userImage, ...userAvatarStyles.profileImage}} alt='user avatar' />
        ) : (
          <AvatarPreloader  style={{...userAvatarStyles.userImage, ...userAvatarStyles.profileImage}}/>
        )}
      </RenderWithCondition>
    </div>
  );
});
