import React from "react";

import { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";

import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { Calendar } from "@shared/planningWork";
import { TextFont } from "@ui";

interface IProps {
  dateKey: string;
  closeEvent: () => void;
  handleDate: (key: string, value: Dayjs) => void;
  currentDay: string;
}

export const DateTab = ({ dateKey, closeEvent, handleDate, currentDay }: IProps) => (
  <>
    <button onClick={closeEvent} style={{ marginLeft: 12 }}>
      <TextFont style={{ ...modalStyles.headerTitle, margin: 0, fontWeight: "400" }}>
        <FormattedMessage id="close" />
      </TextFont>
    </button>
    <div style={{ padding: "20px 12px" }}>
      <Calendar
        press={(value) => handleDate(dateKey, value)}
        currentDay={currentDay}
        startDay={""}
        disable={{ style: false, button: false }}
      />
    </div>
  </>
);
