import React, { useMemo } from "react";
import { FC } from "react";

import { useSelector } from "react-redux";

import { useDimension } from "@hooks/useDimension";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { RootState } from "@store";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate } from "@store/screenDay";

import { Header } from "./header";
import Main from "./main/Main";

interface ITimeTable {
  handlePress: (arg: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  filteredEventsOfDay: (IEventOfDaySimplified | IEventOfDaySimplified[])[];
  isFetching: boolean;
}

export const Timetable: FC<ITimeTable> = (props) => {
  const { isTablet } = useDimension();
  const { startDate } = useSelector((state: RootState) => state.screenDay);
  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);
  const selectedDay = useSelector(selectCurrentDate);

  const isTightModeTablet = useMemo(() => isTightMode || !isTablet, [isTightMode, isTablet]);

  return (
    <>
      <div id="timetable" style={{ position: "relative", height: "100%" }}>
        {!isTightModeTablet && <Header startDate={startDate} selectedDay={selectedDay} />}
        <Main handlePress={props.handlePress} eventsOfDay={props.filteredEventsOfDay} isFetching={props.isFetching} />
      </div>
    </>
  );
};
