import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TextFont } from "@components/ui";
import globalState from "@utils/globalState";

interface IProps {
  currentTab: (value: boolean) => void;
  activeTab: boolean;
  isGroupAll?: boolean;
}
export const LineTabs: FC<IProps> = ({ currentTab, activeTab, isGroupAll }) => {
  const buttonLeftTextColor = !activeTab || isGroupAll ? globalState.colorSchema.white : globalState.colorSchema.text.main;
  const buttonRightTextColor = activeTab ? globalState.colorSchema.white : globalState.colorSchema.text.main;

  return (
    <LineWrap>
      <TabWrap onClick={() => !isGroupAll && activeTab && currentTab(!activeTab)} active={!activeTab} disabled={isGroupAll}>
        <TextFont className="justify-center" size="10px" weight="500" color={buttonLeftTextColor}>
          <FormattedMessage id="myGroups" />
        </TextFont>
      </TabWrap>
      <TabWrap onClick={() => !activeTab && currentTab(!activeTab)} active={activeTab}>
        <TextFont className="justify-center" size="10px" weight="500" color={buttonRightTextColor}>
          <FormattedMessage id="fixedGroups" />
        </TextFont>
      </TabWrap>
    </LineWrap>
  );
};

const LineWrap = styled.div`
  display: flex;
  position: relative;
  height: 26px;
  gap: 6px;
  padding: 7px 12px;
`;
const TabWrap = styled.div<{ active: boolean; disabled?: boolean }>`
  display: flex;
  gap: 9px;
  padding: 11px 24px;
  border-radius: 5px;
  border: 1px solid ${globalState.colorSchema.text.accent};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: ${globalState.colorSchema.white};
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: ${globalState.colorSchema.lighGrey};
  }
  &:active {
    background-color: ${globalState.colorSchema.grey};
  }
  ${({ active, disabled }) =>
    active &&
    !disabled &&
    `
    background-color: ${globalState.colorSchema.text.accent};
    transition: all 0.5s ease;
    &:hover {
    background-color: ${globalState.colorSchema.text.accent};
  }
   }
  `}
  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${globalState.colorSchema.grey500};
    opacity: 0.4;
    cursor: not-allowed;
    transition: all 0.5s linear;
    &:active {
    background-color: ${globalState.colorSchema.grey500};
    }
    &:hover {
    background-color: ${globalState.colorSchema.grey500};
   }
  `}
`;
