import React, { FC, useCallback, useState } from "react";

import dayjs from "dayjs";

import { ReactComponent as SvgDialog } from "@assets/dialog.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { THandleOpenWorkDay } from "../addWorkDaysReminder/useWorkDaysController";

import { Comment } from "./Comment";

export const bg = {
  COMPLETED: "#EFFAF4",
  NOT_COMPLETED: "#FFEFEF",
};

interface IProps {
  element: WorkDay;
  allowRemove?: boolean;
  press?: THandleOpenWorkDay;
  remove: (arg: string) => void;
}

export const RenderWorkDay: FC<IProps> = ({ element, press, remove, allowRemove = true }) => {
  const [isVisibleComment, setIsVisibleComment] = useState<boolean>(false);

  const displayDate = useCallback((i: WorkDay) => `${dayjs(i.startTime).format("HH:mm")}-${dayjs(i.endTime).format("HH:mm")}`, []);

  const handlePressDay = (date: string) => {
    if (!press) return;

    press(date, !!element.startTime);
  };

  return (
    <section style={{ ...styles.workDay, ...(element.status && { backgroundColor: bg[element.status] }) }}>
      <button disabled={!press} style={styles.main} onClick={() => handlePressDay(element.date)}>
        <TextFont color={globalState.colorSchema.text.main}>
          {`${dayjs(element.date).format("DD MMMM")} ${element.startTime && element.endTime ? displayDate(element) : ""}`}
        </TextFont>
      </button>

      {element.comment ? (
        <button style={styles.button} onClick={() => setIsVisibleComment(true)}>
          <SvgDialog />
        </button>
      ) : (
        <>
          <RenderWithCondition condition={allowRemove}>
            <button style={styles.button} onClick={() => remove(element.date)}>
              <SvgRemove fill={"#B3BEB8"} width={9} height={9} />
            </button>
          </RenderWithCondition>
        </>
      )}

      <ModalRenderWithCondition condition={isVisibleComment}>
        <Comment date={element.date} comment={element.comment} close={() => setIsVisibleComment(false)} />
      </ModalRenderWithCondition>
    </section>
  );
};

const styles = {
  workDay: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: "5px 0 5px 10px",
    borderRadius: "45px",
  },
  main: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
  },
  button: {
    display: "flex",
    paddingRight: "10px",
    height: "100%",
    justifyContent: "center",
    paddingLeft: "7px",
  },
};
