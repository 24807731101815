import { createAsyncThunk } from "@reduxjs/toolkit";

import { TypeKeysPersonalGoal, ITask } from "@interfaces/personalGoals.interface";
import { personalGoalsService } from "@services";
import { getError } from "@store/getError";

interface IGetGoal {
  category: TypeKeysPersonalGoal;
  yearMonth: string;
  tasks: ITask[];
}

interface IUpdateListTasks {
  goalId: string;
  tasks: ITask[];
}

export const getList: any = createAsyncThunk("personalGoals/getList", async (yearMonth: string, thunkAPI) => {
  try {
    const response = await personalGoalsService.getGoalList(yearMonth);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getGoal: any = createAsyncThunk("personalGoals/getGoal", async ({ category, yearMonth, tasks }: IGetGoal, thunkAPI) => {
  try {
    const response = await personalGoalsService.getGoal(category, yearMonth);
    const responseTasks = await personalGoalsService.updateListTasks(response.data.id, tasks);

    return { response, responseTasks, notifyText: "Цель сохранена!" };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateListTasks: any = createAsyncThunk(
  "personalGoals/updateListTasks",
  async ({ goalId, tasks }: IUpdateListTasks, thunkAPI) => {
    try {
      const response = await personalGoalsService.updateListTasks(goalId, tasks);

      return { response, goalId, notifyText: "Цель сохранена!" };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  }
);

export const statusTask: any = createAsyncThunk(
  "personalGoals/statusTask",
  async ({ id, status }: { id: string; status: boolean }, thunkAPI) => {
    try {
      const response = await personalGoalsService.statusTask(id, status);

      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  }
);
