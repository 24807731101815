import { useState, useMemo, useCallback, Dispatch, SetStateAction } from "react";

import { IMeetParticipants } from "@interfaces/meet.interface";
import { IExternalUser } from "@services/meet.service";

import { TFilter } from "./constants";

interface IPropsUseData {
  participants: IMeetParticipants[];
  externalUsers: IExternalUser[];
}

export type UseData = ({ participants, externalUsers }: IPropsUseData) => {
  resultParticipants: (IMeetParticipants | IExternalUser)[];
  allParticipants: number;
  filter: TFilter;
  setFilter: Dispatch<SetStateAction<TFilter>>;
};

export const useData: UseData = ({ participants, externalUsers }) => {
  const [filter, setFilter] = useState<TFilter>("all");

  const filteredParticipants: IMeetParticipants[] = useMemo(() => {
    if (!participants) return [];

    switch (filter) {
      case "all":
        return participants;
      case "attender":
        return participants?.filter((i) => i.status === "ATTENDER" || i.status === "OWNER");
      case "non_attender":
        return participants?.filter((i) => i.status === "NON_ATTENDER");
      case "maybe_attender":
        return participants?.filter((i) => i.status === "MAYBE_ATTENDER");
      case "non_answer":
        return participants?.filter((i) => !i.status);
      case "with_comment":
        return participants?.filter((i) => i.statusComment);
      default:
        return participants;
    }
  }, [participants, filter]);

  const resultParticipants = useMemo(() => {
    if (filter === "all" || filter === "non_answer") return [...filteredParticipants, ...(externalUsers ?? [])];
    return filteredParticipants;
  }, [externalUsers, filter, filteredParticipants]);

  const allParticipants = (participants?.length ?? 0) + (externalUsers?.length ?? 0);

  return {
    filter,
    setFilter,
    resultParticipants,
    allParticipants,
  };
};
