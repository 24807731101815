import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { InterfaceBusinessTask } from "@interfaces";
import { createPersonal, getPersonal } from "@store/personalTasks";
import { getPersonalSettings as getFilterSettings } from "@store/userSettings/api";
import { toFormatDate, deleteSearchParams } from "@utils";

import {
  create,
  getGroupedList,
  deleteTask,
  update,
  clone,
  moveDate,
  workDayComplete,
  getFilteredList,
  close,
  reopen,
  workDayNotComplete,
  get,
  createUnplanned,
  getStatistic,
  forgetWorkDay,
  workDayCancelComplete,
  paggingTransferredList,
  forgetAllTasks,
  paggingFilteredList,
  workDayNeedMoreTime,
  getForUpdate,
  delegate,
  takeToWork,
  markWorkDay,
  updatePosition,
  takeMe,
  createControl,
  pause,
  deleteWorkDay,
} from "./api";
import { defaultFilter, TaskSubtypes } from "./constants";
import { changeTask } from "./utils";

const initialState: InterfaceBusinessTask.IState = {
  isLoadingList: false,
  isLoadingTask: false,
  isLoadingPaggingAll: false,
  isLoadingGetTask: false,
  isOpenModalTask: false,
  errorMsg: null,
  tasks: [],
  singleSections: {
    transferred: { content: [], total: 0, isShowList: false },
    delegated: { content: [], total: 0, isShowList: false },
    takenBack: { content: [], total: 0, isShowList: false },
  },
  allTasks: { content: [], total: 0 },
  currentTask: null,
  search: "",
  filter: defaultFilter,
  statistic: null,
  isPersonalTask: false,
};

const businessTasks = createSlice({
  name: "businessTasks",
  initialState,
  reducers: {
    filterTaskListsByTaskId: (state, { payload }) => {
      state.tasks = state.tasks.filter((task) => task.id !== payload);
      state.singleSections.transferred.content = state.singleSections.transferred.content.filter((task) => task.id !== payload);
      state.singleSections.delegated.content = state.singleSections.delegated.content.filter((task) => task.id !== payload);
      state.singleSections.takenBack.content = state.singleSections.takenBack.content.filter((task) => task.id !== payload);
    },
    filterSingleSectionsByTaskId: (state, { payload }: PayloadAction<{ id: string; type: InterfaceBusinessTask.TSingleSections }>) => {
      state.singleSections[payload.type].content = state.singleSections[payload.type].content.filter((task) => task.id !== payload.id);
    },
    updateTaskId: (state, { payload }) => {
      state.isOpenModalTask = !!payload;
      if (!payload) {
        deleteSearchParams(["task", "personal"]);

        state.currentTask = null;
      }
    },
    setFilter: (state: InterfaceBusinessTask.IState, action: PayloadAction<Partial<InterfaceBusinessTask.IFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setGrouping: (state: InterfaceBusinessTask.IState, action: PayloadAction<InterfaceBusinessTask.TypeGroupedByTasks>) => {
      state.filter = { ...state.filter, groupByType: action.payload };
    },
    dropFilter: (state: InterfaceBusinessTask.IState, { payload }: PayloadAction<Partial<InterfaceBusinessTask.IFilter>>) => {
      state.filter = { ...defaultFilter, ...payload };
    },
    setSingleSectionShowList: (state, { payload }: PayloadAction<{ value: boolean; type: InterfaceBusinessTask.TSingleSections }>) => {
      state.singleSections[payload.type] = {
        ...state.singleSections[payload.type],
        isShowList: payload.value,
      };
    },
    setIsPersonalTask: (state, { payload }) => {
      state.isPersonalTask = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilterSettings.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(getFilterSettings.fulfilled, (state, { payload }) => {
        state.filter = { ...defaultFilter, ...payload.response.data.filter };
      })
      .addCase(getFilterSettings.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(getGroupedList.pending, (state, { meta }) => {
        state.isLoadingList = !meta.arg?.notLoader;
        state.errorMsg = null;
      })
      .addCase(getGroupedList.fulfilled, (state, { payload }) => {
        state.isLoadingList = false;

        /*
          Если запрос был отменен с фронта, выполнение падает сюда.
          Response не содержит data
        */
        if (!payload.response?.data) return;

        state.tasks = [
          ...(payload.response.data?.urgent ?? []),
          ...(payload.response.data?.important ?? []),
          ...(payload.response.data?.contact ?? []),
        ];
        state.singleSections = {
          transferred: {
            ...state.singleSections.transferred,
            content: payload.response?.data?.transferred?.content ?? [],
            total: payload.response?.data?.transferred?.total ?? 0,
          },
          delegated: {
            ...state.singleSections.delegated,
            content: payload.response?.data?.delegated?.content ?? [],
            total: payload.response?.data?.delegated?.total ?? 0,
          },
          takenBack: {
            ...state.singleSections.takenBack,
            content: payload.response?.data?.takenBack?.content ?? [],
            total: payload.response?.data?.takenBack?.total ?? 0,
          },
        };
      })
      .addCase(getGroupedList.rejected, (state, { payload }) => {
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(paggingTransferredList.pending, (state) => {
        state.isLoadingList = true;
        state.errorMsg = null;
      })
      .addCase(
        paggingTransferredList.fulfilled,
        (state, { payload }: PayloadAction<{ response: any; type: InterfaceBusinessTask.TSingleSections }>) => {
          state.isLoadingList = false;

          state.singleSections[payload.type].content = [
            ...state.singleSections[payload.type].content,
            ...(payload.response.data[payload.type]?.content ?? []),
          ];
          state.singleSections[payload.type].total = payload.response.data[payload.type]?.total ?? 0;
        },
      )
      .addCase(paggingTransferredList.rejected, (state, { payload }) => {
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(getFilteredList.pending, (state, { meta }) => {
        state.isLoadingList = !meta.arg?.notLoader;
        state.errorMsg = null;
      })
      .addCase(getFilteredList.fulfilled, (state, { payload }) => {
        state.isLoadingList = false;
        state.filter = { ...state.filter, ...payload.filter };
        state.allTasks = payload.page;
      })
      .addCase(getFilteredList.rejected, (state, { payload }) => {
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(paggingFilteredList.pending, (state) => {
        state.isLoadingPaggingAll = true;
        state.errorMsg = null;
      })
      .addCase(paggingFilteredList.fulfilled, (state, { payload }) => {
        state.isLoadingPaggingAll = false;
        state.filter = { ...state.filter, ...payload.filter };
        state.allTasks = {
          content: [...(state.allTasks.content ?? []), ...(payload.page.content ?? [])],
          total: payload.page.total ?? 0,
        };
      })
      .addCase(paggingFilteredList.rejected, (state, { payload }) => {
        state.isLoadingPaggingAll = false;
        state.errorMsg = payload;
      })

      .addCase(updatePosition.pending, (state, payload) => {
        state.tasks = [
          ...(payload.meta.arg.result.urgent ?? []),
          ...(payload.meta.arg.result.important ?? []),
          ...(payload.meta.arg.result.contact ?? []),
        ];
        state.errorMsg = null;
      })
      .addCase(updatePosition.fulfilled, (state, { payload }) => {
        state.tasks = [...(payload.data.urgent ?? []), ...(payload.data.important ?? []), ...(payload.data.contact ?? [])];
      })
      .addCase(updatePosition.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      })

      .addCase(get.pending, (state) => {
        state.isLoadingGetTask = true;
        state.errorMsg = null;
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.isLoadingGetTask = false;
        state.currentTask = payload.response?.data;
      })
      .addCase(get.rejected, (state, { payload }) => {
        state.isLoadingGetTask = false;
        state.errorMsg = payload;
      })

      .addCase(getForUpdate.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(getForUpdate.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        if (payload.id === state.currentTask?.id) {
          state.currentTask = payload.response?.data;
        }

        state.tasks = changeTask({ id: payload.id, tasks: state.tasks, task: payload.response.data });
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({ id: payload.id, tasks: state.allTasks.content, task: payload.response.data }),
        };
      })
      .addCase(getForUpdate.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(create.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        const task = {
          ...payload.response.data,
          subType: "BUSINESS",
        };

        if (payload.isAdd) {
          state.tasks = [task, ...state.tasks];
        }

        state.allTasks = {
          ...state.allTasks,
          content: [task, ...state.allTasks.content],
        };
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(createUnplanned.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(createUnplanned.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.tasks = [payload.response.data, ...state.tasks];
      })
      .addCase(createUnplanned.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(update.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        if (state.currentTask?.id === payload.id) {
          state.currentTask = payload.response.data;
        }

        if (payload.response.data.workDays.some((i) => i.date === payload.date)) {
          state.tasks = changeTask({ id: payload.id, tasks: state.tasks, task: payload.response.data });
        } else {
          state.tasks = [...state.tasks].filter((i) => i.id !== payload.id);
        }
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({ id: payload.id, tasks: state.allTasks.content, task: payload.response.data }),
        };
        state.singleSections = {
          transferred: {
            ...state.singleSections.transferred,
            content: changeTask({
              id: payload.id,
              tasks: state.singleSections.transferred.content,
              task: payload.response.data,
            }),
          },
          delegated: {
            ...state.singleSections.delegated,
            content: changeTask({
              id: payload.id,
              tasks: state.singleSections.delegated.content,
              task: payload.response.data,
            }),
          },
          takenBack: {
            ...state.singleSections.takenBack,
            content: changeTask({
              id: payload.id,
              tasks: state.singleSections.takenBack.content,
              task: payload.response.data,
            }),
          },
        };
      })
      .addCase(update.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(deleteTask.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.tasks = [...state.tasks].filter((item) => item.id !== payload.id);
        if (state.allTasks.content.findIndex((i) => i.id === payload.id) !== -1) {
          state.allTasks = {
            ...state.allTasks,
            content: [...state.allTasks.content].filter((item) => item.id !== payload.id),
            total: state.allTasks.total - 1,
          };
        }
      })
      .addCase(deleteTask.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(reopen.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(reopen.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        state.tasks = changeTask({ id: payload.id, tasks: state.tasks, task: { status: "OPEN" } });
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({ id: payload.id, tasks: state.allTasks.content, task: { status: "OPEN" } }),
        };
        if (payload.id === state.currentTask?.id) {
          state.currentTask = { ...state.currentTask, status: "OPEN" };
        }
      })
      .addCase(reopen.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(pause.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(pause.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        if (payload.isStatusWorkDay) {
          state.tasks = changeTask({ id: payload.id, tasks: state.tasks, task: { status: "PAUSED" } });
        } else {
          state.tasks = state.tasks.filter((task) => task.id !== payload.id);
        }
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({ id: payload.id, tasks: state.allTasks.content, task: { status: "PAUSED" } }),
        };
      })
      .addCase(pause.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(close.pending, (state) => {
        state.isLoadingTask = true;
        state.isLoadingList = true;
        state.errorMsg = null;
      })
      .addCase(close.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.isLoadingList = false;
        const task = state.tasks.filter((i) => i.id === payload.id)[0];

        if (task && task.workDays.every((i) => i.status !== "COMPLETED")) {
          state.tasks = state.tasks.filter((task) => task.id !== payload.id);
        } else {
          state.tasks = changeTask({ id: payload.id, task: { ...payload.amount, status: "CLOSED" }, tasks: state.tasks });
          state.allTasks = {
            ...state.allTasks,
            content: changeTask({ id: payload.id, task: { ...payload.amount, status: "CLOSED" }, tasks: state.allTasks.content }),
          };
        }
      })
      .addCase(close.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(delegate.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(delegate.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        if (toFormatDate(dayjs()) === toFormatDate(payload.date)) {
          state.tasks = [...state.tasks].filter((i) => i.id !== payload?.id);
        }
      })
      .addCase(delegate.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(takeMe.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(takeMe.fulfilled, (state) => {
        state.isLoadingTask = false;
      })
      .addCase(takeMe.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(clone.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(clone.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.currentTask = payload.response.data;
        state.tasks = [payload.response.data, ...state.tasks];
        state.allTasks = {
          ...state.allTasks,
          content: [payload.response.data, ...state.allTasks.content],
        };
      })
      .addCase(clone.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(moveDate.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(moveDate.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.tasks = [...state.tasks].filter((i) => i.id !== payload?.id);
      })
      .addCase(moveDate.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(workDayComplete.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(workDayComplete.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        state.tasks = changeTask({ id: payload.id, task: payload.response?.data, tasks: state.tasks });
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({ id: payload.id, task: payload.response?.data, tasks: state.allTasks.content }),
        };
      })
      .addCase(workDayComplete.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(workDayNotComplete.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(workDayNotComplete.fulfilled, (state) => {
        state.isLoadingTask = false;
      })
      .addCase(workDayNotComplete.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(workDayNeedMoreTime.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(workDayNeedMoreTime.fulfilled, (state, { payload }) => {
        state.tasks = changeTask({
          tasks: state.tasks,
          id: payload.id,
          workDay: { date: payload.date, obj: { status: "NEED_MORE_TIME" } },
        });
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({
            tasks: state.allTasks.content,
            id: payload.id,
            workDay: { date: payload.date, obj: { status: "NEED_MORE_TIME" } },
          }),
        };
      })
      .addCase(workDayNeedMoreTime.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      })

      .addCase(workDayCancelComplete.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(workDayCancelComplete.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        state.tasks = changeTask({
          tasks: state.tasks,
          id: payload.id,
          task: { status: "OPEN" },
          workDay: { date: payload.date, obj: { status: "NOT_COMPLETED", isCompleted: false } },
        });
        state.allTasks = {
          ...state.allTasks,
          content: changeTask({
            tasks: state.allTasks.content,
            id: payload.id,
            task: { status: "OPEN" },
            workDay: { date: payload.date, obj: { status: "NOT_COMPLETED", isCompleted: false } },
          }),
        };
      })
      .addCase(workDayCancelComplete.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(forgetWorkDay.pending, (state) => {
        state.isLoadingTask = true;
        state.isLoadingList = true;
        state.errorMsg = null;
      })
      .addCase(forgetWorkDay.fulfilled, (state) => {
        state.isLoadingTask = false;
        state.isLoadingList = false;
        state.singleSections.delegated.total = state.singleSections.delegated.total - 1;
      })
      .addCase(forgetWorkDay.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(takeToWork.pending, (state) => {
        state.isLoadingTask = true;
        state.isLoadingList = true;
        state.errorMsg = null;
      })
      .addCase(takeToWork.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.isLoadingList = false;

        if (payload.isAdd) {
          state.tasks = [...state.tasks, payload.response?.data];
        }
      })
      .addCase(takeToWork.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(markWorkDay.pending, (state) => {
        state.isLoadingList = true;
        state.errorMsg = null;
      })
      .addCase(markWorkDay.fulfilled, (state) => {
        state.isLoadingList = false;
      })
      .addCase(markWorkDay.rejected, (state, { payload }) => {
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(forgetAllTasks.pending, (state) => {
        state.isLoadingList = true;
        state.errorMsg = null;
      })
      .addCase(forgetAllTasks.fulfilled, (state, { payload }: PayloadAction<InterfaceBusinessTask.TSingleSections>) => {
        state.isLoadingList = false;
        state.singleSections[payload] = {
          ...state.singleSections[payload],
          content: [],
        };
      })
      .addCase(forgetAllTasks.rejected, (state, { payload }) => {
        state.isLoadingList = false;
        state.errorMsg = payload;
      })

      .addCase(getStatistic.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(getStatistic.fulfilled, (state, { payload }) => {
        state.statistic = payload.response?.data;
      })
      .addCase(getStatistic.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      })
      .addCase(createControl.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(createControl.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = null;

        const data = payload.response.data;

        if (toFormatDate(dayjs()) === toFormatDate(dayjs(data?.workDays[0]?.date))) {
          state.tasks = [data, ...state.tasks];
        }
        state.allTasks = {
          ...state.allTasks,
          content: [data, ...state.allTasks.content],
        };
      })
      .addCase(createControl.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(getPersonal.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(getPersonal.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;
        state.currentTask = payload.response?.data;
      })
      .addCase(getPersonal.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })

      .addCase(createPersonal.pending, (state) => {
        state.isLoadingTask = true;
        state.errorMsg = null;
      })
      .addCase(createPersonal.fulfilled, (state, { payload }) => {
        state.isLoadingTask = false;

        const data = payload.response.data;
        /* Force setting subType since BE doesn't return subType */
        data.subType = TaskSubtypes.Personal;

        if (payload.isAdd) {
          state.tasks = [data, ...state.tasks];
        }
        state.allTasks = {
          ...state.allTasks,
          content: [data, ...state.allTasks.content],
        };
      })
      .addCase(createPersonal.rejected, (state, { payload }) => {
        state.isLoadingTask = false;
        state.errorMsg = payload;
      })
      .addCase(deleteWorkDay.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(deleteWorkDay.fulfilled, (state, { payload }) => {
        const { date } = payload;

        if (state.currentTask) {
          state.currentTask.workDays = [...state.currentTask.workDays].filter((i) => i.date !== date);
        }

        if (payload.isDateNow) {
          state.tasks = [...state.tasks].filter((i) => i.id !== payload.id);
        }
      })
      .addCase(deleteWorkDay.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      });
  },
});

/* eslint-enable */

export const {
  updateTaskId,
  setGrouping,
  setFilter,
  dropFilter,
  setSingleSectionShowList,
  filterTaskListsByTaskId,
  filterSingleSectionsByTaskId,
  setIsPersonalTask,
} = businessTasks.actions;

export default businessTasks.reducer;
