export type TypeActiveModal = "comment" | "notEngaged" | "additionalTask";
export type TypeElements = "statistic" | "button" | "title" | "list";

export interface IActiveModal {
  visible: TypeActiveModal;
  id?: string;
}

export const titlesStatistic = {
  totalTasksAmount: "totalPlanned",
  completedTasksAmount: "done",
  personalReasonTasksAmount: "cancelForPersonalReason",
  externalReasonTasksAmount: "cancelForExternalReason",
  unplannedTasksAmount: "completedUnplannedTasks",
};

export interface IReason {
  forgot: "FORGOT";
  notHaveTime: "NO_TIME";
  notRelevant: "NOT_RELEVANT";
  outsideReason: "OTHER";
  [propName: string]: any;
}

export const reasonsNotEngaged: IReason = {
  forgot: "FORGOT",
  notHaveTime: "NO_TIME",
  notRelevant: "NOT_RELEVANT",
  outsideReason: "OTHER",
};
