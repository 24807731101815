import { createAsyncThunk } from "@reduxjs/toolkit";

import { tagService } from "@services";
import { getError } from "@store/getError";

export const getList: any = createAsyncThunk("tag/getList", async (name: string, thunkAPI) => {
  try {
    const response = await tagService.getList(name);
    return { response, search: name };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const create: any = createAsyncThunk("tag/create", async (name: string, thunkAPI) => {
  try {
    const response = await tagService.create({ name });
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI, "Не удалось создать тег");
  }
});
