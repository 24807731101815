import React, { FC } from "react";

import { TextFont } from "@ui";

import { ITab } from "./types";

interface IProps extends ITab {
  width?: string;
}

export const Tab: FC<Omit<IProps, "id">> = ({ title, onPress, isActive, width = "50%" }) => (
  <button
    onClick={onPress}
    style={{
      ...styles.container,
      width,
      ...(isActive && {
        border: "1px solid rgba(0, 0, 0, 0.04)",
        backgroundColor: "#fff",
        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.4)",
      }),
    }}
  >
    <TextFont
      type="bold"
      style={{
        ...styles.title,
        ...(isActive && {
          fontWeight: "700",
        }),
      }}
    >
      {title}
    </TextFont>
  </button>
);

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1 solid rgba(0, 0, 0, 0.04)",
    height: 28,
    borderRadius: 8,
  },
  title: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
  },
};
