import { useEffect } from "react";

import emitter from "@services/emitter";

export const useSubscribe = (event: string, handler: (...args: any[]) => void, deps: unknown[] = []) => {
  useEffect(() => {
    emitter.on(event, handler);

    return () => {
      emitter.off(event, handler);
    };
  }, [event, handler, ...deps]);
};
