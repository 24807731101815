import React, { FC, useCallback, useEffect, useState } from "react";

import FlatList from "flatlist-react";

import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { ModalUI, HeaderModal, SearchInput, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { showToast } from "@utils/toast";

import { Item } from "../../participants/participantsList/Item";

interface IProps {
  onClose: () => void;
  meet: IEventsOfDay;
  onPress: (arg: string) => void;
}

export const ToManage: FC<IProps> = ({ onClose, meet, onPress }) => {
  const [search, setSearch] = useState("");
  const [selectId, setSelectId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (meet.repeat || meet.parentEvent) {
      showToast("changeAuthorForAllInSeries");
    }
  }, []);

  const matchedParticipants = (meet?.participants ?? []).filter((i) => {
    const isEqual = new RegExp(search).test(
      `${(i as IMeetParticipants).user?.firstName} ${(i as IMeetParticipants).user?.middleName} ${
        (i as IMeetParticipants).user?.lastName
      } ${(i as IMeetParticipants).user?.email}`,
    );
    return isEqual && i.status !== "OWNER";
  });

  const renderItem = useCallback(
    ({ item }: { item: IMeetParticipants }) => (
      <Item key={item.id} isBusy isAdded={false} item={{ ...item.user }} onPress={(arg) => setSelectId(arg.id)} isRenderCheckbox={false} />
    ),
    [],
  );

  return (
    <ModalUI isVisible onClose={onClose} scrollEnable={false}>
      <HeaderModal title="chooseAuthor" onClose={onClose} />

      <section style={styles.container}>
        <SearchInput value={search} setValue={setSearch} isDrop />
        <div style={{ overflowY: "auto", height: "85%" }}>
          <FlatList
            renderWhenEmpty={() => <TextFont style={{ textAlign: "center", paddingTop: 10 }}>Не найдено</TextFont>}
            list={matchedParticipants.map((i) => ({ item: i }))}
            keyExtractor={(i) => i.id}
            renderItem={renderItem}
          />
        </div>
      </section>

      {selectId && (
        <NotificationConfirm
          isOpen
          phraseId="chooseAuthorQuestion"
          phraseOkId="yes"
          onOk={() => onPress(selectId)}
          onCancel={() => setSelectId(undefined)}
        />
      )}
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 20,
    gap: 20,
    height: "100%",
  },
};
