import React from "react";

import { ReactComponent as CloseRed } from "@assets/close-red.svg";
import { ReactComponent as SvgComplete } from "@assets/complete.svg";
import { ReactComponent as SvgRepeat } from "@assets/repeat-circle.svg";

import { OneSResolutions } from "../resolutionOneSActionButtons/constants";

export interface IOneSStatusBadge {
  textId?: string;
  defaultMessage: string;
  icon: JSX.Element;
}

export const oneSTaskStatusBadges: Record<OneSResolutions, IOneSStatusBadge> = {
  EXECUTED: {
    textId: "oneSExecuted",
    defaultMessage: "Исполнено",
    icon: <SvgComplete />,
  },

  AGREED: {
    textId: "oneSApproved",
    defaultMessage: "Согласовано",
    icon: <SvgComplete />,
  },
  AGREED_WITH_REMARKS: {
    textId: "oneSApprovedWithComments",
    defaultMessage: "Согласовано с замечаниями",
    icon: <SvgComplete />,
  },
  NOT_AGREED: {
    textId: "oneSNotApproved",
    defaultMessage: "Не согласовано",
    icon: <CloseRed />,
  },
  FAMILIARIZED: {
    textId: "oneSFamiliarized",
    defaultMessage: "Ознакомился",
    icon: <SvgComplete />,
  },
  REPEAT_AGREEMENT: {
    textId: "oneSRepeatingAgreement",
    defaultMessage: "Повтор согласования",
    icon: <SvgRepeat />,
  },
  COMPLETE_AGREEMENT: {
    textId: "oneSCompletedAgreement",
    defaultMessage: "Завершено согласование",
    icon: <SvgComplete />,
  },
  REGISTER: {
    textId: "oneSRegistrated",
    defaultMessage: "Зарегистрировано",
    icon: <SvgComplete />,
  },
  DO_NOT_REGISTER: {
    textId: "oneSNotRegistrated",
    defaultMessage: "Не зарегистрировано",
    icon: <SvgComplete />,
  },
  REPEAT_REGISTRATION: {
    textId: "oneSRepeatingRegistration",
    defaultMessage: "Повтор регистрации",
    icon: <SvgRepeat />,
  },
  COMPLETE_REGISTRATION: {
    textId: "oneSCompletedRegistration",
    defaultMessage: "Завершена регистрация",
    icon: <SvgComplete />,
  },
  CONFIRMED: {
    textId: "oneSConfirmed",
    defaultMessage: "Утверждено",
    icon: <SvgComplete />,
  },
  NOT_CONFIRMED: {
    textId: "oneSNotConfirmed",
    defaultMessage: "Не утверждено",
    icon: <CloseRed />,
  },
  COMPLETE_CONFIRMATION: {
    textId: "oneSCompletedConfirmation",
    defaultMessage: "Завершено утверждение",
    icon: <SvgComplete />,
  },
  SIGN: {
    textId: "oneSSigned",
    defaultMessage: "Подписано",
    icon: <SvgComplete />,
  },
  REJECT: {
    textId: "oneSRejected",
    defaultMessage: "Отклонено",
    icon: <CloseRed />,
  },
  RETURN: {
    textId: "returned",
    defaultMessage: "Возвращено",
    icon: <SvgRepeat />,
  },
  REPEAT_CONFIRMATION: {
    textId: "oneSRepeatingConfirmation",
    defaultMessage: "Повтор утверждения",
    icon: <SvgRepeat />,
  },
  REPEAT_SIGNATURE: {
    textId: "oneSRepeatingSignature",
    defaultMessage: "Повтор подписания",
    icon: <SvgRepeat />,
  },
  COMPLETE_SIGNATURE: {
    textId: "oneSCompletedSignature",
    defaultMessage: "Завершено подписание",
    icon: <SvgComplete />,
  },
  COMPLETE: {
    textId: "oneSCompletedExecution",
    defaultMessage: "Завершено",
    icon: <SvgComplete />,
  },
};
