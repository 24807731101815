import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";

import { IMeet } from "@interfaces/meet.interface";
import { ArrowSvg, TextFont } from "@ui";
import globalState from "@utils/globalState";

import { TStringUnknownTupleArray } from "../utils";

import { AccessOptions } from "./components/AccessOptions";

interface IProps {
  meet: IMeet;
  isEditMode: boolean;
  handleData: (name: string | TStringUnknownTupleArray, value: unknown) => void;
}

export const AccessRightsPicker: FC<IProps> = ({ meet, isEditMode, handleData }) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  return (
    <>
      <div style={styles.header} onClick={() => setIsSectionExpanded(!isSectionExpanded)}>
        <TextFont size={18} weight="700" type="bold">
          <FormattedMessage id="access" defaultMessage={"Доступ"} />
        </TextFont>
        <ArrowSvg type={isSectionExpanded ? "up" : "down"} fill="#B3BEB8" width={26} height={26} />
      </div>

      {isSectionExpanded && (
        <div style={{ display: isSectionExpanded ? "block" : "none" }}>
          <AccessOptions meet={meet} handleData={handleData} isEditMode={isEditMode} />
        </div>
      )}
    </>
  );
};

const styles = {
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingLeft: 8,
    borderRadius: 10,
    backgroundColor: globalState.colorSchema.white,
  },
};
