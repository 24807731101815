import React, { FC, ReactNode, useEffect } from "react";

import { useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { AttachFileContextMenuButton } from "@shared/fileAttachments";
import { selectUserPermissoins } from "@store/user";
import globalState from "@utils/globalState";

import { TaskMeetings } from "../../../taskMeetings/TaskMeeting";

interface IProps {
  isPersonalTask?: boolean;
  componentDelegate: ReactNode;
  task: ITask | null;
  close: () => void;
  openFilePicker: ({ closeOverModal }: { closeOverModal?: boolean }) => void;
}

export const Actions: FC<IProps> = ({ openFilePicker, isPersonalTask, componentDelegate, task, close }) => {
  const permissions = useSelector(selectUserPermissoins);

  useEffect(() => {
    document.getElementById("modalBusinessTask").addEventListener("click", (e) => {
      if (!document.getElementById("businessTaskActions")?.contains(e?.target)) {
        close();
      }
    });
  }, []);

  return (
    <div id="businessTaskActions" style={{ ...styles.container, position: "absolute" }}>
      <RenderWithCondition condition={permissions.CAN_DELEGATE && !isPersonalTask}>
        <div className="action">{componentDelegate}</div>
      </RenderWithCondition>
      <RenderWithCondition condition={task?.type !== "CONTROL" && !isPersonalTask}>
        <div className="action">
          <TaskMeetings closeOverModal={close} taskId={task?.id ?? ""} onlyButton />
        </div>
      </RenderWithCondition>
      <div className="action">
        <AttachFileContextMenuButton openFilePicker={() => openFilePicker({ closeOverModal: true })} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    top: 30,
    right: 0,
    minWidth: "185px",
    borderRadius: 8,
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.09)",
    backgroundColor: globalState.colorSchema.white,
    zIndex: 999,
  },
};
