import { http } from "@api/httpClient";

class NoteService {
  getList = async (yearMonth: string, userId: string): Promise<any> =>
    await http.get(`api/crud/v1/notes/list?yearMonth=${yearMonth}&userId=${userId}`);

  create = async (data: { yearMonth: string; content: string }): Promise<any> => await http.post("/api/crud/v1/notes", data);

  update = async (id: string, data: Record<string, string>): Promise<any> => await http.patch(`/api/crud/v1/notes/${id}`, data);

  delete = async (id: string): Promise<any> => await http.delete(`/api/crud/v1/notes/${id}`);
}

export const noteService = new NoteService();
