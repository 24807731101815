import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { Colors } from "@theme/colors";
import { ContentEditable, ModalUI, TextFont } from "@ui";

interface IProps {
  value: string;
  setValue: (arg: string) => void;
  isVisible: boolean;
  onClose: () => void;
  onSend: () => void;
}

export const ModalComment: FC<IProps> = ({ value, setValue, isVisible, onClose, onSend }) => {
  const handleSend = () => {
    onSend();
    onClose();
  };
  return (
    <ModalUI isVisible={isVisible} className="bottomModal" onClose={onClose}>
      <div style={{ display: "flex", flexDirection: "column", paddingLeft: 12, paddingRight: 12, gap: 16, paddingTop: 20 }}>
        <ContentEditable
          style={styles.input}
          onChange={(e) => setValue(e)}
          value={value}
          autoFocus
          placeholder="Комментарий организатору"
        />

        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <button onClick={onClose} style={{ ...styles.button, backgroundColor: Colors.LIGHT.lighGrey }}>
            <TextFont size={18} type="bold" color={Colors.LIGHT.text.grey}>
              <FormattedMessage id="cancelClose" />
            </TextFont>
          </button>
          <button onClick={handleSend} style={{ ...styles.button, backgroundColor: Colors.LIGHT.background.green }}>
            <TextFont size={18} type="bold" color={Colors.LIGHT.white}>
              <FormattedMessage id="send" />
            </TextFont>
          </button>
        </div>
      </div>
    </ModalUI>
  );
};

const styles = {
  input: {
    border: `1px solid ${Colors.LIGHT.background.grey}`,
    borderRadius: 10,
    padding: "16px 12px",
  },
  button: {
    display: "flex",
    borderRadius: 10,
    justifyContent: "center",
    paddingTop: 16,
    paddingBottom: 16,
    width: "45%",
  },
};
