import React, { FC, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";

import { ReactComponent as CloseSvg } from "@assets/close-thin.svg";
import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { Modal } from "../../../header/modal/Modal";

interface IProps {
  months?: string[];
  isAddMonth: boolean;
  onSave: (arg: Dayjs) => void;
  handleRemoveMonth: (yearMonth: string) => void;
}

const sortedDates = (dates: string[]) => {
  const soretedDates = [...dates].sort((a, b) => {
    const dateA = dayjs(a, { format: "YYYY-MM" });
    const dateB = dayjs(b, { format: "YYYY-MM" });

    return dateA - dateB;
  });

  return soretedDates;
};

export const WorkMonths: FC<IProps> = ({ months, isAddMonth, onSave, handleRemoveMonth }) => {
  const [isVisible, setIsVisible] = useState(false);
  const currentYear = new Date().getFullYear().toString();
  const sortedMonth = useMemo(() => sortedDates(months ?? []) ?? [], [months]);

  const handlePress = (arg: Dayjs, isDisabled: boolean) => {
    if (!isDisabled) {
      onSave(arg);
      setIsVisible(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <TextFont type="bold" size={18} weight="700">
          <FormattedMessage id="monthOfWorkGoal" />
        </TextFont>
        <RenderWithCondition condition={isAddMonth}>
          <button onClick={() => setIsVisible(true)}>
            <SvgPlus width={16} height={16} fill={globalState.colorSchema.background.grey} />
          </button>
        </RenderWithCondition>
      </div>

      <RenderWithCondition condition={months}>
        <div style={styles.list}>
          {sortedMonth?.map((month) => {
            const year = dayjs(month).format("YYYY");

            return (
              <div key={month} style={styles.month}>
                <TextFont style={{ textTransform: "capitalize" }} size={16}>
                  {dayjs(month).format("MMMM")} {currentYear !== year ? year : ""}
                </TextFont>
                <button onClick={() => handleRemoveMonth(month)} style={{ marginLeft: 4 }}>
                  <CloseSvg />
                </button>
              </div>
            );
          })}
        </div>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          selectedMonths={months}
          pressMonth={handlePress}
          startDate={dayjs().format("YYYY-MM")}
          isActiveDisabled={false}
        />
      </ModalRenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    paddingTop: 20,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  list: {
    marginTop: 10,
    display: "flex",
    "flex-wrap": "wrap",
    gap: 10,
  },
  month: {
    borderRadius: 45,
    backgroundColor: "white",
    display: "flex",
    padding: "3px 9px",
    alignItems: "center",
  },
};
