import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgCalendar } from "@assets/calendar.svg";
import { ReactComponent as SvgCollapse } from "@assets/collapse.svg";
import { ReactComponent as SvgToday } from "@assets/today.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TSingleSections } from "@interfaces/businessTask.interface";
import { Modal } from "@screens/day/components/header/modal";
import { ListTasks } from "@screens/day/components/ListTasks/ListTasks";
import { SingleSectionHeader } from "@screens/day/components/ListTasks/taskItem/headers";
import { Timetable } from "@screens/day/components/timetable";
import { ListSingleSection, Notes } from "@shared";
import { selectBusinessTasks } from "@store/businessTask";
import { selectConfigure, setSetting } from "@store/configure";
import { saveDate, selectCurrentDate, updateId } from "@store/screenDay";
import { ArrowSvg, TextFont } from "@ui";
import { calendar, isTablet, showToast } from "@utils";
import globalState from "@utils/globalState";

import { tightModeElements } from "../tightMode";

export const DayTablet = () => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleSingleSection, setIsVisibleSingleSection] = useState<TSingleSections | undefined>(undefined);
  const [isVisibleMeet, setIsVisibleMeet] = useState(false);
  const [isVisibleTask, setIsVisibleTask] = useState(false);

  const dateNow = useSelector(selectCurrentDate);
  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);
  const {
    singleSections: {
      transferred: { content: moveTasks },
      delegated: { content: delegatedTasks },
    },
  } = useSelector(selectBusinessTasks);

  const handlePress = (id: string, type: "TASK" | "MEETING") => {
    dispatch(updateId({ id, modalOpen: true }));

    if (type === "MEETING") setIsVisibleMeet(true);
    if (type === "TASK") {
      setIsVisibleTask(true);
    }
  };

  const editDay = (arg: "prev" | "next") => {
    dispatch(saveDate(dateNow.add(arg === "prev" ? -1 : 1, "day")));
  };

  const collapseMode = () => {
    dispatch(setSetting({ key: "isTightMode", val: false }));
  };

  const renderDate = useMemo(() => `${dateNow.locale("ru").format("DD.MM.YYYY")}, ${calendar.getWeekDayRu(dateNow.day())}`, [dateNow]);
  const isTightModeTablet = useMemo(() => isTightMode && isTablet, [isTightMode]);
  const typeSingleSection: TSingleSections = useMemo(() => {
    if (moveTasks.length) {
      return "transferred";
    }
    return "delegated";
  }, [moveTasks.length]);

  const { colorSchema } = globalState;

  return (
    <>
      <div style={styles.container}>
        <div style={styles.side}>
          <div style={styles.header}>
            <div style={{ flex: 4 }}>
              {moveTasks.length || delegatedTasks.length ? (
                <SingleSectionHeader
                  type={typeSingleSection}
                  show={() => setIsVisibleSingleSection(typeSingleSection)}
                  styleTextShow={{ color: colorSchema.text.accent, marginLeft: 10 }}
                  styleWrapper={{ justifyContent: "flex-start" }}
                />
              ) : (
                <TextFont style={styles.headerText}>
                  <FormattedMessage id="urgent" />
                </TextFont>
              )}
            </div>
            {/* <button style={{ flex: 0.3 }} onClick={() => dispatch(setSetting({ key: "isOpenListTasks", val: !isOpenListTasks }))}>
              {isOpenListTasks ? <SvgLeft fill={colorSchema.text.main} /> : <SvgRight fill={colorSchema.text.main} />}
            </button> */}
          </div>
          <ListTasks style={{ height: "100%" }} />
        </div>
        <div style={{ ...styles.side, position: "relative", borderLeft: "1px solid #E3E9E7", borderRight: "1px solid #E3E9E7" }}>
          <div style={styles.header}>
            <div style={styles.wrapperDate}>
              <button onClick={() => editDay("prev")}>
                <ArrowSvg type="left" width={20} height={20} fill="#297952" />
              </button>
              <TextFont type="bold" style={{ ...styles.headerText, fontWeight: "700" }}>
                {renderDate}
              </TextFont>
              <button onClick={() => editDay("next")}>
                <ArrowSvg type="right" width={20} height={20} fill="#297952" />
              </button>
            </div>

            <div style={{ flexDirection: "row", alignItems: "center" }}>
              <button onClick={collapseMode} style={{ marginRight: 10 }}>
                <SvgCollapse />
              </button>
              <button
                onClick={() => {
                  showToast("today");
                  dispatch(saveDate(dayjs()));
                }}
                style={{ marginRight: 10 }}
              >
                <SvgToday />
              </button>
              <button onClick={() => setIsVisible(true)}>
                <SvgCalendar />
              </button>
            </div>
          </div>
          <div style={{ ...styles.content, height: "85%" }}>
            <Timetable
              isVisibleTask={isVisibleTask}
              isVisibleMeet={isVisibleMeet}
              setIsVisibleMeet={setIsVisibleMeet}
              setIsVisibleTask={setIsVisibleTask}
              handlePress={handlePress}
            />
          </div>
          <RenderWithCondition condition={isTightModeTablet}>{tightModeElements.tabbar}</RenderWithCondition>
        </div>
        <div style={{ ...styles.side, minWidth: "33%", maxWidth: "33%" }}>
          <div style={{ ...styles.header, justifyContent: "center" }}>
            <TextFont style={{ ...styles.headerText }}>
              <FormattedMessage id="notes" />
            </TextFont>
          </div>
          <div style={{ ...styles.content, paddingRight: 24, overflowY: "auto", height: "85%" }}>
            <Notes autoFocus={false} />
          </div>
        </div>
        <ModalRenderWithCondition condition={isVisible}>
          <Modal isVisible={isVisible} setIsVisible={setIsVisible} isFullWidth isOnlyMonthMode />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={!!isVisibleSingleSection}>
          <ListSingleSection type={isVisibleSingleSection} close={() => setIsVisibleSingleSection(undefined)} />
        </ModalRenderWithCondition>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "row",
    backgroundColor: "white",
    height: "100%",
  },
  side: {
    backgroundColor: "#F5F9F8",
    flex: 1,
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    backgroundColor: "#E3E9E7",
    height: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  wrapperDate: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
  },
  headerText: {
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  content: {
    padding: "12px",
  },
};
