import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as CloseRed } from "@assets/close-red.svg";
import { ReactComponent as SvgComplete } from "@assets/complete.svg";
import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { oneSTaskStatusBadges } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/oneSStatusBadge/constants";
import { AssistantToast, TextFont } from "@ui";
import globalState from "@utils/globalState";

import { titleReason } from "../assets/constants";
import { ReactComponent as SvgPause } from "../assets/pause_status.svg";

interface IProps {
  task: ITask;
}

export const ViewSaveStatus: FC<IProps> = ({ task }) => {
  const renderElements = (): { element: JSX.Element | null; comment?: JSX.Element | null } => {
    if (task.isFromOneS && task?.oneSTaskResolution && task.status !== "CLOSED") {
      const oneSBadge = oneSTaskStatusBadges[task.oneSTaskResolution];

      if (!oneSBadge) return { element: null };

      return {
        element: (
          <div style={{ display: "flex", gap: 3, alignItems: "center" }}>
            <div style={{ ...styles.iconWrapper, ...{ marginRight: 10 } }}>
              <div style={styles.icon}>{oneSBadge.icon}</div>
              <TextFont style={styles.iconText}>
                <FormattedMessage id={oneSBadge.textId} defaultMessage={oneSBadge.defaultMessage} />
              </TextFont>
            </div>
            {task.assistant && <AssistantToast assistant={task.assistant} view="svgInBillet" />}
          </div>
        ),
      };
    }
    switch (task.status) {
      case "CLOSED":
        return {
          element: (
            <RenderWithCondition condition={task.closeReason || task.isFromOneS}>
              <div style={styles.iconWrapper}>
                <CloseRed style={styles.icon} />
                <TextFont style={{ ...styles.iconText, color: globalState.colorSchema.red }}>
                  <FormattedMessage id={task.closeReason ? titleReason[task?.closeReason] : "closedTask"} />
                </TextFont>
              </div>
            </RenderWithCondition>
          ),
          comment: (
            <RenderWithCondition condition={!!task?.closeComment}>
              <TextFont style={styles.closeText}>{task.closeComment}</TextFont>
            </RenderWithCondition>
          ),
        };
      case "COMPLETED":
        return {
          element: (
            <div style={{ ...styles.iconWrapper, marginRight: 10 }}>
              <SvgComplete style={styles.icon} />
              <TextFont style={styles.iconText}>
                <FormattedMessage id={"readyTask"} />
              </TextFont>
            </div>
          ),
        };
      case "PAUSED":
        return {
          element: (
            <div style={{ ...styles.iconWrapper, marginRight: 10 }}>
              <SvgPause style={styles.icon} />
              <TextFont style={{ ...styles.iconText, color: globalState.colorSchema.grey500 }}>
                <FormattedMessage id={"suspendTask"} />
              </TextFont>
            </div>
          ),
          comment: (
            <RenderWithCondition condition={!!task?.pauseComment}>
              <TextFont style={styles.closeText}>{task?.pauseComment}</TextFont>
            </RenderWithCondition>
          ),
        };

      default:
        return { element: <div style={{ flex: 1, flexWrap: "wrap", display: "flex" }} /> };
    }
  };

  const { element, comment } = renderElements();

  return (
    <div style={{ gap: 6, marginBottom: 6, display: "flex", flexDirection: "column" }}>
      {element}
      {task.assistant && task.createdByAssistant && !task.isFromOneS && <AssistantToast assistant={task.assistant} view="billet" />}
      {comment}
    </div>
  );
};

const styles = {
  iconWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 9px",
    borderRadius: 55,
    alignItems: "center",
    alignSelf: "flex-start",
    backgroundColor: globalState.colorSchema.white,
  },
  icon: {
    width: 17,
    height: 17,
    marginRight: 5,
  },
  iconText: {
    fontSize: 14,
    color: globalState.colorSchema.green,
  },
  closeText: {
    fontWeight: "400",
    fontSize: 18,
    lineHeight: "24px",
    color: globalState.colorSchema.white,
    marginBottom: 10,
  },
};
