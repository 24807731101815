import React, { FC } from "react";

import { useIntl } from "react-intl";

import { maxLinesInput } from "@utils";
import globalState from "@utils/globalState";

import { ICommonFields } from "../types";

interface IProps extends ICommonFields {
  name: string;
  description: string;
  autoFocus: boolean;
}

export const Inputs: FC<IProps> = ({ handleData, name, description, autoFocus }) => {
  const handleChangeText = (text: string, name: string) => {
    if (maxLinesInput(text)) {
      handleData(name, text);
    }
  };

  const textAreaAdjust = (element) => {
    element.target.style.height = "1px";
    element.target.style.height = `${element.target.scrollHeight}px`;
  };

  const placeholderMeetName = useIntl().formatMessage({ id: "meetingName" });
  const placeholderMeetDescription = useIntl().formatMessage({ id: "meetingDescroption" });

  return (
    <>
      <textarea
        style={{ ...styles.input, ...styles.taskName }}
        placeholder={placeholderMeetName}
        value={name}
        onChange={(e) => handleChangeText(e.target.value, "name")}
        autoFocus={autoFocus}
        onKeyUp={textAreaAdjust}
        maxLength={100}
      />
      <textarea
        style={{ ...styles.input, ...styles.taskDescription }}
        placeholder={placeholderMeetDescription}
        value={description}
        onChange={(e) => handleData("description", e.target.value)}
        onKeyUp={textAreaAdjust}
      />
    </>
  );
};

const styles = {
  input: {
    backgroundColor: globalState.colorSchema.white,
    borderRadius: 9,
    padding: 16,
    paddingBottom: 8,
    fontSize: 18,
    width: "92%",
    minHeight: "56px",
  },
  taskName: {
    margin: "8px 0",
    paddingTop: 16,
  },
  taskDescription: {
    paddingTop: 16,
  },
};
