import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { IDataUserMessage } from "@interfaces/user.interface";
import { SendFeedbackNotification } from "@screens/profile/components/sender/sendFeedback/sendFeedbackNotification";
import { sendUserMessage } from "@store/user/api";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import globalState from "@utils/globalState";

import { Sender } from ".";

interface IProps {
  onClose: () => void;
}

export const WishSender = ({ onClose }: IProps) => {
  const dispatch = useDispatch();
  const { isTablet } = useDimension();

  const [message, setMessage] = useState<string>("");
  const [isSendFeedbackVisible, setIsSendFeedbackVisible] = useState(false);
  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState(false);

  const sendEvent = async () => {
    setIsLoadingSendMessage(true);
    const data: IDataUserMessage = {
      message,
      messageType: "WISH",
    };

    const response = await dispatch(sendUserMessage(data));

    if (response.payload.response.status === 204) {
      setIsSendFeedbackVisible(true);
    }
    setIsLoadingSendMessage(false);
  };

  return (
    <div>
      <HeaderModal
        title="wish"
        isEdit={false}
        onClose={onClose}
        style={{ backgroundColor: globalState.colorSchema.disabled }}
        isClose={!isTablet}
      />
      <Sender message={message} setMessage={setMessage} sendEvent={sendEvent} isLoading={isLoadingSendMessage} placeholderId="howImprove" />
      <RenderWithCondition condition={isSendFeedbackVisible}>
        <SendFeedbackNotification closeParent={onClose} setIsSendFeedbackVisible={setIsSendFeedbackVisible} />
      </RenderWithCondition>
    </div>
  );
};
