export const actions: Array<string> = [
  "deleteWorkDay",
  "delegate",
  "takeMe",
  "addWorkDay",
  "complete",
  "workDayNotComplete",
  "takeToWork",
  "pause",
];

export const actionsAllTasks: Array<string> = [...actions, "close"];

export const actionsDelegatedTasks: Array<string> = [
  "takeToWork",
  "complete",
  "close",
  "delegate",
  "forgetWorkDay",
  "takeToWork",
  "deleteTask",
];

export const actionsMove: Array<string> = [
  "takeMe",
  "delegate",
  "complete",
  "forgetWorkDay",
  "close",
  "markWorkDay",
  "takeToWork",
  "deleteTask",
];

export const actionsBsnGoal: Array<string> = [
  "create",
  "update",
  "deleteTask",
  "complete",
  "close",
  "reopen",
  "clone",
  "workDayComplete",
  "delegate",
  "deleteWorkDay",
  "takeMe",
  "pause",
];

export const avaliableScreen = ["/", "/month", "/analysis", "/tasks"];
