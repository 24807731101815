import React, { FC, useEffect, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";

import { ReactComponent as SvgDeadLine } from "@assets/calendar_red.svg";
import { RenderWithCondition } from "@hoc";
import { Calendar } from "@shared/planningWork";
import { commonStyles } from "@styles/styles";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDate, toFormatDateRu } from "@utils/toFormatTime";

import { ReactComponent as SvgQuestion } from "../../../../assets/question.svg";
import { modalStyles } from "../../../../styles";
import { CommonFields } from "../types";

interface IProps extends CommonFields {
  date: string;
  startDate: string;
  field: string;
  isVisibleTooltip?: boolean;
  titleId?: string;
  styleContainer?: any;
}

export const Deadline: FC<IProps> = ({
  date,
  startDate,
  handleData,
  field,
  isVisibleTooltip = true,
  titleId = "deadlineDay",
  styleContainer,
}) => {
  const blockRef = useRef(null);

  const [switchDeadLine, setSwitchDeadLine] = useState(Boolean(date));
  const [isTooltip, setIsTooltip] = useState(false);
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

  useEffect(() => {
    if (isVisibleCalendar) {
      blockRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isVisibleCalendar, blockRef]);

  const pressCalendar = (dateWeek: Dayjs) => {
    handleData(field, toFormatDate(dateWeek) === date ? null : toFormatDate(dateWeek));
    setIsVisibleCalendar(false);
  };

  const handleSwitchDeadline = () => {
    if (switchDeadLine) {
      handleData(field, null);
      setIsVisibleCalendar(false);
    } else {
      setIsVisibleCalendar(true);
    }
    setSwitchDeadLine(!switchDeadLine);
  };

  const style = styles();

  return (
    <section
      style={{
        ...(isVisibleCalendar && { backgroundColor: globalState.colorSchema.white, borderRadius: 11 }),
        ...styleContainer,
        position: "relative",
      }}
      ref={blockRef}
    >
      <button
        style={{ ...commonStyles.billet, marginTop: 0, ...(isVisibleCalendar && { borderBottom: "1px solid #E3E9E7" }) }}
        onClick={handleSwitchDeadline}
      >
        <div style={modalStyles.blockSwitch}>
          <SvgDeadLine style={{ marginRight: 8 }} />
          <div>
            <TextFont type="bold" style={{ ...modalStyles.blockTurnText, fontWeight: "700" }}>
              <FormattedMessage id={titleId} />
            </TextFont>
            <RenderWithCondition condition={Boolean(date) && switchDeadLine}>
              <TextFont size={12} weight="400" style={style.textDate}>
                {toFormatDateRu(dayjs(date ?? ""))}
              </TextFont>
            </RenderWithCondition>
          </div>
          <RenderWithCondition condition={isVisibleTooltip}>
            <SvgQuestion onMouseOver={() => setIsTooltip(true)} onMouseOut={() => setIsTooltip(false)} style={{ marginLeft: 7 }} />
          </RenderWithCondition>
        </div>
        <Toggle checked={switchDeadLine} onChange={handleSwitchDeadline} icons={false} />
        <RenderWithCondition condition={isVisibleTooltip && isTooltip}>
          <div style={{ ...style.tooltip, position: "absolute" }}>
            <TextFont style={{ fontSize: 12 }}>
              <FormattedMessage id="deadlineWhat" />
            </TextFont>
          </div>
        </RenderWithCondition>
      </button>
      <RenderWithCondition condition={isVisibleCalendar}>
        <Calendar currentDay={date} startDay={startDate} press={pressCalendar} deadline={date} />
      </RenderWithCondition>
    </section>
  );
};

const styles = () => {
  const { colorSchema } = globalState;
  return {
    tooltip: {
      display: "flex",
      "flex-direction": "column",
      padding: "10px",
      backgroundColor: colorSchema.white,
      alignSelf: "flex-start",
      right: 0,
      bottom: "100%",
      width: "290px",
      borderRadius: "10px",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.09)",
    },
    textDate: {
      color: colorSchema.blue,
    },
  };
};
