import React, { useEffect } from "react";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { RootState } from "@store";

import { Auth, CheckCode, Registration, Mounth, Day, Analysis, Task, Profile } from "../screens";

import ExtendedRoutes from "./components/ExtendedRoutes";

export const AppRouter = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <BrowserRouter>
      <ExtendedRoutes authPaths={["/auth", "/checkCode"]} publicPaths={["/auth", "/checkCode", "/registration"]}>
        <Route path="/auth" element={<Auth />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/checkCode" element={<CheckCode />} />
        <Route path="/month" element={<Mounth />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/">
          <Route index element={<Day />} />
          <Route path="analysis" element={<Analysis />} />
        </Route>
        <Route path="/tasks" element={<Task />} />
      </ExtendedRoutes>
    </BrowserRouter>

    //   <NavigationContainer ref={navigationRef}>
    //     <Stack.Navigator>
    //       {isLoggedIn ? (
    //         <>
    //           <Stack.Screen
    //             name="mounth"
    //             component={Mounth}
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: isTablet ? Colors.white : Colors.background.darkGreen,
    //               },
    //               headerTitleAlign: 'center',
    //               headerTitle: () => <Header />,
    //               headerLeft: () => null,
    //               gestureEnabled: false,
    //               headerBackVisible: false,
    //               orientation,
    //             }}
    //           />
    //           <Stack.Screen
    //             name="day"
    //             component={Day}
    //             options={{
    //               header: () =>
    //                 !(isTightMode && isTablet) ? (
    //                   <Header isChangeMounth={false} isRenderDayAndMounth={true} isRenderCalendar={true} />
    //                 ) : (
    //                   tightModeElements.header
    //                 ),
    //               headerLeft: () => null,
    //               gestureEnabled: false,
    //               headerBackVisible: false,
    //               headerShadowVisible: false,
    //               orientation,
    //             }}
    //           />
    //           <Stack.Screen
    //             name="analysis"
    //             component={Analysis}
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: isTablet ? Colors.white : Colors.background.main,
    //               },
    //               contentStyle: {
    //                 backgroundColor: Colors.white,
    //               },
    //               headerTitle: titleAnalysis,
    //               headerLeft: () => <HeaderAnalysis />,
    //               gestureEnabled: false,
    //               headerShadowVisible: false,
    //               orientation,
    //             }}
    //           />
    //           <Stack.Screen
    //             name="task"
    //             component={Task}
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: isTablet ? Colors.white : Colors.background.main,
    //               },
    //               headerTitleAlign: 'center',
    //               headerTitle: () => <TaskHeader />,
    //               headerLeft: () => null,
    //               gestureEnabled: false,
    //               headerBackVisible: false,
    //               headerShadowVisible: false,
    //               orientation,
    //             }}
    //           />
    //           <Stack.Screen
    //             name="profile"
    //             component={Profile}
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: Colors.background.darkGreen,
    //               },
    //               header: () => '',
    //               headerTitle: () => '',
    //               headerLeft: () => null,
    //               gestureEnabled: false,
    //               headerBackVisible: false,
    //               orientation,
    //             }}
    //           />
    //         </>
    //       ) : (
    //         <>
    //           <Stack.Screen
    //             name="auth"
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: Colors.background.main,
    //               },
    //               headerTitle: '',
    //               headerShadowVisible: false,
    //               headerBackTitleVisible: false,
    //               headerBackVisible: false,
    //               orientation,
    //             }}
    //             component={Auth}
    //           />
    //           <Stack.Screen
    //             name="registration"
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: Colors.background.main,
    //               },
    //               headerTitle: () => '',
    //               headerLeft: () => null,
    //               headerShadowVisible: false,
    //               headerBackTitleVisible: false,
    //               headerBackVisible: false,
    //               orientation,
    //             }}
    //             component={Registration}
    //           />
    //           <Stack.Screen
    //             name="checkCode"
    //             component={CheckCode}
    //             options={{
    //               headerStyle: {
    //                 backgroundColor: Colors.background.main,
    //               },
    //               headerTitle: '',
    //               headerShadowVisible: false,
    //               headerBackTitleVisible: false,
    //               orientation,
    //             }}
    //           />
    //         </>
    //       )}
    //     </Stack.Navigator>
    //   </NavigationContainer>
  );
};
/* eslint-enable */
