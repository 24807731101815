import React from "react";

import { FormattedMessage } from "react-intl";

import img from "@assets/notFound.png";
import { RenderWithCondition } from "@hoc";
import { TextFont } from "@ui";

interface IProps {
  handleResetFilter: () => void;
  titleTextId?: string;
  descriptionTextId?: string;
  disableButton?: boolean;
}

export const NotFound = ({ titleTextId, descriptionTextId, disableButton, handleResetFilter }: IProps) => (
  <section style={styles.container}>
    <img style={styles.image} src={img} />
    <TextFont style={styles.title}>
      <FormattedMessage id={titleTextId ? titleTextId : "taskNotFound"} />
    </TextFont>
    <TextFont style={styles.description}>
      <FormattedMessage id={descriptionTextId ? descriptionTextId : "taskNotFoundNotice"} />
    </TextFont>
    <RenderWithCondition condition={!disableButton}>
      <button style={styles.buttonReset} onClick={handleResetFilter}>
        <TextFont style={styles.textReset}>
          <FormattedMessage id="filterReset" />
        </TextFont>
      </button>
    </RenderWithCondition>
  </section>
);

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 100,
  },
  image: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    fontWeight: "800",
    fontSize: 24,
    lineHeight: "32px",
    "text-align": "center",
    marginTop: 24,
  },
  description: {
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    "text-align": "center",
    color: "#000000",
    marginTop: 8,
    width: 400,
  },
  buttonReset: {
    width: 286,
    height: 56,
    backgroundColor: "#DAF1E5",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    "flex-direction": "column",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 24,
  },
  textReset: {
    fontWeight: "700",
    fontSize: 18,
    lineHeight: "24px",
    "text-align": "center",
    color: "#5F6E67",
  },
};
