import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISession } from "@interfaces/user.interface";

import { getList, removeSession } from "./api";

interface IState {
  sessions: ISession[];
  isLoading: boolean;
  errorMsg: null | string;
}

const initialState: IState = {
  sessions: [],
  isLoading: false,
  errorMsg: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getList.fulfilled, (state, { payload }) => {
        state.sessions = payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(getList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(removeSession.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(removeSession.fulfilled, (state, { payload }) => {
        const tempSessions = [...state.sessions];

        state.sessions = tempSessions.filter((i) => !payload.ids?.includes(i.id));
        state.isLoading = false;
      })
      .addCase(removeSession.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export default sessionSlice.reducer;
