import { reduce, isPlainObject, isEqual } from "lodash";

export type TTypeObject = Record<string, any>;

export function diff(obj1: TTypeObject, obj2: TTypeObject) {
  return reduce(
    obj1,
    function (result: TTypeObject, value: any, key: string) {
      if (isPlainObject(value)) {
        const isDiff = diff(value, obj2[key] ?? {});
        if (Object.keys(isDiff).length) {
          result[key] = isDiff;
        }
      } else if (!isEqual(value, obj2[key])) {
        result[key] = value;
      }
      return result;
    },
    {}
  );
}
