import React, { FC, useEffect, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { commonStyles } from "@styles/styles";
import { TextFont, TimePicker } from "@ui";
import { EditTimerOptions } from "@ui/TimePicker";
import { toFormatDate, toFormatHHmm } from "@utils";

interface IProps {
  handleTime: (type: EditTimerOptions, arg: any) => string;
  value: string;
  type: EditTimerOptions;
  isVisible: boolean;
  title: string;
}

export const Time: FC<IProps> = ({ handleTime, value, isVisible, type, title }) => {
  const [localTime, setLocalTime] = useState(toFormatHHmm(dayjs(value)));

  useEffect(() => {
    setLocalTime(toFormatHHmm(dayjs(value)));
  }, [value]);

  const onChangeTime = (valueInput: string) => {
    setLocalTime(valueInput);

    if (valueInput.length === 5) {
      return handleTime(type, dayjs(`${toFormatDate(dayjs(value))} ${valueInput}`));
    }
  };

  return (
    <section style={styles.container}>
      <button
        style={{
          ...commonStyles.billet,
          display: "grid",
          gridTemplateColumns: "80% 20%",
          ...(isVisible && { border: "1px solid #E3E9E7" }),
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <TextFont style={styles.textBold}>
          <FormattedMessage id={title} />
        </TextFont>
        <TimePicker onChange={onChangeTime} value={localTime} />
      </button>
    </section>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    marginTop: 8,
    borderRadius: 11,
  },
  textBold: {
    fontSize: 16,
    fontWeight: "700",
  },
};
