// import "./wdyr"; // ToDo: Раскомментировать, для вывода инфо о ре-рендарах в консоль

import React from "react";

import ReactDOM from "react-dom/client";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <App />
  </>,
);

serviceWorkerRegistration.unregister();
