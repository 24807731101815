import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { TextFont } from "@ui";

interface IProps {
  email: string;
  setIsInviteExternalParticipantModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddNonExistingParticipantButton: FC<IProps> = ({ email, setIsInviteExternalParticipantModalVisible }) => (
  <div
    onClick={() => {
      setIsInviteExternalParticipantModalVisible(true);
    }}
    style={styles.container}
  >
    <TextFont size={16}>
      <p>
        {email} - <FormattedMessage id="addParticipant" defaultMessage={"Добавить участника"} />
      </p>
    </TextFont>
  </div>
);

const styles = {
  container: {
    margin: "16px 0 16px 0",
    cursor: "pointer",
  },
};
