import { toast } from "react-toastify";

import { TypeNotify } from "@store/middlewares/notify/constants";

import { intl } from "./translate";

const bg: Record<TypeNotify, string> = {
  warning: "#FFB800",
  success: "#000000BF",
  error: "#FF3B30",
};

let lastMessage = "";

export const showToast = (text: string, type: TypeNotify = "success") => {
  if (lastMessage !== text) {
    toast[type](intl.getMessage(text), {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 25,
      theme: "dark",
      icon: false,
      style: {
        backgroundColor: bg[type],
        padding: "0 5px",
        height: "42px",
      },
    });

    lastMessage = text;
    setTimeout(() => (lastMessage = ""), 1000);
  }
};
