import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { saveDate } from "@store/screenDay";

const useGoToSpecificDays = (args?: { handleClose?: () => void; callback?: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToSpecificDay = (day: string) => {
    navigate("/");
    dispatch(saveDate(dayjs(day)));

    args?.handleClose?.();
    args?.callback?.();
  };

  return goToSpecificDay;
};

export default useGoToSpecificDays;
