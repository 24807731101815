import globalState from "@utils/globalState";

type TKeys = "billet" | "blockDate" | "wrapperDate" | "oneOfLines" | "twoOfLines";

export const commonStyles: Record<TKeys, any> = {
  billet: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px",
    backgroundColor: globalState.colorSchema.white,
    borderRadius: 11,
    marginTop: 8,
    width: "100%",
  },
  wrapperDate: {
    display: "flex",
    "flex-direction": "row",
  },
  blockDate: {
    backgroundColor: globalState.colorSchema.background.main,
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 9,
    paddingRight: 5,
  },
  oneOfLines: {
    lineHeight: "24px",
    height: 24,
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    "white-space": "nowrap",
  },
  twoOfLines: {
    "line-height": "24px",
    maxHeight: 48,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    webkitLineClamp: "2",
    LineClamp: "2",
    "-line-clamp": "2",
    WebkitBoxOrient: "vertical",
    "-webkit-box-orient": "vertical",
  }
};
