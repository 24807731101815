import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import {getGroupedList, getStatistic, selectBusinessTasks, TaskSubtypes} from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { Prompting, TextFont } from "@ui";
import { toFormatDate } from "@utils";
import globalState from "@utils/globalState";
import { TTaskSubType} from "@interfaces/businessTask.interface";
import { Modal, SwipeList } from "../ListTasks";

import { ReactComponent as SvgEmptyList } from "./assets/empty_list.svg";
import { ButtonsAnalysis } from "./Buttons";
import { AdditionalTask, Comment, HeaderAnalysis, NotEngaged } from "./components";
import { IActiveModal, TypeActiveModal, TypeElements, titlesStatistic } from "./config";

export const Analysis: FC = () => {
  const dispatch = useDispatch<any>();
  const { isTablet } = useDimension();

  const [isVisible, setIsVisible] = useState(false);
  const [activeModal, setActiveModal] = useState<IActiveModal | undefined>(undefined);
  const [id, setId] = useState("");

  const [itemSubtype, setItemSubtype] = useState<TaskSubtypes>();
  const isPersonalTask = useMemo(() => itemSubtype === TaskSubtypes.Personal, [itemSubtype]);

  const dateNow = useSelector(selectCurrentDate);
  const { tasks, statistic, isLoadingList } = useSelector(selectBusinessTasks);

  useEffect(() => {
    dispatch(getStatistic(toFormatDate(dateNow)));
  }, [tasks]);

  useEffect(() => {
    dispatch(getGroupedList({ date: toFormatDate(dateNow) }));
  }, [dateNow]);

  const closeModal = useCallback(() => setActiveModal(undefined), []);

  const handlePress = (id: string, subType?: TTaskSubType) => {
    setItemSubtype(subType);
    setId(id);
    setIsVisible(true);
  };

  const isToday = useMemo(() => toFormatDate(dateNow) === toFormatDate(dayjs()), [dateNow]);

  const msgDidYouDoThisTask = useIntl().formatMessage({ id: "didYouDoThisTask" });
  const msgToday = useIntl().formatMessage({ id: "today" }).toLowerCase();

  const modals: Record<TypeActiveModal, JSX.Element> = {
    comment: <Comment close={closeModal} id={activeModal?.id} isPersonalTask={itemSubtype === TaskSubtypes.Personal} />,
    additionalTask: <AdditionalTask close={closeModal} />,
    notEngaged: <NotEngaged id={activeModal?.id} close={closeModal} isPersonalTask={isPersonalTask} />,
  };

  const elements: Record<TypeElements, JSX.Element> = {
    title: (
      <RenderWithCondition condition={tasks.length}>
        <div style={styles.wrapperTitle}>
          <TextFont type="bold" style={styles.title}>{`${msgDidYouDoThisTask} ${
            isToday ? msgToday : dateNow.format("DD.MM.YY г.")
          }?`}</TextFont>
        </div>
      </RenderWithCondition>
    ),
    list: (
      <RenderWithCondition condition={tasks.length}>
        <LoaderRenderWithCondition condition={isLoadingList}>
          <SwipeList
            allowPressIcon
            data={tasks}
            press={handlePress}
            buttons={(data) => <ButtonsAnalysis {...data} setActiveModal={setActiveModal} />}
            setItemSubtype={setItemSubtype}
          />
        </LoaderRenderWithCondition>
      </RenderWithCondition>
    ),
    button: (
      <div style={styles.wrapperButton}>
        <button style={styles.button} onClick={() => setActiveModal({ ...activeModal, visible: "additionalTask" })}>
          <TextFont type="bold" size={18} weight="700" color={globalState.colorSchema.white} style={{ textAlign: "center" }}>
            <FormattedMessage id="addUnplannedTask" />
          </TextFont>
        </button>
      </div>
    ),
    statistic: (
      <RenderWithCondition condition={statistic}>
        <>
          <TextFont type="bold" size={20} weight="700" style={{ ...styles.title, marginBottom: 10 }}>
            <FormattedMessage id="planningResult" />
          </TextFont>

          {statistic &&
            Object.keys(statistic).map(
              (i) =>
                i !== "businessTasks" && (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 6,
                    }}
                  >
                    <TextFont size={16} style={{ flex: 6 }}>
                      <FormattedMessage id={titlesStatistic[i]} />
                    </TextFont>
                    <TextFont type="bold" size={16} style={{ flex: 1, textAlign: "center" }}>
                      {statistic[i]}
                    </TextFont>
                  </div>
                )
            )}
        </>
      </RenderWithCondition>
    ),
  };

  return (
    <div style={{ display: "grid", gridTemplateRows: "7% 93%", height: "100vh" }}>
      <HeaderAnalysis />
      <div style={{ display: "flex", overflow: "auto", flexDirection: isTablet ? "row" : "column" }}>
        <div style={{ ...styles.sideTablet, ...styles.leftSideTablet, marginRight: isTablet ? "20px" : 0 }}>
          {tasks.length ? (
            <>
              {elements.title}
              <div style={{ flex: 0.95 }}>{elements.list}</div>
            </>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "80%" }}>
              <SvgEmptyList />
              <TextFont type="bold" style={{ fontSize: 24, marginTop: 30 }}>
                Список задач пуст
              </TextFont>
              <div style={{ marginTop: 20 }}>{elements.button}</div>
            </div>
          )}
        </div>
        <div style={{ ...styles.sideTablet, ...styles.rightSideTablet }}>
          <RenderWithCondition condition={tasks.length}>
            <div style={{ marginBottom: 40 }}>{elements.button}</div>
          </RenderWithCondition>

          <div style={{ margin: "0 18px" }}>{elements.statistic}</div>
        </div>
      </div>

      <RenderWithCondition condition={Boolean(activeModal)}>{modals[activeModal?.visible]}</RenderWithCondition>

      <Prompting keySetting="isPromptAnalysis" kind="analysis" />
      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} id={id} taskSubType={itemSubtype} />
      </ModalRenderWithCondition>
    </div>
  );
};

const styles = {
  wrapperTitle: {
    padding: "0 12px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
  },
  wrapperButton: {
    padding: "0 12px",
  },
  button: {
    display: "flex",
    borderRadius: "10px",
    backgroundColor: globalState.colorSchema.background.green,
    "flex-direction": "row",
    justifyContent: "center",
    padding: "16px 10px",
    width: "100%",
  },
  sideTablet: {
    backgroundColor: globalState.colorSchema.background.main,
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  leftSideTablet: {
    flex: 1.2,
    borderTopRightRadius: "20px",
  },
  rightSideTablet: {
    flex: 0.8,
    borderTopLeftRadius: "20px",
    paddingLeft: "18px",
    paddingRight: "18px",
  },
};
