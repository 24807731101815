import { createAsyncThunk } from "@reduxjs/toolkit";

import { personalTasks } from "@services/personalTasks.service";
import { resetMeetCache } from "@store/businessTask/api";
import { getError } from "@store/getError";

export const getPersonal: any = createAsyncThunk("personalTasks/get", async (id: string, thunkAPI) => {
  try {
    const response = await personalTasks.get(id);
    return { response, id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const createPersonal: any = createAsyncThunk(
  "personalTasks/create",
  async (data: { body: Record<string, string>; isAdd: boolean }, thunkAPI) => {
    try {
      const response = await personalTasks.create(data.body);

      resetMeetCache(response, thunkAPI, "LIST");
      return { response, isAdd: data.isAdd };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);
