import React, { FC, useEffect, useState } from "react";

import { AnyAction } from "@reduxjs/toolkit";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TOneSTaskResolutions } from "@interfaces/businessGoals.interface";
import { setOnesTaskResolution } from "@store/businessTask/api";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { AddResolutionCommentModal } from "./AddResolutionCommentModal";
import { OneSResolutions as ButtonTypes, IResoutionButton, OneSResolutions } from "./constants";
import { generateButtonDescriptionsAsset } from "./utils";

interface IProps {
  taskId: string;
  availableResulutions: TOneSTaskResolutions[];
  oneSTaskResolution: TOneSTaskResolutions | null;
  oneSTaskStatus: TOneSTaskResolutions;
}

export const Resolution1CActions: FC<IProps> = ({ taskId, availableResulutions, oneSTaskResolution, oneSTaskStatus }) => {
  const [isCommentModalVisible, setIsCommentVisible] = useState(false);
  const [pressedButton, setPressedButton] = useState<IResoutionButton | null>(null);
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const [response, setResponse] = useState<AnyAction>();
  const dispatch = useDispatch();
  const styles = style();
  const [isLoading, setIsLoading] = useState(false);

  const handleTaskResolution = async (resolution: OneSResolutions) => {
    setIsLoading(true);
    const response = await dispatch(setOnesTaskResolution({ taskId, resolution }));
    setResponse(response);
    setIsLoading(false);
  };

  const handleResolutionPress = async (button: IResoutionButton) => {
    switch (button.type) {
      case ButtonTypes.COMPLETE_AGREEMENT:
      case ButtonTypes.REGISTER:
      case ButtonTypes.COMPLETE_REGISTRATION:
      case ButtonTypes.CONFIRMED:
      case ButtonTypes.NOT_CONFIRMED:
      case ButtonTypes.SIGN:
      case ButtonTypes.REJECT:
      case ButtonTypes.COMPLETE_CONFIRMATION:
      case ButtonTypes.COMPLETE_SIGNATURE:
        handleTaskResolution(button.type);
        break;
      case ButtonTypes.AGREED:
      case ButtonTypes.EXECUTED:
      case ButtonTypes.AGREED_WITH_REMARKS:
      case ButtonTypes.NOT_AGREED:
      case ButtonTypes.REPEAT_AGREEMENT:
      case ButtonTypes.DO_NOT_REGISTER:
      case ButtonTypes.REPEAT_REGISTRATION:
      case ButtonTypes.REPEAT_CONFIRMATION:
      case ButtonTypes.REPEAT_SIGNATURE:
      case ButtonTypes.RETURN:
      case ButtonTypes.COMPLETE:
      case ButtonTypes.FAMILIARIZED:
        setPressedButton(button);
        setIsCommentVisible(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if ((response && response?.meta?.requestStatus === "rejected") || response?.error?.message) {
      setIsButtonsVisible(true);
    } else {
      setIsButtonsVisible(false);
    }
  }, [response]);

  useEffect(() => {
    setIsButtonsVisible(!oneSTaskResolution);
  }, []);

  const generateButtons = () => {
    const buttonsAsset = generateButtonDescriptionsAsset(availableResulutions);

    return (
      <div style={styles.container}>
        {buttonsAsset &&
          buttonsAsset.map((button, index) => {
            const isFullWidthBtn = index === 0 && (buttonsAsset.length === 1 || buttonsAsset.length === 3);
            const isSingleBtn = buttonsAsset.length === 1;

            const btnStyless = {
              ...styles.button,
              ...(button.isAccent && styles.accentButton),
              ...(isFullWidthBtn && styles.fullWidthButton),
              ...(isSingleBtn && { height: 56 }),
            };

            return (
              <button style={btnStyless} onClick={() => handleResolutionPress(button)} key={button.type} disabled={isLoading}>
                <TextFont style={{ ...styles.btnText, ...(button.isAccent && { color: "#fff", fontWeight: "bold" }) }}>
                  <FormattedMessage id={button.textId} defaultMessage={button.defaultMessage} />
                </TextFont>
              </button>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <RenderWithCondition condition={isButtonsVisible}>
        <>{generateButtons()}</>
      </RenderWithCondition>
      <ModalRenderWithCondition condition={isCommentModalVisible}>
        <AddResolutionCommentModal
          isVisible={isCommentModalVisible}
          setIsVisible={setIsCommentVisible}
          buttonMeta={pressedButton}
          taskId={taskId}
        />
      </ModalRenderWithCondition>
    </>
  );
};

const style = () => {
  const theme = globalState.colorSchema;

  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
      "flex-direction": "row",
      justifyContent: "space-between",
    },
    button: {
      width: "49%",
      marginBottom: 10,
      padding: "10px 5px 10px 5px",
      minHeight: 44,
      borderRadius: 9,
      backgroundColor: theme.lighGrey,
      justifyContent: "center",
      alignItems: "center",
    },
    accentButton: {
      backgroundColor: theme.background.green,
    },
    fullWidthButton: {
      width: "100%",
    },
    btnText: {
      fontSize: 16,
      color: theme.text.main,
      textAlign: "center",
    },
  };
};
