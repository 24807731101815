import React, { useMemo, useState } from "react";

import { isEqual } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { SCREEN_PADDING } from "@constants/settings";
import { updateUser } from "@store/user";
import { TextFont, HeaderModal, ModalUI } from "@ui";

type TypeKey = "firstName" | "lastName" | "middleName";

export const inputs: Array<TypeKey> = ["lastName", "firstName", "middleName"];

interface IProps {
  close: () => void;
  isVisible: boolean;
  startData: Record<TypeKey, string | null>;
}

export const ModalEditFIO = ({ close, isVisible, startData }: IProps) => {
  const dispatch = useDispatch<any>();

  const [data, setData] = useState(startData);

  const changeData = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value ? value : null,
    });
  };

  const handleSave = async () => {
    await dispatch(updateUser(data));
    close();
  };

  const isEdit = useMemo(() => !isEqual(data, startData), [data, startData]);

  const placeholders = {
    firstName: useIntl().formatMessage({ id: "firstName" }),
    lastName: useIntl().formatMessage({ id: "lastName" }),
    middleName: useIntl().formatMessage({ id: "middleName" }),
  };

  return (
    <ModalUI isVisible={isVisible} onClose={close}>
      <HeaderModal onClose={close} title="yourName" isEdit={isEdit} onSave={handleSave} isCancel={false} />
      <div style={{ ...styles.container, ...SCREEN_PADDING }}>
        <div style={styles.code}>
          {inputs.map((input) => (
            <input
              value={data[input] ?? ""}
              placeholder={placeholders[input]}
              onChange={(e) => changeData(input, e.target.value)}
              style={styles.input}
              maxLength={255}
            />
          ))}
        </div>

        <button style={{ ...styles.button, ...(!isEdit && styles.buttonDisabled) }} disabled={!isEdit} onClick={handleSave}>
          <TextFont type="bold" style={{ color: "#fff", fontSize: 18 }}>
            <FormattedMessage id="save" />
          </TextFont>
        </button>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    paddingTop: 18,
  },
  code: {
    display: "flex",
    "flex-direction": "column",
    marginBottom: 20,
  },
  input: {
    fontSize: 18,
    borderRadius: 10,
    border: "1px solid #B3BEB8",
    marginRight: 10,
    padding: "16px 20px",
    height: 56,
    marginBottom: 10,
    backgroundColor: "#fff",
  },
  button: {
    width: "100%",
    backgroundColor: "#297952",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    height: 56,
  },
  buttonDisabled: {
    backgroundColor: "#C7C7C7",
  },
};
