import React, { FC, useMemo } from "react";

import { FormattedMessage } from "react-intl";

import { RenderWithCondition } from "@hoc";
import { TOneSTaskResolutions } from "@interfaces/businessGoals.interface";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { OneSResolutions } from "./resolutionOneSActionButtons/constants";

interface IProps {
  isFromOnes: boolean;
  oneSTaskStatus: TOneSTaskResolutions | null;
}

/*
ToDo: Будущий спринт. Убрать хардкод и сделать маппинг статусов как только
с бэка будет приходить поле со статусом.
*/

export const OneSTaskStatusHeader: FC<IProps> = ({ isFromOnes = false, oneSTaskStatus }) => {
  const styles = style();

  /* Для пропса oneSTaskStatus убрать смешение oneSTaskType и oneSTaskAvailableResolutions  */
  const textStatus = useMemo(() => {
    switch (oneSTaskStatus) {
      case "AGREEMENT":
        return "oneSApproveStatus";
      case "FAMILIARIZATION":
        return "oneSFamiliarizeStatus";
      case "EXECUTION":
      case "COMPLETE":
        return "oneSExecuteStatus";

      default:
        return "oneSExecuteStatus";
    }
  }, [oneSTaskStatus]);

  return (
    <>
      <RenderWithCondition condition={isFromOnes && oneSTaskStatus && textStatus}>
        <div style={styles.container}>
          <TextFont style={styles.text}>
            <FormattedMessage id={textStatus} />
          </TextFont>
        </div>
      </RenderWithCondition>
    </>
  );
};

const style = () => {
  const theme = globalState.colorSchema;

  return {
    container: {
      display: "flex",
      "flex-direction": "column",
      alignItems: "center",
      justifyContent: "center",
      height: 36,
      backgroundColor: theme.lightYellow,
    },
    text: {
      color: theme.orange,
    },
  };
};
