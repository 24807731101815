import React, { FC } from "react";

import { NotificationClear } from "./NotificationClear";
import { NotificationConfirm } from "./NotificationConfirm";
import { NotificationError } from "./NotificationError";
import { NotificationSuccess } from "./NotificationSuccess";

interface IProps {
  type: "success" | "confirm" | "error" | "clear";
  phraseId: string;
  phraseOkId?: string;
  phraseCancelId?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

export const Notification: FC<IProps> = ({ type, phraseId, phraseCancelId, phraseOkId, onOk, onCancel }) => {
  switch (type) {
    case "success":
      return <NotificationSuccess phrase={phraseId} />;
    case "confirm":
      return (
        <NotificationConfirm phraseId={phraseId} phraseCancelId={phraseCancelId} phraseOkId={phraseOkId} onCancel={onCancel} onOk={onOk} />
      );
    case "clear":
      return <NotificationClear phraseId={phraseId} phraseOkId={phraseOkId} onOk={onOk} />;
    case "error":
      return <NotificationError />;
    default:
      return null;
  }
};
