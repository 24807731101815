import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgAnalysis } from "@assets/analysis.svg";
import { ModalRenderWithCondition } from "@hoc";
import { Modal as ModalTask } from "@screens/day/components/ListTasks";
import { Modal } from "@screens/day/components/timetable/main/modal/Modal";
import { TextFont } from "@ui";

export const TabbarTablet = () => {
  const navigate = useNavigate();

  const [isVisibleTask, setIsVisibleTask] = useState(false);
  const [isVisibleMeet, setIsVisibleMeet] = useState(false);

  return (
    <aside style={styles.container}>
      <div style={{ ...styles.tightTab, position: "absolute" }}>
        <button onClick={() => setIsVisibleTask(true)} style={{ ...styles.tightTabBlock, ...styles.borderRight }}>
          <TextFont type="bold" style={{ ...styles.tightBlockText, marginRight: 5 }}>
            +
          </TextFont>
          <TextFont type="bold" style={styles.tightBlockText}>
            <FormattedMessage id="task" />
          </TextFont>
        </button>
        <button onClick={() => setIsVisibleMeet(true)} style={{ ...styles.tightTabBlock, ...styles.borderRight }}>
          <TextFont type="bold" style={{ ...styles.tightBlockText, marginRight: 5 }}>
            +
          </TextFont>
          <TextFont type="bold" style={styles.tightBlockText}>
            <FormattedMessage id="meeting" />
          </TextFont>
        </button>
        <button onClick={() => navigate("analysis")} style={{ ...styles.tightTabBlock, flex: 1.2 }}>
          <SvgAnalysis />
          <TextFont type="bold" style={styles.tightBlockText}>
            <FormattedMessage id="dayAnalysis" />
          </TextFont>
        </button>
      </div>
      <ModalRenderWithCondition condition={isVisibleMeet}>
        <Modal isVisible={isVisibleMeet} meetId={""} setIsVisible={setIsVisibleMeet} />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleTask}>
        <ModalTask isVisible={isVisibleTask} id={""} setIsVisible={setIsVisibleTask} />
      </ModalRenderWithCondition>
    </aside>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  tightTab: {
    display: "flex",
    height: "36px",
    bottom: "7%",
    padding: "0 10px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    zIndex: 997,
  },
  tightTabBlock: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  tightBlockText: {
    fontSize: "16px",
    color: "#297952",
    fontWeight: "700",
  },
  borderRight: {
    borderRight: "1px solid #E3E9E7",
  },
};
