import React, { FC, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Close } from "@assets/close-thin.svg";
import { ReactComponent as SvgMeet } from "@assets/meet.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { selectSpecialTag } from "@store/tag";
import { selectTheme } from "@store/theme";
import { Colors } from "@theme/colors";
import { Checkbox, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IEventItem {
  item: IMeet;
  checkbox?: boolean;
  isChecked?: boolean;
  allowDeletion?: boolean;
  date: string;
  onDelete?: () => void;
  onPress?: (meetingId: string) => void;
  handleCheckedItems?: (id: string, item: IMeet, date: string) => void;
}

export const EventItem: FC<IEventItem> = ({ item, checkbox, isChecked, date, allowDeletion, onDelete, onPress, handleCheckedItems }) => {
  const [isItemChecked, setIsItemChecked] = useState(isChecked);
  const tagName = useSelector(selectSpecialTag(item.tagId ?? ""));
  const theme = selectTheme("extra");

  const startDate = dayjs(item.startTime).format("DD.MM");
  const startTime = dayjs(item.startTime).format("HH:mm");
  const style = styles();
  const handleItemCheckBox = (event: React.MouseEvent<HTMLButtonElement>, itemId: string, date: string) => {
    event.stopPropagation();
    setIsItemChecked(!isItemChecked);
    handleCheckedItems && handleCheckedItems(itemId, item, date);
  };

  const handleDeleteWrapper = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete();
  };

  return (
    <div
      style={style.containerStyles}
      onClick={() => {
        onPress && onPress(item.id);
      }}
    >
      {checkbox ? (
        <Checkbox isChecked={isItemChecked} onChange={(e) => handleItemCheckBox(e, item.id, date)} style={style.checkbox} />
      ) : (
        <div style={style.meetIcon}>
          <SvgMeet fill={theme.background.green} />
        </div>
      )}
      <div style={style.eventInfo}>
        <TextFont>
          <RenderWithCondition condition={tagName}>
            <span style={{ fontWeight: "bold" }}>{tagName?.name} </span>
          </RenderWithCondition>
          <span style={style.text}>{item.name}</span>
        </TextFont>
      </div>

      <div style={{ display: "flex", gap: 5 }}>
        <MeetTime checkbox={checkbox}>{startDate}</MeetTime>
        <MeetTime checkbox={checkbox}>{startTime}</MeetTime>
      </div>
      <RenderWithCondition condition={allowDeletion}>
        <button
          onClick={(e) => {
            handleDeleteWrapper(e);
          }}
          style={style.removeIconContainer}
        >
          <Close />
        </button>
      </RenderWithCondition>
    </div>
  );
};

const styles = () => {
  const { colorSchema } = globalState;

  return {
    containerStyles: {
      display: "flex",
      "flex-direction": "row",
      height: 48,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 5,
      alignItems: "center",
      backgroundColor: colorSchema.white,
      borderRadius: 5,
      cursor: "pointer",
    },
    eventInfo: {
      paddingRight: 5,
      flex: 1,
      maxHeight: 36,
      "overflow-y": "hidden",
    },
    text: {
      size: 14,
      fontWeight: 400,
      lineHeight: "18px",
    },
    meetIcon: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    removeIconContainer: {
      marginLeft: 10,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 5,
      paddingRight: 5,
    },
    checkbox: {
      marginRight: 8,
      marginLeft: 5,
    },
  };
};

const MeetTime = styled.p<{ checkbox }>`
  color: ${(props) => (props.checkbox ? "#297952" : props.theme.extra.text.accent)};
  line-height: "18px";
  font-size: 14;
  margin-left: "auto";
`;
