import React from "react";

import { ReactComponent as SvgMeet } from "@assets/meet.svg";
import { ReactComponent as SvgPeople } from "@assets/people.svg";
import { TEventType } from "@interfaces/eventsOfDay.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

export const HEIGHT_ICON = 13;

type TConfigEvent = {
  backgroundColor: string;
  textColor: string;
  icon: JSX.Element;
};

export const configEvent = (height?: number, iconFillColor?: string, hasParticipants?: boolean): Record<TEventType, TConfigEvent> => ({
  TASK: {
    backgroundColor: "#D1E7E0",
    textColor: "#297952",
    icon: (
      <TextFont type="bold" style={{ color: iconFillColor ?? "#297952", fontSize: 12, fontWeight: "700" }}>
        З
      </TextFont>
    ),
  },
  MEETING: {
    backgroundColor: "#EAF1FE",
    textColor: globalState.colorSchema.blue,
    icon: (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
        {hasParticipants ? (
          <SvgPeople height={height} width={height} fill={iconFillColor ?? Colors.DARK.blue} />
        ) : (
          <SvgMeet height={height} fill={iconFillColor ?? Colors.DARK.blue} />
        )}
      </div>
    ),
  },
});
