import React from "react";

import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { Modal as ModalTask } from "@screens/day/components/ListTasks";
import { AddCircle, HeaderModal, ModalUI } from "@ui";
import { Notification } from "@ui/notification/Notification";
import { NotificationClear } from "@ui/notification/NotificationClear";

import { useData } from "./useData";

import { Actions, Description, Tasks, WorkMonths } from "./index";

interface IModal {
  isVisible: boolean;
  close: () => void;
  goalId: string;
}

export const Modal = ({ isVisible, close, goalId }: IModal) => {
  const {
    id,
    ref,
    isEdit,
    isCancel,
    setIsCancel,
    isDelete,
    setIsDelete,
    data,
    handleData,
    isVisibleModal,
    setIsVisibleModal,
    isLoading,
    goal,
    handlePress,
    handleSave,
    handleAddMonth,
    handleDeleteNotification,
    handleCancelNotification,
    msgBusinessDeleteAlert,
    msgCancelExeption,
    isVisibleEmptyYearMonths,
    setIsVisibleEmptyYearMonths,
    handleRemoveMonth,
  } = useData({ close, goalId });

  return (
    <div>
      <ModalUI isVisible={isVisible} onClose={isEdit ? () => setIsCancel(true) : close}>
        <LoaderRenderWithCondition condition={isLoading}>
          <>
            <>
              <HeaderModal
                title={goal ? "goal" : "newGoal"}
                onClose={close}
                isEdit={isEdit}
                onCancel={() => setIsCancel(true)}
                onSave={handleSave}
              />
              <section style={styles.main}>
                <Description goal={goal} description={data.description ?? ""} handleData={handleData} />
                <div style={styles.actions}>
                  <WorkMonths
                    months={data.yearMonths ?? []}
                    isAddMonth={data.status === "NOT_DONE"}
                    onSave={handleAddMonth}
                    handleRemoveMonth={handleRemoveMonth}
                  />
                  {goal ? (
                    <>
                      <Tasks tasks={goal.businessTasks} press={handlePress} />
                      <Actions
                        goal={goal}
                        setIsDelete={setIsDelete}
                        status={goal ? goal.status : "NOT_DONE"}
                        isEdit={isEdit}
                        close={close}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </>
            <RenderWithCondition condition={!!goal?.businessTasks?.length}>
              <AddCircle press={() => handlePress("")} bottom={42} right={22} />
            </RenderWithCondition>
          </>
        </LoaderRenderWithCondition>
        <ModalRenderWithCondition condition={isVisibleModal}>
          <ModalTask
            data={{ businessGoalId: goalId }}
            isVisible={isVisibleModal}
            setIsVisible={setIsVisibleModal}
            id={id}
            disablePersonalTasks
          />
        </ModalRenderWithCondition>
        <RenderWithCondition condition={isDelete}>
          {goal?.businessTasks?.length ? (
            <NotificationClear phraseId="businessDeleteNotAllow" onOk={() => setIsDelete(false)} phraseOkId="Ок" />
          ) : (
            <Notification
              type="confirm"
              phraseId={msgBusinessDeleteAlert}
              onOk={handleDeleteNotification}
              onCancel={() => setIsDelete(false)}
            />
          )}
        </RenderWithCondition>
        <RenderWithCondition condition={isVisibleEmptyYearMonths}>
          <NotificationClear phraseId="lastMonthDeleting" onOk={() => setIsVisibleEmptyYearMonths(false)} phraseOkId="Ок" />
        </RenderWithCondition>

        <RenderWithCondition condition={isCancel}>
          <Notification type="confirm" phraseId={msgCancelExeption} onOk={handleCancelNotification} onCancel={() => setIsCancel(false)} />
        </RenderWithCondition>
      </ModalUI>
    </div>
  );
};

const styles = {
  main: {
    flex: 1,
  },
  actions: {
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    paddingLeft: "11px",
    paddingRight: "11px",
    backgroundColor: "#F5F9F8",
  },
  active: {
    backgroundColor: "#E3E9E7",
    border: "1px solid #297952",
  },
  inactive: {
    backgroundColor: "#ECF0EF",
  },
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
};
