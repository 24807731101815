import React, { FC, memo, useMemo, MouseEvent, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ReactComponent as CloseSvg } from "@assets/close-thin.svg";
import { ReactComponent as SvgDeadline } from "@assets/deadline.svg";
import { ReactComponent as SvgDelegate } from "@assets/delegate.svg";
import { ReactComponent as SvgOneS } from "@assets/one-s.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import { RenderWithCondition } from "@hoc";
import { useInfoTask } from "@hooks/useInfoTask";
import { ITask } from "@interfaces/businessGoals.interface";
import { TTaskSubType } from "@interfaces/businessTask.interface";
import { IColors } from "@interfaces/theme.interface";
import { IStateUser } from "@interfaces/user.interface";
import { TaskSubtypes, selectBusinessTasks } from "@store/businessTask";
import { selectTheme } from "@store/theme";
import { selectUserPermissoins } from "@store/user";
import { commonStyles } from "@styles/styles";
import { TextFont } from "@ui";
import { Checkbox } from "@ui";
import { bgItem } from "@ui/taskActions/config";
import { arePropsEqual } from "@utils/index";
import { toFormatHHmm } from "@utils/toFormatTime";

import { Icon } from "./icon/Icon";

interface IItem {
  index: number;
  item: ITask;
  press: (id: string, subType?: TTaskSubType) => void;
  bg?: string;
  isLast?: boolean;
  allowForget?: boolean;
  onRightClick: (arg1: MouseEvent<HTMLButtonElement>, arg2: string, arg3: TaskSubtypes, isFromOneS: boolean) => void;
  checkbox?: boolean;
  isChecked?: boolean;
  allowDeletion?: boolean;
  meetingTaskStyles?: boolean;
  onDelete?: () => void;
  handleCheckedTask?: (id: ITask) => void;
  allowPressIcon?: boolean;
  sectionTitle?: string;
  isRightClick?: boolean;
}

export const Item: FC<IItem> = memo(function Item({
  index,
  item,
  press,
  bg,
  isLast = false,
  onRightClick,
  allowForget = false,
  checkbox = false,
  isChecked = false,
  allowDeletion = false,
  meetingTaskStyles = false,
  onDelete,
  handleCheckedTask,
  allowPressIcon,
  sectionTitle,
  ...props
}) {
  const name = useLocation().pathname;
  const colorSchema = selectTheme("extra");
  const { provided, innerRef } = props;

  const style = styles(colorSchema);

  const { isCompleted, workDay, status, handleForget, handleCheckBox } = useInfoTask(item);
  const [isItemChecked, setIsItemChecked] = useState<boolean>(isChecked);

  const isPersonalTask = item.subType === TaskSubtypes.Personal;
  const isFromOneS = item.isFromOneS ?? false;

  const { currentTask } = useSelector(selectBusinessTasks);
  const permissions = useSelector(selectUserPermissoins);

  const handlePressIcon = (e) => {
    if (allowPressIcon && !isFromOneS) {
      handleCheckBox(e);
    }
  };

  const handleTaskCheckBox = (event: React.MouseEvent<HTMLButtonElement>, item: ITask) => {
    event.stopPropagation();
    setIsItemChecked(!isItemChecked);
    handleCheckedTask && handleCheckedTask(item);
  };

  const handleDeleteWrapper = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete();
  };

  const isDeadlineOut = useMemo(() => item.plannedEndDate && dayjs().isAfter(item.plannedEndDate, "day"), [item.plannedEndDate]);

  const generateDate = useMemo(() => {
    const color = isDeadlineOut ? colorSchema.white : "#939393";

    let text = "";

    if (item.plannedEndDate) {
      text = dayjs(item.plannedEndDate).format("DD.MM");
    } else if (workDay?.startTime && workDay.endTime) {
      text = `${toFormatHHmm(dayjs(workDay?.startTime))}-${toFormatHHmm(dayjs(workDay?.endTime))}`;
    }

    if (!text) return "";

    return (
      <div style={{ ...style.wrapperDate, ...(!!isDeadlineOut && style.wrapperDeadlineOut) }}>
        {item.plannedEndDate ? <SvgDeadline fill={isDeadlineOut ? colorSchema.white : colorSchema.orange} /> : ""}
        <TextFont style={{ color }}>{text}</TextFont>
      </div>
    );
  }, [item.timeEnabled, item.plannedEndDate, workDay?.startTime, workDay?.endTime, isDeadlineOut]);

  const textDelegate = (delegated: IStateUser | null) =>
    `${delegated?.lastName} ${delegated?.firstName ? `${delegated?.firstName.slice(0, 1)}.` : ""}`;

  const colorTitle = useMemo(
    () => (isCompleted || status === "dealWith" ? colorSchema.grey : colorSchema.text.main),
    [isCompleted, status],
  );

  const projectName = useMemo(() => {
    const shortName = item.project?.shortName ? `${item.project?.shortName} ` : "";
    if (shortName) return shortName;

    const projectName = item.project?.name;
    if (projectName?.length > 21) {
      return `${projectName.slice(0, 3)}...${projectName.slice(projectName.length - (generateDate ? 30 : 40), projectName.length)}`;
    }
    return projectName;
  }, [item.project?.shortName, item.project?.name, generateDate]);

  const containerStyles = {
    ...style.taskBlock,
    ...(!innerRef && !provided && { cursor: "pointer" }),
    ...(meetingTaskStyles && style.msContainerStyles),
    ...(isLast && { marginBottom: 100 }),
    ...(!!bg && { backgroundColor: bg }),
    ...(index === 0 && !meetingTaskStyles && style.taskFirstBlock),
    ...(item.id === currentTask?.id && name === "/tasks" && { backgroundColor: "#fff" }),
    ...(name === "/analysis" &&
      !item.unplanned &&
      workDay &&
      bgItem[workDay.status] &&
      workDay && { backgroundColor: bgItem[workDay.status] }),
    ...(isPersonalTask && { backgroundColor: colorSchema.lightPurple, opacity: 1 }),
    ...(props.isRightClick && { backgroundColor: colorSchema.lighGrey }),
  };

  const taskNameStyles = {
    ...style.title,
    ...(item.delegatedToUser || item.project || item.plannedEndDate || item.tag ? { marginBottom: 1 } : {}),
    ...(meetingTaskStyles && { marginBottom: 0, paddingTop: 5 }),
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  return (
    <div
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      ref={innerRef}
      style={{ ...containerStyles, ...(provided && getItemStyle(provided?.draggableProps?.style)) }}
      onClick={() => press(item.id, item.subType)}
      onContextMenu={(e) => onRightClick(e, item.id, item.subType, item.isFromOneS)}
    >
      {checkbox ? (
        <Checkbox isChecked={isItemChecked} onChange={(e) => handleTaskCheckBox(e, item)} style={{ marginLeft: 10 }} />
      ) : (
        <Icon type={item.priority} status={status} extraIcon={item.status} press={handlePressIcon} />
      )}

      <div style={{ flex: 10 }}>
        <div style={{ display: "flex", justifyContent: "space-between", margin: 0, padding: 0 }}>
          <div style={{ ...taskNameStyles }}>
            <RenderWithCondition condition={item.tag?.name}>
              <span style={{ fontWeight: "bold", color: colorTitle, fontSize: 16 }}>{`${item.tag?.name} `}</span>
            </RenderWithCondition>
            <TextFont style={commonStyles.twoOfLines} color={colorTitle} size={16}>
              {item.name}
            </TextFont>
          </div>

          <RenderWithCondition condition={allowForget}>
            <div style={{ justifyContent: "flex-end" }}>
              <button
                onClick={(e) => handleForget(e, sectionTitle)}
                style={{ width: 20, height: 20, justifyContent: "center", alignItems: "center" }}
              >
                <SvgRemove fill={"#B3BEB8"} width={10} height={10} />
              </button>
            </div>
          </RenderWithCondition>
        </div>

        <div style={style.containerDelegate}>
          <RenderWithCondition condition={item.delegatedFromUser}>
            <div style={{ ...style.blockDelegate, marginRight: 10 }}>
              <div style={style.blockDelegateIcon}>
                <SvgTakeBack width={12} height={12} />
              </div>
              <RenderWithCondition condition={item.delegatedFromUser?.lastName}>
                <TextFont
                  color={item.status === "COMPLETED" ? "#DFD8FF" : colorSchema.purple}
                  style={style.delegateText}
                >{`от ${textDelegate(item.delegatedFromUser)}`}</TextFont>
              </RenderWithCondition>
            </div>
          </RenderWithCondition>
          <RenderWithCondition condition={permissions.CAN_DELEGATE && item.delegatedToUser}>
            <div style={style.blockDelegate}>
              <div style={style.blockDelegateIcon}>
                <SvgDelegate width={12} height={12} />
              </div>
              <RenderWithCondition condition={item.delegatedToUser?.lastName}>
                <TextFont
                  color={item.status === "COMPLETED" ? "#FFE296" : colorSchema.yellow}
                  style={style.delegateText}
                >{`на ${textDelegate(item.delegatedToUser)}`}</TextFont>
              </RenderWithCondition>
            </div>
          </RenderWithCondition>
        </div>

        <div style={style.taskBlockMain}>
          <div style={{ display: "flex", maxWidth: "80%" }}>
            <RenderWithCondition condition={isFromOneS}>
              <SvgOneS style={{ marginRight: 8 }} />
            </RenderWithCondition>

            {item.project ? (
              <div style={{ ...style.project, backgroundColor: isCompleted ? "#ECF0EF" : "#EAF1FE" }}>
                <TextFont style={{ ...style.projectName, color: isCompleted ? colorSchema.grey : colorSchema.blue }}>
                  {projectName}
                </TextFont>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", minWidth: 65 }}>
            <RenderWithCondition condition={generateDate}>
              <div style={{ display: "flex", marginBottom: 6.5, justifyContent: "flex-start" }}>{generateDate}</div>
            </RenderWithCondition>
          </div>
        </div>
      </div>

      {allowDeletion && (
        <button
          onClick={(e) => {
            handleDeleteWrapper(e);
          }}
          style={{ ...style.removeIconContainer, ...(generateDate && { alignSelf: "flex-start" }) }}
        >
          <CloseSvg scale={0.5} />
        </button>
      )}
    </div>
  );
},
arePropsEqual);

const styles = (colors: IColors) => ({
  title: {
    display: "flex",
    gap: "5px",
    maxWidth: "90%",
    fontSize: 16,
  },
  wrapperDate: {
    display: "flex",
    alignItems: "center",
    height: 24,
  },
  wrapperDeadlineOut: {
    backgroundColor: colors.red,
    borderRadius: 3,
    paddingRight: 6,
  },
  taskBlock: {
    display: "grid",
    "grid-template-rows": "1fr",
    gridTemplateColumns: "33px 1fr auto",
    gridGap: 10,
    paddingTop: 3,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5,
    borderBottom: `1px solid ${colors.lighGrey}`,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.background.main,
  },
  taskFirstBlock: {
    borderTop: `1px solid ${colors.lighGrey}`,
  },
  taskBlockMain: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "flex-end",
    maxHeight: 24,
  },
  project: {
    display: "flex",
    padding: "0 5px 0 5px",
    borderRadius: 6,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  projectName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  containerDelegate: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 5,
  },
  blockDelegate: {
    display: "flex",
    justifyContent: "flex-end",
  },
  blockDelegateIcon: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
  },
  delegateText: {
    "text-align": "right",
    marginLeft: 6,
  },
  msContainerStyles: {
    marginBottom: 8,
    borderBottomWidth: 0,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    minHeight: 48,
    backgroundColor: colors.white,
  },
  removeIconContainer: {
    height: 25,
    width: 25,
  },
});
