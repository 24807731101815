import React, { FC, useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgMap } from "@assets/map.svg";
import { regExpOnlyUrl, regExpUrl } from "@constants/regExp";
import { RenderWithCondition } from "@hoc";
import { Tab } from "@screens/day/components/tabs/Tab";
import { userSelector } from "@store/user";
import { selectUserPersonalSettings } from "@store/userSettings";
import { updatePersonalSettings } from "@store/userSettings/api";
import { TextFont, Billet, ModalUI, HeaderModal } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";

import { instruction, tabs } from "./config";

const eventHelperUtils = new EventHelperUtils();
interface IProps {
  link: string;
  setLink: (arg: string) => void;
}

export const LinkToZoom: FC<IProps> = ({ link, setLink }) => {
  const dispatch = useDispatch();

  const [isVisible, setisVisible] = useState(false);
  const [activeTab, setactiveTab] = useState<"pc" | "mobile">("pc");

  const settings = useSelector(selectUserPersonalSettings);
  const { directorId } = userSelector();

  useEffect(() => {
    if (settings?.videoConferencePermanentUrl) {
      setLink(settings?.videoConferencePermanentUrl);
    }
  }, [settings?.videoConferencePermanentUrl]);

  const handleInput = async (val: string) => {
    let tempText = "";
    const matches = val.match(regExpUrl);

    if (matches && matches[0]) {
      tempText = matches[0];
    } else tempText = val;

    setLink(tempText);

    eventHelperUtils.debounce(() => {
      if ((!!tempText.match(regExpOnlyUrl) && settings?.videoConferencePermanentUrl !== tempText) || tempText === "") {
        dispatch(updatePersonalSettings({ id: directorId, body: { videoConferencePermanentUrl: tempText } }));
      }
    }, 1000);
  };

  return (
    <>
      <div style={{ gap: 8, display: "flex", flexDirection: "column" }}>
        <TextFont size={18} weight="700">
          <FormattedMessage id="meetConstLink" />
        </TextFont>
        <input onChange={(e) => handleInput(e.target.value)} value={link} style={styles.input} placeholder="Ввести ссылку" />
        <TextFont size={12} color={"#5F6E67"}>
          Если у вас есть постоянная ссылка, вы можете добавить её сюда, и далее добавлять эту ссылку в свои встречи в один клик
        </TextFont>
        <Billet
          textId="manualZoom"
          onPress={() => setisVisible(true)}
          styleText={{ fontWeight: "700" }}
          before={<SvgMap fill={"green"} />}
        />
      </div>

      <ModalUI onClose={() => setisVisible(false)} isVisible={isVisible} scrollEnable={false}>
        <HeaderModal onClose={() => setisVisible(false)} title="manual" />
        <div style={{ padding: 16, height: "100%" }}>
          <div style={styles.wrapperTabs}>
            <div style={styles.tabs}>
              {tabs.map((tab) => {
                const title = useIntl().formatMessage({ id: tab.title });
                return <Tab key={tab.id} isActive={activeTab === tab.id} onPress={() => setactiveTab(tab.id)} title={title} />;
              })}
            </div>
          </div>

          <div style={{ overflowY: "scroll", height: "85%", padding: 10 }}>
            {instruction[activeTab].map((point, index) => (
              <div key={point.title}>
                <div style={{ display: "flex", gap: 5, width: "90%" }}>
                  <TextFont size={14} style={{ lineHeight: "24px" }}>
                    {index + 1}.{" "}
                  </TextFont>
                  <TextFont size={14} style={{ lineHeight: "24px" }}>
                    {point.title}
                  </TextFont>
                </div>
                <RenderWithCondition condition={point.img || point.imgAndroid}>
                  <Image style={{}} src={point.imgAndroid ? point.imgAndroid : point.img} />
                </RenderWithCondition>
              </div>
            ))}
          </div>
        </div>
      </ModalUI>
    </>
  );
};

const styles = {
  input: {
    padding: 12,
    backgroundColor: "white",
    borderRadius: 9,
  },
  wrapperTabs: {
    width: "95%",
    height: 50,
    backgroundColor: "#F5F9F8",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 16,
  },
  tabs: {
    width: "100%",
    height: 32,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
};

const Image = styled.img`
  width: 100%;
  margin: 12px 0;
  background-origin: content-box;
  :hover {
    scale: 1.2;
  }
`;
