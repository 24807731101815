import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { omit } from "lodash";

import { IFilter, TSingleSections } from "@interfaces/businessTask.interface";
import { OneSResolutions } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/resolutionOneSActionButtons/constants";
import { businessTasks } from "@services";
import { StoreTagTypes, api } from "@services/api";
import {
  IAddWorkDay,
  IClose,
  ICommentWorkDay,
  IGroupedList,
  IMoveDate,
  INeedMoreTime,
  IUpdatePosition,
} from "@services/businessTasks.service";
import { personalTasks } from "@services/personalTasks.service";
import { TaskSubtypes } from "@store/businessTask/constants";
import { getError } from "@store/getError";
import { handleRemoveTaskFromSingleSection } from "@store/middlewares/updateTasks/updateTasks.middleware";
import { showToast, toFormatDate } from "@utils";
import { intl } from "@utils/translate";

import { getServiceTasks } from "./utils";

interface IUpdateTask {
  id: string;
  isPersonalTask: boolean;
  data: Record<string, string>;
}

/**
 * Костыль. РТКК пока не знает об изменениях задач. Сбрасываем кэш.
 * ToDo: убрать после перевода апи задач на рткк.
 */
export const resetMeetCache = (response: any, thunkAPI, resetType: "ALL" | "LIST") => {
  const workDays = response?.data?.workDays;
  if (workDays && workDays.length) {
    workDays.map((workDay) => {
      if (workDay.startTime !== null && workDay.endTime !== null) {
        if (resetType === "LIST") {
          thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
        }

        if (resetType === "ALL") {
          thunkAPI.dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
        }
      }
    });
  }
};

export const getGroupedList: any = createAsyncThunk("businessTasks/getGroupedList", async (params: IGroupedList, thunkAPI) => {
  try {
    const response = await businessTasks.getGroupedList(omit(params, ["notLoader"]));
    return { response, notLoader: !!params.notLoader };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const paggingTransferredList: any = createAsyncThunk(
  "businessTasks/paggingTransferredList",
  async (data: { params: IGroupedList; type: TSingleSections }, thunkAPI) => {
    try {
      const response = await businessTasks.getGroupedList(data.params);
      return { response, next: data.params.next, type: data.type };
    } catch (error) {
      return getError(error, thunkAPI, "Данные не загрузились");
    }
  },
);

export const getFilteredList = createAsyncThunk("businessTasks/getFilteredList", async (params: Partial<IFilter>, thunkAPI) => {
  try {
    const { data } = await businessTasks.getFilteredList(omit(params, ["notLoader"]) as IFilter);
    return data;
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const paggingFilteredList = createAsyncThunk("businessTasks/paggingFilteredList", async (params: Partial<IFilter>, thunkAPI) => {
  try {
    const { data } = await businessTasks.getFilteredList(params);
    return data;
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const getSpecifyList = createAsyncThunk("businessTasks/getSpecifyList", async (goalId: string, thunkAPI) => {
  try {
    const response = await businessTasks.getList(goalId);
    return { response };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const updatePosition = createAsyncThunk("businessTasks/updatePosition", async (data: IUpdatePosition, thunkAPI) => {
  try {
    await getServiceTasks(data.isPersonalTask).updatePosition(data);

    return { data: data.result };
  } catch (error) {
    return getError(error, thunkAPI, "Данные не синхронизировались. Повторите попытку позже");
  }
});

export const get: any = createAsyncThunk("businessTasks/get", async (data: { id: string; isPersonalTask: boolean }, thunkAPI) => {
  try {
    const { id, isPersonalTask } = data;
    const response = await getServiceTasks(isPersonalTask).get(id);

    return { response, id, isPersonalTask };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const getForUpdate: any = createAsyncThunk(
  "businessTasks/getForUpdate",
  async (data: { id: string; isPersonalTask: boolean; actionName?: string }, thunkAPI) => {
    try {
      const { id, isPersonalTask, actionName } = data;
      const response = await getServiceTasks(isPersonalTask).get(id);

      if (actionName === "takeMe" && response) {
        const controlTasks = response?.data?.controlTasks ?? [];

        if (controlTasks.length) {
          showToast("controlTaskIsClosed");
        }
      }

      return { response, id };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const create = createAsyncThunk(
  "businessTasks/create",
  async (data: { body: Record<string, string>; isAdd: boolean; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const response = await getServiceTasks(data?.isPersonalTask).create(data.body);

      resetMeetCache(response, thunkAPI, "LIST");
      return { response, isAdd: data.isAdd };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const createUnplanned = createAsyncThunk("businessTasks/createUnplanned", async (data: Record<string, string>, thunkAPI) => {
  try {
    const response = await businessTasks.createUnplanned(data);
    return { response };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const createControl: any = createAsyncThunk(
  "businessTasks/createControl",
  async (data: { body: Record<string, string> }, thunkAPI) => {
    try {
      const response = await businessTasks.createControl(data.body);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const update = createAsyncThunk("businessTasks/update", async ({ id, isPersonalTask, data }: IUpdateTask, thunkAPI) => {
  try {
    const date = thunkAPI.getState().screenDay.date;
    const response = await getServiceTasks(isPersonalTask).update(id, data);

    resetMeetCache(response, thunkAPI, "ALL");

    return { response, id, date: toFormatDate(date) };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const deleteTask: any = createAsyncThunk(
  "businessTasks/deleteTask",
  async (data: { id: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const { id, isPersonalTask } = data;
      const response = await getServiceTasks(isPersonalTask).delete(id);

      thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

      return { response, id };
    } catch (error) {
      return getError(error, thunkAPI, intl.getMessage("deleteAlert"));
    }
  },
);

export const complete: any = createAsyncThunk("businessTasks/complete", async (data: { id: string; isPersonalTask: boolean }, thunkAPI) => {
  try {
    const { id, isPersonalTask } = data;
    const response = await getServiceTasks(isPersonalTask).complete(id);

    return { response, id, isPersonalTask, notifyText: intl.getMessage("missionCompleted") };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const close: any = createAsyncThunk("businessTasks/close", async (data: { amount: IClose; isPersonalTask: boolean }, thunkAPI) => {
  try {
    const { amount, isPersonalTask } = data;
    const response = await getServiceTasks(isPersonalTask).close(amount);

    thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

    return { response, id: amount.id, isPersonalTask, amount: { closeReason: amount.reason, closeComment: amount.comment } };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const reopen: any = createAsyncThunk("businessTasks/reopen", async (data: { id: string; isPersonalTask: boolean }, thunkAPI) => {
  try {
    const { id, isPersonalTask } = data;
    const response = await getServiceTasks(isPersonalTask).reopen(id);

    thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

    return { response, id };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const pause: any = createAsyncThunk(
  "businessTasks/pause",
  async (data: { id: string; isStatusWorkDay: boolean; isDeadline: boolean; comment: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const { id, isPersonalTask, isStatusWorkDay, isDeadline, comment } = data;
      const response = await getServiceTasks(isPersonalTask).pause(id, comment);

      if (isDeadline) {
        showToast("deadlineDeleted");
      }

      thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

      return { response, id, isStatusWorkDay, isPersonalTask };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const clone: any = createAsyncThunk("businessTasks/clone", async (data: { id: string; isPersonalTask: boolean }, thunkAPI) => {
  try {
    const { id, isPersonalTask } = data;
    let response;

    if (isPersonalTask) {
      response = await personalTasks.clone(id);
      response.data.subType = TaskSubtypes.Personal;
    } else {
      response = await businessTasks.clone(id);
    }

    showToast("taskCopied");
    return { response };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const moveDate = createAsyncThunk("businessTasks/moveDate", async (data: IMoveDate, thunkAPI) => {
  try {
    const response = await getServiceTasks(data?.isPersonalTask).moveDate(data);

    return { response, id: data.id };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const workDayComplete = createAsyncThunk(
  "businessTasks/workDayComplete",
  async ({ id, date, isPersonalTask }: { id: string; date: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const response = await getServiceTasks(isPersonalTask).workDayComplete(id, date);

      return {
        id,
        response,
        notifyText: intl.getMessage(response?.data?.status === "COMPLETED" ? "missionCompleted" : "workedOnToday"),
      };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const workDayNotComplete = createAsyncThunk(
  "businessTasks/workDayNotComplete",
  async ({ id, date, body, isPersonalTask }: { id: string; date: string; body?: any; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const response = await getServiceTasks(isPersonalTask).workDayNotComplete(id, date, body);

      return { id, response, isPersonalTask };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const workDayNeedMoreTime = createAsyncThunk(
  "businessTasks/workDayNeedMoreTime",
  async ({ id, body, isPersonalTask }: { id: string; body: INeedMoreTime; isPersonalTask: boolean }, thunkAPI) => {
    try {
      await getServiceTasks(isPersonalTask).workDayNeedMoreTime(id, body);

      return {
        id,
        date: body.currentDate,
        notifyText: `${intl.getMessage("planningSuccess")} ${dayjs(body.additionalDate).format("DD.MM.YYYY")}`,
      };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const workDayCancelComplete = createAsyncThunk(
  "businessTasks/workDayCancelComplete",
  async ({ id, date, isPersonalTask }: { id: string; date: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const response = await getServiceTasks(isPersonalTask).workDayCancelComplete(id, date);

      return { response, id, date };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const workDayComment = createAsyncThunk(
  "businessTasks/workDayComment",
  async (data: { id: string; body: ICommentWorkDay; isPersonalTask: boolean }, thunkAPI) => {
    try {
      await getServiceTasks(data?.isPersonalTask).commentWorkDay(data.id, data.body);
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const addWorkDay = createAsyncThunk(
  "businessTasks/addWorkDay",
  async (data: { id: string; body: IAddWorkDay; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const response = await getServiceTasks(data?.isPersonalTask).addWorkDay(data.id, data.body);

      thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

      return {
        response,
        id: data.id,
        notifyText: `${intl.getMessage("planningSuccess")} ${dayjs(data.body.additionalDate).format("DD.MM.YYYY")}`,
        isPersonalTask: data.isPersonalTask,
      };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const markWorkDay = createAsyncThunk(
  "businessTasks/markWorkDay",
  async (data: { id: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const { id, isPersonalTask } = data;
      await getServiceTasks(isPersonalTask).markWorkDay(id);

      return { id };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const forgetWorkDay = createAsyncThunk(
  "businessTasks/forgetWorkDay",
  async (data: { id: string; isPersonalTask: boolean; type?: string }, thunkAPI) => {
    try {
      const { id, isPersonalTask, type } = data;

      if (type === "delegated") {
        businessTasks.hideDelegated(id);
      } else if (type === "transferred") {
        await getServiceTasks(isPersonalTask).forgetWorkDay(id);
      } else if (type === "takenBack") {
        businessTasks.hideTakenBack(id);
      }

      return { id, type };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const forgetAllTasks = createAsyncThunk("businessTasks/forgetAllTasks", async (type: TSingleSections, thunkAPI) => {
  try {
    if (type === "delegated") {
      await businessTasks.forgetAllDelegated();
    } else if (type === "takenBack") {
      await businessTasks.hideAllTakenBack();
    } else {
      await businessTasks.forgetAllTransferred();
    }

    /* ToDo: перенести в reset мидлвару. Для этого экшен должен вернуть риспонс запроса.  */
    thunkAPI.dispatch(api.util.invalidateTags([StoreTagTypes.Calendar]));

    return type;
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const takeToWork = createAsyncThunk(
  "businessTasks/takeToWork",
  async (data: { id: string; isPersonalTask: boolean; date: string; isAdd: boolean }, thunkAPI) => {
    try {
      const response = await getServiceTasks(data?.isPersonalTask).takeToWork(data.id, data.date);

      thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

      return {
        id: data.id,
        isAdd: data.isAdd,
        isPersonalTask: data.isPersonalTask,
        response,
        notifyText: `${intl.getMessage("planningSuccess")} ${dayjs(data.date).format("DD.MM.YYYY")}`,
      };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const deleteWorkDay = createAsyncThunk(
  "businessTasks/deleteWorkDay",
  async (data: { id: string; isPersonalTask: boolean; date: string; skippedDate: string | null }, thunkAPI) => {
    try {
      const dateNow = thunkAPI.getState().screenDay.date;
      const response = await getServiceTasks(data?.isPersonalTask).deleteWorkDay(data.id, data.date);

      if (data.date === data.skippedDate) {
        handleRemoveTaskFromSingleSection(thunkAPI, "transferred", data.id);
      }

      thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

      return {
        response,
        id: data.id,
        date: data.date,
        isDateNow: data.date === toFormatDate(dateNow),
        isPersonalTask: data.isPersonalTask,
      };
    } catch (error) {
      return getError(error, thunkAPI);
    }
  },
);

export const delegate = createAsyncThunk("businessTasks/delegate", async (data: { id: string; userId: string; date: Dayjs }, thunkAPI) => {
  try {
    const response = await businessTasks.delegate(data.id, data.userId);
    setTimeout(() => showToast("delegateTaskSuccess"), 1000);

    thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

    return { response, id: data.id, date: data.date };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const takeMe = createAsyncThunk("businessTasks/takeMe", async (id: string, thunkAPI) => {
  try {
    const response = await businessTasks.takeBack(id);

    thunkAPI.dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

    return { response, id };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const hideAllTakenBack = createAsyncThunk("businessTasks/hideAllTakenBack", async (thunkAPI) => {
  try {
    const response = await businessTasks.hideAllTakenBack();

    return { response };
  } catch (error: unknown) {
    return getError(error, thunkAPI);
  }
});

export const hideTakenBackTask = createAsyncThunk("businessTasks/hideTakenBackTask", async (id: string, thunkAPI) => {
  try {
    const response = await businessTasks.hideTakenBack(id);

    return { response };
  } catch (error: unknown) {
    return getError(error, thunkAPI);
  }
});

export const getStatistic = createAsyncThunk("businessTasks/getStatistic", async (date: string, thunkAPI) => {
  try {
    const response = await businessTasks.getStatistic(date);
    return { response };
  } catch (error) {
    return getError(error, thunkAPI);
  }
});

export const attachFileToTask: any = createAsyncThunk(
  "businessTasks/attachFileToTask",
  async (data: { taskId: string; fileId: string }, thunkAPI) => {
    try {
      const response = await businessTasks.attachFileToTask(data.taskId, data.fileId);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const addTaskComment: any = createAsyncThunk(
  "businessTasks/addComment",
  async (data: { eventId: string; comment: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const { eventId, comment, isPersonalTask } = data;
      const response = await getServiceTasks(isPersonalTask).addComment(eventId, comment);

      thunkAPI.dispatch(get({ id: data.eventId, isPersonalTask }));
      return { response };
    } catch (error: unknown) {
      return getError(error, thunkAPI);
    }
  },
);

export const editTaskComment: any = createAsyncThunk(
  "businessTasks/editComment",
  async (data: { eventId: string; commentId: string; comment: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const { eventId, commentId, comment, isPersonalTask } = data;
      const response = await getServiceTasks(isPersonalTask).editComment(eventId, commentId, comment);
      thunkAPI.dispatch(get({ id: data.eventId, isPersonalTask }));

      return { response };
    } catch (error: unknown) {
      return getError(error, thunkAPI);
    }
  },
);

export const deleteTaskComment: any = createAsyncThunk(
  "businessTasks/deleteComment",
  async (data: { eventId: string; commentId: string; isPersonalTask: boolean }, thunkAPI) => {
    try {
      const { eventId, commentId, isPersonalTask } = data;
      await getServiceTasks(isPersonalTask).deleteComment(eventId, commentId);

      thunkAPI.dispatch(get({ id: data.eventId, isPersonalTask }));
    } catch (error: unknown) {
      return getError(error, thunkAPI, intl.getMessage("deleteAlert"));
    }
  },
);

export const setOnesTaskResolution = createAsyncThunk(
  "businessTask/setOnesTaskResolution",
  async (data: { taskId: string; resolution: OneSResolutions; comment?: string }, thunkAPI) => {
    try {
      return await businessTasks.setOneSResolution(data.taskId, data.resolution, data?.comment);
    } catch (error: unknown) {
      return getError(error, thunkAPI);
    }
  },
);
