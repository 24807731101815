import React, { FC, useState } from "react";

import { SCREEN_PADDING } from "@constants/settings";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IExternalUser } from "@services/meet.service";
import { HeaderModal, ModalUI, SearchInput } from "@ui";

import { useData } from "../useData";

import { Filters } from "./Filters";

interface IProps {
  onClose: () => void;
  isVisible: boolean;
  participants: IMeetParticipants[];
  externalUsers: IExternalUser[];
  renderItem: (user: IExternalUser | IMeetParticipants, isModalList?: boolean) => JSX.Element;
  isAuthor: boolean;
}

export const ModalList: FC<IProps> = ({ onClose, isVisible, externalUsers, participants, renderItem, isAuthor }) => {
  const { filter, setFilter, allParticipants, resultParticipants } = useData({
    participants: participants ?? [],
    externalUsers: externalUsers ?? [],
  });
  const [search, setSearch] = useState("");

  const matchedParticipants = resultParticipants.filter((i) => {
    const isEqual = new RegExp(search).test(
      `${(i as IMeetParticipants).user?.firstName} ${(i as IMeetParticipants).user?.middleName} ${(i as IMeetParticipants).user?.lastName}`,
    );
    return isEqual;
  });

  return (
    <ModalUI scrollEnable={false} isVisible={isVisible} onClose={onClose}>
      <HeaderModal title="meetingMembers" onClose={onClose} />

      <div style={styles.container}>
        <div style={{ ...SCREEN_PADDING, display: "flex", flexDirection: "column", gap: 12 }}>
          <SearchInput value={search} setValue={setSearch} isDrop />
          <Filters
            filter={filter}
            onPress={setFilter}
            allParticipants={allParticipants}
            participants={participants}
            externalUsersLength={(externalUsers ?? []).length}
            isAuthor={isAuthor}
          />
        </div>

        <div style={styles.list}>{matchedParticipants.map((user) => renderItem(user, true))}</div>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    height: "100%",
    display: "flex",
    "flex-direction": "column",
    gap: 12,
    marginTop: 12,
  },
  list: {
    maxHeight: "77%",
    display: "flex",
    "flex-direction": "column",
    "overflow-y": "auto",
  },
};
