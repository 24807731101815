import React, { FC } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SvgComplete } from "@assets/complete.svg";
import { IGoal } from "@interfaces/businessGoals.interface";
import { ContentEditable } from "@ui/ContentEditable";
import { TextFont } from "@ui/TextFont";
import { maxLinesInput } from "@utils";
import globalState from "@utils/globalState";

interface IDescription {
  goal: IGoal;
  description: string;
  handleData: (name: string, val: any) => void;
}

export const Description: FC<IDescription> = ({ goal, description, handleData }) => {
  const handleChangeText = (text: string) => {
    if (maxLinesInput(text)) {
      handleData('description', text);;
    }
  };

  if (!goal || goal.status === "NOT_DONE") {
    const placeholder = useIntl().formatMessage({ id: "goalDescription" });

    return (
      <div style={{ paddingRight: "12px", paddingLeft: "12px", paddingTop: "20px" }}>
        <TextFont type="bold" style={styles.mainTitle}>
          <FormattedMessage id="businessGoalDescription" />
        </TextFont>
        <ContentEditable
          autoFocus={!goal}
          placeholder={placeholder}
          onChange={handleChangeText}
          value={description}
          style={styles.mainInput}
        />
      </div>
    );
  }
  return (
    <section style={styles.container}>
      <div style={styles.header}>
        <TextFont
          type="bold"
          color={globalState.colorSchema.white}
          style={{ ...styles.mainTitle, width: goal.status === "DONE" ? "70%" : "80%" }}
        >
          <FormattedMessage id="businessGoalDescription" />
        </TextFont>
        <div style={styles.iconWrapper}>
          <SvgComplete style={styles.icon} />
          <TextFont style={styles.iconText}>Готово</TextFont>
        </div>
      </div>
      <TextFont style={styles.description}>{goal.description}</TextFont>
    </section>
  );
};

const styles = {
  container: {
    backgroundColor: globalState.colorSchema.background.darkGreen,
    paddingTop: "20px",
    paddingLeft: "12px",
    paddingRight: "12px",
    marginBottom: "-20px",
    paddingBottom: "50px",
  },
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  iconWrapper: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "9px",
    paddingLeft: "9px",
    borderRadius: "55px",
    alignItems: "center",
    backgroundColor: globalState.colorSchema.white,
  },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "5px",
  },
  iconText: {
    fontSize: "14px",
    color: globalState.colorSchema.green,
  },
  description: {
    color: "white",
    fontSize: "18px",
    lineHeight: "24px",
  },
  mainTitle: {
    fontSize: "18px",
    fontWeight: "700",
  },
  mainInput: {
    backgroundColor: globalState.colorSchema.white,
    borderRadius: "13px",
    minHeight: "100px",
    padding: "16px",
    marginTop: "5px",
    paddingTop: "16px",
    fontSize: "18px",
    width: "92%",
  },
};
