import React, { FC, useEffect } from "react";

import { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";

import { useTime } from "@hooks/useTime";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { commonStyles } from "@styles/styles";
import { TextFont } from "@ui";
import { EditTimerOptions } from "@ui/TimePicker";
import { toFormatStartTime } from "@utils";

import { modalStyles } from "../../../../styles";
import { CommonFields } from "../types";

interface IProps extends CommonFields {
  workDay: WorkDay;
  dateNow: Dayjs;
  workDays: WorkDay[];
  timeEnabled: boolean;
  setTimeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkDays: React.Dispatch<React.SetStateAction<WorkDay[]>>;
}

export const TimeDay: FC<IProps> = ({ workDay, dateNow, workDays, timeEnabled, setTimeEnabled, setWorkDays }) => {
  const { startTime, endTime, handleTime } = useTime({ startValue: workDay?.startTime, endValue: workDay?.endTime });

  useEffect(() => {
    const start = !timeEnabled ? null : workDay?.startTime ?? toFormatStartTime(dateNow).startTime;
    const end = !timeEnabled ? null : workDay?.endTime ?? toFormatStartTime(dateNow).endTime;

    handleTime(EditTimerOptions.StartTime, start);
    handleTime(EditTimerOptions.EndTime, end);
  }, [timeEnabled]);

  useEffect(() => {
    const tempDays = [...workDays];
    const findIndex = tempDays.findIndex((i) => i.date === dateNow.format("YYYY-MM-DD"));

    if (findIndex === -1) return;

    tempDays.splice(findIndex, 1, { ...tempDays[findIndex], startTime, endTime });
    setWorkDays(tempDays);
  }, [startTime, endTime]);

  const onChangeSwitch = () => {
    setTimeEnabled(!timeEnabled);
  };

  return (
    <section style={{ backgroundColor: "#fff", borderRadius: 11 }}>
      <div style={{ ...commonStyles.billet, width: "auto", borderBottom: "1px solid #E3E9E7", marginTop: 0 }}>
        <div style={modalStyles.blockSwitch}>
          <TextFont type="bold" weight="700" size={16}>
            <FormattedMessage id="taskTimeSet" />
          </TextFont>
        </div>
        <Toggle checked={timeEnabled} onChange={onChangeSwitch} icons={false} />
      </div>
    </section>
  );
};
