import { http } from "@api/httpClient";
import { ITask, TypeKeysPersonalGoal } from "@interfaces/personalGoals.interface";

class PersonalGoalsService {
  getGoalList = async (yearMonth: string): Promise<any> => await http.get(`/api/crud/v1/personal-goals/list?yearMonth=${yearMonth}`);

  getGoal = async (category: TypeKeysPersonalGoal, yearMonth: string): Promise<any> =>
    await http.get(`/api/crud/v1/personal-goals/category/${category}/yearMonth/${yearMonth}`);

  updateListTasks = async (goalId: string, tasks: ITask[]): Promise<any> =>
    await http.put(`/api/crud/v1/personal-tasks/${goalId}/update-list`, tasks);

  statusTask = async (id: string, status: boolean): Promise<any> => await http.post(`/api/crud/v1/personal-tasks/${id}/complete${status}`);
}

export const personalGoalsService = new PersonalGoalsService();
