import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgAttention } from "@assets/danger-circle.svg";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  style?: React.CSSProperties;
  textInfo: string;
}

export const RepeatInfo: FC<IProps> = ({ style, textInfo }) => (
  <div style={{ ...styles.info, ...style }}>
    <div style={styles.line}>
      <div style={styles.wrapIcon}>
        <SvgAttention />
      </div>
      <TextFont size={16} lineHeight={24} weight="700" style={{ marginLeft: 5 }}>
        <FormattedMessage id={"attention"} defaultMessage={"Внимание!"} />
      </TextFont>
    </div>
    <div style={styles.line}>
      <div>
        <div style={styles.wrapIcon} />
      </div>
      <TextFont size={14} lineHeight={22}>
        <FormattedMessage id={textInfo} />
      </TextFont>
    </div>
  </div>
);

const styles = {
  info: {
    display: "flex",
    "flex-direction": "column",
    padding: "9px 16px",
    backgroundColor: globalState.colorSchema.orange20,
    borderRadius: 10,
    width: "100%",
    border: `0.5px solid ${globalState.colorSchema.orange}`,
    "box-sizing": "border-box",
  },
  line: {
    display: "flex",
    "flex-direction": "row",
    gap: 10,
    alignItems: "center",
  },
  wrapIcon: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
  },
};
