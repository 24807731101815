import React, { useState, memo, useMemo, FC } from "react";

import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgAnalysis } from "@assets/analysis.svg";
import { ReactComponent as SvgCalendar } from "@assets/calendar.svg";
// import { ReactComponent as SvgExpand } from "@assets/expand.svg";
import { ReactComponent as SvgNotAnswerMeets } from "@assets/three_persons.svg";
import { ReactComponent as SvgToday } from "@assets/today.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useGetUnansweredCountQuery } from "@services/meetApi";
import { SelectRole, UnansweredMeets } from "@shared";
import { setIsVisibleAppCalendar } from "@store/calendar/slice";
import { saveDate, selectCurrentDate } from "@store/screenDay";
import { themeSelector } from "@store/theme";
import { Colors } from "@theme/colors";
import { ArrowSvg } from "@ui";
import { TextFont } from "@ui/TextFont";
import { calendar, showToast } from "@utils";
import { myTracker } from "@utils/myTracker";

import { Modal } from "./modal";

export const Header: FC = memo(function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useDimension();
  const { extra } = themeSelector();

  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleUnansweredMeets, setIsVisibleUnansweredMeets] = useState(false);

  const date = useSelector(selectCurrentDate);

  const { data: unansweredCount } = useGetUnansweredCountQuery();

  const editDay = (arg: "prev" | "next") => {
    dispatch(saveDate(date.add(arg === "prev" ? -1 : 1, "day")));
  };

  const renderDate = useMemo(() => `${date.locale("ru").format("DD MMMM")}, ${calendar.getWeekDayRu(date.day())}`, [date]);

  const handleClickCalendarIcon = () => {
    setIsVisible(true);
    dispatch(setIsVisibleAppCalendar(true));
  };

  return (
    <section style={styles.container}>
      <div style={{ flex: 1, paddingLeft: 5, display: "flex", gap: 16 }}>
        <RenderWithCondition condition={!date.isAfter(dayjs(), "date")}>
          <button
            disabled={extra === "ASSISTANT"}
            onClick={() => {
              myTracker("AnalysisDayClick");
              navigate("analysis");
            }}
          >
            <SvgAnalysis fill={extra === "ASSISTANT" ? "#EEEDE9" : "#297952"} />
          </button>
        </RenderWithCondition>

        <SelectRole />
      </div>
      <div style={styles.wrapperTitle}>
        <button onClick={() => editDay("prev")} style={{ alignItems: "flex-end", width: "15%" }}>
          <ArrowSvg type="left" fill="#B3BEB8" />
        </button>
        <TextFont type="bold" style={styles.title}>
          {renderDate}
        </TextFont>
        <button onClick={() => editDay("next")} style={{ width: "15%" }}>
          <ArrowSvg type="right" fill="#B3BEB8" />
        </button>
      </div>
      <div style={styles.actions}>
        <button
          onClick={() => setIsVisibleUnansweredMeets(true)}
          disabled={!unansweredCount}
          style={{
            ...styles.wrapperNotAnswerMeets,
            backgroundColor: unansweredCount ? Colors.LIGHT.lightRed : Colors.LIGHT.lighGrey,
            cursor: unansweredCount ? "pointer" : "default",
          }}
        >
          <SvgNotAnswerMeets fill={unansweredCount ? Colors.LIGHT.red : Colors.LIGHT.grey500} />
          {!!unansweredCount && (
            <TextFont size={14} color={Colors.LIGHT.red}>
              {unansweredCount}
            </TextFont>
          )}
        </button>
        <>
          <button
            onClick={() => {
              showToast("today");
              dispatch(saveDate(dayjs()));
            }}
            style={styles.icon}
          >
            <SvgToday fill={extra === "ASSISTANT" ? "#453831" : "#297952"} />
          </button>
          <button onClick={handleClickCalendarIcon} style={styles.icon}>
            <SvgCalendar fill={extra === "ASSISTANT" ? "#453831" : "#297952"} />
          </button>
        </>
      </div>
      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} isFullWidth={isTablet} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleUnansweredMeets}>
        <UnansweredMeets onClose={() => setIsVisibleUnansweredMeets(false)} />
      </ModalRenderWithCondition>
    </section>
  );
});

const styles = {
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "0.2fr auto 0.2fr",
    alignItems: "center",
    paddingBottom: "8.5px",
    borderBottom: "1px solid #ECF0EF",
  },
  wrapperTitle: {
    display: "flex",
    flex: 3,
    "flex-direction": "row",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    "text-align": "center",
    marginHorizontal: "10px",
  },
  actions: {
    display: "flex",
    marginLeft: "auto",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "11px",
    gap: 15,
  },
  icon: {
    padding: 0,
  },
  wrapperNotAnswerMeets: {
    display: "flex",
    height: 30,
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 51,
    gap: 4,
    alignItems: "center",
  },
};
