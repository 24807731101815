import React, { FC, HTMLAttributes } from "react";

import { FormattedMessage } from "react-intl";

import { commonStyles } from "@styles";

import { ArrowSvg } from "./ArrowSvg";
import { TextFont } from "./TextFont";

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  textId: string;
  additionalText?: string;
  styleButton?: any;
  styleText?: any;
  onPress?: (...arg: any) => void;
  before?: JSX.Element;
}

export const Billet: FC<IProps> = ({ textId, styleButton, styleText, additionalText, onPress, before, ...props }) => (
  <button style={{ ...commonStyles.billet, ...styleButton }} onClick={onPress} {...props}>
    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
      {before}
      <TextFont style={{ ...styles.text, ...styleText }}>
        {additionalText}
        <FormattedMessage id={textId} />
      </TextFont>
    </div>
    <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
  </button>
);

const styles = {
  text: {
    fontSize: "16px",
    fontWeight: "700",
  },
};
