import React, { useEffect, useMemo, useRef, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { ReactComponent as SvgNotAccess } from "@assets/not_access.svg";
import { createNote, getList } from "@store/note";
import { notesSelector } from "@store/note/selectors";
import { selectCurrentDate } from "@store/screenDay";
import { RootState } from "@store/store";
import { themeSelector } from "@store/theme";
import { selectIsModeAssistant, userSelector } from "@store/user";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { ReactComponent as SvgSave } from "./assets/save.svg";
import { Note } from "./note";

interface INotes {
  backgroundColor?: string;
  autoFocus?: boolean;
  isMounthDate?: boolean;
}

export const Notes = ({ backgroundColor, autoFocus = true, isMounthDate }: INotes) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [content, setContent] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { date: mounthScreenDate } = useSelector((state: RootState) => state.date);
  const { currentUser } = userSelector();
  const { extra } = themeSelector();
  const date = useSelector(selectCurrentDate);
  const notes = notesSelector();
  const isModeAssistant = useSelector(selectIsModeAssistant);
  const dateNotes = useMemo(() => (isMounthDate ? mounthScreenDate : date), [isMounthDate, mounthScreenDate, date]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getList({ yearMonth: dateNotes.format("YYYY-MM"), userId: currentUser?.id }));
    }
  }, [dateNotes, currentUser?.id]);

  const handleInput = (text: string) => {
    setContent(text);
  };

  const handleSave = async () => {
    const createDate = dateNotes.format("YYYY-MM");
    await dispatch(createNote({ yearMonth: createDate, content }));
    setContent("");
    handleBlur();
  };

  const handleOutsidePress = () => {
    if (content) {
      handleSave();
    }
    handleBlur();
  };

  const handleBlur = () => {
    ref.current && ref.current.blur();
    setIsEdit(false);
  };
  const placeholder = useIntl().formatMessage({ id: "noteText" });

  if (extra === "ASSISTANT" && isModeAssistant) {
    return (
      <div style={styles.notAccess}>
        <SvgNotAccess />
        <TextFont size={18} weight="700" color={"#D3C6BDDB"}>
          <FormattedMessage id="hiddenNotes" />
        </TextFont>
      </div>
    );
  }

  return (
    <section style={styles.container}>
      <div style={styles.inputBlock}>
        <textarea
          onFocus={() => setIsEdit(true)}
          onBlur={handleOutsidePress}
          autoFocus={autoFocus}
          style={styles.input}
          onChange={(e) => handleInput(e.target.value)}
          value={content}
          ref={ref}
          placeholder={placeholder}
        />
        {isEdit && content ? (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <button onClick={handleSave}>
              <SvgSave />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {notes?.map((item, index) => (
        <Note key={item.id} index={index} backgroundColor={backgroundColor} {...item} />
      ))}
    </section>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "auto",
  },
  inputBlock: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "100px",
    marginTop: "10px",
    paddingBottom: "20px",
  },
  input: {
    color: globalState.colorSchema.text.main,
    fontSize: "18px",
    height: "75px",
    marginBottom: "20px",
  },
  actions: {
    position: "absolute",
    left: "-10px",
    width: "100%",
    height: "52px",
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    backgroundColor: globalState.colorSchema.background.main,
    padding: "14px",
  },
  notAccess: {
    display: "flex",
    "flex-direction": "column",
    width: "100%",
    alignItems: "center",
    gap: 14,
    justifyContent: "center",
    height: "80%",
  },
};
