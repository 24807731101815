import React, { memo, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { IGoal } from "@interfaces/personalGoals.interface";
import { selectIsModalOpen } from "@store/configure";
import { TextFont } from "@ui/TextFont";
import globalState from "@utils/globalState";

import { Icon } from "./Icon/Icon";

interface IItemList {
  item: IGoal;
  handlePressIcon: (arg: string) => void;
}

export const GoalItem = memo(({ item, handlePressIcon }: IItemList) => {
  const tasks = item.personalTasks;
  const [isCompleted, setIsCompleted] = useState(false);

  const isModalOpen = useSelector(selectIsModalOpen);

  const completed = useMemo(() => tasks.filter((task) => task.completed).length, [tasks]);

  useEffect(() => {
    setIsCompleted(completed === tasks.length);
  }, [completed, tasks.length]);

  const selectCompletedTasks = useMemo(() => `${completed}/${tasks.length}`, [completed, tasks.length]);

  const color = useMemo(() => {
    if (isCompleted || isModalOpen) return globalState.colorSchema.grey;
    return globalState.colorSchema.text.main;
  }, [isCompleted, isModalOpen]);

  const iconType = useMemo(() => {
    if (isCompleted) return "complete";
    if (isModalOpen) return "inactive";
    return "active";
  }, [isCompleted, isModalOpen]);

  return (
    <button onClick={() => handlePressIcon(item.id)} style={styles.item}>
      <div style={styles.icon}>
        <Icon type={iconType} title={item.category} />
      </div>
      <div style={{ display: "flex" }}>
        <TextFont style={{ wordBreak: "break-all" }} color={color}>
          {tasks.map((task) => task.name).join("; ")}
        </TextFont>
      </div>
      <TextFont color={color} style={styles.status}>
        {selectCompletedTasks}
      </TextFont>
    </button>
  );
});

const styles = {
  item: {
    display: "grid",
    gridTemplateColumns: "0.1fr 1fr 0.1fr",
    marginBottom: "10px",
    paddingBottom: "10px",
    width: "100%",
  },
  icon: {
    display: "flex",
    "flex-direction": "row",
  },
  tasks: {
    "text-align": "start",
  },
  status: {
    "text-align": "right",
    fontSize: "12px",
    color: "#5F6E67",
  },
};
