import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { selectSpecialTag } from "@store/tag";
import { commonStyles } from "@styles/styles";
import { TextFont, ArrowSvg } from "@ui";

import { CommonFields } from "../types";

import { ModalTag } from "./ModalTag";

interface IProps extends CommonFields {
  id: string;
}

export const Tag: FC<IProps> = ({ id, handleData }) => {
  const [isVisibleTag, setIsVisibleTag] = useState(false);

  const currentTag = useSelector(selectSpecialTag(id));

  return (
    <>
      <button style={commonStyles.billet} onClick={() => setIsVisibleTag(true)}>
        <TextFont style={styles.textThin}>
          <FormattedMessage id="tag" />
        </TextFont>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TextFont style={{ color: "#B3BEB8" }}>{currentTag?.name}</TextFont>
          <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
        </div>
      </button>
      <RenderWithCondition condition={isVisibleTag}>
        <ModalTag isVisible={isVisibleTag} setIsVisible={setIsVisibleTag} currentTagId={currentTag?.id} setTag={handleData} />
      </RenderWithCondition>
    </>
  );
};

const styles = {
  textThin: {
    fontSize: "16px",
    fontWeight: "normal",
  },
};
