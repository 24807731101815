import React, { forwardRef, useCallback } from "react";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ITask } from "@interfaces/personalGoals.interface";
import "../animated.css";
import { ContentEditable } from "@ui";

interface ITaskItem {
  task: ITask;
  isAddTask: boolean;
  setIsAddTask: (arg: boolean) => void;
  tasks: ITask[];
  setTasks: (arg: ITask[]) => void;
  addTask: () => void;
}

export const TaskItem = forwardRef<HTMLDivElement, ITaskItem>((props, ref) => {
  const { task, isAddTask, setIsAddTask, setTasks, tasks, addTask } = props;

  const handleDeleteTask = useCallback(() => {
    const tempTasks = [...tasks].filter((item) => item.id !== task.id);

    setTasks(tempTasks);
    setIsAddTask(false);
  }, [setIsAddTask, setTasks, task.id, tasks]);

  const handleHideKeyboard = () => {
    if (!task.name) {
      handleDeleteTask();
    }
  };

  const handleEditTask = useCallback(
    (value: boolean | string, property: string) => {
      const tempTasks = [...tasks];
      const elemIndex = tasks.findIndex((item) => item.id === task.id);

      tempTasks.splice(elemIndex, 1, { ...tempTasks[elemIndex], [property]: value });
      setTasks(tempTasks);
    },
    [tasks, setTasks, task.id]
  );

  const handleKeyUp = (event: any) => {
    if (task.name.trim().length < 1) return;

    if (event.code === "Enter") {
      event.preventDefault();
      return addTask();
    }
    if (event.currentTarget.innerText.trim().length >= 100 && event.code !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <section style={styles.mainBlock}>
      <input
        type="checkbox"
        onChange={(e) => handleEditTask(e.target.checked, "completed")}
        checked={task.completed}
        style={styles.checkbox}
        className="input"
        color="#219653"
      />
      <div style={styles.editBlockInput}>
        <ContentEditable
          autoFocus={isAddTask}
          style={{ inlineSize: "80%", alignSelf: "flex-start", minHeight: 20, border: "none", wordBreak: "break-all", fontSize: "16px" }}
          value={task.name}
          onChange={(value) => handleEditTask(value, "name")}
          onBlur={handleHideKeyboard}
          onKeyDown={handleKeyUp}
        />
        <button style={styles.iconDelete} onClick={handleDeleteTask}>
          <SvgRemove fill="#333333" width={10} height={10} />
        </button>
      </div>
    </section>
  );
});

const styles = {
  mainBlock: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    padding: "10px",
    borderRadius: "11px",
    marginBottom: "10px",
    alignItems: "center",
  },
  editBlockInput: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "12px",
  },
  checkbox: {
    marginRight: "10px",
    marginTop: "-2px",
    width: "auto",
    height: "auto",
  },
  iconDelete: {
    display: "flex",
    alignItems: "center",
  },
};
