import globalState from "@utils/globalState";

interface IStylesForDay {
  isSelect: boolean;
  isDisabled?: boolean;
  isPastDay?: boolean;
  isToday: boolean;
  isDeadline: boolean;
  enableDisable?: boolean;
  deadline: string;
}

export const stylesForDay = (props: IStylesForDay): { wrapper: React.CSSProperties; text: React.CSSProperties } => {
  if (props.isDisabled && props.enableDisable) {
    return { wrapper: {}, text: { color: globalState.colorSchema.grey } };
  }
  if (props.isDeadline && props.isSelect && props.deadline) {
    return {
      wrapper: { backgroundColor: "#A1B6AD", border: `1px solid ${globalState.colorSchema.orange}` },
      text: { color: globalState.colorSchema.white },
    };
  }
  if (props.isDeadline && !props.isSelect && props.deadline) {
    return {
      wrapper: { backgroundColor: globalState.colorSchema.white, border: `1px solid ${globalState.colorSchema.orange}` },
      text: {},
    };
  }
  if (props.isToday && props.isSelect) {
    return { wrapper: { backgroundColor: globalState.colorSchema.background.green }, text: { color: globalState.colorSchema.white } };
  }
  if (props.isToday && !props.isSelect) {
    return {
      wrapper: { backgroundColor: globalState.colorSchema.white, border: `1px solid ${globalState.colorSchema.background.green}` },
      text: { color: globalState.colorSchema.text.accent },
    };
  }

  if (props.isPastDay && props.isSelect) {
    return {
      wrapper: { backgroundColor: globalState.colorSchema.lighGrey },
      text: { color: globalState.colorSchema.grey },
    };
  }

  if (props.isPastDay && !props.isSelect) {
    return { wrapper: {}, text: { color: globalState.colorSchema.grey } };
  }

  if (props.isSelect) return { wrapper: { backgroundColor: "#A1B6AD" }, text: { color: globalState.colorSchema.white } };
  return { wrapper: {}, text: { color: globalState.colorSchema.text.main } };
};
