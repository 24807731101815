import React, { FC } from "react";

import ContentLoader from "react-content-loader";

interface IItemContentLoaderProps {
  width?: number | string;
  height?: number | string;
}

export const ItemContentLoader: FC<IItemContentLoaderProps> = ({ width = "100%", height = "100%" }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#ecf0ef"
    foregroundColor="#f5f9f8"
    backgroundOpacity={1}
    foregroundOpacity={0.7}
  >
    {/* Title */}
    <rect x="6" y="40" rx="3" ry="3" width={width} height="50" />

    {/* Tag & date */}
    <rect x="6" y="100" rx="6" ry="6" width="215" height="30" />
    <rect x="230" y="100" rx="6" ry="6" width="215" height="30" />

    {/* Work days title */}
    <rect x="6" y="145" rx="3" ry="3" width="100" height="14" />

    {/* Workdays labels */}
    <rect x="7" y="170" rx="9" ry="9" width="100" height="25" />
    <rect x="120" y="170" rx="9" ry="9" width="100" height="25" />

    {/* Add work days btn */}
    <rect x="6" y="220" rx="6" ry="6" width={width} height="50" />

    {/* Meetings */}
    <rect x="6" y="290" rx="3" ry="3" width="120" height="14" />

    <rect x="6" y="340" rx="6" ry="6" width={width} height="50" />
    {/* <rect x="6" y="390" rx="6" ry="6" width={width} height="50" /> */}
    {/* <rect x="6" y="460" rx="6" ry="6" width={width} height="50" /> */}

    {/* Comments */}
    {/* <rect x="6" y="530" rx="3" ry="3" width="120" height="14" />
    <rect x="6" y="555" rx="6" ry="6" width={width} height="50" /> */}

    {/* Complete btn */}
    {/* <rect x="6" y="620" rx="6" ry="6" width={width} height="50" /> */}

    {/* Stubs */}
    {/* <rect x="6" y="690" rx="6" ry="6" width={width} height="50" />
    <rect x="6" y="760" rx="6" ry="6" width={width} height="50" />
    <rect x="6" y="830" rx="6" ry="6" width={width} height="50" /> */}
  </ContentLoader>
);
