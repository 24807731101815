import React, { ChangeEvent } from "react";

import { useLocation } from "react-router-dom";

import { ReactComponent as SvgClose } from "@assets/close-bold.svg";
import { ReactComponent as SvgSuccess } from "@assets/success_task.svg";
import { TTypePriorityTask, TTypeStatusTask } from "@interfaces/businessGoals.interface";

import { objXml, colors, ExtraIcon, TIcon } from "./config";

export interface IIcon {
  type: TTypePriorityTask;
  status: TIcon;
  extraIcon?: TTypeStatusTask;
  press: (e: ChangeEvent<HTMLButtonElement>) => void;
}

export const Icon = ({ type, status, extraIcon, press }: IIcon) => {
  const name = useLocation().pathname;

  const bgIcon = extraIcon === "CLOSED" || extraIcon === "PAUSED" ? "white" : colors()[status].text;

  return (
    <button
      onClick={press}
      style={{
        ...styles.itemIcon,
        backgroundColor: colors(extraIcon === "COMPLETED")[status].bg,
        cursor: name !== "/tasks" ? "pointer" : "default",
      }}
    >
      <div style={{ position: "relative", width: 30, height: 30 }}>
        {objXml[type](bgIcon)}
        {extraIcon && <ExtraIcon type={extraIcon} />}
      </div>
    </button>
  );
};

const styles = {
  itemIcon: {
    height: "100%",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    display: "flex",
    "flex-direction": "row",
    justifyContent: "center",
    alignItems: "center",
  },
};
