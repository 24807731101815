import React, { FC } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { TextFont, ModalUI } from "@ui";

import { TypeRepeat, tempMeetRepeat, titleType } from "../../../constants";

interface IProps {
  close: () => void;
  type: TypeRepeat;
  reset: () => void;
  changeMeet: (value: any) => void;
}

export const RepeatType: FC<IProps> = ({ close, changeMeet, type, reset }) => {
  const handleSelect = (value: TypeRepeat) => {
    changeMeet({ ...tempMeetRepeat, type: value });

    if (value === "NOT_REPEAT") {
      reset();
    } else {
      close();
    }
  };

  return (
    <ModalUI isVisible={true} className="bottomModal" bg="white" onClose={close}>
      <div>
        <button onClick={close} style={{ ...styles.roundContainer, position: "absolute" }}>
          <SvgClose />
        </button>
        <div style={{ marginTop: 50 }}>
          {Object.keys(titleType).map((element) => (
            <div key={element} style={{ backgroundColor: type === element ? "#ECF0EF" : "#fff" }}>
              <button onClick={() => handleSelect(element)} style={styles.optionButton}>
                <TextFont>
                  <FormattedMessage id={titleType[element]} />
                </TextFont>
              </button>
              <div style={styles.line} />
            </div>
          ))}
        </div>
      </div>
    </ModalUI>
  );
};

const styles = {
  roundContainer: {
    display: "flex",
    "flex-direction": "column",
    top: 20,
    right: 12,
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: "#ECF0EF",
  },
  optionButton: {
    display: "flex",
    "flex-direction": "column",
    height: 50,
    justifyContent: "center",
    padding: "0 12px",
    width: "100%",
  },
  line: {
    height: 1,
    backgroundColor: "#ddd",
    margin: "0 12px",
  },
};
