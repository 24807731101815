import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectBusinessTasks } from "@store/businessTask";
import { TextFont } from "@ui";
import { intl } from "@utils/translate";

interface IProps {
  section: string;
}

export const SectionHeader = ({ section }: IProps) => {
  const screen = useLocation().pathname;

  const [title, setTitle] = useState("");
  const {
    filter: { groupByType },
  } = useSelector(selectBusinessTasks);

  useEffect(() => {
    if (section === "transferred") return setTitle("");

    if (groupByType === "PRIORITY" || screen === "/") return setTitle(intl.getMessage(section.toLowerCase()));

    setTitle(section);
  }, [section, screen, groupByType]);

  return <TextFont style={styles.title}>{title}</TextFont>;
};

const styles = {
  title: {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "4px",
    marginTop: "13px",
    marginLeft: "12px",
    width: "95%",
  },
};
