import React, { FC, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { selectTheme } from "@store/theme";
import { commonStyles } from "@styles/styles";
import { TextFont, ArrowSvg } from "@ui";
import { Notification } from "@ui/notification/Notification";

import { modalStyles } from "../../styles";
import ActionsSheetModeToDate from "../ActionsSheetModeToDate";
import { THandleOpenWorkDay } from "../addWorkDaysReminder/useWorkDaysController";
import useGoToSpecificDays from "../hooks/useGoToSpecificDays";

import { RenderWorkDay } from "./RenderWorkDay";

interface IProps {
  workDays: WorkDay[];
  controlTask?: boolean;
  press?: THandleOpenWorkDay;
  remove: (arg: string) => void;
  onAddDays: () => void;
  disable?: { value: boolean; press: () => void };
  allowWorkdaysAddition?: boolean;
  handleClose: () => void;
}

export const WorkDays: FC<IProps> = ({
  workDays,
  press,
  remove,
  onAddDays,
  disable,
  controlTask,
  allowWorkdaysAddition = true,
  handleClose,
}) => {
  const theme = selectTheme("extra");
  const latestClickedDay = useRef<string>();
  const [isVisibleDayActionsModal, setIsVisibleDayActionsModal] = useState(false);
  const [areYouSureToCancelEditChanges, setAreYouSureToCancelEditChanges] = useState(false);
  const goToSpecificDay = useGoToSpecificDays();

  const onClickMoveToDateFromActionSheet = () => {
    setAreYouSureToCancelEditChanges(true);
  };

  const onPress = () => {
    if (disable?.value) {
      return disable.press();
    }
    return onAddDays();
  };

  const onClickByDate = (day: string, isStartTimeExist?: boolean) => {
    latestClickedDay.current = day;

    if (isStartTimeExist) {
      setIsVisibleDayActionsModal(true);
      return;
    }

    setAreYouSureToCancelEditChanges(true);
  };

  const sortedWorkDays = useMemo(() => {
    if (!workDays) return [];
    return [...workDays].sort((a, b) => (dayjs(a.date) < dayjs(b.date) ? -1 : 1));
  }, [workDays]);

  const msgCancelExeption = useIntl().formatMessage({ id: "cancelException" });
  const onOkNotification = () => {
    goToSpecificDay(latestClickedDay.current);
    setAreYouSureToCancelEditChanges(false);
    setIsVisibleDayActionsModal(false);
    handleClose();
  };

  return (
    <section>
      <TextFont style={{ ...modalStyles.mainH1, marginBottom: 8 }}>
        <FormattedMessage id={controlTask ? "modalControlDelegateTitle" : "workingDays"} />
      </TextFont>
      <RenderWithCondition condition={Boolean(workDays?.length)}>
        <div style={styles.list}>
          {sortedWorkDays.map((i) => (
            <RenderWorkDay
              press={(date, isStartTimeExist) => {
                // TODO confusing logic, refactoring is needed
                if (press) {
                  press?.(date, isStartTimeExist);
                } else {
                  onClickByDate(date, isStartTimeExist);
                }
              }}
              remove={remove}
              key={i.id}
              element={i}
              allowRemove={allowWorkdaysAddition}
            />
          ))}
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={allowWorkdaysAddition}>
        <button style={commonStyles.billet} onClick={onPress}>
          <TextFont style={{ ...modalStyles.blockTurnText, color: theme.text.accent }}>
            {"+ "}
            <FormattedMessage id={controlTask ? "taskControlDaysAdd" : "taskWorkDaysAdd"} />
          </TextFont>
          <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
        </button>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDayActionsModal}>
        <ActionsSheetModeToDate
          onClose={() => setIsVisibleDayActionsModal(false)}
          goToSpecificDay={onClickMoveToDateFromActionSheet}
          openAddDays={onAddDays}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={areYouSureToCancelEditChanges}>
        <Notification
          type="confirm"
          phraseId={msgCancelExeption}
          onOk={onOkNotification}
          onCancel={() => setAreYouSureToCancelEditChanges(false)}
        />
      </ModalRenderWithCondition>
    </section>
  );
};

const styles = {
  list: {
    display: "flex",
    "flex-direction": "row",
    gap: 5,
    "flex-wrap": "wrap",
    alignItems: "center",
  },
};
