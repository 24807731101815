import React, { useEffect, useMemo, useRef } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgDone } from "@assets/done_thin.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { RenderWithCondition } from "@hoc";
import { selectIsModalOpen } from "@store/configure";
import { selectYearMonth } from "@store/date";
import { getMonthGoal, saveMonthGoal, selectMonthGoal } from "@store/monthGoal";
import { TextFont } from "@ui/TextFont";
import { isTablet, maxLinesInput } from "@utils";
import globalState from "@utils/globalState";

import styles from "./targetMonth.module.css";

export const TargetMonth = () => {
  const refEdit = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [edit, setEdit] = React.useState(false);
  const [description, setDescription] = React.useState("");

  const isModalOpen = useSelector(selectIsModalOpen);
  const goal = useSelector(selectMonthGoal);
  const yearMonth = useSelector(selectYearMonth);

  useEffect(() => {
    setDescription(goal.description);

    refEdit.current.innerText = goal.description;
  }, [goal.description, goal.id]);

  useEffect(() => {
    dispatch(getMonthGoal(yearMonth));
  }, [yearMonth]);

  useEffect(() => {
    setEdit(description !== goal.description);
  }, [description, goal.description]);

  const handleChangeText = (text: string) => {
    if (maxLinesInput(text)) {
      setDescription(text);
    }
  };

  const handleFocus = () => {
    refEdit?.current.focus();
  };

  const handleSave = () => {
    if (edit && isValidMonth) {
      dispatch(saveMonthGoal({ id: goal.id, description: description ? description : null }));
      setEdit(false);
    }
  };

  const isValidMonth = useMemo(() => dayjs().isSameOrBefore(yearMonth, "month"), [yearMonth]);

  return (
    <section style={{ backgroundColor: globalState.colorSchema.background.darkGreen, marginBottom: -15, paddingTop: 20 }}>
      <header className={styles.header}>
        <TextFont color={"white"} size={24} className={styles.title}>
          <FormattedMessage id="monthGoal" />
        </TextFont>
        <RenderWithCondition condition={isValidMonth}>
          <div
            style={{
              minWidth: 60,
              alignItems: "flex-end",
              position: "absolute",
              right: 0,
              height: 60,
              justifyContent: "center",
              paddingRight: 12,
            }}
          >
            {edit ? (
              <button className={styles.button} onClick={handleSave}>
                <SvgDone fill={globalState.colorSchema.white} />
                <TextFont className={styles.textSave} color="white">
                  <FormattedMessage id="save" />
                </TextFont>
              </button>
            ) : (
              <button onClick={handleFocus}>
                <SvgEdit fill={isModalOpen && isTablet ? globalState.colorSchema.background.grey : globalState.colorSchema.white} />
              </button>
            )}
          </div>
        </RenderWithCondition>
      </header>
      <div
        data-ph={isValidMonth ? "Установите цель..." : "Нельзя редактировать цель прошедшего месяца"}
        ref={refEdit}
        className={`${styles.textareaElementTargetMonth} ${styles.input}`}
        contentEditable={isValidMonth}
        onInput={(e) => handleChangeText(e.currentTarget.innerText)}
      />
    </section>
  );
};
