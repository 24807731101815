import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";

import { TextFont } from "@ui";
import globalState from "@utils/globalState";

interface ISettingsOptionItem {
  isToggleEnabled: boolean;
  disableToggle: boolean;
  onToggleChange: React.Dispatch<React.SetStateAction<boolean>>;
  textId: string;
  defaultText?: string;
  style?: React.CSSProperties;
}

export const SettingsOptionItem: FC<ISettingsOptionItem> = ({
  textId,
  isToggleEnabled,
  disableToggle,
  defaultText,
  onToggleChange,
  ...props
}) => {
  const styles = style();

  return (
    <div style={{ ...styles.optionContainer, ...props.style }}>
      <div style={styles.optionTextContainer}>
        <TextFont size={16} type="bold" style={styles.settingsText}>
          <FormattedMessage id={textId} defaultMessage={defaultText} />
        </TextFont>
      </div>
      <Toggle style={{ marginLeft: 10 }} checked={isToggleEnabled} onChange={onToggleChange} icons={false} disabled={disableToggle} />
    </div>
  );
};

const style = () => {
  const { colorSchema } = globalState;

  return {
    settingsText: {
      color: colorSchema.text.darkGreen,
      fontWeight: "700",
    },
    optionContainer: {
      display: "flex",
      backgroundColor: colorSchema.white,
      height: 72,
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 10,
      marginTop: 10,
      "flex-direction": "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    optionTextContainer: {
      width: "80%",
    },
  };
};
