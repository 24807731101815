import React, { useState, useMemo, useEffect } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { RootState } from "@store";
import { editMonth, saveMonth } from "@store/date";
import { userSelector } from "@store/user";
import { TextFont, ArrowSvg } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDate } from "@utils/toFormatTime";

import { Modal } from "./modal/Modal";

export const Header = () => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const { date } = useSelector((state: RootState) => state.date);
  const { currentUser } = userSelector();

  const isPrevious = useMemo(() => date.isAfter(dayjs(currentUser?.appActivity?.firstActivity ?? dayjs()), "month"), [date, currentUser]);
  const setModalOpen = (isOpen: boolean) => {
    setIsVisible(isOpen);
  };

  const handlePressMonth = (yearMonth: Dayjs, isDisabled: boolean) => {
    if (isDisabled) {
      return;
    }
    dispatch(saveMonth(yearMonth));
    setIsVisible(false);
  };

  return (
    <div style={styles.container}>
      <button onClick={() => dispatch(editMonth("minus"))} style={{ marginLeft: "13px" }}>
        <RenderWithCondition condition={isPrevious}>
          <ArrowSvg type="left" fill={globalState.colorSchema.background.darkGreen} />
        </RenderWithCondition>
      </button>
      <button onClick={() => setModalOpen(true)} style={{ ...styles.titleWrap }}>
        <CustomTextFont type="bold">{`${dayjs().month(date.month()).format("MMMM")} `}</CustomTextFont>
        <CustomTextFont>{`${date.year()}`}</CustomTextFont>
        <TextFont type="bold" style={styles.title}>
          г.
        </TextFont>
      </button>
      <button onClick={() => dispatch(editMonth("plus"))} style={{ marginRight: "13px" }}>
        <ArrowSvg type="right" fill={globalState.colorSchema.background.darkGreen} />
      </button>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal
          isVisible={isVisible}
          setIsVisible={setModalOpen}
          pressMonth={handlePressMonth}
          startDate={currentUser?.appActivity?.firstActivity ?? toFormatDate(dayjs())}
        />
      </ModalRenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E3E9E7",
  },
  titleWrap: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    height: "60px",
    justifyContent: "center",
  },
  title: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    color: globalState.colorSchema.background.darkGreen,
  },
};

const CustomTextFont = styled(TextFont)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${() => globalState.colorSchema.background.darkGreen};
  text-transform: capitalize;
  margin-right: 5px;
`;
