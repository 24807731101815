import globalState from "@utils/globalState";

export const styles = {
  delegateWrapper: {
    backgroundColor: globalState.colorSchema.background.darkGreen,
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "9px",
    paddingBottom: "12px",
    display: "flex",
    "flex-direction": "column",
  },
  delegateTitle: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#fff",
    marginBottom: "4px",
  },
  delegateBlock: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    borderRadius: "45px",
    backgroundColor: "#fff",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "3px",
    paddingBottom: "3px",
    alignSelf: "flex-start",
    height: "28px",
  },
  buttonsContainer: {
    marginTop: 8,
    display: "flex",
    "flex-direction": "row",
  },
};
