import globalState from "@utils/globalState";

export const modalStyles = {
  header: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
    padding: 9,
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: globalState.colorSchema.text.accent,
  },
  blockSwitch: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
  },
  imgSwitch: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  blockTurnText: {
    fontSize: 16,
    fontWeight: 400,
  },
  imgTurn: {
    width: 9,
    height: 17,
    marginLeft: 8,
  },
  mainH1: {
    fontSize: 18,
    fontWeight: 700,
  },
};
